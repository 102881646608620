import { useCallback } from 'react'
import { useMutation } from '@apollo/client'

import { switchWorkspaceMutation } from '../api/graphql/auth-client'
import { setToken } from '../helpers'

export default function useSwitchWorkspace() {
  const [switchWorkspaceByID, { loading, error }] = useMutation(
    switchWorkspaceMutation,
  )

  const switchWorkspace = useCallback(
    async (workspaceID: string, redirectTo?: string) => {
      const { data } = await switchWorkspaceByID({
        variables: { workspaceID },
      })

      if (data && data.userAccountSettings.changeCurrentAccount.token) {
        // Update token in local storage
        const { token } = data.userAccountSettings.changeCurrentAccount
        setToken(token)

        const urlParams = new URLSearchParams(window.location.search)

        const urlWorkspaceId = urlParams.get('accountID')
        const urlLinkId = urlParams.get('linkID')

        // Reload the page
        if (redirectTo) {
          window.location.assign(redirectTo)
        } else if (urlWorkspaceId || urlLinkId) {
          let cleanedUrl = window.location.href
            // Prevent looping by removing accountID from the URL
            .replace(/accountID=([-a-z0-9]*)/i, '')
          // Report page can use linkID in the URL to apply filters
          // This should be stripped out when switching accounts
          // .replace(/linkID=([-a-z0-9]*)/i, '')

          const cleanedUrlParams = new URLSearchParams(cleanedUrl)

          if (cleanedUrlParams.size === 1) {
            cleanedUrl = cleanedUrl.replace('?&', '')
          } else if (cleanedUrlParams.size === 0) {
            cleanedUrl = cleanedUrl.replace('?', '')
          }

          window.location.replace(cleanedUrl)
        } else {
          window.location.reload()
        }
      }
    },
    [],
  )

  return { switchWorkspace, loading, error }
}
