import React, {
  DetailedHTMLProps,
  LiHTMLAttributes,
  OlHTMLAttributes,
} from 'react'
import classNames from 'classnames'

import Link from './link'
import { supportEmail } from '../core/constants'
import styles from '../styles/typography.module.scss'

interface HeadingProps {
  type?: number
  children?: React.ReactNode
  className?: string
  style?: object
  align?: 'center' | 'left' | 'right'
}

export const Heading = ({
  type,
  children,
  className,
  align = 'center',
  style = {},
  ...props
}: HeadingProps) => {
  const c = classNames(className, styles.heading)

  switch (type) {
    case 1:
      return (
        <h1
          className={c}
          style={{ ...style, textAlign: align, marginTop: 0 }}
          {...props}
        >
          {children}
        </h1>
      )
    case 2:
      return (
        <h2 className={c} style={{ ...style, textAlign: align }} {...props}>
          {children}
        </h2>
      )
    case 3:
      return (
        <h3 className={c} style={{ ...style, textAlign: align }} {...props}>
          {children}
        </h3>
      )
    case 4:
      return (
        <h4 className={c} style={{ ...style, textAlign: align }} {...props}>
          {children}
        </h4>
      )
    case 5:
      return (
        <h5 className={c} style={{ ...style, textAlign: align }} {...props}>
          {children}
        </h5>
      )
    default:
      return (
        <h3 className={c} style={{ ...style, textAlign: align }} {...props}>
          {children}
        </h3>
      )
  }
}

interface TextProps {
  className?: string
  style?: React.CSSProperties
  children: React.ReactElement | string
}

export const BoxedText = ({ className, children }: TextProps) => {
  return (
    <span className={classNames(className, styles.boxedText)}>{children}</span>
  )
}

interface NoteTextProps extends TextProps {
  label?: string
  sameLine?: boolean
}

export const NoteText = ({
  label = 'Note:',
  sameLine,
  className,
  style,
  children,
}: NoteTextProps) => {
  return (
    <p
      className={classNames(className, styles.noteText, {
        [styles.sameLine]: sameLine,
      })}
      style={style}
    >
      <strong>{label} </strong>
      {children}
    </p>
  )
}

interface ListItemProps
  extends DetailedHTMLProps<LiHTMLAttributes<HTMLLIElement>, HTMLLIElement> {
  children: React.ReactNode
}

export const TickListItem = ({
  className,
  children,
  ...props
}: ListItemProps) => {
  return (
    <li className={classNames(styles.tickListItem, className)} {...props}>
      {children}
    </li>
  )
}

interface OrderedListProps
  extends DetailedHTMLProps<
    OlHTMLAttributes<HTMLOListElement>,
    HTMLOListElement
  > {
  children: React.ReactNode
}

export const OrderedList = ({
  className,
  children,
  ...props
}: OrderedListProps) => {
  return (
    <ol className={classNames(styles.orderedList, className)} {...props}>
      {children}
    </ol>
  )
}

interface SuccessTextProps {
  color?: 'green' | 'black'
  className?: string
  children: React.ReactNode
}

export const SuccessText = ({
  color = 'green',
  className,
  children,
}: SuccessTextProps) => {
  return (
    <p
      className={classNames(styles.successText, className, {
        [styles.green]: color === 'green',
      })}
    >
      {children}
    </p>
  )
}

interface ErrorMessageProps {
  className?: string
  showSupport?: boolean
  children?: string | React.ReactNode
}

export const ErrorMessage = ({
  className,
  showSupport,
  children,
}: ErrorMessageProps) => {
  return (
    <p className={classNames(className, styles.errorMsg)}>
      <span>{children || 'We encountered an error.'}</span>{' '}
      {showSupport && (
        <span>
          Please try again later or contact support:{' '}
          <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>.
        </span>
      )}
    </p>
  )
}
