import React from 'react'

import { Label } from './input'
import Tooltip from './tooltip'
import styles from '../styles/graph-slider.module.scss'

interface Props {
  metricID: string
  value?: number
  onChange?: () => void
  type?: 'SD' | 'AE'
}

export default function GraphSlider({
  metricID,
  value,
  onChange,
  type = 'SD',
}: Props): React.ReactElement {
  // SD 1, 1.5, 2, 2.5, 3 ( 3 being least)
  // AE 15|25|50|95|99 (99 being the least)
  const values = {
    SD: [3, 2.5, 2, 1.5, 1],
    AE: [99, 95, 50, 25, 15],
  }

  const index = typeof value === 'undefined' ? 2 : values[type].indexOf(value)
  if (index === -1) {
    return <></>
  }

  return (
    <span className={styles.areaItemSlider}>
      <span className={styles.labelWrapper}>
        <Label id="range-slider">No. of anomalies</Label>
      </span>
      <span className={styles.sliderWrapper}>
        <b>less</b>
        <input
          id="range-slider"
          name="range-slider"
          type="range"
          min="1"
          max="5"
          defaultValue={index + 1}
          onMouseUp={async (e: React.MouseEvent<HTMLInputElement>) => {
            const { value: val } = e.target as HTMLInputElement
            // HACK: Tom's values need to be reversed
            const parsedIndex = parseInt(val, 10) - 1
            if (index !== parsedIndex) {
              const parsedValue = values[type][parsedIndex]
              const data: {
                metricID: string
                nStandardDeviations?: number
                tPercentile?: number
              } = {
                metricID,
                nStandardDeviations: parsedValue,
                tPercentile: parsedValue,
              }
              if (type === 'SD') {
                delete data.tPercentile
              } else if (type === 'AE') {
                delete data.nStandardDeviations
              }

              // ! V1 endpoint no longer used
              //  const res = await make.post({
              //    endpoint: 'update-key-metric',
              //    data: editMetricItem,
              //  })

              if (
                // res &&
                onChange
              ) {
                onChange()
              }
            }
          }}
        />
        <b>more</b>
      </span>
      <Tooltip
        id="sensitivity-slider-tooltip"
        useIcon
        tooltipMessage="This slider sets how sensitive the anomaly detection is and at what threshold it should send alert emails."
      />
    </span>
  )
}
