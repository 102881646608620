import React, { useState } from 'react'
import { useMutation } from '@apollo/client'

import Input from './input'
import Link from './link'
import Modal from './modal'
import { FieldSlot, FormRow } from './row'
import { sendFeedback } from '../api/graphql/onboarding-client'
import { supportEmail } from '../core/constants'
import useLogAction from '../hooks/useLogAction'
import styles from '../styles/request-new-connector-modal.module.scss'

interface RequestNewConnectorModalProps {
  toggleActive: React.Dispatch<React.SetStateAction<boolean>>
  connectorName?: string
  connectorLogo?: string
}

export default function RequestNewConnectorModal({
  toggleActive,
  connectorName,
  connectorLogo,
}: RequestNewConnectorModalProps) {
  const logAction = useLogAction()

  const [sendFeedbackMutation, { loading }] = useMutation(sendFeedback)

  const [requestData, setRequestData] = useState({
    connectorName: connectorName || '',
    additionalInfo: '',
    loading: false,
    error: false,
  })

  return (
    <>
      <Modal
        className={styles.requestConnectorModal}
        setIsOpen={toggleActive}
        modalHeader="Request a new connector"
        yesText="Send request"
        yesButtonDisabled={!requestData.connectorName}
        yesButtonLoading={loading}
        onYes={async () => {
          setRequestData({ ...requestData, error: false })

          try {
            await sendFeedbackMutation({
              variables: {
                message: 'Request new connector',
                page: '/connect',
                feedbackContext: {
                  feedbackPrompt: requestData.connectorName,
                  feedbackResponse: requestData.additionalInfo,
                },
              },
            })

            await logAction({
              variables: {
                action: 'request-new-connector',
                extra: `{"connectorName": "${requestData.connectorName}", "supportingInformation": "${requestData.additionalInfo}"}`,
                websiteSection: 'connect',
                functionName: 'requestNewConnector',
                pagePath: '/connect',
              },
            })
          } catch (err) {
            setRequestData({ ...requestData, error: true })
          } finally {
            toggleActive(false)
          }
        }}
        footerContent={
          requestData.error && (
            <p className={styles.error}>
              There was an error submitting your request. Please try again or
              contact support (
              <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>).
            </p>
          )
        }
      >
        {connectorName ? (
          <>
            <img
              className={styles.connectorLogo}
              src={connectorLogo}
              alt={connectorName}
            />
            <p>
              Thanks for showing interest in our{' '}
              <span className={styles.connectorName}>"{connectorName}"</span>{' '}
              connector.
            </p>
          </>
        ) : (
          <>
            <p>
              Let us know the name of the platform you'd like to connect to and
              we'll get back to you about enabling it.
            </p>
            <FormRow
              align="flex-start"
              vAlign="flex-start"
              last
              className={styles.formRow}
            >
              <FieldSlot column>
                <Input
                  name="connectorName"
                  label="Connector name"
                  value={requestData.connectorName}
                  onValueChange={(val) =>
                    setRequestData({ ...requestData, connectorName: val })
                  }
                  required
                  placeholder="Name of platform to connect to"
                />
              </FieldSlot>
            </FormRow>
          </>
        )}
        <FormRow
          align="flex-start"
          vAlign="flex-start"
          last
          className={styles.formRow}
        >
          <FieldSlot noPadding column>
            <Input
              name="supportingInformation"
              type="textArea"
              label="Supporting information"
              value={requestData.additionalInfo}
              onValueChange={(val) =>
                setRequestData({ ...requestData, additionalInfo: val })
              }
              placeholder="(Optional) Add supporting information on how you want to use the connector"
              multilineInput
              textAreaHeight={80}
              lineBreakAll={false}
            />
          </FieldSlot>
        </FormRow>
      </Modal>
    </>
  )
}
