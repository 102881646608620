import React from 'react'
import classNames from 'classnames'

import styles from '../styles/header-panel.module.scss'

interface Props {
  children: any
  className?: string
}

export function Panel({ children, className }: Props): React.ReactElement {
  return <div className={classNames(styles.panel, className)}>{children}</div>
}

export default function HeaderPanel({
  children,
  className,
}: Props): React.ReactElement {
  return (
    <div className={classNames(className, styles.headerPanel)}>{children}</div>
  )
}
