import React from 'react'
import classNames from 'classnames'
import { nanoid } from 'nanoid'

import styles from '../styles/anomaly-filter.module.scss'

import explainTrafficIcon from '../assets/explain-traffic-icon.svg'
import explainTrafficIconAlt from '../assets/explain-traffic-icon-alt.svg'

import explainContentIcon from '../assets/explain-content-icon.svg'
import explainContentIconAlt from '../assets/explain-content-icon-alt.svg'

import explainUserIcon from '../assets/explain-user-icon.svg'
import explainUserIconAlt from '../assets/explain-user-icon-alt.svg'

import Input from './input'

export type FilterTypes = 'all' | 'content' | 'traffic' | 'user'

export interface FilterProps {
  filter: FilterTypes
  setFilter: (string: FilterTypes) => void
  showIcon?: boolean
  tabbed?: boolean
  name?: string
}

interface DataProps {
  name: FilterTypes
  active: string
  nonActive: string
  label: string
}

const data: DataProps[] = [
  {
    name: 'content',
    active: explainContentIconAlt,
    nonActive: explainContentIcon,
    label: 'Content',
  },
  {
    name: 'traffic',
    active: explainTrafficIconAlt,
    nonActive: explainTrafficIcon,
    label: 'Traffic',
  },
  {
    name: 'user',
    active: explainUserIconAlt,
    nonActive: explainUserIcon,
    label: 'User',
  },
]

export default function Filter({
  setFilter,
  filter,
  showIcon = true,
  tabbed = false,
  name = '',
}: FilterProps): React.ReactElement {
  if (tabbed) {
    return (
      <div className={styles.tabsWrapper}>
        <Input
          name={`tabbed-filter-${name}`}
          id={nanoid()}
          label="All"
          type="radio"
          value="all"
          checked={filter === 'all'}
          onClick={(): any => {
            setFilter('all')
          }}
        />
        {data.map((item) => (
          <Input
            key={item.name}
            name={`tabbed-filter-${name}`}
            id={nanoid()}
            label={item.label}
            type="radio"
            value={item.name}
            checked={filter === item.name}
            onClick={(): any => {
              setFilter(item.name)
            }}
          />
        ))}
      </div>
    )
  }
  return (
    <div className={styles.container}>
      <button
        onClick={() => setFilter('all')}
        className={classNames(styles.button, {
          [styles.active]: filter === 'all',
        })}
      >
        <span>All</span>
      </button>
      {data.map((item) => (
        <button
          key={item.name}
          onClick={() => setFilter(item.name)}
          className={classNames(styles.button, {
            [styles.active]: filter === item.name,
          })}
        >
          {showIcon && (
            <img
              src={filter === item.name ? item.active : item.nonActive}
              className={styles.explainIcon}
              alt={item.name}
            />
          )}
          <span>{item.label}</span>
        </button>
      ))}
    </div>
  )
}
