import React from 'react'
import classNames from 'classnames'
import numeral from 'numeraljs'

import { Preloader } from './loader'
import styles from '../styles/usage-report-callouts.module.scss'

interface UsageReportCalloutsProps {
  successMetric: string
  data?: number | null
  dateRange: string
  loading: boolean
}

export default function UsageReportCallouts({
  successMetric,
  data,
  dateRange,
  loading,
}: UsageReportCalloutsProps): React.ReactElement | null {
  return (
    <div
      className={classNames(styles.metricDataBannerBox, {
        [styles.isEmpty]: data === null || data === undefined,
      })}
    >
      {loading || data === null || data === undefined ? (
        <Preloader className={styles.loader} />
      ) : (
        <>
          <h2>Total {successMetric}</h2>
          <h3>{numeral(data || 0).format('0,0.[0]a')} </h3>
          <h4>{dateRange}</h4>
        </>
      )}
    </div>
  )
}
