import React from 'react'

import styles from '../styles/helpful.module.scss'

interface HelpfulProps {
  onClickYes: any
  onClickNo: any
}
export default function Helpful({
  onClickYes,
  onClickNo,
}: HelpfulProps): React.ReactElement {
  return (
    <div className={styles.helpfulWrapper}>
      <span className={styles.helpfulTitle}>Helpful?</span>
      <button
        className={styles.yes}
        type="button"
        aria-label="next"
        onClick={onClickYes}
      >
        <span>Yes</span>
      </button>
      <button
        className={styles.no}
        type="button"
        aria-label="next"
        onClick={onClickNo}
      >
        <span>No</span>
      </button>
    </div>
  )
}
