export const timeOfDayGreeting = (date: Date): string => {
  const hour = date.getHours()
  switch (true) {
    case hour >= 12 && hour < 18:
      return 'Good afternoon'
    case hour >= 18 || hour <= 3:
      return 'Good evening'
    default:
      return 'Good morning'
  }
}

export const secondsToHms = (d: number) => {
  const h = Math.floor(d / 3600)
  const m = Math.floor((d % 3600) / 60)
  const s = Math.floor((d % 3600) % 60)

  const hDisplay = h > 0 ? h + (h === 1 ? 'h ' : 'h ') : ''
  const mDisplay = m > 0 ? m + (m === 1 ? 'm ' : 'm ') : ''
  const sDisplay = s > 0 ? s + (s === 1 ? 's' : 's') : ''

  return hDisplay + mDisplay + sDisplay
}
