import React from 'react'

import ConnectButton from './connect-button'
import Row, { Slot } from './row'
import styles from '../styles/connect-analytics-blocker.module.scss'

interface ConnectAnalyticsBlockerProps {
  reconnect?: boolean
  includeAdobe?: boolean
}

export default function ConnectAnalyticsBlocker({
  reconnect,
  includeAdobe = true,
}: ConnectAnalyticsBlockerProps) {
  return (
    <Row style={{ marginTop: '60px', marginBottom: '150px' }}>
      <Slot width={580} className={styles.moduleBox}>
        <h1 className={styles.title}>Get automated insights</h1>
        <p className={styles.par}>
          Connect to your analytics to import metrics, create campaign reports
          and find insights.
        </p>
        <ConnectButton reconnect={reconnect} />
        {includeAdobe && <ConnectButton adobe reconnect={reconnect} />}
      </Slot>
    </Row>
  )
}
