import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { nanoid } from 'nanoid'

import { unsubscribeMarketing } from '../api/REST/account-client'
import Button from '../components/button'
import SiteLogo, { LogoTagline } from '../components/site-logo'
import { setToken } from '../helpers'
import styles from '../styles/generic-page.module.scss'

interface Props {
  match: any
}

export function SetToken({ token }: { token: string }): void {
  setToken(token)
  // @ts-ignore
  if (window.dataLayer && window.dataLayer.push) {
    // @ts-ignore
    window.dataLayer.push({ event: 'user-marketing-optout', token })
  }
}
export default function MarketingOptOut({ match }: Props): React.ReactElement {
  const { userId } = match.params

  const history = useHistory()

  useEffect(() => {
    const optout = async () => {
      await unsubscribeMarketing({ uid: userId })
    }

    optout()
  }, [])

  return (
    <div className={styles.ssoWrapperOuter}>
      <section className={styles.ssoWrapper}>
        <SiteLogo link className={styles.svg} />
        <LogoTagline />
        <div className={styles.box}>
          <p>
            You have successfully been unsubscribed from product update emails.
          </p>
        </div>
        <Button
          onPress={() =>
            history.push({
              pathname: '/login',
              key: nanoid(),
              state: { applied: true },
            })
          }
        >
          Login
        </Button>
      </section>
    </div>
  )
}
