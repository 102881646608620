import React, { useEffect, useMemo, useState } from 'react'
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client'

import { currentUserDetails, linkOrCode } from '../api/apollo/variables'
import {
  fetchEloquaPicklists,
  getEloquaOrgList,
  getIntegrationsStatus,
  saveEloquaBasicKey,
  updateEloquaOrgDisplayName,
  updateEloquaPicklists,
} from '../api/graphql/integrations-client'
import { getCampaignCodeGenerator } from '../api/graphql/track-create-client'
import Button from '../components/button'
import Input, { ClickEditInput, Label } from '../components/input'
import Link from '../components/link'
import { Preloader } from '../components/loader'
import { FieldSlot, FormRow, LabelSlot } from '../components/row'
import SelectBox, { SelectBoxChecklist } from '../components/select-box'
import Tooltip from '../components/tooltip'
import { BoxedText } from '../components/typography'
import { dynamicTextValues, supportEmail } from '../core/constants'
import { isAdminUser, isSupportUser } from '../helpers'
import styles from '../styles/integrations-settings.module.scss'

export const EloquaConnectorSettings = () => {
  const { userPermission } = useReactiveVar(currentUserDetails)

  const linkCopy = useReactiveVar(linkOrCode)

  const {
    data: connectorData,
    loading: loadingConnectorStatus,
    error: errorConnectorStatus,
  } = useQuery(getIntegrationsStatus)

  // Can connect to multiple Eloqua orgs: Load list of connected orgs
  const [
    getOrgList,
    { data: eloquaOrgsData, loading: loadingOrgList, error: orgListError },
  ] = useLazyQuery(getEloquaOrgList)
  const [
    getDropdownParams,
    {
      data: dropdownParamsData,
      loading: loadingDropdownParams,
      error: dropdownParamsError,
    },
  ] = useLazyQuery(getCampaignCodeGenerator)
  const [getEloquaPicklists, { data: eloquaPicklistsData }] = useLazyQuery(
    fetchEloquaPicklists,
  )
  const [setEloquaPicklists, { loading: setPicklistsLoading }] = useMutation(
    updateEloquaPicklists,
  )

  const [selectedEloquaOrg, setSelectedEloquaOrg] = useState<{
    eloquaOrgDisplayName: string
    eloquaOrgID: string
    eloquaOrgName: string
  } | null>(null)
  const [selectedDropdowns, setSelectedDropdowns] = useState<string[]>([])
  const [hasBeenUpdated, setHasBeenUpdated] = useState(false)
  const [updateSuccess, setUpdateSuccess] = useState(false)
  const [updateError, setUpdateError] = useState(false)

  // Check if the connector is already active
  const connectorStatus = useMemo(() => {
    if (!connectorData) return 'inactive'

    return connectorData.currentCompany.eloquaIntegrationStatus
  }, [connectorData])

  const eloquaOrgsList = useMemo(() => {
    if (!eloquaOrgsData) return []

    return eloquaOrgsData.track.eloquaQueries.getConnectedEloquaOrgs
      .eloquaOrgList
  }, [eloquaOrgsData])

  const dropdownParamsList = useMemo(() => {
    if (!dropdownParamsData) return []

    return dropdownParamsData.campaignCodeGenerator.paramDefs
      .filter(({ fieldType }) => fieldType === 'select')
      .map(({ fieldID, fieldName, helpText }) => ({
        value: fieldID,
        label: fieldName,
        tooltip: helpText,
      }))
  }, [dropdownParamsData])

  // If connection exists, get connected org list
  useEffect(() => {
    if (connectorStatus !== 'active' || !isAdminUser(userPermission)) return

    getOrgList()
    getDropdownParams()
  }, [userPermission, connectorStatus])

  // If only one org is connected, use that for the parameter dropdown syncer
  useEffect(() => {
    if (eloquaOrgsList.length === 1) {
      setSelectedEloquaOrg({ ...eloquaOrgsList[0] })

      getEloquaPicklists({
        variables: {
          eloquaOrgID: eloquaOrgsList[0].eloquaOrgID,
        },
      })
    }
  }, [eloquaOrgsList])

  useEffect(() => {
    if (!hasBeenUpdated && eloquaPicklistsData) {
      setSelectedDropdowns(
        eloquaPicklistsData.currentCompany.eloquaIntegrationData
          ?.parameterIDList || [],
      )
    }
  }, [hasBeenUpdated, eloquaPicklistsData])

  if (loadingConnectorStatus || loadingOrgList || loadingDropdownParams) {
    return <Preloader />
  }

  if (errorConnectorStatus || orgListError || dropdownParamsError) {
    return (
      <p className={styles.errorMsg}>
        There was an error connecting to Eloqua. Please contact support (
        <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>
        ).
      </p>
    )
  }

  return (
    <>
      {connectorStatus === 'active' ? (
        <>
          <p className={styles.tenantSuccess}>
            Your Eloqua account has been connected. You can now update email
            HTML on the{' '}
            <BoxedText>
              <Link href="/track/create-links">
                {dynamicTextValues.trackCreatePage[linkCopy]}
              </Link>
            </BoxedText>{' '}
            page.
          </p>
          <div className={styles.divider} />
          {dropdownParamsList.length > 0 && (
            <>
              <p>
                You can sync your Eloqua picklists with our dropdown parameters.
                {eloquaOrgsList.length > 1
                  ? ' Select one of your Eloqua organisations below and then pick which dropdowns to sync.'
                  : ' Choose which parameters to sync below.'}
              </p>
              {updateError && (
                <p className={styles.errorMsg}>
                  There was an error connecting to Eloqua. Please contact
                  support (
                  <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>
                  ).
                </p>
              )}
              {updateSuccess && (
                <p className={styles.tenantSuccess}>
                  Your Eloqua picklists have been updated.
                </p>
              )}
              {eloquaOrgsList.length > 1 && (
                <FormRow className={styles.addTenantFormRow}>
                  <LabelSlot noPadding>
                    <Label id="eloqua-org-name">
                      Eloqua organisation to sync
                    </Label>
                  </LabelSlot>
                  <FieldSlot noPadding>
                    <SelectBox
                      id="eloqua-org-name"
                      labelKey="eloquaOrgDisplayName"
                      valueKey="eloquaOrgID"
                      isClearable
                      value={selectedEloquaOrg}
                      options={eloquaOrgsList}
                      onChange={(newOption) => {
                        setSelectedEloquaOrg(newOption)

                        if (newOption) {
                          getEloquaPicklists({
                            variables: {
                              eloquaOrgID: newOption.eloquaOrgID,
                            },
                          })
                        }
                      }}
                    />
                  </FieldSlot>
                </FormRow>
              )}
              {selectedEloquaOrg && (
                <FormRow className={styles.addTenantFormRow}>
                  <LabelSlot noPadding>
                    <Label id="eloqua-picklist-selector">
                      <Tooltip
                        id="eloqua-picklist-selector-tooltip"
                        useIcon
                        tooltipMessage="If ticked these drop-downs will be added and synced with your Eloqua account."
                      >
                        Dropdown parameters to sync
                      </Tooltip>
                    </Label>
                  </LabelSlot>
                  <FieldSlot noPadding>
                    <div className={styles.picklistInputs}>
                      <SelectBoxChecklist
                        id="eloqua-picklist-selector"
                        className={styles.picklist}
                        isSearchable
                        allLabel="All"
                        menuPosition="fixed"
                        value={dropdownParamsList.filter(({ value }) =>
                          selectedDropdowns.includes(value),
                        )}
                        options={dropdownParamsList}
                        onChange={(newOptions) => {
                          setSelectedDropdowns(
                            newOptions.map(({ value }) => value),
                          )
                          setHasBeenUpdated(true)
                        }}
                      />
                      <Button
                        className={styles.submitButton}
                        type="submit"
                        style={{ width: '100%' }}
                        loading={setPicklistsLoading}
                        isDisabled={
                          !hasBeenUpdated ||
                          !selectedEloquaOrg ||
                          !selectedDropdowns ||
                          selectedDropdowns.length === 0
                        }
                        onPress={async () => {
                          try {
                            setUpdateError(false)

                            if (!selectedEloquaOrg) {
                              return
                            }

                            await setEloquaPicklists({
                              variables: {
                                eloquaOrgID: selectedEloquaOrg.eloquaOrgID,
                                parameterIDList: selectedDropdowns,
                              },
                            })

                            setUpdateSuccess(true)
                          } catch {
                            setUpdateError(true)
                          }
                        }}
                      >
                        Sync Eloqua picklists
                      </Button>
                    </div>
                  </FieldSlot>
                </FormRow>
              )}
            </>
          )}
        </>
      ) : (
        <p>
          Your account is not connected to Eloqua. Click 'Connect' below and we
          will begin the setup process for you.
        </p>
      )}
    </>
  )
}

export const EloquaConnectorSupport = () => {
  const { userPermission } = useReactiveVar(currentUserDetails)

  const linkCopy = useReactiveVar(linkOrCode)

  const {
    data: connectorData,
    loading: loadingConnectorStatus,
    error: errorConnectorStatus,
  } = useQuery(getIntegrationsStatus)

  // Can connect to multiple Eloqua orgs: Load list of connected orgs
  const [
    getOrgList,
    { data: eloquaOrgsData, loading: loadingOrgList, error: orgListError },
  ] = useLazyQuery(getEloquaOrgList)

  const [
    authenticateEloqua,
    { loading: authenticatingEloqua, error: authError },
  ] = useMutation(saveEloquaBasicKey)
  const [updateOrgDisplayName] = useMutation(updateEloquaOrgDisplayName)

  const [eloquaAccountDetails, setEloquaAccountDetails] = useState({
    companyName: '',
    userName: '',
    eloquaPassword: '',
  })
  const [isPassword, setIsPassword] = useState(true)

  // Check if the connector is already active
  const connectorStatus = useMemo(() => {
    if (!connectorData) return 'inactive'

    return connectorData.currentCompany.eloquaIntegrationStatus
  }, [connectorData])

  const eloquaOrgsList = useMemo(() => {
    if (!eloquaOrgsData) return []

    return eloquaOrgsData.track.eloquaQueries.getConnectedEloquaOrgs
      .eloquaOrgList
  }, [eloquaOrgsData])

  // If connection exists, get connected org list
  useEffect(() => {
    if (connectorStatus !== 'active' || !isSupportUser(userPermission)) return

    getOrgList()
  }, [userPermission, connectorStatus])

  // Only Uplfiter admins should be able to connect to Eloqua
  if (!isSupportUser(userPermission)) {
    return null
  }

  if (loadingConnectorStatus || loadingOrgList) {
    return <Preloader />
  }

  if (errorConnectorStatus || orgListError || authError) {
    return (
      <p className={styles.errorMsg}>
        There was an error connecting to Eloqua. Please try again later or
        contact support (
        <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>
        ).
      </p>
    )
  }

  return (
    <>
      {connectorStatus === 'active' && (
        <>
          <p className={styles.tenantSuccess}>
            Your Eloqua account has been connected. You can now update email
            HTML on the{' '}
            <BoxedText>
              <Link href="/track/create-links">
                {dynamicTextValues.trackCreatePage[linkCopy]}
              </Link>
            </BoxedText>{' '}
            page.
          </p>
          <div className={styles.divider} />
        </>
      )}
      {eloquaOrgsList.length > 0 && (
        <>
          {eloquaOrgsList.map(
            ({ eloquaOrgDisplayName, eloquaOrgID, eloquaOrgName }, index) => {
              return (
                <React.Fragment key={eloquaOrgID}>
                  {index > 0 && <div className={styles.divider} />}
                  <ClickEditInput
                    id={`displayName-${index}`}
                    className={styles.editTenantField}
                    value={eloquaOrgDisplayName}
                    onChange={async (val) => {
                      await updateOrgDisplayName({
                        variables: {
                          eloquaOrgID,
                          eloquaDisplayName: val,
                        },
                        refetchQueries: [getEloquaOrgList],
                      })
                    }}
                  />
                  <ul style={{ margin: 0 }}>
                    <li>
                      <strong>Org ID:</strong> {eloquaOrgID}
                    </li>
                    <li>
                      <strong>Org Name:</strong> {eloquaOrgName}
                    </li>
                  </ul>
                </React.Fragment>
              )
            },
          )}
          <div className={styles.divider} />
        </>
      )}
      <h4>Connect a new Eloqua organistion</h4>
      <form
        onSubmit={async (e) => {
          e.preventDefault()

          const { companyName, userName, eloquaPassword } = eloquaAccountDetails

          if (!companyName || !userName || !eloquaPassword) return

          await authenticateEloqua({
            variables: {
              companyName,
              userName,
              eloquaPassword,
            },
            refetchQueries: [getEloquaOrgList],
          })

          setEloquaAccountDetails({
            companyName: '',
            userName: '',
            eloquaPassword: '',
          })
        }}
      >
        <FormRow className={styles.addTenantFormRow}>
          <LabelSlot noPadding>
            <Label id="companyName">
              <Tooltip
                id="company-name-tooltip"
                useIcon
                tooltipMessage="The company name shown for your Eloqua account."
              >
                Company name
              </Tooltip>
            </Label>
          </LabelSlot>
          <FieldSlot noPadding>
            <Input
              name="companyName"
              type="text"
              label="Company name"
              required
              value={eloquaAccountDetails.companyName}
              onValueChange={(val) =>
                setEloquaAccountDetails({
                  ...eloquaAccountDetails,
                  companyName: val,
                })
              }
            />
          </FieldSlot>
        </FormRow>
        <FormRow className={styles.addTenantFormRow}>
          <LabelSlot noPadding>
            <Label id="userName">
              <Tooltip
                id="user-name-tooltip"
                useIcon
                tooltipMessage="The user name shown for your Eloqua account."
              >
                User name
              </Tooltip>
            </Label>
          </LabelSlot>
          <FieldSlot noPadding>
            <Input
              name="userName"
              type="text"
              label="User name"
              required
              value={eloquaAccountDetails.userName}
              onValueChange={(val) =>
                setEloquaAccountDetails({
                  ...eloquaAccountDetails,
                  userName: val,
                })
              }
            />
          </FieldSlot>
        </FormRow>
        <FormRow className={styles.addTenantFormRow}>
          <LabelSlot noPadding>
            <Label id="eloquaPassword">
              <Tooltip
                id="eloqua-password-tooltip"
                useIcon
                tooltipMessage="The password you use to log in to Eloqua."
              >
                Eloqua password
              </Tooltip>
            </Label>
          </LabelSlot>
          <FieldSlot noPadding className={styles.passwordField}>
            <Input
              name="eloquaPassword"
              type={isPassword ? 'password' : 'text'}
              label="Eloqua password"
              required
              value={eloquaAccountDetails.eloquaPassword}
              onValueChange={(val) =>
                setEloquaAccountDetails({
                  ...eloquaAccountDetails,
                  eloquaPassword: val,
                })
              }
            />
            <Button
              variant="text"
              color="grey"
              className={styles.showPassword}
              onPress={() => setIsPassword(!isPassword)}
            >
              {isPassword ? 'Show' : 'Hide'}
            </Button>
          </FieldSlot>
        </FormRow>

        <Button
          type="submit"
          style={{ width: '100%' }}
          loading={authenticatingEloqua}
          isDisabled={
            !eloquaAccountDetails.companyName ||
            !eloquaAccountDetails.userName ||
            !eloquaAccountDetails.eloquaPassword
          }
        >
          Connect to Eloqua
        </Button>
      </form>
    </>
  )
}
