import React, { useMemo, useEffect, useState } from 'react'
import { useLazyQuery, useReactiveVar } from '@apollo/client'
import moment from 'moment'
import classNames from 'classnames'

import AddMultiValuesTags from './add-multi-values-tags'
import Button from './button'
import Input, { Label, PassiveInput } from './input'
import { Preloader } from './loader'
import Modal from './modal'
import { FormRow, LabelSlot, FieldSlot } from './row'
import { SelectBoxSimple } from './select-box'
import Tooltip from './tooltip'
import { explainAnomalyDataReactive } from '../api/apollo/legacy-reducers'
import { currentUserDetails } from '../api/apollo/variables'
import { getAccountUsers } from '../api/graphql/workspace-client'
import ShareIcon from '../assets/icon-share-white.svg'
import ShareIconHover from '../assets/icon-share.svg'
import { sharingPermissionTypes } from '../core/constants'
import styles from '../styles/share-anomaly-button.module.scss'

/* **************************************************************** */
/* Old types used in V1 API                                         */
/* **************************************************************** */

// interface CreateSharingLink {
//   sharingPermission: 'public' | 'private'
//   originUrl: string
// }

// interface UpdateSharingLink {
//   originUrl: string
//   shareLinkID: string
//   sharingPermission: 'public' | 'private' | 'off'
// }

// interface GetSharingLinkData {
//   shareLinkID: string
// }

// interface ShareSharingLink {
//   shareLinkID: string
//   shareEmails: string[]
//   note?: string
//   pageData: {
//     anomDate: string
//     metric: string
//     siteSection: string
//   }
// }

/* **************************************************************** */
/* **************************************************************** */

export function getShareEmails(shareEmails, typedValue): string[] {
  if (typedValue === '') {
    return shareEmails
  }
  if (shareEmails.length > 0) {
    if (shareEmails[shareEmails.length - 1] === typedValue) {
      return shareEmails
    }
    return [...shareEmails, ...[typedValue]]
  }
  return [typedValue]
}

type PermissionType = 'private' | 'public' | 'off'

const ShareAnomalyButton = () => {
  const { workspaceID } = useReactiveVar(currentUserDetails)

  const explainAnomalyData = useReactiveVar(explainAnomalyDataReactive)

  const [getAccountProfiles, { data: accountProfilesData }] = useLazyQuery(
    getAccountUsers,
  )

  const [sharingLinkData, setSharingLinkData] = useState({
    sharingPermission: '',
    publicLink: '',
    shared: false,
  })
  // const [submissionStatus, setSubmissionStatus] = useState<'' | 'success' | 'failed'>('')
  const [active, setActive] = useState(false)
  const [loading, setLoading] = useState(false)
  const [typedValue, setTypedValue] = useState('')
  const [shareEmails, setShareEmails] = useState<string[]>([])
  const [note, setNote] = useState('')
  const [permission, setPermission] = useState<PermissionType>('private')
  const { shareLinkID, displayName, anomalyDate } =
    explainAnomalyData?.description || {}

  // Fetch all users
  useEffect(() => {
    if (workspaceID) {
      getAccountProfiles({
        variables: {
          accountID: workspaceID,
        },
      })
    }
  }, [workspaceID])

  useEffect(() => {
    if (shareLinkID) {
      // eslint-disable-next-line
      ;(async function getSharingDetails() {
        // ! V1 endpoint no longer used
        // const req = await make.get({
        //   endpoint: `get-sharing-link-data/${shareLinkID}`,
        // })

        setSharingLinkData({
          ...sharingLinkData,
          // ...result
        })
        // const { sharingPermission } = result

        // setPermission(sharingPermission)
      })()
    }
  }, [shareLinkID])

  useEffect(() => {
    if (
      shareLinkID === '' &&
      (permission === 'public' || permission === 'private')
    ) {
      // eslint-disable-next-line @typescript-eslint/no-extra-semi
      ;(async function getContent() {
        // ! V1 endpoint no longer used
        // const req = await make.post({
        //   endpoint: 'create-sharing-link',
        //   data: {
        //     sharingPermission: permission,
        //     originUrl,
        //   },
        // })

        setSharingLinkData({
          ...sharingLinkData,
          // ...result
        })
      })()
    } else if (
      sharingLinkData.sharingPermission !== '' &&
      sharingLinkData.sharingPermission !== permission
    ) {
      // eslint-disable-next-line @typescript-eslint/no-extra-semi
      ;(async function getContent() {
        // const { origin, pathname } = window.location
        // const originUrl = `${origin}${pathname}`

        // ! V1 endpoint no longer used
        // const req = await make.post({
        //   endpoint: 'update-sharing-link',
        //   data: {
        //     sharingPermission: permission,
        //     shareLinkID,
        //     originUrl,
        //   },
        // })

        setSharingLinkData({
          ...sharingLinkData,
          sharingPermission: permission,
        })
      })()
    }
  }, [permission])

  const userList = useMemo(() => {
    if (!accountProfilesData) return []

    return accountProfilesData.account.userAccountProfiles.map(
      ({ userEmail }) => userEmail,
    )
  }, [accountProfilesData])

  const { sharingPermission, publicLink, shared } = sharingLinkData

  return (
    <div className={styles.container}>
      <div className={styles.buttonWrapper}>
        {sharingPermission && (
          <div className={styles.buttonStatus}>
            <span
              className={classNames({
                [styles.private]: sharingPermission === 'private',
                [styles.public]: sharingPermission !== 'public',
              })}
            >
              public
            </span>
          </div>
        )}
        <Button
          icon={{
            src: ShareIcon,
            alt: 'Share',
            iconAfter: true,
            hoverImg: ShareIconHover,
            imgHeight: 18,
          }}
          onPress={() => {
            setActive(true)
            setTypedValue('')
            setShareEmails([])
            setSharingLinkData({
              ...sharingLinkData,
              shared: false,
            })
          }}
        >
          Share
        </Button>
      </div>
      {shared && active && (
        <Modal
          setIsOpen={setActive}
          modalHeader="Share anomaly"
          yesText="OK"
          onYes={() => setActive(false)}
        >
          <p>Success! Your anomaly has been sent.</p>
        </Modal>
      )}
      {!shared && active && (
        <Modal
          setIsOpen={setActive}
          modalHeader="Share anomaly"
          yesText={permission === 'off' ? 'Share' : 'Share'}
          yesButtonDisabled={
            getShareEmails(shareEmails, typedValue).filter((i) => i !== '')
              .length === 0
          }
          onYes={(): void => {
            // eslint-disable-next-line
            ;(async function getContent() {
              const useShareEmails = getShareEmails(
                shareEmails,
                typedValue,
              ).filter((i) => i !== '' && i.indexOf('@') !== -1)
              if (useShareEmails.length === 0) {
                setLoading(false)
                return
              }
              const data = {
                pageData: {
                  anomDate: moment(anomalyDate, 'YYYY-MM-DD').format(
                    'Do MMM YYYY',
                  ),
                  metric: displayName,
                  siteSection: 'explain',
                },
                shareLinkID,
                shareEmails: useShareEmails,
                note,
              }
              if (shareLinkID === '' && publicLink !== '') {
                const sl = publicLink.split('/').pop()
                data.shareLinkID = sl
              }

              // ! V1 endpoint no longer used
              // await make.post({
              //   endpoint: 'share-sharing-link',
              //   data: {
              //     ...sharingLinkData,
              //     sharingPermission: permission,
              //     shared: true,
              //   },
              // })

              setSharingLinkData({
                ...sharingLinkData,
                sharingPermission: permission,
                shared: true,
              })
              setLoading(false)
            })()
          }}
        >
          <div className={classNames({ [styles.loading]: loading })}>
            {loading && (
              <div className={styles.preloader}>
                <Preloader style={{ height: 45, width: 60 }} />
              </div>
            )}
            <FormRow last>
              <LabelSlot noPadding column>
                <Label id="generated-link" modalHeading>
                  Share link
                </Label>
              </LabelSlot>
              <FieldSlot noPadding column>
                <PassiveInput
                  id="generated-link"
                  value={typeof publicLink === 'undefined' ? '' : publicLink}
                  copy
                />
                <p className={styles.footNote}>
                  This link is currently {sharingLinkData.sharingPermission}.
                </p>
              </FieldSlot>
            </FormRow>
            <FormRow last>
              <LabelSlot noPadding column>
                <Label id="permission" modalHeading>
                  Who can see this
                </Label>
              </LabelSlot>
              <FieldSlot noPadding column>
                <SelectBoxSimple
                  key={permission}
                  name="permission"
                  value={permission}
                  className={styles.selectBox}
                  onChange={(permissionType) => {
                    setPermission(permissionType as PermissionType)
                  }}
                >
                  {Object.keys(sharingPermissionTypes).map((key) => {
                    const item = sharingPermissionTypes[key]
                    return (
                      <option value={key} key={key}>
                        {item.long}
                      </option>
                    )
                  })}
                </SelectBoxSimple>
              </FieldSlot>
            </FormRow>
            <FormRow last>
              <LabelSlot noPadding column>
                <Label id="share-with" modalHeading>
                  <Tooltip
                    id="share-with-tooltip"
                    useIcon
                    tooltipMessage="Add multiple users by separating email addresses with commas."
                  >
                    Share with via email
                  </Tooltip>
                </Label>
              </LabelSlot>
              <FieldSlot noPadding column>
                <AddMultiValuesTags
                  type="modal"
                  placeholder="Add email or find teammates"
                  possibleValues={userList}
                  enableCommaMultiItems
                  onKeyUp={(val: string) => {
                    setTypedValue(val)
                  }}
                  onChange={(val: string[]) => {
                    setShareEmails(val)
                  }}
                />
              </FieldSlot>
            </FormRow>
            <FormRow last>
              <LabelSlot noPadding column>
                <Label id="note" modalHeading optional="(optional)">
                  Note
                </Label>
              </LabelSlot>
              <FieldSlot noPadding column>
                <Input
                  id="note"
                  name="note"
                  type="textArea"
                  value={note}
                  onChange={(val: string): void => {
                    setNote(val)
                  }}
                />
              </FieldSlot>
            </FormRow>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default ShareAnomalyButton
