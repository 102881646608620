import React, { useMemo, useEffect, useCallback } from 'react'
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client'
import numeral from 'numeraljs'

import AddMultiValuesTags from './add-multi-values-tags'
import Input, { Label } from './input'
import Modal from './modal'
import { FormRow, LabelSlot, FieldSlot } from './row'
import Tooltip from './tooltip'
import { SuccessText } from './typography'
import { currentUserDetails, linkOrCode } from '../api/apollo/variables'
import { getAccountUsers } from '../api/graphql/workspace-client'
import { shareCodesFromList } from '../api/graphql/track-actions-client'
import { getShareEmails } from '../helpers/track-module'
import useLogAction from '../hooks/useLogAction'

export interface ShareModalState {
  active: boolean
  shared: boolean
  typedValue: string
  shareEmails: string[]
  note: string
  subject: string
}

interface ShareCampaignCodesModalProps {
  selectedCodes: string[]
  shareModalState: ShareModalState
  setShareModalState: React.Dispatch<React.SetStateAction<ShareModalState>>
}

const ShareCampaignCodesModal = ({
  selectedCodes,
  shareModalState,
  setShareModalState,
}: ShareCampaignCodesModalProps) => {
  const { shareEmails, typedValue, subject, note, shared } = shareModalState

  const { userFirstName, userLastName, workspaceID } = useReactiveVar(
    currentUserDetails,
  )

  const referToLinks = useReactiveVar(linkOrCode)

  const logAction = useLogAction()

  const [getAccountProfiles, { data: accountProfilesData }] = useLazyQuery(
    getAccountUsers,
  )

  const [
    shareCodes,
    { loading: shareCodesLoading, error: shareCodesError },
  ] = useMutation(shareCodesFromList)

  useEffect(() => {
    if (workspaceID) {
      getAccountProfiles({ variables: { accountID: workspaceID } })
    }
  }, [workspaceID])

  const userList = useMemo(() => {
    if (!accountProfilesData) return []

    return accountProfilesData.account.userAccountProfiles.map(
      (user) => user.userEmail,
    )
  }, [accountProfilesData])

  const shareCodesAction = useCallback(async () => {
    const emailsToShare = getShareEmails(shareEmails, typedValue).filter(
      (i) => i !== '' && i.indexOf('@') !== -1,
    )

    if (emailsToShare.length === 0) return

    const data = {
      subject: subject === '' ? 'Campaign links' : subject,
      mergeStats: false,
      emailList: emailsToShare,
      codeList: [...selectedCodes],
      note,
    }

    await shareCodes({ variables: data })

    logAction({
      variables: {
        action: 'button-share-codes',
        extra: JSON.stringify({
          users: emailsToShare.length,
          codes: selectedCodes.length,
          note,
          subject: data.subject,
        }),
        websiteSection: 'track',
        functionName: 'shareCodes',
        pagePath: '/track',
      },
    })
  }, [shareEmails, note, subject])

  return (
    <Modal
      setIsOpen={() =>
        setShareModalState({
          active: false,
          shared: false,
          typedValue: '',
          shareEmails: [],
          note: '',
          subject: '',
        })
      }
      modalHeader={`Share ${referToLinks}${
        selectedCodes.length > 1 ? 's' : ''
      }`}
      noText={!shareCodesError && !shared ? 'Cancel' : 'Back'}
      yesButtonLoading={shareCodesLoading}
      yesButtonDisabled={
        !shareCodesError && !shared
          ? getShareEmails(shareEmails, typedValue).filter(
              (i) => i !== '' && i.indexOf('@') !== -1,
            ).length === 0
          : undefined
      }
      yesText={!shareCodesError ? <>{shared ? 'OK' : 'Share'}</> : undefined}
      onYes={
        // eslint-disable-next-line no-nested-ternary
        !shareCodesError
          ? shared
            ? () =>
                setShareModalState({
                  active: false,
                  shared: false,
                  typedValue: '',
                  shareEmails: [],
                  note: '',
                  subject: '',
                })
            : async () => {
                await shareCodesAction()

                setShareModalState((curr) => ({
                  ...curr,
                  shared: true,
                }))
              }
          : undefined
      }
    >
      {shareCodesError ? (
        <p>There was an error sharing your codes. Please try again later.</p>
      ) : (
        <>
          {shared ? (
            <SuccessText>
              Success! Your {numeral(selectedCodes.length).format('0,0')}{' '}
              selected {referToLinks}
              {selectedCodes.length > 1 ? 's have' : ' has'} been sent.
            </SuccessText>
          ) : (
            <>
              <p>
                Send an email with an attached .csv file containing your{' '}
                {numeral(selectedCodes.length).format('0,0')} selected campaign
                link
                {selectedCodes.length > 1 ? 's' : ''}.
              </p>
              <div>
                <FormRow last>
                  <LabelSlot noPadding column>
                    <Label id="share-with" modalHeading>
                      <Tooltip
                        id="share-with-tooltip"
                        useIcon
                        tooltipMessage="Add multiple users by separating email addresses with commas."
                      >
                        Share with
                      </Tooltip>
                    </Label>
                  </LabelSlot>
                  <FieldSlot noPadding column>
                    <AddMultiValuesTags
                      type="modal"
                      placeholder="Add email or find teammates"
                      possibleValues={userList}
                      enableCommaMultiItems
                      onKeyUp={(val) =>
                        setShareModalState((curr) => ({
                          ...curr,
                          typedValue: val,
                        }))
                      }
                      onChange={(val) =>
                        setShareModalState((curr) => ({
                          ...curr,
                          shareEmails: val,
                        }))
                      }
                    />
                  </FieldSlot>
                </FormRow>
                <FormRow last>
                  <LabelSlot noPadding column>
                    <Label id="subject" modalHeading>
                      <Tooltip
                        id="email-subject-tooltip"
                        useIcon
                        tooltipMessage="This is the subject line of the email."
                      >
                        Email subject
                      </Tooltip>
                    </Label>
                  </LabelSlot>
                  <FieldSlot noPadding column>
                    <Input
                      id="subject"
                      name="subject"
                      type="text"
                      value={subject}
                      placeholder="Campaign links"
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        const { value: val } = event.target

                        setShareModalState((curr) => ({
                          ...curr,
                          subject: val,
                        }))
                      }}
                    />
                  </FieldSlot>
                </FormRow>
                <FormRow last>
                  <LabelSlot noPadding column>
                    <Label id="note" modalHeading>
                      <Tooltip
                        id="email-note-tooltip"
                        useIcon
                        tooltipMessage="This text will be included in the email."
                      >
                        Email note
                      </Tooltip>
                    </Label>
                  </LabelSlot>
                  <FieldSlot noPadding column>
                    <Input
                      id="note"
                      name="note"
                      type="textArea"
                      value={note}
                      placeholder={`${userFirstName} ${userLastName} would like you to use the attached campaign codes.`}
                      onChange={(val: string) => {
                        setShareModalState((curr) => ({
                          ...curr,
                          note: val,
                        }))
                      }}
                    />
                  </FieldSlot>
                </FormRow>
              </div>
            </>
          )}
        </>
      )}
    </Modal>
  )
}

export default ShareCampaignCodesModal
