import React, { useState, useMemo, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  XYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalGridLines,
  LineSeries,
  AreaSeries,
  CustomSVGSeries,
  Crosshair,
  Hint,
} from 'react-vis'
import numeral from 'numeraljs'
import moment from 'moment'
import { nanoid } from 'nanoid'
import classNames from 'classnames'
import ReactMarkdown from 'react-markdown'

import '../styles/graph-vis.scss'

import AnomalyDiff from './anomaly-diff'
import { TagsWrapper, Tag } from './add-multi-values-tags'
import Button from './button'
import { Preloader } from './loader'
import explainedPNG from '../assets/icon-explained-white-bg.png'
import notInterestingPNG from '../assets/icon-grey-dot.svg'
import iconWarning from '../assets/icon-warning.svg'
import iconWarningGreen from '../assets/icon-warning-green.svg'
import dotPNG from '../assets/neutral-grey-arrow.svg'
import { siteContainerWidth, detectionMethods } from '../core/constants'
import {
  getAnomalyBreakdowUrl,
  getAnomalyType,
  getItemByKeyValue,
  getDimensionTitle,
} from '../helpers'
import { aggregateGraphData } from '../helpers/graph'
import useResize from '../hooks/useResize'
import styles from '../styles/graph.module.scss'

export function getTypeObject(type, invertPolarity = false) {
  switch (type) {
    case 'explained':
      return {
        image: explainedPNG,
        title: 'with insights',
      }
    case 'not-interesting':
      return {
        image: notInterestingPNG,
        title: 'Unimportant',
      }
    default:
      return {
        image: invertPolarity ? iconWarningGreen : iconWarning,
        title: 'Unexplained',
      }
  }
}

export function getDataRange(item, graphData) {
  const { x } = item
  const itemIndex = getItemByKeyValue(graphData, 'x', x, true)
  if (itemIndex !== -1) {
    const res = itemIndex === 0 ? [] : [graphData[itemIndex - 1]]
    res.push(graphData[itemIndex])
    // res.push(graphData[itemIndex + 1])
    return res
  }
  return null
}

export function HintContent({
  item,
  gaDimensions,
  increaseSentimentPositive,
}: {
  item: any
  gaDimensions: any
  increaseSentimentPositive: boolean
}): null | React.ReactElement {
  const type = getAnomalyType(item)
  // const { title } = getTypeObject(type)
  // const { date } = found
  const { userSuppliedCause, date, userSuppliedAction, dimensions } = item
  return (
    <div className={styles.box}>
      <h3 className={styles.title}>
        {moment(date, 'DD/MM/YYYY').format('D MMM')}
        <b className={styles.dot} />
        Anomaly
      </h3>
      <AnomalyDiff
        invertPolarity={!increaseSentimentPositive}
        compact
        actual={item.actual}
        expected={item.expected}
        className={styles.boxDiff}
      />
      {type === null &&
        gaDimensions &&
        Array.isArray(dimensions) &&
        dimensions.length > 0 && (
          <>
            <h3 className={styles.tagsTitle}>Top changing dimensions:</h3>
            <TagsWrapper noBorder className={styles.tagsWrapper}>
              <>
                {dimensions.map((dimension) => {
                  return (
                    <Tag
                      // key={dimension.mDN}
                      key={nanoid()}
                      type="blue"
                      className={styles.tag}
                    >
                      <AnomalyDiff
                        invertPolarity={!increaseSentimentPositive}
                        arrowOnly
                        actual={dimension.a}
                        expected={dimension.e}
                      />
                      <span className={styles.tag}>
                        {getDimensionTitle(dimension.mDN, gaDimensions)}
                      </span>
                    </Tag>
                  )
                })}
              </>
            </TagsWrapper>
            <p className={styles.titleLink}>Click to explore anomaly</p>
          </>
        )}
      {type === 'explained' && (
        <>
          <div className={styles.markDown}>
            {userSuppliedCause && (
              <>
                <h3>What happened</h3>
                <ReactMarkdown
                  source={`${userSuppliedCause.slice(0, 90)}${
                    userSuppliedCause.length > 90 ? '...' : ''
                  }`}
                />
              </>
            )}
            {userSuppliedAction && (
              <>
                <h3 className={styles.marginTop}>What to do next</h3>
                <ReactMarkdown
                  source={`${userSuppliedAction.slice(0, 90)}${
                    userSuppliedAction.length > 90 ? '...' : ''
                  }`}
                />
              </>
            )}
          </div>
        </>
      )}

      {type === 'not-interesting' && (
        <>
          <p className={styles.para}>This has been marked as unimportant.</p>
          <p className={styles.titleLink}>Click to explore anomaly</p>
        </>
      )}
    </div>
  )
}

interface AnomalyXY extends Anomaly {
  x: number
  y: number
}

interface Props {
  onMouseHover?: (item: any) => void
  data: ExplainGraphData
  metricID: string
  metricName: string
  width?: number
  noInterraction?: boolean
  gaDimensions?: any
  increaseSentimentPositive?: boolean
  detectionMethod?: undefined | string
  onClickAnomaly?: (clickedDate: string, clickedMetricID: string) => void
  children?: React.ReactNode
}

export default function Graph({
  onMouseHover,
  data,
  metricID,
  metricName,
  width,
  noInterraction = false,
  increaseSentimentPositive = true,
  gaDimensions,
  detectionMethod,
  onClickAnomaly,
  children,
}: Props): React.ReactElement {
  const history = useHistory()

  const { callData } = data
  const [showArea, setShowArea] = useState(true)
  // const [showArea, setShowArea] = useState(true)
  const [graphData, setGraphData] = useState([])
  const [expectedGraphData, setExpectedGraphData] = useState([])

  const [crosshairData, setCrosshairData] = useState<
    { x: number; y: number }[]
  >([])
  const [currentHint, setCurrentHint] = useState<null | AnomalyXY>(null)

  const [currentDate, setCurrentDate] = useState<null | {
    x: string
    date: string
  }>(null)
  const [anomalyData, setAnomalyData] = useState<AnomalyXY[]>([])

  useEffect((): void => {
    const [nextGraphData, nextAnomalyData] = aggregateGraphData(
      data.dates,
      data.metricValues,
      data.anomalyRange,
    )
    if (nextGraphData) {
      setGraphData(nextGraphData)
    }

    if (nextAnomalyData) {
      setAnomalyData(nextAnomalyData)
    }

    if (data.expectedValues.length > 0) {
      const [nextExpectedGraphData] = aggregateGraphData(
        data.dates,
        data.expectedValues,
        data.anomalyRange,
      )

      if (nextExpectedGraphData) {
        setExpectedGraphData(nextExpectedGraphData)
      }
    }
  }, [data])

  const screenWidth = useResize()

  const graphWidth: number = useMemo(() => {
    let res: number
    if (width) {
      res = width
    } else if (screenWidth > 1260) {
      res = 1108
    } else if (screenWidth < siteContainerWidth) {
      res = screenWidth - 40
    } else if (screenWidth <= siteContainerWidth + 150) {
      res = screenWidth - 150
    } else {
      res = 960
    }
    return res - 34
  }, [width, screenWidth])

  const isMobile = screenWidth <= 768
  const desktopMargin = { bottom: 40, left: 60, right: 20, top: 20 }
  const mobileMargin = { bottom: 30, left: 40, right: 10, top: 20 }
  const graphHeigth = graphWidth >= 800 ? 320 : 200

  const dm = useMemo(() => {
    if (
      detectionMethod &&
      Object.prototype.hasOwnProperty.call(detectionMethods, detectionMethod)
    ) {
      return `(${detectionMethods[detectionMethod].short})`
    }
    return ''
  }, [detectionMethod])

  const showExpectedValueButton = dm === '(AI)' || dm === '(SD)'
  const showExpectedAreaSeries =
    showExpectedValueButton && showArea && data && data.lower.length > 0

  const allZeroesY = useMemo(() => {
    const found = graphData.filter((item: any) => {
      return item.y !== 0
    })
    return found.length === 0
  }, [graphData])

  if (callData.loading) {
    return (
      <Preloader
        style={{ height: 40, margin: `${graphHeigth / 2 - 20}px auto` }}
      />
    )
  }

  return (
    <div
      className={classNames(styles.container, {
        [styles.increaseSentimentPositive]: increaseSentimentPositive,
        [styles.noData]: !callData.loading && graphData.length <= 0,
      })}
    >
      <div className={styles.graphWrapper}>
        <div className={styles.yAxisTitleWrapper}>
          <h2 className={styles.yAxisTitle}>{metricName}</h2>
        </div>
        {showExpectedValueButton && (
          <div className={styles.map}>
            {children}
            <Button
              variant="text"
              color="grey"
              className={classNames(styles.areaButton, {
                [styles.areaButtonActive]: showArea,
              })}
              onPress={() => setShowArea(!showArea)}
            >
              Show expected value {dm}
            </Button>
          </div>
        )}
        <XYPlot
          height={graphHeigth}
          width={graphWidth}
          margin={isMobile ? mobileMargin : desktopMargin}
          onMouseLeave={() => {
            setCrosshairData([])
            setCurrentHint(null)
            setCurrentDate(null)
          }}
          onClick={(): void => {
            if (
              !noInterraction &&
              detectionMethod !== 'SD' &&
              crosshairData &&
              crosshairData.length > 0
            ) {
              const { x } = crosshairData[0]
              const itemIndex = getItemByKeyValue(graphData, 'x', x, true)
              if (data.dates && data.dates[itemIndex]) {
                const clickedDate = data.dates[itemIndex]
                if (onClickAnomaly) {
                  onClickAnomaly(clickedDate, metricID)
                } else {
                  history.push(
                    getAnomalyBreakdowUrl({ date: clickedDate, metricID }),
                  )
                }
              }
            }
            // history.push(getAnomalyBreakdowUrl({ date, metric }))
          }}
        >
          {showExpectedAreaSeries && (
            <AreaSeries
              curve="curveMonotoneX"
              data={data.upper.map((upper, index) => {
                const lower = data.lower[index]
                const y0 = lower < 0 ? 0 : lower
                return { x: index, y: upper, y0 }
              })}
              color="#A6CEE3"
              opacity={0.05}
            />
          )}
          {showExpectedAreaSeries && (
            <AreaSeries
              curve="curveMonotoneX"
              data={data.upper.map((upper, index) => {
                const lower = data.lower[index]
                const diff = ((upper - lower) / 100) * 25
                const y0 = lower + diff
                return { x: index, y: upper - diff, y0: y0 < 0 ? 0 : y0 }
              })}
              color="#A6CEE3"
              opacity={0.1}
            />
          )}
          {showExpectedAreaSeries && (
            <AreaSeries
              curve="curveMonotoneX"
              data={data.upper.map((upper, index) => {
                const lower = data.lower[index]
                const diff = ((upper - lower) / 100) * 50
                const y0 = lower + diff
                return { x: index, y: upper - diff, y0: y0 < 0 ? 0 : y0 }
              })}
              color="#A6CEE3"
              opacity={0.1}
            />
          )}
          {allZeroesY && (
            <LineSeries
              curve="curveMonotoneX"
              data={[{ x: 1, y: 1 }]}
              strokeStyle="dashed"
              color="#ffffff"
              opacity={0.4}
            />
          )}
          {showArea && expectedGraphData.length > 0 && (
            <LineSeries
              curve="curveMonotoneX"
              data={expectedGraphData}
              strokeStyle="dashed"
              color="#A6CEE3"
              opacity={0.4}
            />
          )}

          {!noInterraction && (
            <Crosshair values={crosshairData}>
              <></>
            </Crosshair>
          )}
          <HorizontalGridLines />
          <VerticalGridLines />
          <LineSeries
            onNearestX={(coords, { index }) => {
              const dateIndex = index - 1
              const d = graphData[dateIndex < 0 ? 0 : dateIndex]
              const { x, y } = d
              const found = getItemByKeyValue(anomalyData, 'x', x)
              if (onMouseHover) {
                onMouseHover(found)
              }
              if (found !== -1) {
                setCurrentHint({ ...found, x, y })
              } else {
                setCurrentHint(null)
              }
              setCrosshairData([{ x, y }])
              const date = data.dates[dateIndex]
              if (date) {
                setCurrentDate({ x, date })
              } else {
                setCurrentDate(null)
              }
            }}
            data={
              graphData && graphData.length
                ? [{ x: 0, y: 0 }, ...graphData]
                : graphData
            }
            color="#A6CEE3"
          />

          {graphData && graphData.length > 0 && (
            <CustomSVGSeries
              data={graphData}
              customComponent={(): React.ReactElement => {
                return (
                  <image
                    xlinkHref={dotPNG}
                    height="6"
                    width="6"
                    className={styles.neutralDot}
                  />
                )
              }}
            />
          )}
          <XAxis
            // @ts-ignore
            tickFormat={(i: number, index: number): string => {
              // check if number is decimal
              if (i % 1 !== 0) return ''
              const d = moment(data.dates[i], 'DD/MM/YYYY')
              // leave out ever second number
              if (width && width < 768) {
                return index % 2 ? '' : d.format('D MMM')
              }
              if (isMobile) {
                return `${d.format('DD/MM')}`
              }
              return `${d.format('D MMM')}`
            }}
          />
          <YAxis
            tickFormat={(i: string): string => {
              return numeral(i).format('0,0.[0]a')
            }}
          />
          {!callData.loading &&
            anomalyData.length > 0 &&
            anomalyData.map((item) => {
              const type = getAnomalyType(item)
              if (type === 'not-interesting') {
                return null
              }
              const highlighted = getDataRange(item, graphData)
              if (highlighted === null || highlighted.length !== 2) {
                return null
              }
              const showRed = increaseSentimentPositive
                ? highlighted[0].y > highlighted[1].y
                : highlighted[0].y < highlighted[1].y
              // #9B2C2C - red
              // #38A169 - green
              return (
                <LineSeries
                  key={nanoid()}
                  data={highlighted}
                  color={showRed ? '#9B2C2C' : '#38A169'}
                />
              )
            })}
          {!callData.loading && anomalyData.length > 0 && (
            <CustomSVGSeries
              // animation
              data={anomalyData}
              customComponent={(item: Anomaly): React.ReactElement => {
                const { date } = item
                const type = getAnomalyType(item)
                const highlighted = getDataRange(item, graphData)
                if (highlighted === null || highlighted.length !== 2) {
                  return <></>
                }
                const invertPolarity = increaseSentimentPositive
                  ? highlighted[0].y < highlighted[1].y
                  : highlighted[0].y > highlighted[1].y
                return (
                  <image
                    onClick={(): void => {
                      if (!noInterraction) {
                        if (onClickAnomaly) {
                          onClickAnomaly(date, metricID)
                        } else {
                          history.push(
                            getAnomalyBreakdowUrl({ date, metricID }),
                          )
                        }
                      }
                    }}
                    xlinkHref={getTypeObject(type, invertPolarity).image}
                    height="24"
                    width="24"
                    className={styles.anomaly}
                  />
                )
              }}
            />
          )}
          {!noInterraction &&
            detectionMethod !== 'SD' &&
            currentDate !== null &&
            currentHint === null && (
              <Hint
                align={{ horizontal: 'left', vertical: 'bottom' }}
                key={nanoid()}
                value={{ x: currentDate.x, y: 0 }}
                className={styles.hint}
              >
                <div className={styles.dateBox}>
                  <h3 className={styles.title}>
                    <span className={styles.titleDate}>
                      {moment(currentDate.date, 'DD/MM/YYYY').format('D MMM')}
                    </span>
                    <b className={styles.dot} />
                    <span className={styles.titleLink}>
                      Click to explore day
                    </span>
                  </h3>
                </div>
              </Hint>
            )}

          {!noInterraction && currentHint && (
            <Hint
              key={nanoid()}
              value={{ x: currentHint.x, y: currentHint.y }}
              className={styles.hint}
            >
              <HintContent
                increaseSentimentPositive={increaseSentimentPositive}
                item={currentHint}
                gaDimensions={gaDimensions}
              />
            </Hint>
          )}
        </XYPlot>
      </div>
      {!callData.loading && graphData.length === 0 && anomalyData.length === 0 && (
        <div className={styles.noAnomalyMessage}>
          <p>
            Nothing to see here - no anomalies have been found in your date
            range.
          </p>
        </div>
      )}
    </div>
  )
}
