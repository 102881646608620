import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import styles from '../styles/simple-carousel.module.scss'
import Arrow from '../assets/svgs/arrow'
import Helpful from './helpful'
import useMobile from '../hooks/useMobile'

type Item = React.ReactElement | Node | string | null

interface Props {
  items?: Item[]
  className?: string
  headerClassName?: string
  buttonGroupClassName?: string
  previousButtonClassName?: string
  nextButtonClassName?: string
  title?: string
  onClickHelpful?: any
  onClickNotHelpful?: any
  onChangeSlide?: any
  defaultActive?: number
  viewOnly?: boolean
}

export default function SimpleCarousel({
  items,
  className,
  headerClassName,
  buttonGroupClassName,
  previousButtonClassName,
  nextButtonClassName,
  title,
  onChangeSlide,
  onClickHelpful,
  onClickNotHelpful,
  defaultActive = 0,
  viewOnly = false,
}: Props): React.ReactElement | null {
  const [active, setActive] = useState(defaultActive)
  const isMobile = useMobile(769)

  useEffect((): void => {
    setActive(defaultActive)
    // eslint-disable-next-line
  }, [items])

  useEffect((): void => {
    if (onChangeSlide) {
      onChangeSlide(active)
    }
    // eslint-disable-next-line
  }, [active])

  if (!items) return null

  const increment = (): void => {
    if (active + 1 >= items.length) {
      setActive(0)
    } else {
      setActive(active + 1)
    }
  }

  const decrement = (): void => {
    if (active - 1 < 0) {
      setActive(items.length - 1)
    } else {
      setActive(active - 1)
    }
  }

  const c = classNames(styles.carouselBody, className)
  const h = classNames(styles.carouselHeader, headerClassName)
  return (
    <div className={c}>
      <div aria-label="carousel-header" className={h}>
        <div className={styles.column}>
          {title && <h3>{title}</h3>}
          <div className={styles.buttonWrapper}>
            {!isMobile && <span data-type="separator" />}
            {!isMobile && (
              <span className={styles.pagination} aria-label="pagination">
                {`${active + 1} of ${items.length}`}
              </span>
            )}
            <div aria-label="button-group" className={buttonGroupClassName}>
              <button
                className={classNames(
                  styles.nextButton,
                  previousButtonClassName,
                )}
                type="button"
                aria-label="previous"
                onClick={decrement}
              >
                <Arrow className={styles.previousArrow} />
                <span>Previous</span>
              </button>
              {isMobile && (
                <span className={styles.pagination} aria-label="pagination">
                  {`${active + 1} of ${items.length}`}
                </span>
              )}
              <button
                className={classNames(styles.nextButton, nextButtonClassName)}
                type="button"
                aria-label="next"
                onClick={increment}
              >
                <Arrow className={styles.nextArrow} />
                <span>Next</span>
              </button>
            </div>
          </div>
        </div>
        {!viewOnly && (
          <div className={styles.columnRight}>
            <Helpful
              onClickYes={() => {
                if (onClickHelpful) {
                  onClickHelpful(active)
                }
              }}
              onClickNo={() => {
                if (onClickNotHelpful) {
                  onClickNotHelpful(active)
                }
              }}
            />
          </div>
        )}
      </div>
      <div aria-label="carousel body">
        <>
          {items.map((item: Item, index: number): Item | null =>
            index === active && item !== null ? (
              // @ts-ignore
              <React.Fragment key={item.toString()}>{item}</React.Fragment>
            ) : null,
          )}
        </>
      </div>
    </div>
  )
}
