import React, { useState } from 'react'
import { useReactiveVar } from '@apollo/client'

import Link from './link'
import Modal from './modal'
import { BoxedText } from './typography'
import { linkOrCode } from '../api/apollo/variables'
import { dynamicTextValues, supportEmail } from '../core/constants'
import {
  HardWarningModalType,
  SoftWarning,
  SoftWarningModalType,
} from '../helpers/track-create'
import useLogAction from '../hooks/useLogAction'

interface CreateLinkWarningModalProps {
  setCreateLinkWarning: React.Dispatch<
    React.SetStateAction<SoftWarningModalType | HardWarningModalType | null>
  >
}

/** Form can still be submitted for softWarning modals */
interface SoftWarningModalProps extends CreateLinkWarningModalProps {
  createLinkWarning: SoftWarningModalType
  buildLinks: (warningsToIgnore?: SoftWarning[]) => Promise<void>
}

interface HardWarningModalProps extends CreateLinkWarningModalProps {
  createLinkWarning: HardWarningModalType
}

export const CreateLinkAnchorWarningModal = ({
  createLinkWarning,
  setCreateLinkWarning,
  buildLinks,
}: SoftWarningModalProps) => {
  const { fullLinks, ignoreWarnings } = createLinkWarning

  const logAction = useLogAction()

  const hasMultipleLinks = fullLinks.length > 1

  const [loading, setLoading] = useState(false)

  return (
    <Modal
      setIsOpen={() => setCreateLinkWarning(null)}
      modalHeader="Page anchor detected"
      noText="Cancel"
      yesText={`Continue creating link${hasMultipleLinks ? 's' : ''}`}
      yesButtonLoading={loading}
      onYes={async () => {
        setLoading(true)

        await logAction({
          variables: {
            action: 'build-links-ignore-anchor-warning',
            websiteSection: 'track-create',
            functionName: 'ignoreWarning',
            pagePath: '/track/create',
          },
        })

        await buildLinks(ignoreWarnings)

        setLoading(false)
      }}
    >
      <p>
        {hasMultipleLinks ? 'One of your links' : 'Your link'} contains a page
        anchor '#' which will be moved to the end of the link. This is required
        to make sure campaign parameters are captured and the link can auto
        scroll.{' '}
        <Link href="https://support.uplifter.ai/hc/en-us/articles/4469479289873-Can-I-use-anchor-tags-or-in-my-landing-page-URL-with-query-string-parameters-utms-">
          Find out more
        </Link>
        .
      </p>
    </Modal>
  )
}

export const CreateLinkNoUrlModal = ({
  createLinkWarning,
  setCreateLinkWarning,
  buildLinks,
}: SoftWarningModalProps) => {
  const { fullLinks, ignoreWarnings } = createLinkWarning

  const linkCopy = useReactiveVar(linkOrCode)

  const logAction = useLogAction()

  const hasMultipleLinks = fullLinks.length > 1

  const [loading, setLoading] = useState(false)

  return (
    <Modal
      setIsOpen={() => setCreateLinkWarning(null)}
      modalHeader={`Invalid landing page URL${hasMultipleLinks ? '(s)' : ''}`}
      noText={`Back and update landing page URL${
        hasMultipleLinks ? '(s)' : ''
      }`}
      yesText={`Continue creating link${hasMultipleLinks ? 's' : ''}`}
      yesButtonLoading={loading}
      onYes={async () => {
        setLoading(true)

        await logAction({
          variables: {
            action: 'build-links-ignore-no-url-warning',
            websiteSection: 'track-create',
            functionName: 'ignoreWarning',
            pagePath: '/track/create',
          },
        })

        await buildLinks(ignoreWarnings)

        setLoading(false)
      }}
    >
      <p>
        {hasMultipleLinks ? 'One of y' : 'Y'}our landing page URL
        {hasMultipleLinks && 's'} is either empty, invalid or missing https:// -
        users could be directed to an error page.
      </p>
      <p>
        You should only create {hasMultipleLinks ? 'these links' : 'this link'}{' '}
        if you plan on editing the landing page URL{hasMultipleLinks && '(s)'}{' '}
        in the{' '}
        <BoxedText>{dynamicTextValues.trackViewPage[linkCopy]}</BoxedText> table
        before the campaign goes live.
      </p>
    </Modal>
  )
}

export const CreateShortLinkNoUrlModal = ({
  createLinkWarning,
  setCreateLinkWarning,
  buildLinks,
}: SoftWarningModalProps) => {
  const { fullLinks, ignoreWarnings } = createLinkWarning

  const linkCopy = useReactiveVar(linkOrCode)

  const logAction = useLogAction()

  const hasMultipleLinks = fullLinks.length > 1

  const [loading, setLoading] = useState(false)

  return (
    <Modal
      setIsOpen={() => setCreateLinkWarning(null)}
      modalHeader={`Short link${
        hasMultipleLinks ? '(s)' : ''
      } with no landing page URL`}
      noText={`Back and use full link${hasMultipleLinks ? '(s)' : ''}`}
      yesText={`Continue creating link${hasMultipleLinks ? 's' : ''}`}
      yesButtonLoading={loading}
      onYes={async () => {
        setLoading(true)

        await logAction({
          variables: {
            action: 'build-links-ignore-shortlink-no-url-warning',
            websiteSection: 'track-create',
            functionName: 'ignoreWarning',
            pagePath: '/track/create',
          },
        })

        await buildLinks(ignoreWarnings)

        setLoading(false)
      }}
    >
      <p>
        You are using {hasMultipleLinks ? 'short links' : 'a short link'} but
        have not set a landing page URL.
      </p>
      <p>
        You should only create {hasMultipleLinks ? 'these links' : 'this link'}{' '}
        if you plan on adding the landing page URL{hasMultipleLinks && '(s)'} in
        the <BoxedText>{dynamicTextValues.trackViewPage[linkCopy]}</BoxedText>{' '}
        table before the campaign goes live.
      </p>
    </Modal>
  )
}

export const CreateLinkInvalidLengthModal = ({
  createLinkWarning,
  setCreateLinkWarning,
}: HardWarningModalProps) => {
  const {
    type,
    fullLinks,
    characterLimit,
    charactersOverLimit,
  } = createLinkWarning

  const hasMultipleLinks = fullLinks.length > 1

  return (
    <Modal
      setIsOpen={() => setCreateLinkWarning(null)}
      isWarning
      modalHeader={`Link${hasMultipleLinks ? '(s) are' : ' is'} too long`}
      noText="Back and edit parameters"
    >
      <p>
        The resulting{' '}
        {type === 'invalid-landing-page-length' ? 'link' : 'query string'}
        {hasMultipleLinks && 's'} will be too long to work in most web browsers
        or marketing platforms.
      </p>
      <p>
        Your{hasMultipleLinks ? ' longest' : ''}{' '}
        {type === 'invalid-landing-page-length' ? 'link' : 'query string'} is{' '}
        {charactersOverLimit} characters longer than the maximum recommended{' '}
        {characterLimit} characters.
      </p>
      {type === 'invalid-landing-page-length' ? (
        <p>
          Try <strong>changing the landing page</strong> to a shorter URL.
        </p>
      ) : (
        <>
          <p>
            Type <strong>fewer characters into the parameter values</strong> for
            a shorter string.
          </p>
          <p>
            For help email{' '}
            <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>
          </p>
        </>
      )}
    </Modal>
  )
}
