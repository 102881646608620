import React from 'react'
import classNames from 'classnames'

import styles from '../styles/button-row.module.scss'

interface ButtonRowProps {
  children: React.ReactNode
  className?: string
  style?: object
  centerAlign?: boolean
}

export function ButtonRow({
  children,
  className = '',
  style,
  centerAlign,
}: ButtonRowProps): React.ReactElement {
  const buttonClassNames = classNames(className, styles.buttonRow, {
    [styles.centerAlign]: centerAlign,
  })

  return (
    <div className={buttonClassNames} style={style}>
      {children}
    </div>
  )
}
