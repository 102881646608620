import React, { useMemo, useRef } from 'react'
import moment from 'moment'
import FileSaver from 'file-saver'

import Button, { CopyButton } from './button'
import Tooltip from './tooltip'
import { InnerBox, OuterBox } from './two-columns'
import { brandName } from '../core/constants'
import styles from '../styles/new-email-html-preview.module.scss'

interface NewEmailHTMLPreviewProps {
  previousEmailHtml: string
  newEmailHtml: GeneratedEmailHTML | null
  isIntegrationEmail?: boolean
}

export function NewEmailHTMLPreview({
  previousEmailHtml,
  newEmailHtml,
  isIntegrationEmail = false,
}: NewEmailHTMLPreviewProps) {
  const previewRef = useRef<HTMLElement>(null)

  const highlightedOffsets = useMemo(() => {
    // Only do this if items are highlighted in the preview
    // No highlighting occurs if preview is taken from local storage

    if (
      newEmailHtml &&
      typeof newEmailHtml !== 'string' &&
      previewRef &&
      previewRef.current
    ) {
      const highlightedElems = previewRef.current.children

      if (highlightedElems.length > 0) {
        // Span elements are present
        // <span className={styles.highlight}>{match}</span>
        // Preview is constructed in email-code-generator-form
        const childrenOffsets = Array.from(
          new Array(highlightedElems.length),
          (_, index) => highlightedElems[index] as HTMLElement,
        ).map((elem) => elem.offsetTop)

        return childrenOffsets
      }
    }

    return null
  }, [previewRef.current, newEmailHtml])

  const previewSize = useMemo(() => {
    if (previewRef.current) {
      return {
        height: previewRef.current.offsetHeight,
        width: previewRef.current.offsetWidth,
      }
    }

    return { height: 0, width: 0 }
  }, [previewRef.current])

  return (
    <OuterBox>
      <InnerBox>
        <div className={styles.emailPreviewHeader}>
          <p style={{ margin: 0 }}>
            <Tooltip
              id="email-html-tooltip"
              useIcon
              tooltipPosition="right"
              tooltipMessage={
                isIntegrationEmail
                  ? "We've replaced your old HTML with this new HTML."
                  : 'Replace your old HTML with this new HTML in your email platform.'
              }
            >
              {newEmailHtml ? (
                <span>
                  {isIntegrationEmail
                    ? 'New email HTML in your email platform'
                    : 'New email HTML'}
                </span>
              ) : (
                <span>Your last email HTML</span>
              )}
            </Tooltip>
          </p>
          <CopyButton
            className={styles.copyButton}
            value={newEmailHtml?.raw || previousEmailHtml}
          >
            Copy
          </CopyButton>
          <Button
            variant="secondary"
            onPress={async () => {
              const data = newEmailHtml?.raw || previousEmailHtml

              const blob = new Blob([data], {
                type: 'text/plain;charset=utf-8',
              })
              const now = new Date(Date.now())

              await FileSaver.saveAs(
                blob,
                `${moment(now).format(
                  'YYYY-MM-DD',
                )} ${brandName} Email HTML.txt`,
              )
            }}
          >
            Download
          </Button>
        </div>
        <div className={styles.emailPreviewField}>
          <div className={styles.previewCode}>
            <code ref={previewRef}>
              {newEmailHtml?.preview || previousEmailHtml}
            </code>
          </div>
          {highlightedOffsets &&
            highlightedOffsets.length > 0 &&
            previewSize.height > 300 && (
              <div className={styles.scrollIndicator}>
                {highlightedOffsets.map((item) => {
                  const offsetTop =
                    previewSize.height > 0
                      ? (item / previewSize.height) * 100
                      : 0

                  // If the horizontal scroll bar is present, adjust where the markers appear
                  const adjust = previewSize.width > 710 ? 5 : 0

                  return (
                    <div
                      className={styles.highlightIndicator}
                      style={{ top: `calc(${offsetTop}% - ${adjust}px)` }}
                    />
                  )
                })}
              </div>
            )}
        </div>
      </InnerBox>
    </OuterBox>
  )
}
