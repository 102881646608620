import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useReactiveVar } from '@apollo/client'

import { FormField, FormLabel, FormRow } from './form'
import { MultiInputField, MultiInputFieldValue } from './input-v2'
import Link from './link'
import { PillColors } from './select-box'
import { ErrorMessage } from './typography'
import { UrlValidationMessage } from './url-validation-message'
import { currentUserDetails } from '../api/apollo/variables'
import { isAdminUser, isValidUrl } from '../helpers'
import { makeLinkSecure } from '../helpers/track-create'
import { defaultValidationChecksValues } from '../helpers/track-module'
import useLogAction from '../hooks/useLogAction'
import { UpdateFormOptions } from '../hooks/useTrackCreateSavedValues'
import useUrlValidation from '../hooks/useUrlValidation'
import { GetCampaignCodeGeneratorQuery } from '../__gql-types__/graphql'

interface ErrorItem {
  isError: boolean
  isTracked: boolean
}

interface LandingPageFieldProps {
  showField?: boolean
  isApp?: boolean
  validationChecks:
    | GetCampaignCodeGeneratorQuery['campaignCodeGenerator']['validationChecks']
    | null
  savedValue: string[]
  onChange: (newVal: string[], options?: UpdateFormOptions) => void
  submitError?: boolean
}

const LandingPageField = ({
  showField = true,
  isApp,
  validationChecks,
  savedValue,
  onChange,
  submitError,
}: LandingPageFieldProps) => {
  const { userPermission } = useReactiveVar(currentUserDetails)

  const logAction = useLogAction()

  const { validateUrls, validationResults } = useUrlValidation()

  const isAdmin = isAdminUser(userPermission)

  const formRowRef = useRef<HTMLDivElement>(null)

  const [currentValue, setCurrentValue] = useState<MultiInputFieldValue[]>(
    savedValue.map((v) => ({
      value: v,
      label: v,
      pillColor: 'grey',
    })),
  )
  const [noSpacesError, setNoSpacesError] = useState<ErrorItem>({
    isError: false,
    isTracked: false,
  })
  const [noSpecialCharactersError, setNoSpecialCharactersError] = useState<
    ErrorItem
  >({
    isError: false,
    isTracked: false,
  })

  const { noSpecialCharacters, requireLandingPage } = useMemo(() => {
    const _requireLandingCheck = validationChecks?.find(
      (check) => check.name === 'REQUIRE_LANDING_PAGE',
    )?.enabled

    return {
      noSpecialCharacters:
        validationChecks?.find(
          (check) => check.name === 'NO_SPECIAL_CHARS_LANDING_PAGE',
        )?.enabled || false,
      requireLandingPage:
        typeof _requireLandingCheck === 'boolean' ? _requireLandingCheck : true,
    }
  }, [validationChecks])

  // Update pill color to match URL validation status
  useEffect(() => {
    setCurrentValue((curr) => {
      return curr.map(({ value }) => {
        // Not validated yet, or not a valid URL
        if (!Object.prototype.hasOwnProperty.call(validationResults, value)) {
          return { value, label: value, pillColor: 'grey' }
        }

        const { statusCode } = validationResults[value]

        if (!statusCode) {
          return { value, label: value, pillColor: 'grey' }
        }

        if (statusCode >= 200 && statusCode <= 299) {
          return { value, label: value, pillColor: 'green' }
        }

        return { value, label: value, pillColor: 'red' }
      })
    })
  }, [validationResults])

  // Reset URL value on e.g. clearing the form
  // Changing appGroupID can also change the URL
  useEffect(() => {
    if (savedValue.length === 0 && currentValue.length > 0) {
      setNoSpacesError({
        isTracked: false,
        isError: false,
      })
      setNoSpecialCharactersError({
        isTracked: false,
        isError: false,
      })
    }

    setCurrentValue(
      savedValue
        .filter((v) => v !== '')
        .map((v) => ({
          value: v,
          label: v,
          pillColor: 'grey',
        })),
    )
  }, [savedValue])

  // Ensure only one URL is saved for app links
  useEffect(() => {
    if (!isApp || currentValue.length === 0) return

    setCurrentValue((curr) => [curr[0]])
    onChange([savedValue[0]])
  }, [isApp])

  // Validate URLs on change
  useEffect(() => {
    const validUrls = currentValue
      .filter(
        ({ value }) =>
          isValidUrl(value) &&
          !Object.prototype.hasOwnProperty.call(validationResults, value),
      )
      .map(({ value }) => value)

    validateUrls(validUrls)
  }, [currentValue])

  if (!showField) {
    return null
  }

  return (
    <FormRow ref={formRowRef} includePaddingBottom>
      <FormLabel
        id="landing-page"
        tooltip={
          isApp
            ? 'The URL users will go to if they click on the app link on a desktop device.'
            : 'This is the page your user will land on once they click on your link. Make sure your link does not already contain a campaign code.'
        }
      >
        {isApp ? 'Fallback URL' : 'Landing page URL'}
      </FormLabel>
      <FormField>
        <MultiInputField
          id="landing-page-url"
          placeholder="Type landing page URL"
          maxValues={isApp ? 1 : undefined}
          error={
            submitError ||
            noSpacesError.isError ||
            noSpecialCharactersError.isError
          }
          value={currentValue}
          setValue={setCurrentValue}
          noPillForSingleValue
          formatInputValue={(input) => {
            // Replaces spaces and prevents special characters when rule is set
            setNoSpacesError((curr) => ({
              ...curr,
              isError: false,
            }))
            setNoSpecialCharactersError((curr) => ({
              ...curr,
              isError: false,
            }))

            let preparedVal = input

            // Spaces are not allowed in URLs
            // But newline \n and tab \t should be allowed for pasting multiple entries
            if (/[ \f\v]/.test(preparedVal)) {
              preparedVal = preparedVal.replaceAll(/[ \f\v]/g, '')

              if (preparedVal.length > 0) {
                let errorTracked = noSpacesError.isTracked

                if (!errorTracked) {
                  logAction({
                    variables: {
                      action: 'track-error-landing-page-spaces',
                      extra: '',
                      websiteSection: 'track-create',
                      functionName: 'beforeChange',
                      pagePath: '/track/create-links',
                    },
                  })

                  errorTracked = true
                }

                setNoSpacesError({
                  isError: true,
                  isTracked: errorTracked,
                })
              }
            }

            const hasSpecialCharacters =
              noSpecialCharacters &&
              preparedVal.match(
                new RegExp(
                  defaultValidationChecksValues.NO_SPECIAL_CHARS_LANDING_PAGE,
                ),
              ) !== null

            if (hasSpecialCharacters) {
              let errorTracked = noSpecialCharactersError.isTracked

              if (!errorTracked) {
                logAction({
                  variables: {
                    action: 'track-error-landing-page-special-chars',
                    extra: '',
                    websiteSection: 'track-create',
                    functionName: 'beforeChange',
                    pagePath: '/track/create-links',
                  },
                })

                errorTracked = true
              }

              setNoSpecialCharactersError({
                isError: true,
                isTracked: errorTracked,
              })
            }

            return preparedVal
          }}
          duplicateCheckerIgnoreString="https://"
          modifyInputValues={(values) => {
            const blockedValues = values.filter(({ value }) => {
              const hasSpecialCharacters =
                noSpecialCharacters &&
                value.match(
                  new RegExp(
                    defaultValidationChecksValues.NO_SPECIAL_CHARS_LANDING_PAGE,
                  ),
                ) !== null

              return hasSpecialCharacters
            })

            const updatedValues = values
              .filter(({ value }) => {
                /** Disable form if URL contains special characters with rule enabled */
                const hasSpecialCharacters =
                  noSpecialCharacters &&
                  value.match(
                    new RegExp(
                      defaultValidationChecksValues.NO_SPECIAL_CHARS_LANDING_PAGE,
                    ),
                  ) !== null

                return !hasSpecialCharacters
              })
              .map(({ value }) => {
                // Automatically add protocol to links if not present
                const validUrl = makeLinkSecure(value)

                if (validUrl !== value) {
                  // Link did not have protocol
                  logAction({
                    variables: {
                      action: 'track-error-landing-page-missing-protocol',
                      extra: '',
                      websiteSection: 'track-create',
                      functionName: 'onBlur',
                      pagePath: '/track/create-links',
                    },
                  })
                }

                return {
                  value: validUrl,
                  label: validUrl,
                  pillColor: 'grey' as PillColors,
                }
              })

            return { allowedValues: updatedValues, blockedValues }
          }}
          // Save values to localStorage
          customOnChange={(newValues) =>
            onChange(
              newValues,
              newValues.length > 0
                ? { errorsToRemove: ['landingPage'] }
                : {
                    errorsToAdd: requireLandingPage
                      ? ['landingPage']
                      : undefined,
                  },
            )
          }
          addInputOnBlur
          onBlur={() => {
            // Reset isTracked state for errors
            setNoSpacesError((curr) => ({
              ...curr,
              isTracked: false,
            }))
            setNoSpecialCharactersError((curr) => ({
              ...curr,
              isTracked: false,
            }))
          }}
        />
        {noSpacesError.isError && (
          <ErrorMessage>
            Spaces are not allowed in the landing page URL.
          </ErrorMessage>
        )}
        {noSpecialCharactersError.isError && (
          <ErrorMessage>
            {isAdmin ? (
              <>
                Special characters (?=&) are not allowed to avoid users copying
                landing pages with existing parameters.{' '}
                <Link
                  type="arrowForward"
                  href="/track/edit-parameters-and-rules"
                >
                  Disable this rule for deep linking
                </Link>
              </>
            ) : (
              'Special characters (?=&) are disabled by your admin.'
            )}
          </ErrorMessage>
        )}
        {submitError && (
          <ErrorMessage>You must enter a valid landing page URL.</ErrorMessage>
        )}
        {currentValue.map(({ value: u }) => {
          if (!isValidUrl(u)) return null

          return (
            <UrlValidationMessage
              key={u}
              url={u}
              validationDetails={validationResults[u]}
            />
          )
        })}
      </FormField>
    </FormRow>
  )
}

export default LandingPageField
