import { useEffect, useState } from 'react'

export default function useAnimateNumber(
  num: number,
  defaultNum: number,
): number {
  const [animatedNum, setAnimatedNum] = useState(defaultNum || 0)

  useEffect(() => {
    if (Math.floor(num) === Math.floor(animatedNum)) return
    let newVal = 0
    if (Math.floor(num) > Math.floor(animatedNum)) {
      newVal = Math.floor(animatedNum + 2)
      newVal = newVal > num ? num : newVal
    } else if (Math.floor(num) < Math.floor(animatedNum)) {
      newVal = Math.floor(animatedNum - 2)
      newVal = newVal < num ? num : newVal
    }
    setAnimatedNum(newVal)
  }, [num, animatedNum])

  return animatedNum
}
