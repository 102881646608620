import React from 'react'
import { useReactiveVar } from '@apollo/client'

import { CopyButton } from './button'
import ChangeInput from './change-input'
import { currentUserDetails } from '../api/apollo/variables'
import styles from '../styles/account-support-settings.module.scss'

interface AccountSupportSettingsProps {
  className?: string
}

export default function AccountSupportSettings({
  className,
}: AccountSupportSettingsProps) {
  const { userID, workspaceID, companyID } = useReactiveVar(currentUserDetails)

  return (
    <>
      {/* Uplifter employees (support users) only */}
      <div className={className}>
        <div className={styles.subsection}>
          <h3 className={styles.hiddenButton}>
            <span>IDs for debugging</span>
            <CopyButton
              value={` uid:\n${userID}\naccountID:\n${workspaceID}\ncompanyID:\n${companyID}`}
            >
              Copy all
            </CopyButton>
          </h3>
          <ChangeInput
            label="User ID"
            type="text"
            name="UserID"
            initialValue={userID}
            readOnly
            copyButton
            copyText={`uid:\n${userID}`}
          />
          <ChangeInput
            label="Workspace ID"
            type="text"
            name="workspaceID"
            initialValue={workspaceID}
            readOnly
            copyButton
            copyText={`workspaceID:\n${workspaceID}`}
          />
          <ChangeInput
            label="Company ID"
            type="text"
            name="CompanyID"
            initialValue={companyID}
            readOnly
            copyButton
            copyText={`companyID:\n${companyID}`}
          />
        </div>
      </div>
    </>
  )
}
