import React from 'react'

import Modal from './modal'

interface Props {
  children: React.ReactNode
  title: string
  onToggle: React.Dispatch<React.SetStateAction<boolean>>
}

export default function InfoModal({
  children,
  title,
  onToggle,
}: Props): React.ReactElement {
  return (
    <Modal
      setIsOpen={onToggle}
      modalHeader={title}
      yesText="OK"
      onYes={() => onToggle(false)}
    >
      {children}
    </Modal>
  )
}
