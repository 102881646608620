import { useEffect, useState } from 'react'
import useResize from './useResize'
import { siteContainerWidth } from '../core/constants'

const isMobile = (width, maxWidth) => {
  return width < maxWidth
}

export default function useMobile(
  maxWidth: number = siteContainerWidth,
): boolean {
  const width = useResize()
  const [mobile, setMobile] = useState(isMobile(width, maxWidth))

  useEffect((): void => {
    const condition = width < maxWidth
    if (condition !== mobile) {
      setMobile(condition)
    }
  }, [width, mobile, maxWidth])

  return mobile
}
