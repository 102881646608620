import {
  useState,
  useEffect,
  useMemo,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react'
import { useQuery, useReactiveVar } from '@apollo/client'

import { GetOnboardingSectionsQuery } from '../__gql-types__/graphql'
import { updateCachedOnboardingSectionsProgress } from '../api/graphql/custom-queries'
import { getOnboardingSectionsProgress } from '../api/graphql/onboarding-client'
import { currentUserDetails } from '../api/apollo/variables'
import { onboardingSectionData } from '../core/constants'

declare const onboardingProgress: GetOnboardingSectionsQuery
export type OnboardingProgressListItem = typeof onboardingProgress.accountOnboardingProgress.accountOnboardingProgressList[0]
export interface FullOnboardingSections {
  account: OnboardingProgressListItem[]
  user: OnboardingProgressListItem[]
}

export default function useOnboarding(): {
  fullOnboardingSections: FullOnboardingSections
  setFullOnboardingSections: Dispatch<SetStateAction<FullOnboardingSections>>
  mergedOnboardingSections: OnboardingProgressListItem[]
  minimalOnboardingComplete: boolean
  updateOnboardingSection: (sectionID: string, type: 'user' | 'account') => void
} {
  const userDetails = useReactiveVar(currentUserDetails)

  const { workspaceID, userPermission, hasCreatedLinks } = userDetails

  const [fullOnboardingSections, setFullOnboardingSections] = useState<
    FullOnboardingSections
  >({ account: [], user: [] })

  const { data: apolloOnboardingSections } = useQuery(
    getOnboardingSectionsProgress,
  )

  // Set MS Clarity tag for session recording
  useEffect(() => {
    if (!apolloOnboardingSections || hasCreatedLinks) return

    const createdLinkInfo = apolloOnboardingSections.userOnboardingProgress?.userOnboardingProgressList.find(
      (item) => item.onboardingSectionID === 'createCampaignLink',
    )

    if (window.clarity && createdLinkInfo) {
      window.clarity(
        'set',
        'hasCreatedLinks',
        JSON.stringify(createdLinkInfo.sectionCompleted),
      )

      currentUserDetails({
        ...userDetails,
        hasCreatedLinks: createdLinkInfo.sectionCompleted,
      })
    }
  }, [apolloOnboardingSections, hasCreatedLinks])

  useEffect(() => {
    if (
      userPermission !== '' &&
      apolloOnboardingSections &&
      (apolloOnboardingSections.accountOnboardingProgress ||
        apolloOnboardingSections.userOnboardingProgress)
    ) {
      const isAdminUser =
        userPermission === 'admin' || userPermission === 'support'

      const {
        accountOnboardingProgress: { accountOnboardingProgressList },
        userOnboardingProgress: { userOnboardingProgressList },
      } = apolloOnboardingSections

      const formattedSections: FullOnboardingSections = {
        account: [],
        user: [],
      }

      if (Array.isArray(userOnboardingProgressList)) {
        formattedSections.user = [...userOnboardingProgressList]
          // Section must appear in JSON file to populate content
          .filter(
            (section) => onboardingSectionData[section.onboardingSectionID],
          )
          .sort((a, b) => {
            return (
              onboardingSectionData[a.onboardingSectionID].position -
              onboardingSectionData[b.onboardingSectionID].position
            )
          })
      }
      if (Array.isArray(accountOnboardingProgressList)) {
        formattedSections.account = [...accountOnboardingProgressList]
          // Section must appear in JSON file to populate content
          .filter(
            (section) => onboardingSectionData[section.onboardingSectionID],
          )
          .sort((a, b) => {
            return (
              onboardingSectionData[a.onboardingSectionID].position -
              onboardingSectionData[b.onboardingSectionID].position
            )
          })
      }

      setFullOnboardingSections(() => {
        return {
          account: isAdminUser
            ? formattedSections.account.filter(
                (section) =>
                  !onboardingSectionData[section.onboardingSectionID]
                    .regularOnly,
              )
            : formattedSections.account.filter(
                (section) =>
                  !onboardingSectionData[section.onboardingSectionID].isAdmin,
              ),
          user: isAdminUser
            ? formattedSections.user.filter(
                (section) =>
                  !onboardingSectionData[section.onboardingSectionID]
                    .regularOnly,
              )
            : formattedSections.user.filter(
                (section) =>
                  !onboardingSectionData[section.onboardingSectionID].isAdmin,
              ),
        }
      })
    }
  }, [userPermission, apolloOnboardingSections])

  const mergedOnboardingSections = useMemo(() => {
    return fullOnboardingSections.account
      .concat(fullOnboardingSections.user)
      .filter((section) => onboardingSectionData[section.onboardingSectionID])
      .sort((a, b) => {
        return (
          onboardingSectionData[a.onboardingSectionID].position -
          onboardingSectionData[b.onboardingSectionID].position
        )
      })
  }, [fullOnboardingSections])

  const updateOnboardingSection = useCallback(
    (sectionID: string, type: 'user' | 'account') => {
      setFullOnboardingSections((curr) => {
        const newFull: typeof curr = JSON.parse(JSON.stringify(curr))

        const item = newFull[type].find(
          (onboardingSection) =>
            onboardingSection.onboardingSectionID === sectionID,
        )

        if (item) {
          const itemIndex = newFull[type].findIndex(
            (onboardingSection) =>
              onboardingSection.onboardingSectionID === sectionID,
          )

          newFull[type].splice(itemIndex, 1, {
            ...item,
            sectionCompleted: true,
          })

          updateCachedOnboardingSectionsProgress(
            workspaceID,
            type,
            newFull[type],
          )
        }

        return newFull
      })
    },
    [setFullOnboardingSections],
  )

  const minimalOnboardingComplete = useMemo(() => {
    return !!mergedOnboardingSections.find(
      (section) =>
        (section.onboardingSectionID === 'editTaxonomy' &&
          (section.sectionCompleted || section.sectionSkipped)) ||
        (section.onboardingSectionID === 'createdCampaignLink' &&
          (section.sectionCompleted || section.sectionSkipped)),
    )
  }, [fullOnboardingSections])

  return {
    fullOnboardingSections,
    setFullOnboardingSections,
    mergedOnboardingSections,
    minimalOnboardingComplete,
    updateOnboardingSection,
  }
}
