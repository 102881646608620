import React, { useEffect } from 'react'
import { useReactiveVar } from '@apollo/client'

import { passwordState } from '../api/apollo/variables'
import Carousel from '../components/carousel'
import Form from '../components/reset-password-form'
import Result from '../components/reset-password-result'
import Footer from '../components/footer'
import styles from '../styles/forgot-password.module.scss'

interface Props {
  location: Location
}

export default function ResetPassword({ location }: Props): React.ReactElement {
  const currentPasswordState = useReactiveVar(passwordState)

  const { search } = location
  const searchParams = new URLSearchParams(search)
  const token = searchParams.get('token')

  useEffect(() => {
    passwordState({
      ...currentPasswordState,
      forgotPassword: {
        requested: false,
        success: false,
      },
    })
  }, [])

  return (
    <div className={styles.ssoWrapperOuter}>
      <section className={styles.ssoWrapper}>
        <Carousel
          width={460}
          style={{ height: 538 }}
          currentProgress={
            !token || currentPasswordState.resetPassword.requested ? 1 : 0
          }
          items={[
            {
              key: 1,
              component: (props: any): React.ReactElement => (
                <Form {...props} token={token} />
              ),
            },
            {
              key: 2,
              component: (props: any): React.ReactElement => (
                <Result {...props} />
              ),
            },
          ]}
        />
      </section>
      <Footer />
    </div>
  )
}
