import React, { useState, useEffect } from 'react'
import { useReactiveVar } from '@apollo/client'
import { useRouteMatch } from 'react-router-dom'
import classNames from 'classnames'

import Footer from './footer'
import Navigation from './navigation'
import OnboardingProgressWidget from './onboarding-widget'
import { currentUserDetails } from '../api/apollo/variables'
import useOnboarding from '../hooks/useOnboarding'
import { getLocalItem } from '../helpers/local-client'
import styles from '../styles/sitewrapper.module.scss'

interface Props {
  children?: React.ReactNode
  noFooter?: boolean
  className?: string
  justLogo?: boolean
  twoPanelsSignUp?: boolean
}

export default function SiteWrapper({
  children,
  noFooter,
  className,
  justLogo,
  twoPanelsSignUp,
}: Props) {
  const { workspaceID } = useReactiveVar(currentUserDetails)

  const [showOnboardingWidget, setShowOnboardingWidget] = useState(false)

  const { mergedOnboardingSections } = useOnboarding()

  const widgetMatch = useRouteMatch(['/welcome'])

  useEffect(() => {
    if (workspaceID) {
      let widgetHidden = getLocalItem('user-session-data')
      widgetHidden = widgetHidden === null ? false : widgetHidden[workspaceID]
      widgetHidden = widgetHidden ? widgetHidden.hideOnboardingWidget : false
      setShowOnboardingWidget(!widgetHidden)
    }
  }, [workspaceID])

  return (
    <div
      className={classNames(styles.site, {
        [styles.justLogoSite]: justLogo,
        [styles.twoPanelsSignUp]: twoPanelsSignUp,
      })}
    >
      <Navigation justLogo={justLogo} />
      <main className={className}>
        <>
          {children}
          {workspaceID &&
            showOnboardingWidget &&
            !widgetMatch &&
            mergedOnboardingSections &&
            mergedOnboardingSections.length > 0 && (
              <OnboardingProgressWidget
                currentAccount={workspaceID}
                setShowOnboardingWidget={setShowOnboardingWidget}
              />
            )}
        </>
      </main>
      {!noFooter && <Footer />}
    </div>
  )
}
