import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client'

import { currentUserDetails } from '../api/apollo/variables'
import {
  connectGa4Account,
  connectGaAccount,
  getAvailableGAAccounts,
  reconnectGaAccount,
} from '../api/graphql/oauth-client'
import { getAccountDataSource } from '../api/graphql/workspace-client'
import Button from '../components/button'
import Input, { Label } from '../components/input'
import Link from '../components/link'
import { Preloader } from '../components/loader'
import { FieldSlot, FormRow, LabelSlot } from '../components/row'
import SelectBox from '../components/select-box'
import { supportEmail } from '../core/constants'
import { getItemByKeyValue } from '../helpers'
import useOnboarding from '../hooks/useOnboarding'
import styles from '../styles/integrations-settings.module.scss'

interface GAViewProps {
  viewID: string
  viewName: string
  viewDisplayName: string
}

interface GAPropertyProps {
  propertyID: string
  propertyName: string
  propertyDisplayName: string
  views?: GAViewProps[]
}

interface GAAccountProps {
  accountID: string
  accountName: string
  accountDisplayName: string
  properties: GAPropertyProps[]
}

interface ConnectorLocationState {
  connectorID: string
  code: string
  state: string
  credentials?: string
  reconnect?: boolean
}

interface GA4Form {
  propertyType: 'ga4AccountList'
  account: GAAccountProps | null
  property: GAPropertyProps | null
}

interface UAForm {
  propertyType: 'uaAccountList'
  account: GAAccountProps | null
  property: GAPropertyProps | null
  view: GAViewProps | null
}

type GAForm = GA4Form | UAForm

function isGA4Form(form: GAForm): form is GA4Form {
  return form.propertyType === 'ga4AccountList'
}

interface GA4Details {
  status: 'active' | 'requiresReconnect'
  kind: 'GA4'
  ga4AccountName: string
  ga4PropertyName: string
}

interface UADetails {
  status: 'active' | 'requiresReconnect'
  kind: 'Universal Analytics'
  gaAccountName: string
  gaPropertyName: string
  gaViewName: string
}

type GADetails = GA4Details | UADetails

function isGA4Details(details: GADetails): details is GA4Details {
  return details.kind === 'GA4'
}

const GoogleAnalyticsConnector = () => {
  const { workspaceID } = useReactiveVar(currentUserDetails)

  const { state: locationState } = useLocation<ConnectorLocationState>()

  const { updateOnboardingSection } = useOnboarding()

  const credentials = locationState?.credentials || null
  const reconnect = locationState?.reconnect || false

  const [
    fetchDataSource,
    {
      data: connectorData,
      loading: loadingConnectorStatus,
      error: errorFetchingIntegrationStatus,
    },
  ] = useLazyQuery(getAccountDataSource)
  const [
    getGAAccounts,
    { data: gaAccountsData, loading: loadingIntegrationData },
  ] = useLazyQuery(getAvailableGAAccounts)

  const [
    connectUAAccount,
    { loading: loadingConnectUA, error: errorConnectingUA },
  ] = useMutation(connectGaAccount)
  const [
    connectGA4Account,
    { loading: loadingConnectGA4, error: errorConnectingGA4 },
  ] = useMutation(connectGa4Account)
  const [
    reconnectGA,
    { loading: loadingReconnect, error: errorReconnecting },
  ] = useMutation(reconnectGaAccount)

  const [accountData, setAccountData] = useState<GAAccountProps[]>([])
  const [form, setForm] = useState<GAForm>({
    propertyType: 'ga4AccountList',
    account: null,
    property: null,
  })
  const [connectionSuccess, setConnectionSuccess] = useState(false)

  useEffect(() => {
    fetchDataSource()
  }, [])

  // Check if the connector is already active
  // Or requires a reconnect
  const connectorDetails: GADetails | null = useMemo(() => {
    const details = connectorData?.currentAccount.dataSource

    if (!details || ['GA_VIEW', 'GA4_PROP'].indexOf(details.kind) === -1)
      return null

    if (details.kind === 'GA_VIEW') {
      return {
        status: details.connected ? 'active' : 'requiresReconnect',
        kind: 'Universal Analytics',
        gaAccountName: details.gaAccountName || '',
        gaPropertyName: details.gaPropertyName || '',
        gaViewName: details.gaViewName || '',
      }
    }

    return {
      status: details.connected ? 'active' : 'requiresReconnect',
      kind: 'GA4',
      ga4AccountName: details.ga4AccountName || '',
      ga4PropertyName: details.ga4PropertyName || '',
    }
  }, [connectorData])

  // If not connected, fetch accounts
  useEffect(() => {
    if (connectorData && !connectorDetails && credentials) {
      getGAAccounts({
        variables: { credentials },
      })
    }

    if (
      connectorDetails?.status === 'requiresReconnect' &&
      reconnect &&
      credentials
    ) {
      reconnectGA({
        variables: {
          credentials,
          accountID: workspaceID,
        },
        refetchQueries: [getAccountDataSource],
      })
    }
  }, [connectorData, connectorDetails, credentials])

  const fullAccountsList = useMemo(() => {
    if (!gaAccountsData)
      return {
        ga4AccountList: [],
        uaAccountList: [],
      }

    return gaAccountsData.accountSettings.dataSourceQueries.availableGAAccounts
  }, [gaAccountsData])

  // Set list of accounts
  useEffect(() => {
    const allAccounts = fullAccountsList[form.propertyType]

    const availableAccounts = allAccounts.filter((acc) => {
      if (form.propertyType === 'uaAccountList') {
        return (
          acc.properties.length > 0 &&
          acc.properties.some(
            // @ts-ignore
            (prop) => prop.views && prop.views.length > 0,
          )
        )
      }

      return acc.properties.length > 0
    })

    setAccountData(availableAccounts)

    setForm({
      propertyType: form.propertyType,
      account: null,
      property: null,
      view: null,
    })
  }, [form.propertyType, fullAccountsList])

  if (loadingConnectorStatus || loadingIntegrationData || loadingReconnect) {
    return <Preloader />
  }

  if (
    errorFetchingIntegrationStatus ||
    errorConnectingUA ||
    errorConnectingGA4 ||
    errorReconnecting
  ) {
    return (
      <p className={styles.errorMsg}>
        There was an error connecting to your Google Analytics account. Please
        try again later or contact support (
        <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>
        ).
      </p>
    )
  }

  return (
    <div>
      {connectorDetails?.status === 'active' && (
        <p className={styles.tenantSuccess}>
          Your Google Analytics account is connected.
        </p>
      )}
      {/* Full accounts list is only fetched when attempting to connect */}
      {connectionSuccess ||
      (fullAccountsList.ga4AccountList.length === 0 &&
        fullAccountsList.uaAccountList.length === 0) ? (
        <>
          <p>
            <strong>Account type:</strong>{' '}
            {!connectorDetails ? '(Not connected)' : connectorDetails.kind}
          </p>
          <p>
            <strong>Account name:</strong>{' '}
            {!connectorDetails ? (
              '(Not connected)'
            ) : (
              <>
                {isGA4Details(connectorDetails)
                  ? connectorDetails.ga4AccountName
                  : connectorDetails.gaAccountName}
              </>
            )}
          </p>
          <p>
            <strong>Property name:</strong>{' '}
            {!connectorDetails ? (
              '(Not connected)'
            ) : (
              <>
                {isGA4Details(connectorDetails)
                  ? connectorDetails.ga4PropertyName
                  : connectorDetails.gaPropertyName}
              </>
            )}
          </p>
          {connectorDetails && !isGA4Details(connectorDetails) && (
            <p>
              <strong>View name:</strong> {connectorDetails.gaViewName}
            </p>
          )}
        </>
      ) : (
        <>
          <p>
            We recommend connecting to your website's main property, excluding
            bot traffic.
          </p>
          <form
            onSubmit={async (e) => {
              e.preventDefault()

              if (!form.account || !form.property) return

              let success = false

              if (isGA4Form(form)) {
                const { data } = await connectGA4Account({
                  variables: {
                    accountID: workspaceID,
                    creds: credentials,
                    dataSourceDesc: {
                      gaAccountID: form.account.accountID,
                      gaPropertyID: form.property.propertyID,
                      gaAccountName: form.account.accountName,
                      gaPropertyName: form.property.propertyName,
                      dataSourceType: 'GA4_PROP',
                    },
                  },
                })

                if (data) success = true
              } else {
                if (!form.view) return

                const { data } = await connectUAAccount({
                  variables: {
                    accountID: workspaceID,
                    creds: credentials,
                    dataSourceDesc: {
                      gaAccountID: form.account.accountID,
                      gaPropertyID: form.property.propertyID,
                      gaAccountName: form.account.accountName,
                      gaPropertyName: form.property.propertyName,
                      gaViewID: form.view.viewID,
                      gaViewName: form.view.viewName,
                      dataSourceType: 'GA_VIEW',
                    },
                  },
                })

                if (data) success = true
              }

              if (success) {
                // Update onboarding sections
                updateOnboardingSection('connectAnalytics', 'account')
                setConnectionSuccess(true)
                fetchDataSource({ fetchPolicy: 'network-only' })
              }
            }}
          >
            <FormRow last className={styles.propertyTypeFormRow}>
              <LabelSlot column>
                <Label id="accountType">Property Type</Label>
              </LabelSlot>
              <FieldSlot column className={styles.propertyTypeSelector}>
                <Input
                  name="property-type-ga4"
                  id="property-type-ga4"
                  label="GA4"
                  type="radio"
                  value="GA4"
                  checked={form.propertyType === 'ga4AccountList'}
                  onClick={() =>
                    setForm({ ...form, propertyType: 'ga4AccountList' })
                  }
                />
                <Input
                  name="property-type-ua"
                  id="property-type-ua"
                  label="Universal Analytics"
                  type="radio"
                  value="UA"
                  checked={form.propertyType === 'uaAccountList'}
                  onClick={() =>
                    setForm({
                      ...form,
                      propertyType: 'uaAccountList',
                      view: null,
                    })
                  }
                />
              </FieldSlot>
            </FormRow>
            <FormRow last>
              <LabelSlot column>
                <Label id="account">Account</Label>
              </LabelSlot>
              <FieldSlot column>
                <SelectBox
                  key={`account${accountData.length}`}
                  id="account"
                  isDisabled={accountData.length === 0}
                  placeholder="Choose your account"
                  labelKey="accountDisplayName"
                  valueKey="accountID"
                  value={form.account}
                  options={accountData}
                  onChange={(newValue) => {
                    if (!newValue) return

                    const acc = getItemByKeyValue(
                      accountData,
                      'accountID',
                      newValue.accountID,
                    )

                    const newForm: GAForm = {
                      ...form,
                      account: acc === -1 ? null : acc,
                      property: null,
                    }

                    if (!isGA4Form(newForm)) {
                      newForm.view = null
                    }

                    setForm(newForm)
                  }}
                />
              </FieldSlot>
            </FormRow>
            {form.account?.accountID && (
              <>
                <FormRow last>
                  <LabelSlot column>
                    <Label id="property">Property</Label>
                  </LabelSlot>
                  <FieldSlot column>
                    <SelectBox
                      key={`property-${form.account.accountID}`}
                      id="property"
                      isDisabled={!form.account}
                      placeholder="Choose your property"
                      labelKey="propertyDisplayName"
                      valueKey="propertyID"
                      value={form.property}
                      options={form.account.properties}
                      onChange={(newValue) => {
                        if (!newValue) return

                        const prop = form.account
                          ? getItemByKeyValue(
                              form.account.properties,
                              'propertyID',
                              newValue.propertyID,
                            )
                          : null

                        const newForm: GAForm = {
                          ...form,
                          property: prop === -1 ? null : prop,
                        }

                        if (!isGA4Form(newForm)) {
                          newForm.view = null
                        }

                        setForm(newForm)
                      }}
                    />
                  </FieldSlot>
                </FormRow>
                {!isGA4Form(form) && form.property?.propertyID && (
                  <FormRow last>
                    <LabelSlot column>
                      <Label id="view">View</Label>
                    </LabelSlot>
                    <FieldSlot column>
                      <SelectBox
                        key={`view-${form.property.propertyID}`}
                        id="view"
                        isDisabled={!form.property}
                        placeholder="Choose your view"
                        labelKey="viewDisplayName"
                        valueKey="viewID"
                        value={form.view}
                        options={form.property.views}
                        onChange={(newValue) => {
                          if (!newValue) return

                          const siteView = form.property
                            ? getItemByKeyValue(
                                form.property.views,
                                'viewID',
                                newValue.viewID,
                              )
                            : null

                          const newForm: UAForm = {
                            ...form,
                            view: siteView === -1 ? null : siteView,
                          }

                          setForm(newForm)
                        }}
                      />
                    </FieldSlot>
                  </FormRow>
                )}
              </>
            )}
            <Button
              type="submit"
              className={styles.addDataSourceBtn}
              isDisabled={
                isGA4Form(form)
                  ? !form.account || !form.property
                  : !form.account || !form.property || !form.view
              }
              loading={loadingConnectUA || loadingConnectGA4}
            >
              {connectorDetails?.status === 'active' ? 'Add' : 'Change'} data
              source
            </Button>
          </form>
        </>
      )}
      {connectorDetails?.status === 'requiresReconnect' && (
        <p className={styles.errorMsg}>
          Your account needs to be reconnected. Press the 'Reconnect' button
          below.
        </p>
      )}
    </div>
  )
}

export default GoogleAnalyticsConnector
