import React, {
  useState,
  useRef,
  ChangeEvent,
  useEffect,
  forwardRef,
} from 'react'
import classNames from 'classnames'
import ReactMarkdown from 'react-markdown'

import { ButtonRow } from './button-row'
import Button, { ClearButton } from './button'
import InfoModal from './info-modal'
import { LoadingLabel } from './loader'
import Tooltip from './tooltip'
import { markdownHelp } from '../core/constants'
import { copyString } from '../helpers'
import useMobile from '../hooks/useMobile'
import styles from '../styles/input.module.scss'

interface LabelProps {
  id?: string
  children: any
  className?: string
  style?: object
  heading?: boolean
  modalHeading?: boolean
  onClick?: (event: React.MouseEvent) => void
  onKeyDown?: (event: React.FormEvent) => void
  optional?: string
  showRequired?: boolean
  small?: boolean
  tooltip?: React.ReactElement | string
  tooltipClickable?: boolean
}

export function Label({
  id,
  children,
  className,
  style = {},
  heading,
  onClick,
  onKeyDown,
  optional,
  modalHeading,
  showRequired = false,
  small,
  tooltip,
  tooltipClickable,
}: LabelProps) {
  return (
    <label
      role="presentation"
      htmlFor={id}
      className={classNames(className, styles.label, {
        [styles.heading]: heading,
        [styles.modalHeading]: modalHeading,
        [styles.smallLabel]: small,
      })}
      style={style}
      onClick={onClick}
      onKeyDown={onKeyDown}
    >
      {tooltip ? (
        <Tooltip
          id={`${id}-tooltip`}
          useIcon
          tooltipPosition="right"
          tooltipMessage={tooltip}
          clickable={tooltipClickable}
        >
          {children}
          {showRequired && !optional && (
            <span className={styles.required}>*</span>
          )}
        </Tooltip>
      ) : (
        <>
          {children}
          {showRequired && !optional && (
            <span className={styles.required}>*</span>
          )}
        </>
      )}

      {optional && (
        <>
          {' '}
          <b className={styles.optional}>{optional}</b>
        </>
      )}
    </label>
  )
}

interface Props extends React.InputHTMLAttributes<any> {
  id?: string
  name: string
  label?: string | React.ReactNode
  style?: any
  required?: boolean
  placeholder?: string
  onKeyUp?: any
  onKeyDown?: any
  onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onClick?: (event: React.MouseEvent) => void
  onValueChange?: (value: string) => void
  beforeChange?: (value: string) => string
  onChange?: any
  error?: boolean
  className?: string
  children?: any
  autoComplete?: string
  autoCorrect?: string
  autoCapitalize?: string
  prefix?: any
  suffix?: string | React.ReactNode
  delay?: number
  disabled?: boolean
  showClear?: boolean
  autoFocus?: boolean
  multilineInput?: boolean
  maxLength?: number
  textAreaHeight?: number | null
  lineBreakAll?: boolean
}

const Input = forwardRef(
  (
    {
      id,
      name,
      label,
      style,
      value,
      checked,
      readOnly,
      required,
      type,
      placeholder,
      onChange,
      onPaste,
      onValueChange,
      beforeChange,
      onKeyUp,
      onKeyDown,
      onBlur,
      onFocus,
      onClick,
      error,
      className,
      autoComplete,
      autoCorrect,
      autoCapitalize,
      spellCheck,
      prefix,
      suffix,
      children,
      delay,
      disabled,
      showClear,
      autoFocus,
      multilineInput = false,
      maxLength,
      textAreaHeight = null,
      lineBreakAll = true,
      hidden,
    }: Props,
    fwdRef: React.ForwardedRef<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const rest = {
      type,
      checked,
      readOnly,
      autoComplete,
      onFocus,
      required,
      autoCorrect,
      autoCapitalize,
      spellCheck,
      disabled,
      autoFocus,
      hidden,
    }

    const [inputValue, setInputValue] = useState(value)
    const [timeoutHandle, setTimeoutHandle] = useState<null | number>(null)

    useEffect(() => {
      setInputValue(value)
    }, [value])

    const inputClassNames = classNames(className, styles.container, {
      [styles.error]: error,
      [styles.disabled]: disabled,
      [styles.checkboxContainer]: type === 'checkbox',
      [styles.hidden]: hidden,
    })

    const inputRef = useRef(inputValue)
    inputRef.current = inputValue
    // const timeoutRef = useRef(inputValue);
    // let timeout

    const isEmptyValue =
      inputValue === '' ||
      inputValue === null ||
      (Array.isArray(inputValue) &&
        inputValue.length === 1 &&
        inputValue[0] === '')

    const outerSpanClick = (event: any) => {
      if (event.target.tagName.toLowerCase() === 'span') {
        const inputElement =
          event.target.querySelector('input') ||
          event.target.querySelector('textarea')
        inputElement.focus()
      }
    }

    const inputElement = (
      <>
        {type === 'textArea' ? (
          <span
            className={classNames(styles.inputWrapper, {
              [styles.hidden]: hidden,
            })}
          >
            <textarea
              style={{
                height: textAreaHeight ? `${textAreaHeight}px` : 'inherit',
              }}
              ref={fwdRef as React.RefObject<HTMLTextAreaElement>}
              maxLength={maxLength || undefined}
              onChange={(event: ChangeEvent<HTMLTextAreaElement>): void => {
                const { value: val } = event.target
                const targetInputValue = beforeChange ? beforeChange(val) : val

                setInputValue(targetInputValue)

                if (!delay) {
                  if (onChange) {
                    onChange(val)
                  }
                  if (onValueChange) {
                    onValueChange(
                      typeof targetInputValue === 'string'
                        ? targetInputValue
                        : '',
                    )
                  }
                } else {
                  const timeout = window.setTimeout(() => {
                    if (onChange) {
                      onChange(inputRef.current)
                    }
                    if (onValueChange) {
                      onValueChange(
                        typeof targetInputValue === 'string'
                          ? targetInputValue
                          : '',
                      )
                    }
                  }, delay)
                  setTimeoutHandle(timeout)
                }
              }}
              placeholder={
                placeholder || (typeof label === 'string' ? label : '')
              }
              id={id || name}
              name={name}
              className={classNames(styles.textArea, {
                [styles.smallTextArea]: multilineInput,
                [styles.wordBreak]: lineBreakAll,
              })}
              value={inputValue}
              onKeyDown={(e) => {
                if (onKeyDown) {
                  onKeyDown(e)
                }
                // Tab key blurs the input - do not clear timeout
                if (timeoutHandle && e.key !== 'Tab') {
                  window.clearTimeout(timeoutHandle)
                }
              }}
              onClick={(e) => {
                if (onClick && label === '') {
                  onClick(e)
                }
              }}
              onKeyUp={(e) => {
                if (multilineInput && onKeyUp) {
                  onKeyUp(e)
                }
              }}
              onBlur={onBlur}
              {...rest}
            />
          </span>
        ) : (
          <span
            className={classNames(styles.inputWrapper, {
              [styles.hidden]: hidden,
            })}
          >
            {prefix && <em className={styles.prefix}>{prefix}</em>}
            <input
              ref={fwdRef as React.RefObject<HTMLInputElement>}
              onPaste={(e) => {
                if (onPaste) {
                  onPaste(e)
                  e.preventDefault()
                }
              }}
              onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                if (readOnly) {
                  event.preventDefault()
                  event.stopPropagation()
                  return
                }
                const { value: val } = event.target

                const targetInputValue = beforeChange ? beforeChange(val) : val

                setInputValue(targetInputValue)

                if (targetInputValue === '') {
                  if (onChange) {
                    onChange(event)
                  }
                  if (onValueChange) {
                    onValueChange(targetInputValue)
                  }
                } else if (!delay) {
                  if (onChange) {
                    onChange(event)
                  }
                  if (onValueChange) {
                    onValueChange(
                      typeof targetInputValue === 'string'
                        ? targetInputValue
                        : '',
                    )
                  }
                } else {
                  const timeout = window.setTimeout(() => {
                    if (onChange) {
                      onChange(event)
                    }
                    if (onValueChange) {
                      onValueChange(
                        typeof targetInputValue === 'string'
                          ? targetInputValue
                          : '',
                      )
                    }
                  }, delay)

                  setTimeoutHandle(timeout)
                }
              }}
              placeholder={
                placeholder || (typeof label === 'string' ? label : '')
              }
              id={id || name}
              name={name}
              className={styles.input}
              value={inputValue}
              onClick={(e) => {
                if (readOnly) {
                  e.preventDefault()
                  e.stopPropagation()
                  return
                }
                if (onClick && label === '') {
                  onClick(e)
                }
              }}
              {...rest}
              onKeyDown={(event) => {
                if (onKeyDown) {
                  onKeyDown(event)
                }
                // Tab key blurs the input - do not clear timeout
                if (timeoutHandle && event.key !== 'Tab') {
                  window.clearTimeout(timeoutHandle)
                }
              }}
              onKeyUp={(event) => {
                if (onKeyUp) {
                  onKeyUp(event)
                }
              }}
              onBlur={onBlur}
            />

            {suffix && <span className={styles.suffix}>{suffix}</span>}
            {type === 'checkbox' ? (
              <>
                <b className={error ? styles.checkboxError : undefined} />
                <span className={styles.labelText}>{label}</span>
              </>
            ) : (
              type === 'radio' && (
                <>
                  <em className={styles.radioControl} />
                  <span className={styles.labelText}>{label}</span>
                </>
              )
            )}
            {showClear && !isEmptyValue && (
              <ClearButton
                excludeFromTabOrder
                className={styles.clearButton}
                aria-label="cancel"
                onPress={(event) => {
                  setInputValue('')

                  if (onChange) onChange(event)

                  if (onValueChange) onValueChange('')
                }}
              />
            )}
          </span>
        )}
      </>
    )

    if (label === '') {
      return (
        <span
          className={inputClassNames}
          style={style}
          role="button"
          tabIndex={0}
          onClick={outerSpanClick}
          onKeyDown={outerSpanClick}
        >
          {inputElement}
          {children}
        </span>
      )
    }

    return (
      <Label
        id={id || name}
        className={inputClassNames}
        style={style}
        onClick={(e) => {
          if (onClick) {
            onClick(e)
          }
        }}
      >
        {inputElement}
        {children}
      </Label>
    )
  },
)

Input.defaultProps = {
  type: 'text',
  style: {},
  label: '',
  value: '',
  checked: false,
  onChange: null,
  readOnly: false,
  required: false,
}

interface SearchInputProps {
  id?: string
  className?: string
  value?: string
  selectValue?: string
  onChange: (newVal: string | undefined) => void
  delay?: number
  searchTypeList?: { name: string; value: string }[]
  onChangeSearchType?: (type: string) => void
  children?: any
  loading?: boolean
  loadingLabel?: string
  onKeyUp?: (e: KeyboardEvent) => void
}

export function SearchInput({
  id,
  value = '',
  selectValue = '',
  className,
  onChange,
  delay,
  searchTypeList,
  onChangeSearchType,
  children,
  loading,
  loadingLabel = 'Loading',
  onKeyUp,
}: SearchInputProps): React.ReactElement {
  const [currentValue, setCurrentValue] = useState('')
  const [currentSelectValue, setCurrentSelectValue] = useState('')

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  useEffect(() => {
    setCurrentSelectValue(selectValue)
  }, [selectValue])

  const inputClassNames = classNames(styles.searchInput, {
    [styles.searchInputOnly]: !(onChangeSearchType || searchTypeList),
  })

  const wrapperInputClassNames = classNames(
    className,
    styles.searchInputWrapper,
  )

  return (
    <div className={styles.searchContainer}>
      <div className={wrapperInputClassNames}>
        {onChangeSearchType && searchTypeList && (
          <span className={styles.selectBoxWrapper}>
            <select
              id={`select-${id}`}
              name={`select-${id}`}
              value={currentSelectValue}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                const { value: val } = e.target as HTMLSelectElement
                setCurrentSelectValue(val)

                if (onChangeSearchType) onChangeSearchType(val)
              }}
            >
              {searchTypeList.map((item) => (
                <option value={item.value} key={item.value}>
                  {item.name}
                </option>
              ))}
            </select>
          </span>
        )}
        <Input
          className={inputClassNames}
          name="search"
          id={id}
          onValueChange={(val: string | undefined) => {
            setCurrentValue(val || '')
            onChange(val)
          }}
          onKeyUp={onKeyUp}
          delay={delay}
          value={currentValue}
          placeholder="Search"
          autoComplete="off"
          type="text"
        />
      </div>
      <div className={styles.searchSummary}>
        {loading ? <LoadingLabel label={loadingLabel} /> : children}
      </div>
    </div>
  )
}

interface ClickEditInputProps {
  id: string
  name?: string
  value?: string
  className?: string
  onChange: (val: string) => void
  type?: 'textArea' | 'text'
  placeholder?: string
  inputWhenEmpty?: boolean
  enableMD?: boolean
  viewOnly?: boolean
  viewOnlyShowIcon?: boolean
  formatValue?: (value: string) => React.ReactElement
  showBorder?: boolean
  beforeChange?: (val: string) => string
  showCancel?: boolean
  hide?: boolean
  disabled?: boolean
  multilineInput?: boolean
  maxLength?: number
  textAreaHeight?: number | null
  prefix?: any
  disableEditFn?: () => void
  formatSeparators?: boolean
}

export function ClickEditInput({
  id,
  name,
  value = '',
  type,
  className,
  onChange,
  placeholder = '',
  inputWhenEmpty = false,
  enableMD = true,
  viewOnly = false,
  viewOnlyShowIcon = false,
  formatValue,
  showBorder = false,
  beforeChange,
  showCancel = false,
  hide = false,
  disabled = false,
  multilineInput = false,
  maxLength,
  textAreaHeight = null,
  prefix,
  disableEditFn,
  /** Prevents \n and , from being replaced with ;. Useful if multiline input is supposed to be JSON */
  formatSeparators = true,
}: ClickEditInputProps) {
  const mobile = useMobile(769)
  const [editActive, setEditActive] = useState(false)
  const [currentValue, setCurrentValue] = useState(value)
  const [showFormattingHelp, setShowFormattingHelp] = useState(false)

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  const finishedEditing = () => {
    setEditActive(false)

    if (currentValue !== value) {
      if (multilineInput && formatSeparators) {
        onChange(currentValue.replaceAll('\n', ';').replaceAll(',', ';'))
        setCurrentValue(value.replaceAll('\n', ';').replaceAll(',', ';'))
      } else {
        onChange(currentValue)
        setCurrentValue(value)
      }
    }
  }

  const cancelEditing = () => {
    setEditActive(false)
    setCurrentValue(value)
  }

  const shouldBeActive = inputWhenEmpty && currentValue === ''
  const showEditField = mobile || editActive || shouldBeActive

  useEffect(() => {
    if (showEditField && multilineInput && formatSeparators) {
      // Replace semicolons with new lines
      setCurrentValue(currentValue.replaceAll(';', '\n'))
    }
  }, [showEditField])

  if (viewOnly) {
    const viewOnlyClassNames = classNames(
      className,
      styles.clickEditViewOnlyInput,
      {
        [styles.viewOnlyShowIcon]: viewOnlyShowIcon,
      },
    )

    return (
      <>
        <div className={viewOnlyClassNames}>
          {type === 'textArea' && (
            <div className={styles.markDown} role="button" tabIndex={-1}>
              <ReactMarkdown source={value || placeholder} />
            </div>
          )}
          {type !== 'textArea' && (
            <Label id={id}>{value === '' ? placeholder : value}</Label>
          )}
        </div>
      </>
    )
  }

  const useValue = value === '' ? placeholder : value

  return (
    <>
      <div
        className={classNames(className, styles.clickEditInput, {
          [styles.disabled]: disabled,
          [styles.clickEditInputActive]: editActive,
          [styles.showBorder]: showBorder,
          [styles.clickEditInputTextArea]:
            type === 'textArea' && !multilineInput,
          [styles.hide]: hide,
        })}
      >
        {!showEditField && type === 'textArea' && !multilineInput && (
          <div
            className={styles.markDown}
            role="button"
            tabIndex={-1}
            onKeyDown={() => {
              if (disabled) return

              if (disableEditFn) {
                disableEditFn()
                return
              }

              setEditActive(true)
            }}
            onClick={() => {
              if (disabled) return

              if (disableEditFn) {
                disableEditFn()
                return
              }

              setEditActive(true)
            }}
          >
            <ReactMarkdown source={useValue} />
          </div>
        )}
        {!showEditField && (type !== 'textArea' || multilineInput) && (
          <Label
            id={id}
            onKeyDown={() => {
              if (disabled) return

              if (disableEditFn) {
                disableEditFn()
                return
              }

              setEditActive(true)
            }}
            className={classNames({
              [styles.clickEditInputEmpty]: value === '',
            })}
            onClick={(e: React.MouseEvent) => {
              e.preventDefault()
              e.stopPropagation()

              if (disabled) return

              if (disableEditFn) {
                disableEditFn()
                return
              }

              setEditActive(true)
            }}
          >
            {formatValue ? formatValue(useValue) : useValue}
          </Label>
        )}
        {showEditField && (
          <>
            <Input
              prefix={prefix}
              autoFocus
              name={name || id}
              id={id}
              className={classNames(styles.clickEditInputContainer, {
                [styles.multilineInput]: multilineInput,
              })}
              value={currentValue}
              autoComplete="off"
              placeholder={placeholder}
              type={type || 'text'}
              multilineInput={multilineInput}
              maxLength={maxLength}
              textAreaHeight={textAreaHeight}
              beforeChange={beforeChange}
              onChange={(
                event: React.ChangeEvent<HTMLInputElement> | string,
              ) => {
                const val =
                  typeof event === 'string' ? event : event.target.value

                const resultVal = beforeChange ? beforeChange(val) : val

                setCurrentValue(resultVal)
              }}
              onFocus={() => {
                if (disabled) return

                if (disableEditFn) {
                  disableEditFn()
                  return
                }

                setEditActive(true)
              }}
              // onBlur={() => {
              //   finishedEditing()
              // }}
              onKeyUp={(event) => {
                if (event.keyCode === 13 && !disabled && !multilineInput) {
                  finishedEditing()
                }
              }}
              onClick={(e: React.MouseEvent) => {
                e.preventDefault()
                e.stopPropagation()
              }}
            />
            {editActive && (type !== 'textArea' || multilineInput) && (
              <>
                <Button
                  className={styles.inputButton}
                  variant="text"
                  isDisabled={disabled}
                  onPress={(e) => {
                    // e.preventDefault()
                    // e.stopPropagation()

                    finishedEditing()
                  }}
                >
                  OK
                </Button>
                {showCancel && (
                  <ClearButton
                    className={styles.cancelButton}
                    aria-label="cancel"
                    onPress={(e) => {
                      // e.preventDefault()
                      // e.stopPropagation()
                      cancelEditing()
                    }}
                  >
                    Cancel
                  </ClearButton>
                )}
              </>
            )}
          </>
        )}
        {editActive && type === 'textArea' && !multilineInput && (
          <ButtonRow className={styles.buttonRow}>
            {enableMD && (
              <Button
                color="grey"
                aria-label="help"
                onPress={() => setShowFormattingHelp(true)}
              >
                Formatting Help
              </Button>
            )}
            <Button
              variant="secondary"
              color="blue"
              aria-label="cancel"
              onPress={() => cancelEditing()}
            >
              Cancel
            </Button>
            <Button
              variant="secondary"
              type="submit"
              isDisabled={disabled}
              onPress={() => finishedEditing()}
            >
              Save
            </Button>
          </ButtonRow>
        )}
      </div>
      {showFormattingHelp && (
        <InfoModal
          title="Formatting Help"
          onToggle={() => {
            setShowFormattingHelp(false)
          }}
        >
          <ReactMarkdown source={markdownHelp} />
        </InfoModal>
      )}
    </>
  )
}

interface PassiveInputRowProps {
  id: string
  value: string
  row?: boolean
  copy?: boolean
}

export const PassiveInput = ({
  id,
  value,
  row,
  copy = false,
}: PassiveInputRowProps) => {
  const passiveClassNames = classNames(styles.input, {
    [styles.rowInput]: row,
  })
  return (
    <span className={styles.passiveContainer}>
      <input
        type="text"
        className={passiveClassNames}
        id={id}
        name={id}
        value={value}
        readOnly
      />
      {copy && (
        <Button
          variant="text"
          className={styles.inputButton}
          onPress={() => copyString(value)}
        >
          Copy
        </Button>
      )}
    </span>
  )
}

export default Input
