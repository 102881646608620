import React from 'react'
import classNames from 'classnames'

import uplifter from '../assets/logos/uplifter-arrow.png'
import styles from '../styles/loader.module.scss'

interface PreloaderProps {
  className?: string
  style?: React.CSSProperties
}

export function Preloader({ className, style }: PreloaderProps) {
  return (
    <div className={classNames(styles.gifContainer, className)} style={style}>
      <div />
      <div />
      <div />
      <div />
    </div>
  )
}
interface Props {
  className?: string
  minHeight?: string
  height?: string
  light?: boolean
  dark?: boolean
  blank?: boolean
  children?: React.ReactNode
}

export default function Loader({
  className,
  minHeight,
  height,
  light,
  dark,
  blank,
  children,
}: Props): React.ReactElement {
  let filter = ''
  if (light) {
    filter = 'brightness(1.2)'
  } else if (dark) {
    filter = 'brightness(0.5)'
  }

  return (
    <div
      className={classNames(styles.container, className)}
      style={{ height, minHeight, filter }}
    >
      <span
        className={`${blank ? styles.blank : styles.gradient}`}
        style={{ height, minHeight }}
      >
        {children}
      </span>
    </div>
  )
}

export function LoadingLabel({
  label = 'Loading',
  fixedWidth = false,
  className,
}: {
  label?: string
  fixedWidth?: boolean
  className?: string
}): React.ReactElement {
  return (
    <span
      className={classNames(styles.animatedDots, className, {
        [styles.fixedWidth]: fixedWidth,
      })}
    >
      <span>
        {label}{' '}
        <b>
          <b />
          <b />
          <b />
        </b>
      </span>
    </span>
  )
}

interface LoadingLogoProps {
  fullScreen?: boolean
}

export function LoadingLogo({ fullScreen = false }: LoadingLogoProps) {
  return (
    <div
      className={classNames(styles.logoContainer, {
        [styles.fullScreen]: fullScreen,
      })}
    >
      <img src={uplifter} alt="uplifter" className={styles.loader} />
    </div>
  )
}
