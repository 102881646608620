import { gql } from '../../__gql-types__/gql'

export const logActionMutation = gql(`
  mutation LogAction (
		$action: String!,
		$functionName: String!,
		$pagePath: String!,
		$websiteSection: String!,
		$extra: String = "",
		$getParams: String = "",
		$requestBody: String = ""
	) {
		authenticatedLogAction(
			action: $action,
			functionName: $functionName,
			pagePath: $pagePath,
			websiteSection: $websiteSection,
			extra: $extra,
			getParams: $getParams,
			requestBody: $requestBody
		) {
			acctID
			userID
			action
		}
	}
`)
