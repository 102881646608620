import FileSaver from 'file-saver'
import moment from 'moment'

import Make from '.'
import { removeSpecialChars } from '../../helpers'
import { PerformanceReportDataConfig } from '../../types/report-module'

const make = Make('report/')

export const getCampaignLinkRawData = async (
  data: PerformanceReportDataConfig,
  fileName?: string,
): Promise<boolean> => {
  const req = await make.post({
    endpoint: 'campaign-link-dashboard-raw-data',
    data,
    options: {
      ...data,
      headers: {
        'Content-Type':
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      responseType: 'arraybuffer',
    },
  })

  if (!req.success) {
    return false
  }

  if (req.res.data) {
    // @ts-ignore
    const blob = new Blob([new Uint8Array(req.res.data)], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const now = new Date(Date.now())
    await FileSaver.saveAs(
      blob,
      `${moment(now).format('YYYY-MM-DD')}_${
        fileName ? removeSpecialChars(fileName) : 'Link_Performance_Export'
      }.xlsx`,
    )
  }

  return true
}

export const getLostLinksRawData = async <T extends {}>(
  data: T,
): Promise<boolean> => {
  const req = await make.post({
    endpoint: '/link-audit-raw-data',
    data,
    options: {
      ...data,
      headers: {
        'Content-Type':
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      responseType: 'arraybuffer',
    },
  })

  if (!req.success) return false

  if (req.res.data) {
    // @ts-ignore
    const blob = new Blob([new Uint8Array(req.res.data)], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const now = new Date(Date.now())
    await FileSaver.saveAs(
      blob,
      `${moment(now).format('YYYY-MM-DD')}_Other_links_audit.xlsx`,
    )
  }

  return true
}

export const marketingJourneysReportAddImageToCard = async ({
  boardID,
  laneID,
  cardID,
  file,
}: {
  boardID: string
  laneID: string
  cardID: string
  file: File
}): Promise<boolean | any> => {
  const formData = new FormData()
  formData.append('file', file)

  const req = await make.post({
    endpoint: `add-image-to-marketing-funnel-card?boardID=${boardID}&laneID=${laneID}&cardID=${cardID}`,
    data: formData,
    options: {
      'Content-Type': 'multipart/form-data',
    },
  })

  if (req.success && req.res.status === 200) {
    return true
  }

  return req.res
}
