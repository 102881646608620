import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import Button from './button'
import DisplayError from './display-error'
import Input from './input'
import SiteLogo, { LogoTagline } from './site-logo'
import { validatePassword } from '../helpers/forms'
import useAuthenticate from '../hooks/useAuthenticate'
import styles from '../styles/reset-password.module.scss'

interface Props {
  token: string
}

export default function Form({ token }: Props): React.ReactElement {
  const { resetPassword } = useAuthenticate()

  const history = useHistory()

  const [response, setResponse] = useState('')
  const [attempts, setAttempts] = useState(0)
  const [password, setPassword] = useState('')
  const [reTypePassword, setReTypePassword] = useState('')

  return (
    <div>
      <SiteLogo link className={styles.svg} />
      <LogoTagline />
      <div className={styles.divider} />
      <form
        onSubmit={(e: React.SyntheticEvent): void => {
          e.preventDefault()

          if (password !== reTypePassword) {
            setAttempts(attempts + 1)
            setResponse('Passwords do not match')

            return
          }

          const validated = validatePassword(password)

          if (validated === '') {
            setAttempts(0)

            resetPassword(token, password)
          } else {
            setAttempts(attempts + 1)
          }
          setResponse(validated)
        }}
      >
        {response && (
          <DisplayError attempts={attempts}>{response}</DisplayError>
        )}
        <Input
          type="password"
          name="password"
          label="New password"
          onChange={(event: React.ChangeEvent<HTMLInputElement>): any =>
            setPassword(event.target.value)
          }
          value={password}
          required
        />
        <Input
          type="password"
          name="re-type-password"
          label="Re-type password"
          onChange={(event: React.ChangeEvent<HTMLInputElement>): any =>
            setReTypePassword(event.target.value)
          }
          value={reTypePassword}
          required
        />
        <Button type="submit" style={{ marginBottom: 16 }}>
          Set new password
        </Button>
        <div className={styles.divider} />
        <h2>Don't have an account?</h2>
        <Button color="grey" onPress={() => history.push('/create-account')}>
          Sign up here
        </Button>
      </form>
    </div>
  )
}
