import Make from '.'
import { InviteContextData, UpdateUserImage, UploadAccountLogo } from '../types'

const make = Make('account/')

export type SignupMethod = 'password' | 'google' | 'microsoft'

export const getInviteContext = async (
  data: InviteContextData,
): Promise<any> => {
  const req = await make.post({
    endpoint: 'invite-context',
    data,
  })

  if (!req.success) {
    return false
  }

  return req
}

export interface RegisterUser {
  fName: string
  lName: string
  password?: string
  token: string
  signUpMethod?: SignupMethod
  nonce?: string
  id_token?: string
  acceptedMarketing: boolean
  planInterest?: string
  oktaClientID?: string
}

export const registerNewUser = async (data: RegisterUser) => {
  const req = await make.post<{
    code: number
    token: string
    runningDebug: boolean
    isNewlyCreated?: boolean
  }>({
    endpoint: 'register-user',
    data,
  })

  return req
}

export interface CreateClient {
  organisation: string
  email: string
  passW?: string
  fName: string
  lName: string
  phoneNum?: string
  signUpMethod: SignupMethod
  acceptedMarketing: boolean
  planInterest: string | null
  nonce?: string
  id_token?: string | null
  microsoftActivationToken?: string
  utm_source?: string
  utm_medium?: string
  utm_campaign?: string
  utm_term?: string
  utm_content?: string
}

export const createClient = async (data: CreateClient) => {
  const req = await make.post({
    endpoint: 'create-new-client',
    data: {
      ...data,
    },
  })

  if (!req.success && !(req.res.status === 403 || req.res.status === 400)) {
    return false
  }

  return req.res
}

export const uploadAccountLogo = async (
  data: UploadAccountLogo,
): Promise<boolean | any> => {
  const formData = new FormData()
  formData.append('file', data.file)

  const req = await make.post({
    endpoint: 'update-account-logo-image',
    options: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  })

  if (req.success && req.res.status === 200) {
    return true
  }

  return req.res
}

export const updateUserImage = async (
  data: UpdateUserImage,
): Promise<boolean | any> => {
  const formData = new FormData()
  formData.append('file', data.file)

  const req = await make.post({
    endpoint: 'upload-user-profile-pic',
    options: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  })

  if (req.success && req.res.status === 200) {
    return true
  }

  return req.res
}

export const uploadQrLogo = async (
  data: UploadAccountLogo,
): Promise<boolean | any> => {
  const formData = new FormData()
  formData.append('file', data.file)

  const req = await make.post({
    endpoint: 'upload-qr-logo-image',
    options: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  })

  if (req.success && req.res.status === 200) {
    return true
  }

  return req.res
}

export const resolveMicrosoftToken = async (
  microsoftActivationToken: string,
): Promise<any> => {
  const req = await make.post({
    endpoint: 'resolve-microsoft-token',
    data: { microsoftActivationToken },
  })

  if (!req.success) {
    return false
  }

  return req.res.data
}

export const unsubscribeMarketing = async ({ uid }): Promise<boolean | any> => {
  const req = await make.get({
    endpoint: `unsubscribe-marketing?uid=${uid}`,
  })

  if (req.success && req.res.status === 200) {
    return true
  }

  return req.res
}

interface GaCampaignData {
  gaClientID: string
  gaSessionID: string
  campaign_id?: string
  campaign?: string
  source?: string
  medium?: string
  term?: string
  content?: string
}

export const sendGaCampaignData = async ({
  gaClientID,
  gaSessionID,
  ...rest
}: GaCampaignData) => {
  try {
    const req = await make.post({
      endpoint: 'ga-measurement-protocol',
      data: {
        client_id: gaClientID,
        session_id: gaSessionID,
        ...rest,
        engagement_time_msec: '1',
      },
    })

    if (!req.success || req.res.status !== 200) {
      throw new Error(
        // @ts-ignore
        req.res?.error?.response?.data?.detail ||
          'Failed to send GA campaign data',
      )
    }

    return req.res.data
  } catch (err) {
    // @ts-ignore
    return Promise.reject(new Error(err.message))
  }
}
