import React from 'react'
import classNames from 'classnames'

import useAnimatedNumber from '../hooks/useAnimateNumber'
import styles from '../styles/progress-bar.module.scss'

interface Props {
  percentage: number
  showPercent?: boolean
  thin?: boolean
  superThin?: boolean
  green?: boolean
  useColors?: boolean
  className?: string
}

export default function ProgressBar({
  percentage,
  showPercent = true,
  thin = false,
  superThin = false,
  green = false,
  useColors = false,
  className,
}: Props): React.ReactElement {
  const animatedPercentage = useAnimatedNumber(percentage, 0)

  return (
    <div
      className={classNames(className, styles.container, {
        [styles.thin]: thin,
        [styles.superThin]: superThin,
      })}
    >
      <span className={styles.progressContainer}>
        <span
          className={classNames(styles.progress, {
            [styles.red]: useColors && percentage >= 66,
            [styles.yellow]: useColors && percentage < 100 && percentage < 66,
            [styles.green]: green || (useColors && percentage < 34),
          })}
          style={{ width: `${animatedPercentage}%` }}
        />
        {showPercent && <b className={styles.number}>{animatedPercentage}%</b>}
      </span>
    </div>
  )
}
