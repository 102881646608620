import React, { useRef } from 'react'
import { AriaLinkOptions, useLink } from 'react-aria'
import { NavLink as RouterLink } from 'react-router-dom'
import classNames from 'classnames'

import styles from '../styles/link.module.scss'

export const ALL_LINK_TYPES = [
  'arrowForward',
  'arrowBack',
  'plainText',
] as const
type LinkTuple = typeof ALL_LINK_TYPES
type LinkVariants = LinkTuple[number]

// react-router-dom link props: https://v5.reactrouter.com/web/api/Link
interface LinkProps extends AriaLinkOptions {
  /** arrowForward, arrowBack or plainText (default) */
  type?: LinkVariants
  className?: string
  style?: React.CSSProperties
  href: string
  newTab?: boolean
  target?: React.HTMLAttributeAnchorTarget
  /** Should the new link replace the existing one in location history (for e.g. pressing Back button)? */
  replace?: boolean
  /** Used to access a state object in the location (useLocation()) of the next page */
  state?: Object
  children?: React.ReactNode
}

/**
 * Link using Uplifter styles
 * https://react-spectrum.adobe.com/react-aria/useLink.html
 */
const Link = ({
  type = 'plainText',
  className,
  style,
  href,
  // Alex wants all links to open in a new tab
  newTab = true,
  target,
  replace,
  state,
  children,
  ...props
}: LinkProps) => {
  const ref = useRef<HTMLAnchorElement>(null)

  const {
    linkProps,
    // isPressed
  } = useLink(props, ref)

  // Check if href is a page path or anchor
  const externalDomain =
    href.indexOf(window.location.origin) === -1 && !/^[\/#]/.test(href)

  if (externalDomain) {
    return (
      <a
        {...linkProps}
        ref={ref}
        className={classNames(className, styles.link, styles[type])}
        href={href}
        // Off-site links should always open in a new tab
        // Unless otherwise specified
        target={newTab ? '_blank' : target || '_blank'}
        rel={newTab ? 'noreferrer' : undefined}
        style={style}
      >
        {children}
      </a>
    )
  }

  // Allow state to be passed through links
  let to:
    | string
    | { pathname: string; search: string; hash: string; state: Object } = href

  if (state) {
    const { pathname, search, hash } = new URL(
      `${window.location.origin}${href}`,
    )

    to = {
      pathname,
      search,
      hash,
      state,
    }
  }

  return (
    <RouterLink
      {...linkProps}
      ref={ref}
      className={classNames(className, styles.link, styles[type])}
      // activeClassName={styles.activeLink}
      to={to}
      target={newTab ? '_blank' : target}
      replace={replace}
      style={style}
    >
      {children}
    </RouterLink>
  )
}

export default Link
