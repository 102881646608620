import { makeVar } from '@apollo/client'

/* ******************************************************* */
/**
 * The below reactive vars are used as a replacement for redux state management
 * They only exist to support the Explain module
 * Which will be rebuilt at some point
 * When that happens, these should no longer be needed
 * As the state will come directly from Apollo/GQL calls
 */
/* ******************************************************* */

interface MetricsState {
  metrics: GaMetric[]
  currentKeyMetrics: KeyMetrics[]
}

export const explainMetricsDataReactive = makeVar<MetricsState>({
  metrics: [],
  currentKeyMetrics: [],
})

interface DimensionsState {
  gaDimensions: any
}

export const explainDimensionsDataReactive = makeVar<DimensionsState>({
  gaDimensions: null,
})

interface AnomaliesState {
  [metricID: string]: {
    dates: string[]
    anomalyRange: Anomaly[]
    metrics: number[]
    metricID: null | string
    metricValues: number[]
    upper: number[]
    lower: number[]
    expectedValues: number[]
    callData: {
      loading: boolean
      breakdownLoading: string[]
      error: boolean
    }
    lastRange: {
      to: string
      from: string
      selector: string
    }
    breakdown: any
  }
}

export const defaultAnomalies = {
  dates: [],
  anomalyRange: [],
  metrics: [],
  metricID: null,
  upper: [],
  lower: [],
  expectedValues: [],
  callData: {
    loading: false,
    breakdownLoading: [],
    error: false,
  },
  lastRange: {
    to: '',
    from: '',
    selector: '',
  },
  breakdown: {},
}

// export function createAnomalyRangeObject(payload: AnomalyRange): object {
//   const date = moment(payload.anomalyDate, 'YYYYMMDD').format('DD/MM/YYYY')
//   const dimensions = payload.dimensions.slice(0, 3)
//   return {
//     actual: payload.metricActualValue,
//     anomalyDateID: payload.anomalyDateID,
//     anomalyNotInteresting: payload.anomalyNotInteresting,
//     date,
//     difference: payload.metricDifference,
//     dimensions,
//     expected: payload.metricExpectedValue,
//     explainedAnomaly: payload.explainedAnomaly,
//     selectedRelevantDimensions: payload.selectedRelevantDimensions,
//     userSuppliedAction: payload.userSuppliedAction,
//     userSuppliedBenefit: payload.userSuppliedBenefit,
//     userSuppliedCause: payload.userSuppliedCause,
//     userSuppliedEffort: payload.userSuppliedEffort,
//   }
// }

export const explainAnomaliesDataReactive = makeVar<AnomaliesState>({})

interface AnomalyDescription {
  accountName: string
  anomalyDate: string
  anomalyDateID: string
  anomalyNotInteresting: boolean
  apiName: string
  cid: string
  dataSource: string
  dimensions: AnomalyBreakdownDimension[]
  dislikedActions: string[]
  dislikedCauses: string[]
  displayName: string
  explainedAnomaly: boolean
  likedActions: string[]
  likedCauses: string[]
  metric: string
  metricActualValue: number
  metricDifference: number
  metricExpectedValue: number
  metricIncrease: boolean
  selectedRelevantDimensions: AnomalyBreakdownDimension[]
  shareLinkID?: string | null
  systemDeterminedAnomaly: boolean
  increaseSentimentPositive: boolean
  userSuppliedAction: string
  userSuppliedCause: string
  userSuppliedEffort: string
  userSuppliedBenefit: string
}

interface AnomalyState {
  description: null | AnomalyDescription
}

export const explainAnomalyDataReactive = makeVar<AnomalyState>({
  description: null,
})

interface ExplainState {
  pageLayout: ExplainLayout[]
  settings: {
    dateRange: string
    loading: boolean
  }
}

export const explainDataReactive = makeVar<ExplainState>({
  pageLayout: [],
  settings: {
    dateRange: '',
    loading: true,
  },
})

interface Cause {
  actions: {
    action: string
    actionID: string
  }[]
  cause: string
  causeID: string
}

interface CauseState {
  loading: boolean
  recommendedCauses: null | Cause[]
}

export const explainCauseDataReactive = makeVar<CauseState>({
  loading: false,
  recommendedCauses: null,
})
