import React, { useState } from 'react'
import { useMutation } from '@apollo/client'

import Button, { NavigateButton } from './button'
import Input, { Label } from './input'
import { FieldSlot, LabelSlot } from './row'
import { sendFeedback } from '../api/graphql/onboarding-client'
import useLogAction from '../hooks/useLogAction'
import styles from '../styles/welcome-feedback.module.scss'

interface FeedbackProps {
  updateFeedback: (
    state: string,
    label: string | null,
    response: string | null,
  ) => void
}

function ReportSuggestAsk({
  updateFeedback,
}: FeedbackProps): React.ReactElement {
  return (
    <div>
      <NavigateButton
        className={styles.navigateButton}
        onPress={() =>
          updateFeedback(
            'feedback',
            'Please let us know what the issue was.',
            'Report a problem',
          )
        }
      >
        Report a problem
      </NavigateButton>
      <NavigateButton
        className={styles.navigateButton}
        onPress={() =>
          updateFeedback(
            'feedback',
            'What could we do better?',
            'Suggest an improvement',
          )
        }
      >
        Suggest an improvement
      </NavigateButton>
      <NavigateButton
        className={styles.navigateButton}
        onPress={() =>
          updateFeedback('feedback', 'How can we help?', 'Ask a question')
        }
      >
        Ask a question
      </NavigateButton>
      <NavigateButton
        className={styles.navigateButton}
        onPress={() =>
          window.open('https://uk.trustpilot.com/review/uplifter.ai', '_blank')
        }
      >
        Leave a review
      </NavigateButton>
    </div>
  )
}

interface FeedbackFull {
  state: string
  response: string
  label?: string
}

export default function WelcomeFeedbackForm() {
  const logAction = useLogAction()

  const [sendFeedbackMutation] = useMutation(sendFeedback)

  const [feedback, setFeedback] = useState<FeedbackFull>({
    state: 'initial',
    response: '',
  })
  const [feedbackInput, setFeedbackInput] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const updateFeedback = (
    state: string,
    label: string | null,
    response: string | null,
  ) => {
    if (feedback) {
      const newFeedback = { ...feedback, state }
      if (label) {
        newFeedback.label = label
      }

      if (response) {
        newFeedback.response = response
      }

      setFeedback(newFeedback)
    }
  }

  if (feedback === null) return null

  if (feedback.state === 'submitted') {
    return (
      <div>
        <p>Thanks for the feedback!</p>
        <NavigateButton
          back
          style={{ padding: 0 }}
          onPress={() => updateFeedback('initial', null, null)}
        >
          Back
        </NavigateButton>
      </div>
    )
  }

  if (feedback.state === 'feedback') {
    return (
      <form
        className={styles.form}
        onSubmit={async (e) => {
          e.preventDefault()

          setLoading(true)
          setError('')

          try {
            await sendFeedbackMutation({
              variables: {
                message: feedbackInput,
                page: '/welcome',
                feedbackContext: {
                  feedbackPrompt: 'Report/Suggest/Ask',
                  feedbackResponse: feedback.response,
                },
              },
            })

            await logAction({
              variables: {
                action: 'send-feedback-welcome',
                extra: `{"prompt": "Report/Suggest/Ask", "response": "${feedback.response}", "message": "${feedbackInput}"}`,
                websiteSection: 'welcome',
                functionName: 'sendFeedback',
                pagePath: '/welcome',
              },
            })

            updateFeedback('submitted', null, null)
          } catch (err) {
            setError('We ran into a problem. Please try again later')
          } finally {
            setLoading(false)
          }
        }}
      >
        {feedback.label && (
          <LabelSlot noPadding column>
            <Label id="feedbackInput">{feedback.label}</Label>
          </LabelSlot>
        )}
        <FieldSlot noPadding column>
          <Input
            name="feedbackInput"
            type="textArea"
            label="Feedback Input"
            value={feedbackInput}
            onValueChange={(val) => setFeedbackInput(val)}
            required
            placeholder="Enter feedback"
            multilineInput
            textAreaHeight={80}
            lineBreakAll={false}
          />
          {error && (
            <div>
              <p className={styles.error}>{error}</p>
            </div>
          )}
        </FieldSlot>
        <FieldSlot noPadding column>
          <div className={styles.submitRow}>
            <NavigateButton
              back
              style={{ padding: 0 }}
              onPress={() => updateFeedback('initial', null, null)}
            >
              Back
            </NavigateButton>
            <Button loading={loading} isDisabled={loading} type="submit">
              Send
            </Button>
          </div>
        </FieldSlot>
      </form>
    )
  }

  return <ReportSuggestAsk updateFeedback={updateFeedback} />
}
