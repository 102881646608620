import React from 'react'

import Link from './link'
import Tag from './tag'
import Stars from '../assets/star-cluster-yellow.svg'
import { calendarBookingLink } from '../core/constants'
import useSubscriptionLevel from '../hooks/useSubscriptionLevel'

interface Props {
  firstTime?: boolean
  tagLine?: string | React.ReactElement
  children: React.ReactElement
}

export default function CampaignIntroContent({
  firstTime = false,
  tagLine,
  children,
}: Props) {
  const { isEnterprise, loading } = useSubscriptionLevel()

  return (
    <>
      {tagLine && (
        <>{typeof tagLine === 'string' ? <p>{tagLine}</p> : tagLine}</>
      )}
      {!loading && (
        <>
          {isEnterprise ? (
            <>{children}</>
          ) : (
            <Tag inline icon={Stars}>
              <p>
                {firstTime ? 'First time?' : 'Need more help?'}{' '}
                <Link href={calendarBookingLink}>
                  Book a free setup consultation
                </Link>
                {firstTime && (
                  <>
                    {' '}
                    or <Link href="/track/learn">view our setup guide</Link>
                  </>
                )}
                .
              </p>
            </Tag>
          )}
        </>
      )}
    </>
  )
}
