import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client'
import numeral from 'numeraljs'
import { nanoid } from 'nanoid'
import _ from 'lodash'

import Button from './button'
import { CustomLinkAlias } from './custom-link-fields'
import Input from './input'
import OrderArrow from './order-arrow'
import Modal from './modal'
import Pagination from './pagination'
import Table from './table'
import TopScrollbar from './top-scrollbar'
import { GeneratorParameterField } from './track-create-parameter-fields'
import { ErrorMessage } from './typography'
import { currentUserDetails, linkOrCode } from '../api/apollo/variables'
import {
  listAppContextOptions,
  listAppGroups,
} from '../api/graphql/track-edit-client'
import { CustomLinkAliasDetails } from '../helpers/custom-links'
import { maxBatchShortLinks, minBatchShortLinks } from '../helpers/track-module'
import {
  addAutomatedParams,
  AppValues,
  FullLinkResult,
  GeneratorParameterValues,
  LinkType,
} from '../helpers/track-create'
import useTableSortFilter from '../hooks/useTableSortFilter'
import styles from '../styles/track-create-preview-links-table.module.scss'
import { GetCampaignCodeGeneratorQuery } from '../__gql-types__/graphql'

interface EditedLinks {
  [fC: string]: {
    alias: string
    status: UrlStatus
  }
}

interface TrackCreatePreviewLinksTableProps {
  isEmail?: boolean
  editableParameters?: boolean
  generatedStructure?:
    | GetCampaignCodeGeneratorQuery['campaignCodeGenerator']
    | null
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  linksToPreview: FullLinkResult[]
  setLinksToPreview: React.Dispatch<React.SetStateAction<FullLinkResult[]>>
  linkType: LinkType
  appValues?: AppValues
  customLinkAliasDetails: CustomLinkAliasDetails
  setCustomLinkAliasDetails: React.Dispatch<
    React.SetStateAction<CustomLinkAliasDetails>
  >
  onSubmit: (
    linksToCreate: FullLinkResult[],
    bulkStart?: string,
    /** Object with props for all links whose alias was edited */
    editedLinkAliases?: EditedLinks,
  ) => Promise<void>
  createLinkError: boolean
  customLinkError: boolean
}

const TrackCreatePreviewLinksTable = ({
  isEmail,
  editableParameters,
  generatedStructure,
  setIsOpen,
  linksToPreview,
  setLinksToPreview,
  linkType,
  appValues,
  customLinkAliasDetails,
  setCustomLinkAliasDetails,
  onSubmit,
  createLinkError,
  customLinkError,
}: TrackCreatePreviewLinksTableProps) => {
  const { paramDefs = [] } = generatedStructure || {}

  const modalRef = useRef<HTMLDivElement>(null)

  const { workspaceID } = useReactiveVar(currentUserDetails)

  const linkCopy = useReactiveVar(linkOrCode)

  const scrollRef = useRef<HTMLDivElement>(null)
  const ref = useRef<HTMLTableElement>(null)

  const { data: appGroupsData } = useQuery(listAppGroups)
  const [getAppContextOptions, { data: appScreensData }] = useLazyQuery(
    listAppContextOptions,
  )

  const [creatingLinks, setCreatingLinks] = useState(false)
  const [editedLinkAliases, setEditedLinkAliases] = useState<EditedLinks>({})
  const [reservedAliases, setReservedAliases] = useState<string[]>(
    new Array(linksToPreview.length).fill(customLinkAliasDetails.alias || ''),
  )

  // Get app group's name
  const currentAppGroup = useMemo(() => {
    if (
      !(linkType === 'appLink' && !!appValues) ||
      !appGroupsData ||
      !appGroupsData.track.deepLinkQueries.listAppGroups
    ) {
      return null
    }

    return (
      appGroupsData.track.deepLinkQueries.listAppGroups.find(
        (group) => group.appGroupID === appValues.appGroupID,
      ) || null
    )
  }, [appGroupsData, appValues])

  // Get app screen options if app group is selected
  useEffect(() => {
    if (!currentAppGroup) return

    getAppContextOptions({
      variables: { appGroupID: currentAppGroup.appGroupID },
    })
  }, [currentAppGroup])

  /** App screen name */
  const currentAppScreen = useMemo(() => {
    if (!appScreensData || !(linkType === 'appLink' && !!appValues)) {
      return null
    }

    return (
      appScreensData.track.deepLinkQueries.listAppContextOptions.find(
        (appScreen) => appScreen.optionID === appValues.appScreen,
      )?.optionDisplayName || null
    )
  }, [appScreensData, appValues])

  // Build sort functions for param columns
  const sortFns = useMemo(() => {
    const _sortFns = {}

    if (paramDefs) {
      paramDefs.forEach(({ fieldID }, paramIndex) => {
        _sortFns[`${fieldID}-${paramIndex}`] = (rowData: FullLinkResult) =>
          rowData.pDfs[paramIndex].submitValue[0]
      })
    }

    return _sortFns
  }, [paramDefs])

  const {
    orderedList,
    pages,
    rowsPerPage,
    setRowsPerPage,
    activePage,
    setActivePage,
    sortKey,
    orderAsc,
    setSortOrder,
  } = useTableSortFilter({
    startingSortKey: 'urlWithHash',
    inputList: linksToPreview,
    startingRowsPerPage: 10,
    customSorts: sortFns,
  })

  /** Initialised but not changed */
  const customAliasInitialValue = useMemo(
    () => customLinkAliasDetails?.alias || '',
    [],
  )

  const { showLandingPageColumn, linksToCreate } = useMemo(() => {
    return {
      showLandingPageColumn: linksToPreview.some(
        ({ urlWithHash }) => urlWithHash,
      ),
      linksToCreate: linksToPreview.filter(({ selected }) => selected),
    }
  }, [linksToPreview])

  const { allEditedAliasesAreValid, aliasBeingEdited } = useMemo(() => {
    return {
      allEditedAliasesAreValid: Object.values(editedLinkAliases).every(
        ({ alias, status }) =>
          alias.length > 0 && ['', 'valid'].includes(status),
      ),
      aliasBeingEdited:
        Object.keys(editedLinkAliases).find((fc) =>
          ['validating', 'refetching'].includes(editedLinkAliases[fc].status),
        ) || null,
    }
  }, [editedLinkAliases])

  // Set shortLinkIDs if needed
  useEffect(() => {
    if (linkType === 'basic') return

    setLinksToPreview((curr) => {
      return curr.map((link) => ({
        ...link,
        shortLinkID: customLinkAliasDetails?.alias || '',
      }))
    })
  }, [linkType, customLinkAliasDetails])

  if (!generatedStructure || orderedList.length === 0) return null

  return (
    <Modal
      id="links-preview-modal"
      ref={modalRef}
      setIsOpen={setIsOpen}
      width="superWide"
      modalHeader={`Review ${linkCopy}s`}
      afterClose={() => setLinksToPreview([])}
      yesText={`${isEmail ? 'Replace' : 'Create'} ${linksToCreate.length} ${
        isEmail ? 'link' : linkCopy
      }${linksToCreate.length === 1 ? '' : 's'}`}
      yesButtonLoading={creatingLinks}
      yesButtonDisabled={
        !allEditedAliasesAreValid ||
        ['invalid', 'refetching', 'validating'].includes(
          customLinkAliasDetails.status || '',
        ) ||
        linksToPreview.every(({ selected }) => !selected)
      }
      onYes={async () => {
        setCreatingLinks(true)

        await onSubmit(
          linksToCreate,
          linkType !== 'basic' && linksToPreview.length > 1
            ? customLinkAliasDetails?.alias || ''
            : undefined,
          editedLinkAliases,
        )

        setCreatingLinks(false)
      }}
      footerContent={
        <>
          {!createLinkError && !customLinkError && isEmail && (
            <p style={{ margin: 0 }}>
              All instances of {numeral(linksToCreate.length).format('0,0')}{' '}
              link
              {linksToCreate.length !== 1 && 's'} will be replaced in your
              email.
            </p>
          )}
          {createLinkError && (
            <ErrorMessage>
              Error creating {linkCopy}s, please refresh and try again.
            </ErrorMessage>
          )}
          {!createLinkError && customLinkError && (
            <ErrorMessage>
              There was an error creating your{' '}
              {linkType === 'shortLink' ? 'short ' : 'deep '}
              {linkCopy}s. Please refresh the page and try again.
            </ErrorMessage>
          )}
        </>
      }
    >
      {isEmail ? (
        <p>
          Deselect the {isEmail ? 'link' : linkCopy}s you don't wish to track
          and edit individual {isEmail ? 'link' : linkCopy}
          parameters if required.
        </p>
      ) : (
        <p>
          Untick any combinations you don't need for your campaign and then
          click{' '}
          <Button demoOnly className={styles.demoButton}>
            Create {isEmail ? 'link' : linkCopy}s
          </Button>
          .
        </p>
      )}
      {currentAppGroup && (
        <>
          <p>
            <strong>App group:</strong> {currentAppGroup.appGroupName}
          </p>
          {currentAppScreen && (
            <p>
              <strong>App screen:</strong> {currentAppScreen}
            </p>
          )}
        </>
      )}
      {linksToPreview.length >= minBatchShortLinks && (
        <div className={styles.batchShortLinkRow}>
          <p style={{ margin: 0 }}>Short links will start with:</p>
          <div>
            <CustomLinkAlias
              customAliasInitialValue={customAliasInitialValue}
              batchCount={maxBatchShortLinks}
              onAliasChange={(alias, status, isCustom) => {
                const newAliasDetails: Partial<CustomLinkAliasDetails> = {
                  alias,
                  isCustom: isCustom || false,
                  isBatch: true,
                  status,
                  error: false,
                }

                // Resets the alias field in the form if a new alias is fetched
                if (!isCustom) {
                  newAliasDetails.key = nanoid()
                }

                setCustomLinkAliasDetails((curr) => ({
                  ...curr,
                  ...newAliasDetails,
                }))
              }}
            />
          </div>
        </div>
      )}
      {orderedList[activePage - 1] && (
        <div
          className={styles.tableContainer}
          style={pages > 1 ? { marginBottom: 16 } : undefined}
        >
          <TopScrollbar ref={scrollRef}>
            <Table ref={ref}>
              <thead>
                <tr>
                  <th className={styles.checkboxCell}>
                    <Input
                      type="checkbox"
                      id="allNone"
                      name="allNone"
                      checked={linksToPreview.every(({ selected }) => selected)}
                      className={styles.selectItem}
                      label=" "
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const { checked } = e.target

                        setLinksToPreview((curr) =>
                          curr.map((link) => ({ ...link, selected: checked })),
                        )
                      }}
                    />
                  </th>
                  {['shortLink', 'appLink'].includes(linkType) &&
                    linksToPreview.length < minBatchShortLinks && (
                      <th className={styles.shortLinkCell}>
                        {linkType === 'appLink' ? 'App' : 'Short'} link
                      </th>
                    )}
                  {showLandingPageColumn && (
                    <th onClick={() => setSortOrder('urlWithHash')}>
                      Landing page URL
                      <OrderArrow
                        currentKey="urlWithHash"
                        sortKey={sortKey}
                        orderAsc={orderAsc}
                      />
                    </th>
                  )}
                  {paramDefs.map(
                    ({ fieldID, fieldName, metaParameter }, paramIndex) => {
                      const showCol = linksToPreview.some(
                        ({ pDfs }) => pDfs[paramIndex].optionID !== '',
                      )

                      if (!showCol) return null

                      return (
                        <th
                          key={fieldID}
                          onClick={() =>
                            setSortOrder(`${fieldID}-${paramIndex}`)
                          }
                        >
                          {fieldName}
                          {metaParameter ? (
                            <span className={styles.optional}> (meta)</span>
                          ) : (
                            ''
                          )}
                          <OrderArrow
                            currentKey={`${fieldID}-${paramIndex}`}
                            sortKey={sortKey}
                            orderAsc={orderAsc}
                          />
                        </th>
                      )
                    },
                  )}
                </tr>
              </thead>
              <tbody>
                {orderedList[activePage - 1].map((link) => {
                  const { fC, urlWithHash, pDfs, selected } = link

                  const linkIndex = linksToPreview.findIndex(
                    (fullLink) => fC === fullLink.fC,
                  )
                  let adjustedLinkIndex = linkIndex

                  if (Object.keys(editedLinkAliases).length > 0) {
                    // linkIndex needs to be reduced by the number or editedLinkAliases that appear before it
                    linksToPreview
                      .slice(0, linkIndex)
                      .forEach(({ fC: fullCode }) => {
                        if (editedLinkAliases[fullCode]) {
                          adjustedLinkIndex -= 1
                        }
                      })
                  }

                  const formValues: GeneratorParameterValues = {}

                  link.pDfs.forEach((pDf) => {
                    formValues[pDf.fieldID] = [pDf.optionID]
                  })

                  return (
                    <tr key={fC}>
                      <td className={styles.checkboxCell}>
                        <Input
                          type="checkbox"
                          id={fC}
                          name={fC}
                          checked={selected}
                          label=" "
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            const { checked } = e.target

                            setLinksToPreview((curr) => {
                              return curr.map((currLink) => {
                                if (currLink.fC !== fC) {
                                  return currLink
                                }

                                return {
                                  ...currLink,
                                  selected: checked,
                                }
                              })
                            })
                          }}
                        />
                      </td>
                      {['shortLink', 'appLink'].includes(linkType) &&
                        linksToPreview.length < minBatchShortLinks && (
                          <td className={styles.shortLinkCell}>
                            <CustomLinkAlias
                              customAliasInitialValue={
                                customLinkAliasDetails.alias
                              }
                              includeBatchSuffix={(
                                adjustedLinkIndex + 1
                              ).toString(16)}
                              index={linkIndex}
                              reservedAliases={reservedAliases}
                              disabled={
                                !!aliasBeingEdited && aliasBeingEdited !== fC
                              }
                              // Updates the edited aliases
                              onAliasChange={(alias, status) => {
                                if (alias !== customAliasInitialValue) {
                                  setEditedLinkAliases((curr) => ({
                                    ...curr,
                                    [fC]: { alias, status },
                                  }))

                                  setReservedAliases((curr) => {
                                    const newCurr = [...curr]
                                    newCurr.splice(linkIndex, 1, alias)
                                    return newCurr
                                  })
                                } else {
                                  // Remove when the new alias = customAliasDetails.alias
                                  setEditedLinkAliases((curr) => {
                                    const newCurr = { ...curr }
                                    delete newCurr[fC]
                                    return newCurr
                                  })

                                  setReservedAliases((curr) => {
                                    const newCurr = [...curr]
                                    newCurr.splice(
                                      linkIndex,
                                      1,
                                      customLinkAliasDetails.alias,
                                    )
                                    return newCurr
                                  })
                                }
                              }}
                            />
                          </td>
                        )}
                      {showLandingPageColumn && (
                        <td className={styles.urlColumn}>
                          <p>{urlWithHash}</p>
                        </td>
                      )}
                      {paramDefs.map((param, paramIndex) => {
                        const {
                          fieldID,
                          fieldType,
                          required,
                          copyFromField,
                          selectFields,
                        } = param

                        const showCol = linksToPreview.some(
                          ({ pDfs: currLinkPDfs }) =>
                            currLinkPDfs[paramIndex].optionID !== '',
                        )

                        if (!showCol) return null

                        let canEditField = true

                        if (copyFromField && copyFromField.length > 0) {
                          canEditField = false
                        } else if (['fixed', 'unique'].includes(fieldType)) {
                          canEditField = false
                        }

                        return (
                          <td key={`${fieldID}-${pDfs[paramIndex].optionID}`}>
                            {editableParameters && canEditField ? (
                              <GeneratorParameterField
                                generatedStructure={generatedStructure}
                                param={param}
                                required={required}
                                allowMultipleValues={false}
                                formValues={formValues}
                                savedValue={[pDfs[paramIndex].optionID]}
                                updateValueOnly
                                portal={modalRef}
                                onChange={([newValue]) => {
                                  const newLink = _.cloneDeep(link)

                                  let newOptionName = newValue
                                  let newOptionValue = newValue
                                  let newValidParentSelectOptions: {
                                    parentFieldID: string
                                    validOptions: string[]
                                  } | null = null

                                  if (
                                    fieldType === 'select' &&
                                    selectFields &&
                                    selectFields.length > 0
                                  ) {
                                    const selectedOption = selectFields.find(
                                      (option) => option.optionID === newValue,
                                    )

                                    if (selectedOption) {
                                      const {
                                        optionName,
                                        optionValue,
                                      } = selectedOption

                                      newOptionName = optionName
                                      newOptionValue = optionValue

                                      // Add filter for quick reference
                                      if (
                                        selectedOption.optionFilter &&
                                        selectedOption.optionFilter.length > 0
                                      ) {
                                        const filter =
                                          selectedOption.optionFilter[0]

                                        newValidParentSelectOptions = {
                                          parentFieldID: filter.parentFieldID,
                                          validOptions: filter.parentOptionIDs,
                                        }
                                      }
                                    }
                                  }

                                  // These are the only props that need to be updated - we can then use addAutomatedParams to update the rest
                                  newLink.pDfs.splice(paramIndex, 1, {
                                    ...newLink.pDfs[paramIndex],
                                    optionID: newValue,
                                    submitValue: [
                                      newOptionName,
                                      newOptionValue,
                                    ],
                                    validParentSelectOptions:
                                      newValidParentSelectOptions || undefined,
                                  })

                                  const {
                                    fullLinkPdfs,
                                    fullCode,
                                    queryString,
                                  } = addAutomatedParams(
                                    newLink,
                                    0,
                                    generatedStructure,
                                    workspaceID,
                                  )

                                  const uplifterID =
                                    link.fC.split('&up_id=')[1] || ''
                                  const newFC = `${fullCode}${
                                    uplifterID ? `&up_id=${uplifterID}` : ''
                                  }`
                                  const newTC = `${queryString}${
                                    uplifterID ? `&up_id=${uplifterID}` : ''
                                  }`

                                  const newFullLink: FullLinkResult = {
                                    ...link,
                                    pDfs: fullLinkPdfs,
                                    fC: newFC,
                                    tC: newTC,
                                    urlLength: newFC.length,
                                    queryLength: newTC.length,
                                  }

                                  setLinksToPreview((curr) => {
                                    return curr.map((currLink) => {
                                      if (currLink.fC !== fC) {
                                        return currLink
                                      }

                                      return newFullLink
                                    })
                                  })
                                }}
                              />
                            ) : (
                              pDfs[paramIndex].submitValue[0]
                            )}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </TopScrollbar>
        </div>
      )}
      {pages > 1 && (
        <Pagination
          pages={pages}
          activePage={activePage}
          onChange={(index) => setActivePage(index)}
          rowsPerPageData={{
            rowsPerPage,
            maxRowsPerPage: 50,
            totalRows: linksToPreview.length,
            onChange: (newRowsPerPage) => {
              setRowsPerPage(newRowsPerPage)
              setActivePage(1)
            },
          }}
        />
      )}
    </Modal>
  )
}

export default TrackCreatePreviewLinksTable
