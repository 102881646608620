import classNames from 'classnames'
import React from 'react'
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { enGB } from 'date-fns/locale'

import styles from '../styles/date-picker.module.scss'

interface StyledDatePickerProps<SelectsRange extends boolean>
  extends ReactDatePickerProps<string, SelectsRange> {
  grey?: boolean
  isError?: boolean
  buttonOnly?: boolean
}

// https://reactdatepicker.com/
// Props: https://github.com/Hacker0x01/react-datepicker/blob/main/docs/datepicker.md
const StyledDatePicker = <SelectsRange extends boolean = false>({
  grey = false,
  isError,
  buttonOnly,
  isClearable = true,
  ...props
}: StyledDatePickerProps<SelectsRange>) => {
  return (
    <ReactDatePicker
      {...props}
      isClearable={isClearable && !buttonOnly}
      wrapperClassName={classNames(props.wrapperClassName, styles.dateWrapper, {
        [styles.buttonOnly]: buttonOnly,
        [styles.dateError]: isError,
        [styles.grey]: grey,
      })}
      className={classNames(props.className, styles.dateInput, {
        [styles.grey]: grey,
      })}
      clearButtonClassName={classNames(
        props.clearButtonClassName,
        styles.clearButton,
      )}
      popperClassName={styles.popper}
      calendarClassName={classNames(props.calendarClassName, styles.calendar)}
      locale={enGB}
    />
  )
}

export default StyledDatePicker
