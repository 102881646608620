import moment from 'moment'
import _ from 'lodash'

import { getItemByKeyValue } from '.'
import {
  getLocalItem,
  getUserData,
  saveUserData,
  setLocalItem,
} from './local-client'

export type CustomDomainType = 'shortLink' | 'appLink'

export interface CustomLinkAliasDetails {
  key: string
  alias: string
  isCustom: boolean
  isBatch: boolean
  status: UrlStatus
  error: boolean
}

/** Updates localStorage as well as selectedDomain reactive variables */
export const updateSelectedCustomLinkDomain = (
  newDomain: string,
  workspaceID: string,
  linkType: CustomDomainType = 'shortLink',
) => {
  const initialData = getLocalItem('track-create')

  const newData = _.cloneDeep(initialData) || {}

  newData[workspaceID] = {
    ...(newData[workspaceID] || {}),
    options: {
      ...(newData[workspaceID]?.options || {}),
      [`${linkType}Domain`]: newDomain,
    },
  }

  setLocalItem('track-create', newData)
}

export interface SavedCustomLink {
  availableUntil: string
  isAvailable: boolean
  /**
   * This applies to appLinks too now
   * But haven't changed prop name to 'customLinkID'
   * To avoid breaking existing localStorage items
   */
  shortLinkID: string
  isBatchAlias?: boolean
  isCustom?: boolean
}

export const getValidAliasesByDomain = (
  domainID: string,
  options?: { allowCustom?: boolean; returnBatchAliases?: boolean },
) => {
  const { allowCustom, returnBatchAliases } = options || {}

  /** All currently saved aliases for the domain */
  const allStoredAliases: SavedCustomLink[] = getUserData(domainID) || []

  const validAliases = allStoredAliases.filter(
    ({ isAvailable, availableUntil, isBatchAlias, isCustom }) => {
      if (!isAvailable) return false

      if (!allowCustom && isCustom) return false

      if (
        (returnBatchAliases && !isBatchAlias) ||
        (!returnBatchAliases && isBatchAlias)
      ) {
        return false
      }

      const now = new Date(Date.now())

      const diff = moment(availableUntil).utc().diff(moment(now).utc())
      const isExpired = diff < 0

      return !isExpired
    },
  )

  return validAliases
}

export const addGeneratedCustomLinkToStorage = ({
  shortLinkID,
  domainID,
  isAvailable,
  availableUntil,
  isBatchAlias = false,
  isCustom = false,
}: {
  shortLinkID: string
  domainID: string
  isAvailable?: boolean
  availableUntil?: string
  isBatchAlias?: boolean
  isCustom?: boolean
}) => {
  const savedData: SavedCustomLink[] = getUserData(domainID) || []

  const now = new Date(Date.now())

  savedData.unshift({
    availableUntil:
      availableUntil ||
      `${moment(now).add(1, 'day').format('YYYY-MM-DD')} 00:00:00`,
    isAvailable: typeof isAvailable === 'boolean' ? isAvailable : true,
    shortLinkID,
    isBatchAlias,
    isCustom,
  })

  saveUserData(savedData, domainID, false)
}

/**
 * This applies to appLinks too now
 * But haven't changed prop name to 'customLinkID'
 * To avoid breaking existing localStorage items
 */
export const removeCustomLinkFromStorage = (
  shortLinkID: string,
  domainID: string,
) => {
  const savedData = getUserData(domainID)

  if (!savedData || !Array.isArray(savedData)) return

  const foundIndex: number = getItemByKeyValue(
    savedData,
    'shortLinkID',
    shortLinkID,
    true,
  )

  if (foundIndex === -1) return

  const newSavedData: SavedCustomLink[] = _.cloneDeep(savedData)

  newSavedData.splice(foundIndex, 1)

  saveUserData(newSavedData, domainID, false)
}

/** Removes the aliases from localStorage and reactive variable */
export const useAliases = (aliasesToUse: string[], domainID: string) => {
  if (aliasesToUse.length === 0 || !domainID) return

  aliasesToUse.forEach((aliasToUse) => {
    removeCustomLinkFromStorage(aliasToUse, domainID)
  })
}
