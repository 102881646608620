import { brandName } from '../core/constants'

interface QuizProps {
  topic: string
  level: string
  totalQuestions: number
  perQuestionScore: number
  questions: {
    question: string
    choices: string[]
    type: string
    correctAnswer: string
  }[]
}

export const quiz: QuizProps = {
  topic: 'Track>Learn',
  level: 'Beginner',
  totalQuestions: 10,
  perQuestionScore: 1,
  questions: [
    {
      question: 'Campaign links are also known as:',
      choices: [
        'UTMs',
        'Query string parameters',
        'Tracking codes',
        'All of the above',
      ],
      type: 'MCQs',
      correctAnswer: 'All of the above',
    },
    {
      question: 'You should use campaign links to:',
      choices: [
        'Track individual user activity',
        'Compare different campaign approaches and optimise marketing budget',
        'Understand SEO performance of your website',
        'All of the above',
      ],
      type: 'MCQs',
      correctAnswer:
        'Compare different campaign approaches and optimise marketing budget',
    },
    {
      question:
        'Campaign links should be only made for links to your website(s).',
      choices: ['True', 'False'],
      type: 'MCQs',
      correctAnswer: 'True',
    },
    {
      question:
        'The most common parameters in Google Analytics you should always use are:',
      choices: [
        'Source, medium and campaign',
        'Language, geography, product, business unit',
        'Source, medium, campaign, term and content',
        'All of the above',
      ],
      type: 'MCQs',
      correctAnswer: 'Source, medium and campaign',
    },
    {
      question: 'When naming your campaign parameters, best practice dictates:',
      choices: [
        'Always use lowercase',
        'Spaces should be replaced by underscores, dashes or pipes',
        'Avoid special characters and never use ?, & and =',
        'All of the above',
      ],
      type: 'MCQs',
      correctAnswer: 'All of the above',
    },
    {
      question: 'Campaign tracking is only useful for online channels.',
      choices: ['True', 'False'],
      type: 'MCQs',
      correctAnswer: 'False',
    },
    {
      question: 'Creating short links for every campaign link is useful for:',
      choices: [
        'Counting short link clickthroughs when users don’t accept analytics cookies',
        'Creating memorable short links you can use in offline ads',
        'Changing the landing page/parameters after the campaign has started',
        'All of the above',
      ],
      type: 'MCQs',
      correctAnswer: 'All of the above',
    },
    {
      question: 'QR codes are best use to track:',
      choices: [
        'Print: To give users an offer or further information',
        'Billboards adverts (print or digital): To give a passing users the ability to discover more when you have limited space',
        'Events: Displayed on entry they can give participants access to further information without the environmental cost of printing',
        'All of the above',
      ],
      type: 'MCQs',
      correctAnswer: 'All of the above',
    },
    {
      question: `In ${brandName} you can create links by:`,
      choices: [
        'Selecting multiple parameter combinations',
        'Uploading a CSV file',
        'Finding and replacing links in email html',
        'All of the above',
      ],
      type: 'MCQs',
      correctAnswer: 'All of the above',
    },
    {
      question: `In ${brandName} you can create reports to compare:`,
      choices: [
        'Source, Mediums and Campaigns',
        'Page views, sessions, bounce rates',
        'Custom Google or Adobe analytics metrics',
        'All of the above',
      ],
      type: 'MCQs',
      correctAnswer: 'All of the above',
    },
  ],
}
