import React, { useMemo } from 'react'

import useMobile from '../hooks/useMobile'
import DesktopNav from './desktop-nav'
import MobileNav from './mobile-nav'
import useOnboarding from '../hooks/useOnboarding'

interface NavigationProps {
  justLogo?: boolean
}

export default function Navigation({ justLogo = false }: NavigationProps) {
  const mobile = useMobile()

  const { mergedOnboardingSections } = useOnboarding()

  // Displays how many onboarding steps are left to complete
  const onboardingNotifications = useMemo(() => {
    if (mergedOnboardingSections && mergedOnboardingSections.length > 0) {
      const incomplete = mergedOnboardingSections.reduce((acc, curr) => {
        if (
          (curr.sectionCompleted &&
            (curr.sectionSkipped || !curr.sectionSkippedBefore)) ||
          curr.sectionSkipped
        ) {
          return acc
        }
        return acc + 1
      }, 0)

      return incomplete
    }

    return 0
  }, [mergedOnboardingSections])

  if (mobile) {
    return (
      <MobileNav
        justLogo={justLogo}
        onboardingNotifications={onboardingNotifications}
      />
    )
  }

  return (
    <DesktopNav
      justLogo={justLogo}
      onboardingNotifications={onboardingNotifications}
    />
  )
}
