import React from 'react'
import numeral from 'numeraljs'
import classNames from 'classnames'

import { Preloader } from './loader'
import Tooltip from './tooltip'
import styles from '../styles/lost-links-data-callout.module.scss'

interface MetricDataBannerProps {
  successMetric: string
  tooltip?: React.ReactNode
  metricValue?: number
  percentage?: boolean
  dateRange: string
  loading?: boolean
  error?: boolean
}

export default function LostLinksDataCallout({
  successMetric,
  tooltip,
  metricValue,
  percentage = false,
  dateRange,
  loading = false,
  error = false,
}: MetricDataBannerProps) {
  return (
    <div
      className={classNames(styles.metricDataBannerBox, {
        [styles.noData]: error,
        [styles.isEmpty]: !error && loading,
      })}
    >
      {error && (
        <div>
          <h2>{successMetric}</h2>
          <p>No data</p>
        </div>
      )}
      {!error && loading && <Preloader className={styles.loader} />}
      {!error && !loading && (
        <>
          {tooltip && (
            <Tooltip
              id={`${successMetric}-tooltip`}
              tooltipIconClassName={styles.calloutTooltip}
              useIcon
              tooltipMessage={tooltip}
            />
          )}
          <h2>{successMetric}</h2>
          <h3>
            {`${numeral(metricValue || 0).format('0,0.[0]a')}${
              percentage ? '%' : ''
            }`}
          </h3>
          <h4>{dateRange}</h4>
        </>
      )}
    </div>
  )
}
