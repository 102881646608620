import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLazyQuery, useReactiveVar } from '@apollo/client'
import _ from 'lodash'

import useSubscriptionLevel from './useSubscriptionLevel'
import {
  currentUserDetails,
  selectedAppLinkDomainReactive,
  selectedShortLinkDomainReactive,
} from '../api/apollo/variables'
import {
  getCampaignCodeGenerator,
  getShortLinkCustomDomains,
} from '../api/graphql/track-create-client'
import { listDeepLinkServices } from '../api/graphql/track-edit-client'
import {
  CustomDomainType,
  updateSelectedCustomLinkDomain,
} from '../helpers/custom-links'
import { getLocalItem } from '../helpers/local-client'
import { defaultShortLinkDomain } from '../helpers/track-module'

export interface RuleOption {
  optionName: string
  optionValue: string
  selected: boolean
}

export interface AvailableDomain {
  domainID: string
  domainName: string
  domainValue: string
  /** Whether the domain should show on Track>Create */
  selected: boolean
}

const useCustomLinks = () => {
  const { workspaceID, isDemoAccount } = useReactiveVar(currentUserDetails)

  const selectedShortLinkDomain = useReactiveVar(
    selectedShortLinkDomainReactive,
  )
  const selectedAppLinkDomain = useReactiveVar(selectedAppLinkDomainReactive)

  const { isFree, isEnterprise } = useSubscriptionLevel()

  const [getShortLinkDomains, { data: shortLinkDomainData }] = useLazyQuery(
    getShortLinkCustomDomains,
  )
  const [getDeepLinkDomains, { data: deepLinkDomainData }] = useLazyQuery(
    listDeepLinkServices,
  )
  const [getValidationChecks, { data: validationChecksData }] = useLazyQuery(
    getCampaignCodeGenerator,
  )

  const [domainsInitialised, setDomainsInitialised] = useState(false)

  // Initial fetchers
  useEffect(() => {
    if (!workspaceID) return

    if (!validationChecksData) {
      getValidationChecks()
    }

    if (!shortLinkDomainData) {
      getShortLinkDomains()
    }

    if (!deepLinkDomainData) {
      getDeepLinkDomains()
    }
  }, [workspaceID, shortLinkDomainData, deepLinkDomainData])

  const forceShortLinkDomainsRule = useMemo(() => {
    return (
      validationChecksData?.campaignCodeGenerator.validationChecks.find(
        (validationCheck) => validationCheck.name === 'FORCE_CUSTOM_DOMAIN',
      ) || null
    )
  }, [validationChecksData])

  /** List of custom link domains available for the workspace */
  const { availableShortLinkDomains, availableAppLinkDomains } = useMemo(() => {
    const _availableShortLinkDomains: AvailableDomain[] = []

    if (shortLinkDomainData) {
      const ruleValue: RuleOption[] = []

      // If the rule exists, use that value
      if (forceShortLinkDomainsRule) {
        ruleValue.push(
          ...(JSON.parse(
            forceShortLinkDomainsRule.value as string,
          ) as RuleOption[]),
        )
      }

      const fetchedList =
        shortLinkDomainData?.currentCompany.availableShortLinkCustomDomains.map(
          ({ customDomainID, customDomainName }) => {
            const foundInRule = ruleValue.find(
              (domainRule) => domainRule.optionValue === customDomainID,
            )

            return {
              domainID: customDomainID,
              domainName: customDomainName.replace('https://', ''),
              domainValue: customDomainName.replace('https://', ''),
              selected: foundInRule ? foundInRule.selected : true,
            }
          },
        ) || []

      const defaultDomainRule = ruleValue.find(
        ({ optionValue }) => optionValue === defaultShortLinkDomain,
      )

      if (defaultDomainRule) {
        // Add the default shortLink domain
        fetchedList.unshift({
          domainID: defaultShortLinkDomain,
          domainName: defaultShortLinkDomain,
          domainValue: defaultShortLinkDomain,
          selected: defaultDomainRule.selected,
        })
      } else {
        fetchedList.unshift({
          domainID: defaultShortLinkDomain,
          domainName: defaultShortLinkDomain,
          domainValue: defaultShortLinkDomain,
          selected: true,
        })
      }

      _availableShortLinkDomains.push(...fetchedList)
    }

    setDomainsInitialised(true)

    return {
      // Only show domains that are enabled by the rule
      availableShortLinkDomains: _availableShortLinkDomains.filter(
        ({ selected }) => selected,
      ),
      availableAppLinkDomains:
        deepLinkDomainData?.track.deepLinkQueries.listDeepLinkServices.map(
          ({
            deepLinkServiceSubdomain,
            deepLinkServiceID,
            deepLinkServiceName,
          }) => ({
            domainID: deepLinkServiceID,
            domainName: deepLinkServiceName,
            domainValue: deepLinkServiceSubdomain,
            selected: true,
          }),
        ) || [],
    }
  }, [forceShortLinkDomainsRule, shortLinkDomainData, deepLinkDomainData])

  const updateSelectedDomain = useCallback(
    (newDomainID: string, linkType: CustomDomainType = 'shortLink') => {
      if (!workspaceID) return

      if (linkType === 'shortLink') {
        selectedShortLinkDomainReactive(newDomainID)
      } else {
        selectedAppLinkDomainReactive(newDomainID)
      }

      // Update localStorage value
      updateSelectedCustomLinkDomain(newDomainID, workspaceID, linkType)
    },
    [workspaceID],
  )

  // Initialise selected shortlink domain
  useEffect(() => {
    if (!domainsInitialised || availableShortLinkDomains.length === 0) {
      return
    }

    let selectedDomain = availableShortLinkDomains[0].domainID

    const localSelectedDomain = getLocalItem('track-create')

    if (localSelectedDomain) {
      const workspaceData = localSelectedDomain[workspaceID]?.options

      if (
        workspaceData &&
        workspaceData.shortLinkDomain &&
        availableShortLinkDomains.find(
          ({ domainID }) => domainID === workspaceData.shortLinkDomain,
        )
      ) {
        selectedDomain = workspaceData.shortLinkDomain
      }
    }

    selectedShortLinkDomainReactive(selectedDomain)
  }, [domainsInitialised, selectedShortLinkDomain, availableShortLinkDomains])

  // Initialise selected appLink domain
  useEffect(() => {
    if (selectedAppLinkDomain || availableAppLinkDomains.length === 0) {
      return
    }

    let selectedDomain = availableAppLinkDomains[0].domainID

    const localSelectedDomain = getLocalItem('track-create')

    if (localSelectedDomain) {
      const workspaceData = localSelectedDomain[workspaceID]?.options

      if (
        workspaceData &&
        workspaceData.appLinkDomain &&
        availableShortLinkDomains.find(
          ({ domainID }) => domainID === workspaceData.appLinkDomain,
        )
      ) {
        selectedDomain = workspaceData.appLinkDomain
      }
    }

    selectedAppLinkDomainReactive(selectedDomain)
  }, [selectedAppLinkDomain, availableAppLinkDomains])

  return {
    /** Checks account permission level is suitable for shortLinks/appLinks (however hook is being used) */
    canUseShortLinks: isDemoAccount || !isFree,
    canUseAppLinks: isDemoAccount || isEnterprise,
    availableShortLinkDomains,
    availableAppLinkDomains,
    updateSelectedDomain,
  }
}

export default useCustomLinks
