import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'

import classNames from 'classnames'

import Button, { DeleteButton } from './button'
import { Preloader } from './loader'
import styles from '../styles/delete-button-with-confirmation.module.scss'

export interface DeleteButtonWithConfirmationProps {
  /** Used to know how far the confirmation box can expand */
  containerRef: React.RefObject<HTMLElement>
  buttonClassName?: string
  confirmationClassName?: string
  confirmMessage?: string | React.ReactNode
  onConfirm: () => Promise<void> | void
}

const DeleteButtonWithConfirmation = ({
  containerRef,
  buttonClassName,
  confirmationClassName,
  confirmMessage = 'Are you sure?',
  onConfirm,
}: DeleteButtonWithConfirmationProps) => {
  const [loading, setLoading] = useState(false)
  const [confirmActive, setConfirmActive] = useState(false)

  // Reset confirmation message when parent is no longer hovered
  useEffect(() => {
    containerRef.current?.addEventListener('mouseleave', () =>
      setConfirmActive(false),
    )
  }, [])

  return (
    <>
      <DeleteButton
        className={buttonClassName}
        onPress={() => setConfirmActive(true)}
      />
      {confirmActive && (
        <motion.div
          className={classNames(confirmationClassName, styles.confirmation)}
          initial="hidden"
          animate="show"
          variants={{
            hidden: { width: 0 },
            show: { width: containerRef.current?.offsetWidth },
          }}
        >
          {loading ? (
            <Preloader className={styles.loading} />
          ) : (
            <>
              <DeleteButton className={styles.deleteConfirmIcon} />
              <p>{confirmMessage}</p>
              <Button
                className={styles.pillButton}
                style={{ marginLeft: 'auto' }}
                isDisabled={loading}
                onPress={async () => {
                  setLoading(true)

                  await onConfirm()

                  setLoading(false)
                  setConfirmActive(false)
                }}
              >
                Yes
              </Button>
              <Button
                className={styles.pillButton}
                color="blue"
                isDisabled={loading}
                onPress={() => setConfirmActive(false)}
              >
                No
              </Button>
            </>
          )}
        </motion.div>
      )}
    </>
  )
}

export default DeleteButtonWithConfirmation
