import React, { useState, useMemo, useEffect } from 'react'
import { useReactiveVar } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { nanoid } from 'nanoid'
import ReactMarkdown from 'react-markdown'
import classNames from 'classnames'

import {
  explainAnomaliesDataReactive,
  explainDataReactive,
  explainDimensionsDataReactive,
  explainMetricsDataReactive,
} from '../api/apollo/legacy-reducers'
import SiteWrapper from '../components/site-wrapper'
import Manage from '../components/explain-manage'
import Layout from '../components/layout'
import Intro from '../components/intro'
import { ButtonRow } from '../components/button-row'
import Button from '../components/button'
import GraphBox from '../components/graph-box'
import DateRangeTabs from '../components/date-range-tabs'
import HeaderPanel, { Panel } from '../components/header-panel'
import Counter from '../components/counter'
import InfoModal from '../components/info-modal'
import GraphSlider from '../components/graph-slider'
import { metricSettingUp } from '../core/constants'
import {
  getMetricName,
  getItemByKeyValue,
  getAnomalyType,
  getAnomalyBreakdowUrl,
} from '../helpers'
import useMobile from '../hooks/useMobile'
import styles from '../styles/explain.module.scss'

interface EditProps {
  onClick: () => void
  isEdit: boolean
}

export function EditButton({ onClick, isEdit }: EditProps): React.ReactElement {
  return (
    <>
      <Button
        variant="secondary"
        color="blue"
        className={styles.manageButton}
        onPress={onClick}
      >
        {!isEdit ? 'Add key metric' : 'Add/Edit'}
      </Button>
    </>
  )
}

export default function Explain() {
  const explainData = useReactiveVar(explainDataReactive)
  const explainMetricsData = useReactiveVar(explainMetricsDataReactive)
  const explainDimensionsData = useReactiveVar(explainDimensionsDataReactive)
  const explainAnomaliesData = useReactiveVar(explainAnomaliesDataReactive)

  const history = useHistory()

  const isMobile = useMobile(769)

  const [manageActive, setActive] = useState(false)
  const [activeMetric, setActiveMetric] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [mounted, setMounted] = useState(0)

  useEffect(() => {
    const fetchData = async () => {
      if (explainData.pageLayout.length === 0) {
        // ! V1 endpoint no longer used
        // explainDataReactive({
        //   ...explainData,
        //   settings: {
        //     ...explainData.settings,
        //     loading: true,
        //   },
        // })
        // const req = await make.get({
        //   endpoint: 'get-explain-page-layout',
        // })
        // explainDataReactive({
        //   ...explainData,
        //   pageLayout: req?.res?.data?.pageLayout,
        //   settings: {
        //     ...req?.res?.data?.settings,
        //     loading: false,
        //   },
        // })
      }

      if (explainMetricsData.currentKeyMetrics.length === 0) {
        // ! V1 endpoint no longer used
        // await make.get({
        //   endpoint: 'get-current-key-metrics',
        // })
        // explainMetricsDataReactive(({...explainMetricsData, currentKeyMetrics: req.res.data.metrics}))
      }

      if (!explainDimensionsData.gaDimensions) {
        // ! V1 endpoint no longer used
        // const res = await make.get({
        //   endpoint: 'get-ga-custom-dimensions',
        // })
        // explainDimensionsDataReactive({
        //   ...explainDimensionsData,
        //   gaDimensions: res.data || [],
        // })
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (
      activeMetric === '' &&
      explainMetricsData &&
      explainMetricsData.currentKeyMetrics.length > 0 &&
      explainData &&
      explainData.pageLayout.length > 0
    ) {
      const filtered = explainData.pageLayout.filter(({ expanded }) => {
        return !!expanded
      })
      const selectedMetric =
        filtered.length > 0 && filtered[0].metricID
          ? filtered[0].metricID
          : explainMetricsData.currentKeyMetrics[0].metricID

      setActiveMetric(selectedMetric || '')
    }
  }, [explainMetricsData, explainData])

  useEffect(() => {
    const { dateRange } = explainData.settings
    if (
      explainMetricsData &&
      explainMetricsData.currentKeyMetrics.length > 0 &&
      dateRange !== ''
    ) {
      const count = explainMetricsData.currentKeyMetrics.length
      if (mounted !== count) {
        setMounted(count)
        explainMetricsData.currentKeyMetrics.forEach((item) => {
          const { metricID } = item

          if (metricID) {
            if (
              !Object.prototype.hasOwnProperty.call(
                explainAnomaliesData,
                metricID,
              )
            ) {
              // ! V1 endpoint no longer used
              // const { to, from } = getAnomalyDateRange(dateRange)
              // try {
              //   // Start search (loading)
              //   explainAnomaliesDataReactive({
              //     ...explainAnomaliesData,
              //     [metricID]: {
              //       ...defaultAnomalies,
              //       callData: {
              //         breakdownLoading: [],
              //         loading: true,
              //         error: false,
              //       },
              //       metricID,
              //     },
              //   })
              //   const res = await make.get({
              //     endpoint: `get-range/${metricID}/${from}/${to}`,
              //   })
              //   explainAnomaliesDataReactive({
              //     ...explainAnomaliesData,
              //     [metricID]: {
              //       ...defaultAnomalies,
              //       ...explainAnomaliesData[metricID],
              //       callData: {
              //         breakdownLoading: [],
              //         loading: false,
              //         error: false,
              //       },
              //       lastRange: {
              //         ...explainAnomaliesData[metricID].lastRange,
              //         from,
              //         to,
              //         selector,
              //       },
              //     },
              //   })
              // } catch {
              //   explainAnomaliesDataReactive({
              //     ...explainAnomaliesData,
              //     [metricID]: {
              //       ...defaultAnomalies,
              //       callData: {
              //         breakdownLoading: [],
              //         loading: false,
              //         error: true,
              //       },
              //       metricID,
              //     },
              //   })
              // }
            }
          }
        })
      }
    }
  }, [explainData, explainMetricsData, mounted])

  const selector = explainData.settings.dateRange

  const onClickHeaderButton = (currentMetricID: string) => {
    setActiveMetric(currentMetricID)
    // eslint-disable-next-line prefer-const
    let changedState = false

    // const updatedArray = explainData.pageLayout.map((item) => {
    //   const { expanded, metricID } = item
    //   if (
    //     (metricID !== currentMetricID && expanded) ||
    //     (metricID === currentMetricID && !expanded)
    //   ) {
    //     changedState = true
    //     return {
    //       ...item,
    //       expanded: !expanded,
    //     }
    //   }
    //   return item
    // })

    if (changedState) {
      // ! V1 endpoint no longer used
      // explainDataReactive({...explainData, pageLayout: updatedArray})
      // await make.put({
      //   endpoint: 'update-explain-page-layout',
      //   data: {
      //     ...explainData,
      //     pageLayout: updatedArray,
      //   },
      // })
    }
  }
  const currentMetricItem = useMemo(() => {
    if (
      activeMetric !== '' &&
      explainMetricsData.currentKeyMetrics.length > 0
    ) {
      const output = getItemByKeyValue(
        explainMetricsData.currentKeyMetrics,
        'metricID',
        activeMetric,
      )
      return output === -1 ? null : output
    }
    return null
  }, [activeMetric, explainMetricsData])

  return (
    <SiteWrapper>
      <Layout width={1200}>
        <Intro title="Anomaly explanation">
          <p>
            Click on key metrics to explore anomalies which effect performance.
          </p>
        </Intro>

        <HeaderPanel>
          <Panel>
            <ButtonRow>
              {explainData.pageLayout &&
                explainData.pageLayout.map(({ metric, metricID }) => {
                  const data = explainAnomaliesData[metricID]
                    ? explainAnomaliesData[metricID]
                    : null
                  const metricItem = getItemByKeyValue(
                    explainMetricsData.currentKeyMetrics,
                    'metricID',
                    metricID,
                  )
                  // const inProgress = !(
                  //   !metricItem ||
                  //   (metricItem && metricItem.metricSetup === true)
                  // )
                  const setupStatus =
                    metricItem !== -1 ? metricItem.setupStatus : ''
                  const displayName =
                    metricItem !== -1
                      ? metricItem.displayName
                      : getMetricName(
                          [
                            ...explainMetricsData.currentKeyMetrics,
                            ...explainMetricsData.metrics,
                          ],
                          metric,
                        )

                  const active = activeMetric === metricID
                  // !data || (data && !data.anomalyRange)
                  const counter = data
                    ? data.anomalyRange.filter((item) => {
                        return getAnomalyType(item) === null
                      }).length
                    : null
                  return (
                    <Button
                      variant="text"
                      key={nanoid()}
                      color={active ? 'pink' : 'grey'}
                      className={classNames(styles.tabButton, {
                        [styles.tabButtonActive]: active,
                      })}
                      onPress={() => onClickHeaderButton(metricID)}
                    >
                      {displayName}
                      <Counter
                        grey={!active}
                        setupStatus={setupStatus}
                        counter={counter}
                        loading={
                          data && data.callData ? data.callData.loading : false
                        }
                      />
                    </Button>
                  )
                })}
              {!explainData.settings.loading &&
                explainData.pageLayout &&
                !isMobile && (
                  <>
                    {explainData.pageLayout.length > 0 && (
                      <b className={styles.divider} />
                    )}
                    <EditButton
                      onClick={(): void => {
                        setActive(true)
                      }}
                      isEdit={explainData.pageLayout.length > 0}
                    />
                  </>
                )}
            </ButtonRow>
          </Panel>
          <Panel>
            {!explainData.settings.loading &&
              explainData.pageLayout &&
              isMobile && (
                <EditButton
                  onClick={(): void => {
                    setActive(true)
                  }}
                  isEdit={explainData.pageLayout.length > 0}
                />
              )}
            <DateRangeTabs selector={selector} />
          </Panel>
        </HeaderPanel>

        {explainDimensionsData.gaDimensions !== null && currentMetricItem && (
          <div key={nanoid()}>
            <GraphBox
              expanded
              metric={currentMetricItem.apiName}
              metricID={currentMetricItem.metricID}
              onClickAnomaly={(clickedDate, clickedMetricID) => {
                if (currentMetricItem.setupStatus === 'completed') {
                  history.push(
                    getAnomalyBreakdowUrl({
                      date: clickedDate,
                      metricID: clickedMetricID,
                    }),
                  )
                } else {
                  setShowModal(true)
                }
              }}
            >
              {currentMetricItem &&
                (currentMetricItem.detectionMethod === 'SD' ||
                  currentMetricItem.detectionMethod === 'AE') && (
                  <GraphSlider
                    type={currentMetricItem.detectionMethod}
                    metricID={currentMetricItem.metricID}
                    value={
                      currentMetricItem.detectionMethod === 'SD'
                        ? currentMetricItem.nStandardDeviations
                        : currentMetricItem.tPercentile
                    }
                    onChange={() => {
                      // ! V1 endpoint no longer used
                      // await make.get({
                      //   endpoint: 'get-current-key-metrics',
                      // })
                      // explainMetricsDataReactive(({...explainMetricsData, currentKeyMetrics: req.res.data.metrics}))

                      const { dateRange } = explainData.settings
                      if (dateRange !== '') {
                        // ! V1 endpoint no longer used
                        // const { to, from } = getAnomalyDateRange(dateRange)
                        // try {
                        //   // Start search (loading)
                        //   explainAnomaliesDataReactive({
                        //     ...explainAnomaliesData,
                        //     [metricID]: {
                        //       ...defaultAnomalies,
                        //       callData: {
                        //         breakdownLoading: [],
                        //         loading: true,
                        //         error: false,
                        //       },
                        //       metricID,
                        //     },
                        //   })
                        //   const res = await make.get({
                        //     endpoint: `get-range/${metricID}/${from}/${to}`,
                        //   })
                        //   explainAnomaliesDataReactive({
                        //     ...explainAnomaliesData,
                        //     [metricID]: {
                        //       ...defaultAnomalies,
                        //       ...explainAnomaliesData[metricID],
                        //       callData: {
                        //         breakdownLoading: [],
                        //         loading: false,
                        //         error: false,
                        //       },
                        //       lastRange: {
                        //         ...explainAnomaliesData[metricID].lastRange,
                        //         from,
                        //         to,
                        //         selector,
                        //       },
                        //     },
                        //   })
                        // } catch {
                        //   explainAnomaliesDataReactive({
                        //     ...explainAnomaliesData,
                        //     [metricID]: {
                        //       ...defaultAnomalies,
                        //       callData: {
                        //         breakdownLoading: [],
                        //         loading: false,
                        //         error: true,
                        //       },
                        //       metricID,
                        //     },
                        //   })
                        // }
                      }
                    }}
                  />
                )}
            </GraphBox>
          </div>
        )}
      </Layout>
      <Manage active={manageActive} toggleActive={setActive} />
      {showModal && (
        <InfoModal
          title="Setting up metric"
          onToggle={() => {
            setShowModal(false)
          }}
        >
          <ReactMarkdown source={metricSettingUp} />
        </InfoModal>
      )}
    </SiteWrapper>
  )
}
