import React, { useState, useCallback } from 'react'
import { useReactiveVar } from '@apollo/client'
import FileSaver from 'file-saver'
import moment from 'moment'

import { NavigateButton } from './button'
import ButtonDropdown from './button-dropdown'
import FileDragAndDrop from './file-drag-and-drop'
import Input from './input'
import Link from './link'
import { LoadingLabel } from './loader'
import Modal from './modal'
import Tooltip from './tooltip'
import { BoxedText, NoteText } from './typography'
import { linkOrCode } from '../api/apollo/variables'
import { uploadBulkEdit } from '../api/REST/track-client'
import { brandName, dynamicTextValues, supportEmail } from '../core/constants'
import useLogAction from '../hooks/useLogAction'
import styles from '../styles/bulk-edit-modal.module.scss'

export interface BulkEditProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

// This version only uploads full codes that have already been created
// It should be used for storing older codes in Uplifter that were not created here
export default function BulkEdit({ setShowModal }: BulkEditProps) {
  const linkCopy = useReactiveVar(linkOrCode)

  const logAction = useLogAction()

  const [useValidation, setUseValidation] = useState(true)
  const [error, setError] = useState<string | React.ReactElement>('')
  const [success, setSuccess] = useState<string | React.ReactElement>('')

  const [importInProgress, setImportInProgress] = useState(false)

  const onDrop = useCallback(
    async (acceptedFiles) => {
      setSuccess('')

      if (acceptedFiles.length > 0) {
        const res = await uploadBulkEdit(acceptedFiles.pop(), useValidation)

        if (res === true) {
          setError('')
          setSuccess('Your links have been updated.')

          logAction({
            variables: {
              action: 'bulk-edit-codes-success',
              functionName: 'bulkEdit',
              pagePath: '/track/view-links',
              websiteSection: 'track',
              extra: `useValidation: ${useValidation}`,
            },
          })

          window.setTimeout(() => {
            window.location.replace('/track/view-links?bulkEdit=true')
          }, 2000)

          return
        }

        if (
          res?.status === 400 &&
          res?.error?.response?.data &&
          res?.error?.response?.headers['content-type'] ===
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
          // Save the file as a blob and add a button to download it
          const fileBlob = new Blob([new Uint8Array(res.error.response.data)], {
            type:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })

          const now = new Date(Date.now())

          await FileSaver.saveAs(
            fileBlob,
            `${moment(now).format(
              'YYYY-MM-DD',
            )} - ${brandName} Bulk Edit Errors.xlsx`,
          )

          setError(
            <>
              <span>
                There were validation issues with your form.{' '}
                <NavigateButton
                  variant="text"
                  onPress={async () => {
                    await FileSaver.saveAs(
                      fileBlob,
                      `${moment(now).format(
                        'YYYY-MM-DD',
                      )} - ${brandName} Bulk Edit Errors.xlsx`,
                    )
                  }}
                >
                  Download error sheet
                </NavigateButton>
              </span>
            </>,
          )

          return
        }

        const type = res?.error?.response?.data?.detail || ''
        if (
          type === 'BAD_TEMPLATE' ||
          type === 'Template file does not match expected format' ||
          type ===
            'One or more short links are not in use or has not been included'
        ) {
          setError(
            'Incorrect file structure. Make sure all links have short links.',
          )
        } else if (
          type === 'BAD_FILE_FORMAT' ||
          type === 'Only CSV files are acceptable'
        ) {
          setError('Incorrect file type. Please only upload CSVs.')
        } else if (type === 'FAILED_UPLOAD') {
          setError('File upload has failed, please try again.')
        } else {
          setError(
            <span>
              File upload has failed. Please email{' '}
              <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link> for
              help.
            </span>,
          )
        }

        logAction({
          variables: {
            action: 'bulk-edit-codes-failed',
            functionName: 'bulkEdit',
            pagePath: '/track/view-links',
            websiteSection: 'track',
            extra: type,
          },
        })
      }
    },
    [useValidation],
  )

  return (
    <Modal setIsOpen={setShowModal} width="wide" modalHeader="Bulk edit links">
      <p>To bulk edit your existing links' landing pages and/or parameters:</p>
      <ol>
        <li>
          In the first column of the{' '}
          <BoxedText>
            <Link href="/track/view-links">
              {dynamicTextValues.trackViewPage[linkCopy]}
            </Link>
          </BoxedText>{' '}
          table, select the links you want to edit
        </li>
        <li>
          Click on the{' '}
          <ButtonDropdown
            containerClassName={styles.mockButtonDropdown}
            demoOnly
            buttonText="Actions"
          >
            <></>
          </ButtonDropdown>{' '}
          button and click 'Download selected'
        </li>
        <li>Edit landing pages and/or parameters in Excel and save as csv</li>
        <li>Upload your new csv below</li>
      </ol>
      <NoteText>
        Bulk edit only works with links which were created with either a short
        link or Smart link id (up_id).
      </NoteText>
      <div className={styles.checkboxField}>
        <Input
          type="checkbox"
          name="useValidation"
          label="Validate edited parameters match link rules"
          checked={useValidation}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setUseValidation(e.target.checked)
          }
        />
        <Tooltip
          id="bulk-edit-validation-tooltip"
          useIcon
          maxWidth={300}
          tooltipMessage="Your uploaded file will be checked against your workspace's validation rules for new links. If any of your edits fail validation, a file will be returned explaining the errors."
        />
      </div>
      <FileDragAndDrop
        uploadError={error}
        success={success}
        onDrop={async (acceptedFiles) => {
          setImportInProgress(true)

          await onDrop(acceptedFiles)

          setImportInProgress(false)
        }}
      />
      {useValidation && importInProgress && (
        <LoadingLabel
          className={styles.loadingMsg}
          label="Validating uploaded file, please wait"
        />
      )}
    </Modal>
  )
}
