import React, { useState, useMemo } from 'react'
import { useReactiveVar } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { nanoid } from 'nanoid'

import { AnomalyBreakdownRow } from './anomaly-breakdown-table'
import Button from './button'
import Graph from './graph'
import {
  explainAnomaliesDataReactive,
  explainDimensionsDataReactive,
  explainMetricsDataReactive,
} from '../api/apollo/legacy-reducers'
import { maxRowsExplainTable } from '../core/constants'
import {
  getMetricName,
  getAnomalyType,
  getAnomalyBreakdowUrl,
  sortData,
  getItemByKeyValue,
} from '../helpers'
import useMobile from '../hooks/useMobile'
import styles from '../styles/graph-box.module.scss'

interface Props {
  expanded: boolean
  metric: string
  metricID: string
  onClickAnomaly?: (clickedDate: string, clickedMetricID: string) => void
  children?: React.ReactNode
}

export default function GraphBox({
  expanded,
  metric,
  metricID,
  onClickAnomaly,
  children,
}: Props) {
  const history = useHistory()

  const isMobile = useMobile(769)

  /**
   * Old types used in V1 API
   * Will eventually be rebuilt
   */
  const explainMetricsData = useReactiveVar(explainMetricsDataReactive)
  const explainDimensionsData = useReactiveVar(explainDimensionsDataReactive)
  const explainAnomaliesData = useReactiveVar(explainAnomaliesDataReactive)

  const [showCount, setShowCount] = useState(maxRowsExplainTable)
  const [graphWidth, setGraphWidth] = useState(0)
  const [activeItem, setActiveItem]: [any, any] = useState(null)

  const data = explainAnomaliesData[metricID]
    ? explainAnomaliesData[metricID]
    : null

  const ordered = useMemo(() => {
    if (!data || !data.anomalyRange) {
      return []
    }
    return sortData(data.anomalyRange, 'date', false, ({ date }) => {
      return moment(date, 'DD/MM/YYYY').format('YYYYMMDD')
    })
  }, [data])

  const currentMetric = useMemo(() => {
    if (explainMetricsData && explainMetricsData.currentKeyMetrics) {
      const found = getItemByKeyValue(
        explainMetricsData.currentKeyMetrics,
        'metricID',
        metricID,
      )
      if (found !== -1) {
        return found
      }
    }
    return {}
    // eslint-disable-next-line
  }, [explainMetricsData])

  const { increaseSentimentPositive, detectionMethod } = currentMetric

  if (!data) {
    return <></>
  }

  const expandedClassName = expanded ? styles.expanded : styles.collapsed
  const metricName = getMetricName(
    [...explainMetricsData.currentKeyMetrics, ...explainMetricsData.metrics],
    metric,
  )

  return (
    <div className={`${styles.wrapper} ${expandedClassName}`}>
      <div className={styles.body}>
        <div
          className={styles.innerBody}
          ref={(element) => {
            if (element) {
              setGraphWidth(element.offsetWidth)
            }
          }}
        >
          <Graph
            data={data}
            metricID={metricID}
            metricName={metricName}
            gaDimensions={explainDimensionsData.gaDimensions}
            width={graphWidth}
            detectionMethod={detectionMethod}
            increaseSentimentPositive={increaseSentimentPositive}
            onClickAnomaly={(clickedDate, clickedMetricID) => {
              if (onClickAnomaly) {
                onClickAnomaly(clickedDate, clickedMetricID)
              } else {
                history.push(
                  getAnomalyBreakdowUrl({
                    date: clickedDate,
                    metricID: clickedMetricID,
                  }),
                )
              }
            }}
            onMouseHover={(hoveredItem) => {
              setActiveItem(hoveredItem)
            }}
          >
            {children}
          </Graph>
          {!data.callData.loading && ordered.length > 0 && (
            <h2 className={styles.smallTitle}>Most recent</h2>
          )}
          {ordered.slice(0, showCount).map((item) => {
            const { date } = item
            const highlighted = activeItem && activeItem.date === date
            return (
              <AnomalyBreakdownRow
                invertPolarity={!increaseSentimentPositive}
                type={getAnomalyType(item)}
                key={nanoid()}
                item={item}
                metricName={metricName}
                highlighted={highlighted}
                onMetricClick={() => {
                  if (onClickAnomaly) {
                    onClickAnomaly(date, metricID)
                  } else {
                    history.push(getAnomalyBreakdowUrl({ metricID, date }))
                  }
                }}
              />
            )
          })}
          {showCount < data.anomalyRange.length && (
            <Button
              onPress={() => {
                setShowCount(
                  isMobile ? showCount + 5 : data.anomalyRange.length,
                )
              }}
            >
              Load more ({showCount} of {data.anomalyRange.length})
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
