import React, { useRef, useState } from 'react'
import { useMutation } from '@apollo/client'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import classNames from 'classnames'

import Button from './button'
import DeleteButtonWithConfirmation from './delete-button-with-confirmation'
import Input, { Label, ClickEditInput } from './input'
import Modal from './modal'
import Row, { FormRow, LabelSlot, FieldSlot } from './row'
import Tooltip from './tooltip'
import {
  addRemoteDashboard,
  deleteRemoteDashboard,
  listRemoteDashboards,
  updateRemoteDashboard,
} from '../api/graphql/report-client'
import styles from '../styles/dashboard-manage.module.scss'

function AddNewItem({
  show = false,
  buttonOnly = false,
}: {
  show: boolean
  buttonOnly: boolean
}) {
  const [showAdd, setShowAdd] = useState(show)
  const [dashboardName, setDashboardName] = useState('')
  const [embedLink, setEmbedLink] = useState('')
  const [error, setError] = useState('')

  const [addDashboard] = useMutation(addRemoteDashboard)

  return (
    <>
      {showAdd && (
        <Row
          align="space-between"
          className={classNames(styles.row, styles.addNewItemRow)}
        >
          <div className={classNames(styles.tableCell, styles.addNewItem)}>
            <span className={styles.tableCellContent}>
              <span className={styles.tableCellContentPadded}>
                <FormRow last>
                  <LabelSlot noPadding column>
                    <Label id="dashboard-name" modalHeading>
                      Dashboard name
                    </Label>
                  </LabelSlot>
                  <FieldSlot noPadding column>
                    <Input
                      className={styles.input}
                      id="dashboard-name"
                      name="dashboard-name"
                      value={dashboardName}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const { value } = e.target
                        setDashboardName(value)
                      }}
                    />
                  </FieldSlot>
                </FormRow>
                <FormRow last>
                  <LabelSlot noPadding column>
                    <Label id="dashboard-link" modalHeading>
                      <Tooltip
                        id="embed-dashboard-tooltip"
                        useIcon
                        tooltipMessage="Only Google Data Studio and PowerBI dashboards are enabled."
                      >
                        Dashboard embed link
                      </Tooltip>
                    </Label>
                  </LabelSlot>
                  <FieldSlot noPadding column>
                    <Input
                      className={styles.input}
                      id="dashboard-link"
                      name="dashboard-link"
                      value={embedLink}
                      onChange={(
                        e: React.ChangeEvent<HTMLInputElement>,
                      ): void => {
                        const { value } = e.target as HTMLInputElement
                        setEmbedLink(value)
                      }}
                    />
                    {error !== '' && <p className={styles.error}>{error}</p>}
                  </FieldSlot>
                </FormRow>
              </span>
              <span className={styles.tableCellContentPadded}>
                <Button
                  color="blue"
                  isDisabled={dashboardName === '' || embedLink === ''}
                  onPress={async (): Promise<void> => {
                    try {
                      await addDashboard({
                        variables: {
                          dashboardName,
                          embedLink,
                        },
                        refetchQueries: [listRemoteDashboards],
                      })

                      setShowAdd(false)
                    } catch {
                      setError(
                        'Error embedding dashboard, please check your URL and try again',
                      )
                    }

                    // if (!res) {
                    //   setError(
                    //     'Error embedding dashboard, please check your URL and try again',
                    //   )
                    // } else {
                    //   setError('')
                    //   setShowAdd(false)
                    // }
                  }}
                >
                  Add
                </Button>
              </span>
            </span>
          </div>
        </Row>
      )}
      <Row
        align="space-between"
        className={classNames(styles.row, styles.addNewItemButtonRow, {
          [styles.buttonOnly]: buttonOnly,
        })}
      >
        <div className={classNames(styles.tableCell, styles.tableCellButtons)}>
          <Button
            variant="text"
            className={styles.addNewDashboard}
            onPress={() => setShowAdd(!showAdd)}
          >
            Add a new dashboard +
          </Button>
        </div>
      </Row>
    </>
  )
}

interface ManageProps {
  toggleActive: React.Dispatch<React.SetStateAction<boolean>>
  data: EmbedItem[]
}

export default function Manage({ toggleActive, data }: ManageProps) {
  const containerRef = useRef(null)

  const [updateDashboard] = useMutation(updateRemoteDashboard)
  const [deleteDashboard] = useMutation(deleteRemoteDashboard)

  return (
    <Modal
      className={styles.editDashboardsModal}
      width="wide"
      setIsOpen={toggleActive}
      modalHeader="Edit dashboards"
      yesText="OK"
      onYes={() => toggleActive(false)}
    >
      {data.length === 0 && (
        <p className={styles.padded}>
          Please add your first custom report. Only Google Data Studio and
          PowerBI links are currently supported. Email support to add other
          dashboards.
        </p>
      )}
      <div className={classNames(styles.wrapper)}>
        <div ref={containerRef} className={styles.body}>
          <div className={styles.list}>
            <DragDropContext
              onDragEnd={(result) => {
                const { destination, source } = result

                if (!destination) return

                if (
                  destination.droppableId === source.droppableId &&
                  destination.index === source.index
                ) {
                  return
                }

                // TODO: Add function on drag end
                console.log('TBC')
              }}
            >
              <Droppable droppableId="tableDropZone">
                {(droppableProvided) => {
                  return (
                    <div
                      ref={droppableProvided.innerRef}
                      {...droppableProvided.droppableProps}
                      className={styles.table}
                    >
                      <div className={styles.tableBody}>
                        {data.map((item, index) => {
                          return (
                            <Draggable
                              key={JSON.stringify(item)}
                              draggableId={JSON.stringify(item)}
                              index={index}
                              isDragDisabled
                            >
                              {(draggableProvided) => {
                                return (
                                  <div
                                    ref={draggableProvided.innerRef}
                                    {...draggableProvided.draggableProps}
                                    {...draggableProvided.dragHandleProps}
                                  >
                                    <Row
                                      align="space-between"
                                      className={styles.row}
                                      odd={!(index % 2)}
                                    >
                                      <div className={styles.tableCell}>
                                        <span
                                          className={styles.tableCellContent}
                                        >
                                          <span
                                            className={
                                              styles.tableCellContentPadded
                                            }
                                          >
                                            <ClickEditInput
                                              className={styles.input}
                                              id={`dashboard-name-${index}`}
                                              name={`dashboard-name-${index}`}
                                              value={item.dashboardName}
                                              onChange={async (
                                                value: string,
                                              ): Promise<void> => {
                                                const { dashboardID } = item

                                                await updateDashboard({
                                                  variables: {
                                                    dashboardID,
                                                    dashboardName: value,
                                                  },
                                                  refetchQueries: [
                                                    listRemoteDashboards,
                                                  ],
                                                })
                                              }}
                                            />
                                            <ClickEditInput
                                              className={styles.input}
                                              id={`dashboard-link-${index}`}
                                              name={`dashboard-link-${index}`}
                                              value={item.embedLink}
                                              onChange={async (
                                                value: string,
                                              ): Promise<void> => {
                                                const { dashboardID } = item

                                                await updateDashboard({
                                                  variables: {
                                                    dashboardID,
                                                    embedLink: value,
                                                  },
                                                  refetchQueries: [
                                                    listRemoteDashboards,
                                                  ],
                                                })
                                              }}
                                            />
                                          </span>
                                        </span>
                                      </div>
                                      <div className={styles.tableCell}>
                                        <img
                                          src={
                                            item.dashboardThumbnail !== ''
                                              ? item.dashboardThumbnail
                                              : '/powerbi-thumbnail.png'
                                          }
                                          className={styles.dashboardThumbnail}
                                          alt="dashboard thumbnail"
                                        />
                                      </div>
                                      <div
                                        className={classNames(
                                          styles.tableCell,
                                          styles.tableCellButtons,
                                        )}
                                      >
                                        <DeleteButtonWithConfirmation
                                          containerRef={containerRef}
                                          confirmationClassName={
                                            styles.deleteConfirmContainer
                                          }
                                          onConfirm={async () => {
                                            const { dashboardID } = item

                                            await deleteDashboard({
                                              variables: { dashboardID },
                                              refetchQueries: [
                                                listRemoteDashboards,
                                              ],
                                            })
                                          }}
                                        />
                                      </div>
                                    </Row>
                                  </div>
                                )
                              }}
                            </Draggable>
                          )
                        })}
                        {droppableProvided.placeholder}
                        <AddNewItem
                          show={data.length === 0}
                          buttonOnly={data.length === 0}
                        />
                      </div>
                    </div>
                  )
                }}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      </div>
    </Modal>
  )
}
