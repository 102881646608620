import React, { useEffect } from 'react'
import { useReactiveVar } from '@apollo/client'

import { passwordState } from '../api/apollo/variables'
import Carousel from '../components/carousel'
import ForgotPasswordForm from '../components/forgot-password-form'
import ForgotPasswordResult from '../components/forgot-password-result'
import Footer from '../components/footer'
import styles from '../styles/forgot-password.module.scss'

export default function ForgotPassword(): React.ReactElement {
  const currentPasswordState = useReactiveVar(passwordState)

  useEffect(() => {
    passwordState({
      ...currentPasswordState,
      forgotPassword: {
        requested: false,
        success: false,
      },
    })
  }, [])

  return (
    <div className={styles.ssoWrapperOuter}>
      <section className={styles.ssoWrapper}>
        <Carousel
          width={460}
          style={{ height: 538 }}
          currentProgress={
            currentPasswordState.forgotPassword.requested ? 1 : 0
          }
          items={[
            {
              key: 1,
              component: (props: any): React.ReactElement => (
                <ForgotPasswordForm {...props} />
              ),
            },
            {
              key: 2,
              component: (props: any): React.ReactElement => (
                <ForgotPasswordResult {...props} />
              ),
            },
          ]}
        />
      </section>
      <Footer />
    </div>
  )
}
