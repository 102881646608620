import { gql } from '../../__gql-types__/gql'

export const getAccountDataSource = gql(`
	query GetAccountDataSource {
		currentAccount {
			accountID
			blankAccount
			dataSource {
				adobeReportSuiteName
				adobeSegmentName
				connected
				gaAccountName
				gaPropertyName
				gaViewName
				ga4AccountName
				ga4PropertyName
				kind
			}
			trackLearnCopy
		}
	}
`)

export const getCompanyWorkspacesDataSources = gql(`
	query GetCompanyWorkspacesDataSources {
		currentCompany {
			companyID
			accountList {
				accountID
				accountName
				homepage
				blankAccount
				dataSource {
					gaPropertyID
					ga4PropertyID
					gaAccountName
					gaAccountID
					ga4PropertyName
					ga4AccountName
					connected
					adobeSegmentName
					adobeReportSuiteName
					adobeReportID
					ga4AccountID
					gaPropertyName
					gaViewID
					gaViewName
					kind
				}
			}
		}
	}
`)

export const getAccountUsers = gql(`
	query GetAccountUsers ($accountID: String!) {
		account(accountID: $accountID) {
			accountID
			userAccountProfiles {
				accountID
				accountName
				created
				expired
				inviteOpen
				lastLogin
				userEmail
				userID
				userPermission
			}
		}
	}
`)

export const toggleModuleAvailability = gql(`
	mutation ToggleModuleAvailability (
		$accountID: String!
		$companyID: String!
		$trackModule: Boolean
		$reportModule: Boolean
		$explainModule: Boolean
		$connectModule: Boolean
	) {
		userAccountSettings {
			toggleModuleAvailability (
				applyToCompany: false
				accountID: $accountID
				companyID: $companyID
				trackModule: $trackModule
				reportModule: $reportModule
				explainModule: $explainModule
				connectModule: $connectModule
			) {
				... on Account {
					__typename
					accountID
					trackAvailable
					reportAvailable
					explainAvailable
					connectAvailable
				}
			}
		}
	}
`)

export const deleteWorkspace = gql(`
	mutation DeleteWorkspace (
		$workspaceID: String!
	) {
		userAccountSettings {
			deleteAccount(accountID: $workspaceID) {
				newToken
				success
				wasUserCurrentAccount
			}
		}
	}
`)
