import React from 'react'
import styles from './menusvg.module.scss'

interface Props {
  active: boolean
  onClick?: VoidFunction
}

export default function MenuSvg({
  active,
  onClick,
}: Props): React.ReactElement<React.ReactSVGElement> {
  return (
    <div
      className={styles.container}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
    >
      <svg
        className={`${!active && styles.active}`}
        id="Layer_2"
        data-name="Layer 6"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 138.17 118.96"
      >
        <title>nav-bar-icons-new</title>
        <rect className="cls-1" width="138.17" height="20.94" rx="10.47" />
        <rect
          className="cls-1"
          y="98.02"
          width="138.17"
          height="20.94"
          rx="10.47"
        />
        <rect
          className="cls-1"
          y="50.08"
          width="138.17"
          height="20.94"
          rx="10.47"
        />
      </svg>
      <svg
        className={`${styles.close} ${active && styles.active}`}
        aria-hidden="true"
        focusable="false"
        data-prefix="fal"
        data-icon="times"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512"
      >
        <path
          fill="currentColor"
          d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
          className=""
        />
      </svg>
    </div>
  )
}
