import FileSaver from 'file-saver'
import moment from 'moment'

export const sizes = [
  {
    label: 'Small (600 x 600 px)',
    size: '600',
    value: '300',
    qrSize: 70,
  },
  {
    label: 'Medium (1000 x 1000 px)',
    size: '1000',
    value: '500',
    qrSize: 150,
  },
  {
    label: 'Large (2000 x 2000 px)',
    size: '2000',
    value: '1000',
    qrSize: 300,
  },
]

export const defaultFgColour = '#000000'
export const defaultBgColour = '#FFFFFF'

export type QRFormats = 'png' | 'jpg' | 'svg'

// Android phones require a 'quiet zone' to scan properly
// https://delivr.com/faq/1455/whats-with-the-white-border-around-the-qr-code
export const qrToBlob = async (
  container: HTMLDivElement,
  type: QRFormats,
  quietZone = '#fff',
) => {
  let blob: Blob | null = null

  if (type === 'svg') {
    const svg = container.querySelector('svg')

    if (svg) {
      const content = svg.innerHTML
      const viewBox = svg.getAttribute('viewBox')
      const height = svg.getAttribute('height')
      const width = svg.getAttribute('width')

      // Viewbox of downloaded SVG fixed to "0 0 100 100"
      // Input SVG viewBox could be e.g. "0 0 33 33"
      // It scales up with URL length
      // Input SVG needs to be scaled to fit in that viewbox
      // Scale must be 0.96 to include quiet zone
      // Every 0.01 off scale(1) reduces a 300 width by 2.5
      const viewBoxDims = viewBox ? viewBox.split(' ') : ['1']

      const scale =
        (100 / parseInt(viewBoxDims[viewBoxDims.length - 1], 10)) * 0.96
      const transitionScale =
        parseInt(viewBoxDims[viewBoxDims.length - 1], 10) / 100
      const transitionDim = height ? parseInt(height, 10) / 2 : 300
      const initialTransition = transitionDim * transitionScale

      const start = `<svg shape-rendering="crispEdges" height="${height}" width="${width}" viewBox="0 0 100 100" xml:space="preserve" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg"><rect width="100%" height="100%" fill="${quietZone}"/><g transform="translate(290 290) scale(${scale}) translate(-${initialTransition} -${initialTransition})">`

      blob = new Blob([`${start}${content}</g></svg>`], {
        type: 'image/svg+xml',
      })
    }
  } else {
    const canvas = container.querySelector('canvas')

    if (canvas) {
      const ctx = canvas.getContext('2d')

      // Make in-memory canvas to add border
      const inMemCanvas = document.createElement('canvas')
      const inMemCtx = inMemCanvas.getContext('2d')

      if (ctx && inMemCtx) {
        inMemCanvas.width = canvas.width + 12
        inMemCanvas.height = canvas.height + 12
        inMemCtx.fillStyle = quietZone
        inMemCtx.fillRect(0, 0, inMemCanvas.width, inMemCanvas.height)
        inMemCtx.drawImage(canvas, 6, 6)
      }

      blob = await new Promise((resolve) => {
        inMemCanvas.toBlob(resolve, `image/${type}`, 1.0)
      })
    }
  }

  return blob
}

export const downloadQRCode = async (
  blob: Blob | null,
  type: QRFormats = 'png',
  code = '',
) => {
  if (blob) {
    const now = new Date(Date.now())

    const fileName = code
      .replace(/(https:\/\/www.)|(http:\/\/)|(https:\/\/)/gi, '')
      .slice(0, 20)

    await FileSaver.saveAs(
      blob,
      `${moment(now).format('YYYY-MM-DD')} QR to ${fileName}.${type}`,
    )

    return true
  }

  return false
}
