import React, { useState } from 'react'
import { useReactiveVar } from '@apollo/client'

import Button, { CopyButton } from './button'
import { BiLine } from './counter'
import { FormField, FormLabel, FormRow } from './form'
import { PassiveInput } from './input'
import QRCodePreview from './qr-code'
import QRCodeModal from './qr-code-modal'
import { InnerBox, OuterBox } from './two-columns'
import { linkOrCode } from '../api/apollo/variables'
import CogIcon from '../assets/icon-cog.svg'
import DownloadIcon from '../assets/icon-download.svg'
import styles from '../styles/track-show-latest-link.module.scss'

interface ShowLatestCodeProps {
  newLink: string
}

export function ShowLatestCode({ newLink }: ShowLatestCodeProps) {
  const referToLinks = useReactiveVar(linkOrCode)

  const [showQrModal, setShowQrModal] = useState(false)

  return (
    <>
      <OuterBox>
        <InnerBox>
          <FormRow className={styles.topRow} bottomBorder>
            <FormLabel>Your last created {referToLinks}</FormLabel>
            <FormField>
              <div className={styles.inlineRow}>
                <PassiveInput id="generated-code" value={newLink} />
                <CopyButton value={newLink}>Copy</CopyButton>
              </div>
            </FormField>
          </FormRow>
          <FormRow>
            <FormLabel tooltip="An optional QR code which can be scanned by a mobile phone camera to access the landing page with campaign codes.">
              QR code
            </FormLabel>
            <FormField>
              <QRCodePreview
                className={styles.qrCodePreview}
                url={newLink}
                showLogo
                disableCopy
              >
                <Button
                  variant="text"
                  color="grey"
                  className={styles.customiseButton}
                  icon={{
                    src: CogIcon,
                    alt: 'Customise',
                    imgHeight: 20,
                  }}
                  style={{ fontWeight: 600 }}
                  onPress={() => setShowQrModal(true)}
                >
                  Customise
                </Button>
                <Button
                  variant="text"
                  color="grey"
                  className={styles.downloadButton}
                  icon={{
                    src: DownloadIcon,
                    alt: 'Download',
                    imgHeight: 20,
                  }}
                  style={{ fontWeight: 600 }}
                  onPress={() => setShowQrModal(true)}
                >
                  Download
                </Button>
              </QRCodePreview>
              <BiLine arrowTop className={styles.qrBiLine}>
                Always test your <strong>QR code</strong> before use!
              </BiLine>
            </FormField>
          </FormRow>
        </InnerBox>
      </OuterBox>
      {showQrModal && (
        <QRCodeModal code={newLink} setShowModal={setShowQrModal} />
      )}
    </>
  )
}
