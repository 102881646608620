import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client'
import numeral from 'numeraljs'

import { currentUserDetails } from '../api/apollo/variables'
import { getCampaignCodeGenerator } from '../api/graphql/track-create-client'
import {
  addAppContextOption,
  createAppGroup,
  deleteAppContextListOptions,
  deleteAppGroup,
  listAppContextOptions,
  listAppGroups,
  listDeepLinkServices,
  registerApp,
  updateAppContextList,
  updateAppGroup,
  updateRegisteredApp,
} from '../api/graphql/track-edit-client'
import { getUserInfo } from '../api/graphql/user-client'
import Accordion from '../components/accordion'
import Button, { DeleteButton } from '../components/button'
import { ButtonRow } from '../components/button-row'
import DeleteButtonWithConfirmation from '../components/delete-button-with-confirmation'
import { FormField, FormLabel, FormRow } from '../components/form'
import Input, { ClickEditInput, SearchInput } from '../components/input'
import Intro from '../components/intro'
import Layout from '../components/layout'
import Link from '../components/link'
import { Preloader } from '../components/loader'
import Modal from '../components/modal'
import NoDataMessage from '../components/no-data-message'
import OrderArrow from '../components/order-arrow'
import Pagination from '../components/pagination'
import SelectBox from '../components/select-box'
import SiteWrapper from '../components/site-wrapper'
import Table from '../components/table'
import Tooltip from '../components/tooltip'
import { BoxedText, ErrorMessage, Heading } from '../components/typography'
import { RequestBrandedDomainModal } from '../components/upgrade-modals'
import { UrlValidationMessage } from '../components/url-validation-message'
import { supportEmail } from '../core/constants'
import { isValidUrl } from '../helpers'
import { makeLinkSecure } from '../helpers/track-create'
import {
  defaultAppLinkDomain,
  defaultValidationChecksValues,
} from '../helpers/track-module'
import useLogAction from '../hooks/useLogAction'
import useMobile from '../hooks/useMobile'
import useTableSortFilter from '../hooks/useTableSortFilter'
import useUrlValidation from '../hooks/useUrlValidation'
import styles from '../styles/track-edit-app-destinations.module.scss'
import {
  DeepLinkAndroidApp,
  DeepLinkIosApp,
  ListDeepLinkServicesQuery,
  ListAppContextOptionsQuery,
  ListAppGroupsQuery,
} from '../__gql-types__/graphql'

const appFieldsData = {
  deepLinkServiceID: {
    name: 'App link domain',
    tooltip: (
      <p>
        This is the URL domain that users will see when they click on a deep
        link.
      </p>
    ),
  },
  appGroupName: {
    name: 'App group title',
    tooltip: (
      <p>
        This will show in the 'Apps' dropdown on the{' '}
        <BoxedText>Track &gt; Create</BoxedText>
        page.
      </p>
    ),
  },
  redirectWeb: {
    name: 'Default fallback URL',
    tooltip:
      'If the deep link cannot access the app or app store link, the user will be directed to this URL.',
  },
  androidAppName: {
    name: 'Android app name',
    tooltip: 'User-friendly name to identify your Android app in Uplifter.',
  },
  androidPackage: {
    name: 'Android package',
    tooltip: (
      <p>
        Found in your app's Play Store URL. For example, in the{' '}
        <Link href="http://play.google.com/store/apps/details?id=com.google.android.apps.maps">
          Google Maps Play Store link
        </Link>
        , the package name is com.google.android.apps.maps.
      </p>
    ),
  },
  androidPlayStoreLink: {
    name: 'Play Store link',
    tooltip: 'The link to your app on the Google Play Store.',
  },
  assetLinksAndroid: {
    name: 'Asset links',
    tooltip: (
      <p>
        Contextual information you app will receive when a link in this app
        group is clicked. Requires developer input, see details{' '}
        <Link href="https://support.uplifter.ai/hc/en-us/articles/20854251291165-How-to-create-deep-links-to-mobile-applications">
          here
        </Link>
        .
      </p>
    ),
  },
  iosAppName: {
    name: 'iOS app name',
    tooltip: 'User-friendly name to identify your iOS app in Uplifter.',
  },
  iosAppStoreID: {
    name: 'App Store ID',
    tooltip:
      'Found in the "Distribution" section of your Apple Developer account.',
  },
  iosBundleID: {
    name: 'Bundle ID',
    tooltip:
      'Found in the "Distribution" section of your Apple Developer account.',
  },
  iosTeamID: {
    name: 'Team ID',
    tooltip:
      'Found in the Apple Developer program by clicking "Membership Details" on the homepage.',
  },
}

interface FallbackUrlFieldProps {
  fallbackUrlValue: string
  onChange: (val: string) => void
  fallbackUrlError: boolean
  setFallbackUrlError: React.Dispatch<React.SetStateAction<boolean>>
}

const FallbackUrlField = ({
  fallbackUrlValue,
  onChange,
  fallbackUrlError,
  setFallbackUrlError,
}: FallbackUrlFieldProps) => {
  const { workspaceID } = useReactiveVar(currentUserDetails)

  const { validateUrls, validationResults } = useUrlValidation()

  const [getGenerator, { data: generatorData }] = useLazyQuery(
    getCampaignCodeGenerator,
  )

  // Wait for account ID so generator can be cached
  useEffect(() => {
    if (!workspaceID) return

    getGenerator()
  }, [workspaceID])

  const validationChecks = useMemo(() => {
    if (!generatorData) return null

    return generatorData.campaignCodeGenerator.validationChecks
  }, [generatorData])

  const noSpecialCharacters = useMemo(() => {
    return (
      validationChecks?.find(
        (check) => check.name === 'NO_SPECIAL_CHARS_LANDING_PAGE',
      )?.enabled || false
    )
  }, [validationChecks])

  const [noSpacesError, setNoSpacesError] = useState(false)
  const [noSpecialCharactersError, setNoSpecialCharactersError] = useState(
    false,
  )

  /** Replaces spaces and prevents special characters when rule is set */
  const formatFallbackUrl = useCallback(
    (input: string) => {
      setNoSpacesError(false)
      setNoSpecialCharactersError(false)
      setFallbackUrlError(true)

      let preparedVal = input

      // Spaces are not allowed in URLs
      if (/\s/.test(preparedVal)) {
        preparedVal = preparedVal.replaceAll(/\s/g, '')

        if (preparedVal.length > 0) {
          setNoSpacesError(true)
        }
      }

      const hasSpecialCharacters =
        noSpecialCharacters &&
        preparedVal.match(
          new RegExp(
            defaultValidationChecksValues.NO_SPECIAL_CHARS_LANDING_PAGE,
          ),
        ) !== null

      if (hasSpecialCharacters) {
        setNoSpecialCharactersError(true)
      }

      return preparedVal
    },
    [noSpecialCharacters, noSpacesError, noSpecialCharactersError],
  )

  // Check the state of valid URLs
  useEffect(() => {
    if (isValidUrl(fallbackUrlValue)) {
      if (!noSpacesError && !noSpecialCharactersError) {
        setFallbackUrlError(false)
      }

      if (
        !Object.prototype.hasOwnProperty.call(
          validationResults,
          fallbackUrlValue,
        )
      ) {
        validateUrls([fallbackUrlValue])
      }
    }
  }, [fallbackUrlValue])

  return (
    <>
      <Input
        id="default-fallback-url"
        name="default-fallback-url"
        placeholder="Enter URL"
        delay={800}
        error={noSpacesError || noSpecialCharactersError || fallbackUrlError}
        value={fallbackUrlValue}
        beforeChange={(inputValue) => formatFallbackUrl(inputValue)}
        onValueChange={(val: string) => {
          onChange(val)
        }}
        onPaste={(e) => {
          // Specifically to block error message being shown about spaces being removed from pasted data
          const newPastedText = formatFallbackUrl(
            e.clipboardData.getData('Text'),
          )

          onChange(newPastedText)
        }}
        onBlur={(e) => {
          // Accomodate a delay in setting currentValue
          // This may happen if blur occurs too quickly
          const valueOnBlur = e.target.value

          // Automatically add protocol to link if not present
          const validUrl = makeLinkSecure(valueOnBlur)

          // Link did not have protocol
          if (validUrl !== valueOnBlur) {
            onChange(validUrl)
          }
        }}
      />
      {fallbackUrlError && (
        <ErrorMessage>Please enter a valid URL.</ErrorMessage>
      )}
      {noSpacesError && (
        <ErrorMessage>
          Spaces are not allowed in the landing page URL.
        </ErrorMessage>
      )}
      {noSpecialCharactersError && (
        <ErrorMessage>
          Special characters (?=&) are not allowed to avoid users copying
          landing pages with existing parameters.{' '}
          <Link type="arrowForward" href="/track/edit-parameters-and-rules">
            Disable this rule for deep linking
          </Link>
        </ErrorMessage>
      )}
      {isValidUrl(fallbackUrlValue) && (
        <UrlValidationMessage
          url={fallbackUrlValue}
          validationDetails={validationResults[fallbackUrlValue]}
        />
      )}
    </>
  )
}

interface AddAppGroupModalProps {
  setShowAddAppGroupModal: React.Dispatch<React.SetStateAction<boolean>>
  deepLinkServices: ListDeepLinkServicesQuery['track']['deepLinkQueries']['listDeepLinkServices']
}

const AddAppGroupModal = ({
  setShowAddAppGroupModal,
  deepLinkServices,
}: AddAppGroupModalProps) => {
  const { data: homepageData } = useQuery(getUserInfo)

  const homepage = useMemo(() => {
    if (!homepageData) return ''

    return homepageData.currentAccount.homepage
  }, [homepageData])

  const [refetchAppGroups] = useLazyQuery(listAppGroups)

  const [
    addAppGroup,
    { loading: addingAppGroup, error: addAppGroupError },
  ] = useMutation(createAppGroup)
  const [
    registerNewApp,
    { loading: registeringNewApp, error: registerNewAppError },
  ] = useMutation(registerApp)

  const [submitted, setSubmitted] = useState(false)
  const [addAppGroupFields, setAddAppGroupFields] = useState({
    deepLinkServiceID: '',
    appGroupName: '',
    appGroupDescription: '',
    redirectWeb: homepage || '',
    isUAT: false,
  })
  const [fallbackUrlError, setFallbackUrlError] = useState(false)
  const [androidAppFields, setAndroidAppFields] = useState({
    active: false,
    androidAppName: '',
    androidPackage: '',
    androidPlayStoreLink: '',
    assetLinksAndroid: '',
  })
  const [androidAppNameUpdated, setAndroidAppNameUpdated] = useState(false)
  const [iosAppFields, setIosAppFields] = useState({
    active: false,
    iosAppName: '',
    iosAppStoreID: '',
    iosBundleID: '',
    iosTeamID: '',
  })
  const [iosAppNameUpdated, setIosAppNameUpdated] = useState(false)
  const [showBrandedDomainModal, setShowBrandedDomainModal] = useState(false)

  useEffect(() => {
    if (deepLinkServices.length === 0) return

    if (deepLinkServices.length === 1) {
      setAddAppGroupFields((curr) => ({
        ...curr,
        deepLinkServiceID: deepLinkServices[0].deepLinkServiceID,
      }))
    }
  }, [deepLinkServices])

  const deepLinkServicesWithLabels = useMemo(() => {
    return deepLinkServices.map((dL) => ({
      ...dL,
      deepLinkServiceLabel:
        dL.deepLinkServiceID === 'default'
          ? `Uplifter default (${defaultAppLinkDomain})`
          : `${dL.deepLinkServiceName} (${dL.deepLinkServiceSubdomain})`,
    }))
  }, [deepLinkServices])

  const formError = useMemo(() => {
    if (addAppGroupError)
      return { type: 'addAppGroup', msg: 'Error creating app group.' }

    if (registerNewAppError)
      return { type: 'registerNewApp', msg: 'Error registering app.' }

    if (fallbackUrlError)
      return { type: 'fallbackUrl', msg: 'Please enter a valid fallback URL.' }

    if (
      androidAppFields.active &&
      (!androidAppFields.androidPackage ||
        !androidAppFields.androidPlayStoreLink ||
        !androidAppFields.androidAppName ||
        !androidAppFields.assetLinksAndroid)
    ) {
      return {
        type: 'androidAppError',
        msg: 'Please fill in all fields for the Android app.',
      }
    }

    if (
      iosAppFields.active &&
      (!iosAppFields.iosAppName ||
        !iosAppFields.iosAppStoreID ||
        !iosAppFields.iosBundleID ||
        !iosAppFields.iosTeamID)
    ) {
      return {
        type: 'iosAppError',
        msg: 'Please fill in all fields for the iOS app.',
      }
    }

    return null
  }, [
    addAppGroupError,
    registerNewAppError,
    fallbackUrlError,
    androidAppFields,
    iosAppFields,
  ])

  return (
    <>
      <Modal
        setIsOpen={setShowAddAppGroupModal}
        width="wide"
        modalHeader="Add apps"
        yesText="Add apps"
        yesButtonDisabled={
          !addAppGroupFields.deepLinkServiceID ||
          !addAppGroupFields.appGroupName ||
          !addAppGroupFields.redirectWeb
        }
        yesButtonLoading={addingAppGroup || registeringNewApp}
        onYes={async () => {
          setSubmitted(true)

          if (formError || !addAppGroupFields.deepLinkServiceID) return

          const {
            deepLinkServiceID,
            appGroupName,
            appGroupDescription,
            redirectWeb,
            isUAT,
          } = addAppGroupFields

          const { data: appGroupData } = await addAppGroup({
            variables: {
              deepLinkServiceID,
              appGroupName,
              redirectWeb,
              appGroupDescription,
              isUAT,
              /** Redirection to distinct apps via same deeplink service subdomain' */
              // appGroupPath: 'TBC: Future feature',
            },
          })

          if (appGroupData) {
            const {
              track: {
                trackDeeplinkingMutations: {
                  createAppGroup: { appGroupID },
                },
              },
            } = appGroupData

            if (androidAppFields.active) {
              const {
                androidPackage,
                androidPlayStoreLink,
                assetLinksAndroid,
                androidAppName,
              } = androidAppFields

              await registerNewApp({
                variables: {
                  appGroupID,
                  deepLinkServiceID,
                  device: 'android',
                  redirectMethod: 'applink',
                  androidPackage,
                  androidPlayStoreLink,
                  assetLinksAndroid,
                  androidAppName,
                },
              })
            }

            if (iosAppFields.active) {
              const {
                iosAppStoreID,
                iosBundleID,
                iosTeamID,
                iosAppName,
              } = iosAppFields

              await registerNewApp({
                variables: {
                  appGroupID,
                  deepLinkServiceID,
                  device: 'ios',
                  redirectMethod: 'universal',
                  iosAppStoreID,
                  iosBundleID,
                  iosTeamID,
                  iosAppName,
                },
              })
            }

            await refetchAppGroups()

            setShowAddAppGroupModal(false)
          }
        }}
        footerContent={
          submitted && formError && <ErrorMessage>{formError.msg}</ErrorMessage>
        }
      >
        <p>
          Enter the details of the app you want users to be redirected to after
          clicking on an Uplifter app link.
        </p>
        <ul>
          <li>
            Pair Android/iOS apps into an app group users select in{' '}
            <BoxedText>
              <Link href="/track/create">Track &gt; Create links</Link>
            </BoxedText>
            .
          </li>
          <li>
            After an app group has been added, you can set up deep links to
            specific app screens.
          </li>
        </ul>
        <FormRow>
          <FormLabel
            id="app-group-title"
            className={styles.inputLabel}
            tooltip={appFieldsData.appGroupName.tooltip}
          >
            {appFieldsData.appGroupName.name}
          </FormLabel>
          <FormField className={styles.inputField}>
            <Input
              id="app-group-title"
              name="app-group-title"
              placeholder="E.g. UAT apps"
              value={addAppGroupFields.appGroupName}
              onValueChange={(val: string) => {
                setAddAppGroupFields((curr) => ({ ...curr, appGroupName: val }))

                if (!androidAppNameUpdated) {
                  setAndroidAppFields((curr) => ({
                    ...curr,
                    androidAppName: `${val} - Android`,
                  }))
                }

                if (!iosAppNameUpdated) {
                  setIosAppFields((curr) => ({
                    ...curr,
                    iosAppName: `${val} - iOS`,
                  }))
                }

                setSubmitted(false)
              }}
            />
          </FormField>
        </FormRow>
        {deepLinkServices.length > 1 && (
          <FormRow>
            <FormLabel
              id="deep-link-service-id"
              className={styles.inputLabel}
              tooltip={appFieldsData.deepLinkServiceID.tooltip}
            >
              {appFieldsData.deepLinkServiceID.name}
            </FormLabel>
            <FormField className={styles.inputField}>
              <SelectBox
                id="deep-link-service-id"
                name="deep-link-service-id"
                placeholder="Select a domain"
                valueKey="deepLinkServiceID"
                labelKey="deepLinkServiceLabel"
                value={deepLinkServicesWithLabels.find(
                  (dL) =>
                    dL.deepLinkServiceID ===
                    addAppGroupFields.deepLinkServiceID,
                )}
                options={deepLinkServicesWithLabels}
                onChange={(newValue) => {
                  setAddAppGroupFields((curr) => ({
                    ...curr,
                    deepLinkServiceID: newValue?.deepLinkServiceID || '',
                  }))

                  setSubmitted(false)
                }}
              >
                <Button
                  variant="text"
                  className={styles.addButton}
                  onPressStart={() => setShowBrandedDomainModal(true)}
                >
                  Get a branded deep link domain +
                </Button>
              </SelectBox>
            </FormField>
          </FormRow>
        )}
        <FormRow includePaddingBottom bottomBorder>
          <FormLabel
            id="default-fallback-url"
            className={styles.inputLabel}
            tooltip={appFieldsData.redirectWeb.tooltip}
          >
            {appFieldsData.redirectWeb.name}
          </FormLabel>
          <FormField className={styles.inputField}>
            <FallbackUrlField
              fallbackUrlValue={addAppGroupFields.redirectWeb}
              onChange={(val) => {
                setSubmitted(false)
                setAddAppGroupFields((curr) => ({
                  ...curr,
                  redirectWeb: val,
                }))
              }}
              fallbackUrlError={submitted && fallbackUrlError}
              setFallbackUrlError={setFallbackUrlError}
            />
          </FormField>
        </FormRow>
        {/* <FormRow>
        <FormLabel id="is-uat" className={styles.inputLabel} tooltip="Check to flag this app group as testing/development apps.">
          Test/development app(s)
        </FormLabel>
        <FormField className={styles.inputField}>
          <Input
            type="checkbox"
            name="is-uat"
            checked={addAppGroupFields.isUAT}
            label=" "
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSubmitted(false)
              setAddAppGroupFields((curr) => ({
                ...curr,
                isUAT: e.target.checked,
              }))
            }}
          />
        </FormField>
      </FormRow> */}
        <FormRow
          bottomBorder={!androidAppFields.active}
          includePaddingBottom={!androidAppFields.active}
        >
          <FormLabel
            id="android-app"
            className={styles.inputLabel}
            tooltip="Check this if your app group includes an Android app."
          >
            Android app
          </FormLabel>
          <FormField className={styles.inputField}>
            <Input
              type="checkbox"
              name="android-app"
              checked={androidAppFields.active}
              label={' '}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSubmitted(false)
                setAndroidAppFields((curr) => ({
                  ...curr,
                  active: e.target.checked,
                }))
              }}
            />
          </FormField>
        </FormRow>
        {androidAppFields.active && (
          <>
            {['androidAppName', 'androidPackage', 'androidPlayStoreLink'].map(
              (androidAppField) => {
                const { name, tooltip } = appFieldsData[androidAppField]

                return (
                  <FormRow key={androidAppField}>
                    <FormLabel
                      id={androidAppField}
                      className={styles.inputLabel}
                      disabled={!androidAppFields.active}
                      tooltip={tooltip}
                      tooltipClickable
                    >
                      {name}
                    </FormLabel>
                    <FormField className={styles.inputField}>
                      <Input
                        id={androidAppField}
                        name={androidAppField}
                        disabled={!androidAppFields.active}
                        error={
                          submitted &&
                          !androidAppFields[androidAppField] &&
                          formError?.type === 'androidAppError'
                        }
                        placeholder={appFieldsData[androidAppField].name}
                        value={androidAppFields[androidAppField]}
                        onValueChange={(val: string) => {
                          setSubmitted(false)

                          setAndroidAppFields((curr) => ({
                            ...curr,
                            [androidAppField]: val,
                          }))

                          if (androidAppField === 'androidAppName') {
                            setAndroidAppNameUpdated(true)
                          }
                        }}
                      />
                    </FormField>
                  </FormRow>
                )
              },
            )}
            <FormRow includePaddingBottom bottomBorder>
              <FormLabel
                id="android-asset-links"
                className={styles.inputLabel}
                disabled={!androidAppFields.active}
                tooltip={appFieldsData.assetLinksAndroid.tooltip}
              >
                {appFieldsData.assetLinksAndroid.name}
              </FormLabel>
              <FormField className={styles.inputField}>
                <Input
                  id="android-asset-links"
                  name="android-asset-links"
                  type="textArea"
                  disabled={!androidAppFields.active}
                  error={
                    submitted &&
                    !androidAppFields.assetLinksAndroid &&
                    formError?.type === 'androidAppError'
                  }
                  placeholder={appFieldsData.assetLinksAndroid.name}
                  value={androidAppFields.assetLinksAndroid}
                  onValueChange={(val: string) => {
                    setSubmitted(false)
                    setAndroidAppFields((curr) => ({
                      ...curr,
                      assetLinksAndroid: val,
                    }))
                  }}
                />
              </FormField>
            </FormRow>
          </>
        )}
        <FormRow
        // includePaddingBottom={!iosAppFields.active}
        >
          <FormLabel
            id="ios-app"
            className={styles.inputLabel}
            tooltip="Check this if your app group includes an iOS app."
          >
            iOS app
          </FormLabel>
          <FormField className={styles.inputField}>
            <Input
              type="checkbox"
              name="ios-app"
              checked={iosAppFields.active}
              label={' '}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSubmitted(false)
                setIosAppFields((curr) => ({
                  ...curr,
                  active: e.target.checked,
                }))
              }}
            />
          </FormField>
        </FormRow>
        {iosAppFields.active && (
          <>
            {['iosAppName', 'iosAppStoreID', 'iosBundleID', 'iosTeamID'].map(
              (iosAppField, fieldIndex) => {
                const { name, tooltip } = appFieldsData[iosAppField]

                return (
                  <FormRow key={iosAppField}>
                    <FormLabel
                      id={iosAppField}
                      className={styles.inputLabel}
                      disabled={!iosAppFields.active}
                      tooltip={tooltip}
                      tooltipClickable
                    >
                      {name}
                    </FormLabel>
                    <FormField className={styles.inputField}>
                      <Input
                        id={iosAppField}
                        name={iosAppField}
                        disabled={!iosAppFields.active}
                        error={
                          submitted &&
                          !iosAppFields[iosAppField] &&
                          formError?.type === 'iosAppError'
                        }
                        placeholder={name}
                        value={iosAppFields[iosAppField]}
                        onValueChange={(val: string) => {
                          setSubmitted(false)
                          setIosAppFields((curr) => ({
                            ...curr,
                            [iosAppField]: val,
                          }))

                          if (iosAppField === 'iosAppName') {
                            setIosAppNameUpdated(true)
                          }
                        }}
                      />
                    </FormField>
                  </FormRow>
                )
              },
            )}
          </>
        )}
      </Modal>
      {showBrandedDomainModal && (
        <RequestBrandedDomainModal
          onHideModal={setShowBrandedDomainModal}
          linkType="appLink"
        />
      )}
    </>
  )
}

const AddNewAppContextOption = ({ appGroupID }: { appGroupID: string }) => {
  const logAction = useLogAction()

  const [addNewContextOption] = useMutation(addAppContextOption)

  const [newContextOption, setNewContextOption] = useState({
    optionDisplayName: '',
    optionKey: '',
    optionValue: '',
  })
  const [optionValueUpdated, setOptionValueUpdated] = useState(false)

  const addContextOption = useCallback(
    async (contextOptionToAdd: {
      optionDisplayName: string
      optionKey: string
      optionValue: string
    }) => {
      const { optionDisplayName, optionKey, optionValue } = contextOptionToAdd

      if (optionDisplayName === '' || optionKey === '' || optionValue === '') {
        return
      }

      await addNewContextOption({
        variables: {
          appGroupID,
          insertContextList: [contextOptionToAdd],
        },
        refetchQueries: [listAppContextOptions],
      })

      logAction({
        variables: {
          action: 'add-app-context-option',
          extra: JSON.stringify(contextOptionToAdd),
          websiteSection: 'track',
          functionName: 'addAppContextOption',
          pagePath: '/track/edit-app-destinations',
        },
      })

      setNewContextOption({
        optionDisplayName: '',
        optionKey: '',
        optionValue: '',
      })
    },
    [],
  )

  return (
    <tr className={styles.addContextOptionRow}>
      <td>
        <Input
          name="new-option-display-name"
          className={styles.addOptionInput}
          placeholder="Add new app screen name"
          value={newContextOption.optionDisplayName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target as HTMLInputElement

            setNewContextOption((curr) => ({
              ...curr,
              optionDisplayName: value,
              optionValue: optionValueUpdated
                ? curr.optionValue
                : value.toLowerCase().replaceAll(/\s/g, '_'),
            }))
          }}
          onKeyUp={async (e) => {
            if (e.key === 'Enter') {
              addContextOption(newContextOption)
            }
          }}
        />
      </td>
      <td>
        <Input
          name="new-option-key"
          className={styles.addOptionInput}
          placeholder="Add new key"
          value={newContextOption.optionKey}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target as HTMLInputElement

            setNewContextOption((curr) => ({
              ...curr,
              optionKey: value,
            }))
          }}
          onKeyUp={async (e) => {
            if (e.key === 'Enter') {
              addContextOption(newContextOption)
            }
          }}
        />
      </td>
      <td>
        <Input
          name="new-option-value"
          className={styles.addOptionInput}
          placeholder="Add new value"
          value={newContextOption.optionValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target as HTMLInputElement

            setNewContextOption((curr) => ({
              ...curr,
              optionValue: value,
            }))

            setOptionValueUpdated(true)
          }}
          onKeyUp={async (e) => {
            if (e.key === 'Enter') {
              addContextOption(newContextOption)
            }
          }}
        />
      </td>
      <td>
        <Button
          isDisabled={
            newContextOption.optionDisplayName === '' ||
            newContextOption.optionKey === '' ||
            newContextOption.optionValue === ''
          }
          onPress={async () => {
            await addContextOption(newContextOption)
          }}
        >
          Confirm
        </Button>
      </td>
    </tr>
  )
}

interface AppScreensTableRowProps {
  option: ListAppContextOptionsQuery['track']['deepLinkQueries']['listAppContextOptions'][0]
  isOdd?: boolean
  updateContextOption: (options: {
    optionDisplayName: string
    optionKey: string
    optionValue: string
  }) => Promise<void>
  onDelete: () => Promise<void>
}

const AppScreensTableRow = ({
  option,
  isOdd,
  updateContextOption,
  onDelete,
}: AppScreensTableRowProps) => {
  const tableRowRef = useRef<HTMLTableRowElement>(null)

  const { optionID, optionDisplayName, optionKey, optionValue } = option

  return (
    <tr key={optionID} ref={tableRowRef}>
      <td>
        <ClickEditInput
          id={`${optionID}-display-name`}
          name={`${optionID}-display-name`}
          value={optionDisplayName}
          onChange={async (value) => {
            await updateContextOption({
              optionDisplayName: value,
              optionKey,
              optionValue,
            })
          }}
        />
      </td>
      <td>
        <ClickEditInput
          id={`${optionID}-key`}
          name={`${optionID}-key`}
          value={optionKey}
          onChange={async (value) => {
            await updateContextOption({
              optionDisplayName,
              optionKey: value,
              optionValue,
            })
          }}
        />
      </td>
      <td>
        <ClickEditInput
          id={`${optionID}-value`}
          name={`${optionID}-value`}
          value={optionValue}
          onChange={async (value) => {
            await updateContextOption({
              optionDisplayName,
              optionKey,
              optionValue: value,
            })
          }}
        />
      </td>
      <td className={styles.deleteColumn}>
        <DeleteButtonWithConfirmation
          containerRef={tableRowRef}
          confirmationClassName={styles.deleteConfirmContainer}
          confirmMessage="Are you sure you want to remove this app screen?"
          onConfirm={onDelete}
        />
      </td>
    </tr>
  )
}

const AppScreensTable = ({ appGroupID }: { appGroupID: string }) => {
  const logAction = useLogAction()

  const { data: appContextData, error: errorFetchingAppContext } = useQuery(
    listAppContextOptions,
    {
      variables: { appGroupID },
      fetchPolicy: 'network-only',
    },
  )

  const [updateAppContextOption] = useMutation(updateAppContextList)
  const [deleteAppContextOption] = useMutation(deleteAppContextListOptions)

  const appContext = useMemo(() => {
    if (!appContextData) return null

    return appContextData.track.deepLinkQueries.listAppContextOptions
  }, [appContextData])

  const [showAddAppScreen, setShowAddAppScreen] = useState(false)
  const [currOrderAsc, setCurrOrderAsc] = useState(true)

  // Show option to add context item if none exist already
  useEffect(() => {
    if (Array.isArray(appContext) && appContext.length === 0) {
      setShowAddAppScreen(true)
    }
  }, [appContext])

  /**
   * Search appScreenName, appScreenKey and appScreenValue together
   * Custom search fn ensures search is not fuzzy (default)
   */
  const customSearch = useCallback(
    (
      data: ListAppContextOptionsQuery['track']['deepLinkQueries']['listAppContextOptions'],
      searchTerm?: string,
    ) => {
      if (!searchTerm) return data

      const searchPattern = searchTerm
        .replace(/[^a-zA-Z0-9]/g, '')
        .split('')
        .join('.*')

      return data.filter(
        (item) =>
          item.optionDisplayName
            .toLowerCase()
            .match(new RegExp(searchPattern, 'i')) !== null ||
          item.optionKey.toLowerCase().match(new RegExp(searchPattern, 'i')) !==
            null ||
          item.optionValue
            .toLowerCase()
            .match(new RegExp(searchPattern, 'i')) !== null,
      )
    },
    [],
  )

  const {
    initialSort,
    setInitialSort,
    orderAsc,
    sortKey,
    setSortOrder,
    pages,
    activePage,
    setActivePage,
    searchTerm,
    setSearchTerm,
    count,
    orderedList: orderedFields,
    total,
  } = useTableSortFilter({
    inputList: appContext,
    startingRowsPerPage: 10,
    initialSortAsc: currOrderAsc,
    customSearches: {
      all: customSearch,
    },
  })

  const updateContextOption = useCallback(
    async (updatedOption: {
      optionID: string
      optionDisplayName: string
      optionKey: string
      optionValue: string
    }) => {
      await updateAppContextOption({
        variables: {
          appGroupID,
          updateContextList: [updatedOption],
        },
        refetchQueries: [listAppContextOptions],
      })

      logAction({
        variables: {
          action: 'update-app-context-option',
          extra: JSON.stringify(updatedOption),
          websiteSection: 'track',
          functionName: 'updateAppContextOption',
          pagePath: '/track/edit-app-destinations',
        },
      })
    },
    [appGroupID],
  )

  return (
    <div className={styles.appScreensContainer}>
      <Heading type={3} align="left" className={styles.appScreensHeading}>
        App destination screens
      </Heading>
      <p style={{ marginBottom: 32 }}>
        Pages/content you want your links to open inside your apps.
      </p>
      <ButtonRow className={styles.appScreensButtons}>
        <div className={styles.tableSearch}>
          <SearchInput
            value={searchTerm}
            onChange={(value) => {
              setSearchTerm(value || '')
              setActivePage(1)
            }}
            delay={50}
          >
            <p>
              {count !== total ? `${numeral(count).format('0,0')}/` : ''}
              {numeral(total).format('0,0')} option
              {total > 1 ? 's' : ''}
            </p>
          </SearchInput>
        </div>
        <div>
          <Button
            onPress={() => {
              setShowAddAppScreen((curr) => !curr)
            }}
          >
            Add app screen
          </Button>
        </div>
      </ButtonRow>

      <Table className={styles.appScreensTable}>
        <thead>
          <tr>
            <th
              onClick={() => {
                if (initialSort) {
                  setInitialSort(false)
                }
                setCurrOrderAsc(!orderAsc)
                setSortOrder('appScreenName')
              }}
            >
              <div className={styles.tableHeader}>
                App screen name
                <OrderArrow
                  className={styles.orderArrow}
                  currentKey="appScreenName"
                  sortKey={sortKey}
                  orderAsc={orderAsc}
                />
                <Tooltip
                  id="app-screen-name-tooltip"
                  useIcon
                  tooltipIconClassName={styles.headerTooltip}
                  tooltipPosition="bottom"
                  tooltipMessage={
                    <p>
                      The name users can select on{' '}
                      <BoxedText>Track &gt;Create links</BoxedText>.
                    </p>
                  }
                />
              </div>
            </th>
            <th
              onClick={() => {
                if (initialSort) {
                  setInitialSort(false)
                }
                setCurrOrderAsc(!orderAsc)
                setSortOrder('appScreenKey')
              }}
            >
              <div className={styles.tableHeader}>
                Key
                <OrderArrow
                  className={styles.orderArrow}
                  currentKey="appScreenKey"
                  sortKey={sortKey}
                  orderAsc={orderAsc}
                />
                <Tooltip
                  id="app-screen-key-tooltip"
                  useIcon
                  tooltipIconClassName={styles.headerTooltip}
                  tooltipPosition="bottom"
                  tooltipMessage="JSON key that the app will use for redirection within the app."
                />
              </div>
            </th>
            <th
              onClick={() => {
                if (initialSort) {
                  setInitialSort(false)
                }
                setCurrOrderAsc(!orderAsc)
                setSortOrder('appScreenValue')
              }}
            >
              <div className={styles.tableHeader}>
                Value
                <OrderArrow
                  className={styles.orderArrow}
                  currentKey="appScreenValue"
                  sortKey={sortKey}
                  orderAsc={orderAsc}
                />
                <Tooltip
                  id="app-screen-value-tooltip"
                  useIcon
                  tooltipIconClassName={styles.headerTooltip}
                  tooltipPosition="bottom"
                  tooltipMessage="JSON value that the app receives to redirect the user within the app."
                />
              </div>
            </th>
            <th className={styles.deleteColumn} />
          </tr>
        </thead>
        <tbody>
          {showAddAppScreen && (
            <AddNewAppContextOption appGroupID={appGroupID} />
          )}
          {orderedFields && orderedFields[activePage - 1] ? (
            orderedFields[activePage - 1].map((item, index) => {
              const { optionID } = item

              return (
                <AppScreensTableRow
                  option={item}
                  isOdd={!showAddAppScreen ? !(index % 2) : !!(index % 2)}
                  updateContextOption={async (options) => {
                    await updateContextOption({ optionID, ...options })
                  }}
                  onDelete={async () => {
                    await deleteAppContextOption({
                      variables: {
                        appGroupID,
                        deleteContextList: [optionID],
                      },
                      refetchQueries: [listAppContextOptions],
                    })

                    logAction({
                      variables: {
                        action: 'delete-app-context-option',
                        extra: optionID,
                        websiteSection: 'track',
                        functionName: 'deleteAppContextOption',
                        pagePath: '/track/edit-app-destinations',
                      },
                    })
                  }}
                />
              )
            })
          ) : (
            <tr>
              {!searchTerm && (
                <td colSpan={4}>
                  <NoDataMessage
                    showSupportLink={false}
                    errorMsg="No app screens found."
                  />
                </td>
              )}
            </tr>
          )}
        </tbody>
      </Table>

      {pages > 1 && (
        <Pagination
          pages={pages}
          activePage={activePage}
          onChange={(index) => setActivePage(index)}
        />
      )}
      {errorFetchingAppContext && (
        <ErrorMessage>Error fetching app context data.</ErrorMessage>
      )}
    </div>
  )
}

interface EditableAppGroupFieldProps {
  appGroup: ListAppGroupsQuery['track']['deepLinkQueries']['listAppGroups'][0]
  field: 'appGroupName' | 'redirectWeb'
}

const EditableAppGroupField = ({
  appGroup,
  field,
}: EditableAppGroupFieldProps) => {
  const { appGroupID } = appGroup

  const [updateAppGroupField] = useMutation(updateAppGroup)

  return (
    <ClickEditInput
      id={`${appGroupID}-${field}`}
      name={`${appGroupID}-${field}`}
      className={styles.editableField}
      value={appGroup[field]}
      onChange={async (value: string) => {
        await updateAppGroupField({
          variables: {
            appGroupID,
            [field]: value,
          },
          refetchQueries: [listAppGroups],
        })
      }}
    />
  )
}

interface AddAppModalProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  appGroupID: string
  deepLinkServiceID: string
}

const AddAndroidAppModal = ({
  setIsOpen,
  appGroupID,
  deepLinkServiceID,
}: AddAppModalProps) => {
  const [
    registerNewApp,
    { loading: registeringNewApp, error: registerNewAppError },
  ] = useMutation(registerApp)

  const [androidAppFields, setAndroidAppFields] = useState({
    androidAppName: '',
    androidPackage: '',
    androidPlayStoreLink: '',
    assetLinksAndroid: '',
  })

  return (
    <Modal
      setIsOpen={setIsOpen}
      width="wide"
      modalHeader="Add app to group"
      yesText="Add app to group"
      yesButtonDisabled={
        !androidAppFields.androidPackage ||
        !androidAppFields.androidPlayStoreLink ||
        !androidAppFields.androidAppName ||
        !androidAppFields.assetLinksAndroid
      }
      yesButtonLoading={registeringNewApp}
      onYes={async () => {
        const {
          androidPackage,
          androidPlayStoreLink,
          assetLinksAndroid,
          androidAppName,
        } = androidAppFields

        await registerNewApp({
          variables: {
            appGroupID,
            deepLinkServiceID,
            device: 'android',
            redirectMethod: 'applink',
            androidPackage,
            androidPlayStoreLink,
            assetLinksAndroid,
            androidAppName,
          },
          refetchQueries: [listAppGroups],
        })

        setIsOpen(false)
      }}
      footerContent={
        registerNewAppError && (
          <ErrorMessage>Error registering app.</ErrorMessage>
        )
      }
    >
      <p>
        Enter the details of the Android app you want to add to this app group.
      </p>
      {['androidAppName', 'androidPackage', 'androidPlayStoreLink'].map(
        (androidAppField) => {
          const { name, tooltip } = appFieldsData[androidAppField]

          return (
            <FormRow key={androidAppField}>
              <FormLabel
                id={androidAppField}
                className={styles.inputLabel}
                tooltip={tooltip}
              >
                {name}
              </FormLabel>
              <FormField className={styles.inputField}>
                <Input
                  id={androidAppField}
                  name={androidAppField}
                  placeholder={appFieldsData[androidAppField].name}
                  value={androidAppFields[androidAppField]}
                  onValueChange={(val: string) => {
                    setAndroidAppFields((curr) => ({
                      ...curr,
                      [androidAppField]: val,
                    }))
                  }}
                />
              </FormField>
            </FormRow>
          )
        },
      )}
      <FormRow>
        <FormLabel
          id="android-asset-links"
          className={styles.inputLabel}
          tooltip={appFieldsData.assetLinksAndroid.tooltip}
        >
          {appFieldsData.assetLinksAndroid.name}
        </FormLabel>
        <FormField className={styles.inputField}>
          <Input
            id="android-asset-links"
            name="android-asset-links"
            type="textArea"
            placeholder={appFieldsData.assetLinksAndroid.name}
            value={androidAppFields.assetLinksAndroid}
            onValueChange={(val: string) => {
              setAndroidAppFields((curr) => ({
                ...curr,
                assetLinksAndroid: val,
              }))
            }}
          />
        </FormField>
      </FormRow>
    </Modal>
  )
}

const AddIosAppModal = ({
  setIsOpen,
  appGroupID,
  deepLinkServiceID,
}: AddAppModalProps) => {
  const [
    registerNewApp,
    { loading: registeringNewApp, error: registerNewAppError },
  ] = useMutation(registerApp)

  const [iosAppFields, setIosAppFields] = useState({
    iosAppName: '',
    iosAppStoreID: '',
    iosBundleID: '',
    iosTeamID: '',
  })

  return (
    <Modal
      setIsOpen={setIsOpen}
      width="wide"
      modalHeader="Add app to group"
      yesText="Add app to group"
      yesButtonDisabled={
        !iosAppFields.iosAppName ||
        !iosAppFields.iosAppStoreID ||
        !iosAppFields.iosBundleID ||
        !iosAppFields.iosTeamID
      }
      yesButtonLoading={registeringNewApp}
      onYes={async () => {
        const {
          iosAppStoreID,
          iosBundleID,
          iosTeamID,
          iosAppName,
        } = iosAppFields

        await registerNewApp({
          variables: {
            appGroupID,
            deepLinkServiceID,
            device: 'ios',
            redirectMethod: 'universal',
            iosAppStoreID,
            iosBundleID,
            iosTeamID,
            iosAppName,
          },
          refetchQueries: [listAppGroups],
        })

        setIsOpen(false)
      }}
      footerContent={
        registerNewAppError && (
          <ErrorMessage>Error registering app.</ErrorMessage>
        )
      }
    >
      <p>Enter the details of the iOS app you want to add to this app group.</p>
      {['iosAppName', 'iosAppStoreID', 'iosBundleID', 'iosTeamID'].map(
        (iosAppField) => {
          const { name, tooltip } = appFieldsData[iosAppField]

          return (
            <FormRow key={iosAppField}>
              <FormLabel
                id={iosAppField}
                className={styles.inputLabel}
                tooltip={tooltip}
              >
                {name}
              </FormLabel>
              <FormField className={styles.inputField}>
                <Input
                  id={iosAppField}
                  name={iosAppField}
                  placeholder={name}
                  value={iosAppFields[iosAppField]}
                  onValueChange={(val: string) => {
                    setIosAppFields((curr) => ({
                      ...curr,
                      [iosAppField]: val,
                    }))
                  }}
                />
              </FormField>
            </FormRow>
          )
        },
      )}
    </Modal>
  )
}

interface AppAccordionItemProps {
  appGroup: ListAppGroupsQuery['track']['deepLinkQueries']['listAppGroups'][0]
}

const AppAccordionItem = ({ appGroup }: AppAccordionItemProps) => {
  const { appList, appGroupID, appGroupName, deepLinkServiceID } = appGroup

  const [refetchAppGroups] = useLazyQuery(listAppGroups, {
    fetchPolicy: 'network-only',
  })

  const [updateApp, { error: errorUpdatingApp }] = useMutation(
    updateRegisteredApp,
    {
      refetchQueries: [listAppGroups],
    },
  )
  const [
    deleteGroup,
    { loading: deletingAppGroup, error: deleteAppGroupError },
  ] = useMutation(deleteAppGroup)

  const [addAndroidAppModal, setAddAndroidAppModal] = useState(false)
  const [addIosAppModal, setAddIosAppModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteSuccess, setDeleteSuccess] = useState(false)

  const { androidApp, iosApp } = useMemo(() => {
    return {
      androidApp: appList.find(
        (app) => app.__typename === 'DeepLinkAndroidApp',
      ) as DeepLinkAndroidApp,
      iosApp: appList.find(
        (app) => app.__typename === 'DeepLinkIosApp',
      ) as DeepLinkIosApp,
    }
  }, [appList])

  return (
    <>
      <DeleteButton
        className={styles.deleteAppGroupButton}
        onPress={() => setShowDeleteModal(true)}
      />
      <div className={styles.osFieldsContainer}>
        <div
          className={styles.osFieldsColumn}
          style={{
            marginTop: 0,
            paddingLeft: 0,
            borderWidth: 0,
          }}
        >
          {androidApp ? (
            <>
              {['androidAppName', 'androidPackage', 'androidPlayStoreLink'].map(
                (androidAppField) => {
                  const { name, tooltip } = appFieldsData[androidAppField]

                  return (
                    <FormRow
                      key={androidAppField}
                      className={styles.appGroupEditFormRow}
                    >
                      <FormLabel
                        id={androidAppField}
                        className={styles.appGroupEditLabel}
                        tooltip={tooltip}
                      >
                        {name}
                      </FormLabel>
                      <FormField>
                        <ClickEditInput
                          id={androidAppField}
                          className={styles.appGroupEditField}
                          value={androidApp[androidAppField] || ''}
                          onChange={async (value) => {
                            const { appID } = androidApp

                            await updateApp({
                              variables: {
                                appGroupID,
                                appID,
                                deepLinkServiceID,
                                device: 'android',
                                [androidAppField]: value,
                              },
                            })
                          }}
                        />
                      </FormField>
                    </FormRow>
                  )
                },
              )}
              <FormRow className={styles.appGroupEditFormRow}>
                <FormLabel
                  id="android-asset-links"
                  className={styles.appGroupEditLabel}
                  tooltip={appFieldsData.assetLinksAndroid.tooltip}
                >
                  {appFieldsData.assetLinksAndroid.name}
                </FormLabel>
                <FormField>
                  <ClickEditInput
                    id="android-asset-links"
                    type="textArea"
                    multilineInput
                    formatSeparators={false}
                    textAreaHeight={100}
                    value={androidApp.assetLinksAndroid || ''}
                    onChange={async (value) => {
                      const { appID } = androidApp

                      await updateApp({
                        variables: {
                          appGroupID,
                          appID,
                          deepLinkServiceID,
                          device: 'android',
                          assetLinksAndroid: value,
                        },
                      })
                    }}
                  />
                </FormField>
              </FormRow>
            </>
          ) : (
            <Button
              variant="secondary"
              onPress={() => setAddAndroidAppModal(true)}
            >
              Add Android app
            </Button>
          )}
        </div>
        <div
          className={styles.osFieldsColumn}
          style={{ marginTop: 0, paddingRight: 32 }}
        >
          {iosApp ? (
            <>
              {['iosAppName', 'iosAppStoreID', 'iosBundleID', 'iosTeamID'].map(
                (iosAppField) => {
                  const { name, tooltip } = appFieldsData[iosAppField]

                  return (
                    <FormRow
                      key={iosAppField}
                      className={styles.appGroupEditFormRow}
                    >
                      <FormLabel
                        id={iosAppField}
                        className={styles.appGroupEditLabel}
                        tooltip={tooltip}
                      >
                        {name}
                      </FormLabel>
                      <FormField>
                        <ClickEditInput
                          id={iosAppField}
                          className={styles.appGroupEditField}
                          value={iosApp[iosAppField] || ''}
                          onChange={async (value) => {
                            const { appID } = iosApp

                            await updateApp({
                              variables: {
                                appGroupID,
                                appID,
                                deepLinkServiceID,
                                device: 'ios',
                                [iosAppField]: value,
                              },
                            })
                          }}
                        />
                      </FormField>
                    </FormRow>
                  )
                },
              )}
            </>
          ) : (
            <Button variant="secondary" onPress={() => setAddIosAppModal(true)}>
              Add iOS app
            </Button>
          )}
        </div>
      </div>
      {errorUpdatingApp && (
        <ErrorMessage>
          Error updating app.{' '}
          <Link href={supportEmail}>Please contact support.</Link>
        </ErrorMessage>
      )}
      <AppScreensTable appGroupID={appGroupID} />
      {addAndroidAppModal && (
        <AddAndroidAppModal
          setIsOpen={setAddAndroidAppModal}
          appGroupID={appGroupID}
          deepLinkServiceID={deepLinkServiceID}
        />
      )}
      {addIosAppModal && (
        <AddIosAppModal
          setIsOpen={setAddIosAppModal}
          appGroupID={appGroupID}
          deepLinkServiceID={deepLinkServiceID}
        />
      )}
      {showDeleteModal && (
        <Modal
          setIsOpen={setShowDeleteModal}
          isWarning
          modalHeader={`Delete app group '${appGroupName}'?`}
          headerColor="pink"
          noText={deleteSuccess ? 'Close' : 'Cancel'}
          beforeClose={() => {
            refetchAppGroups()
          }}
          yesText="Delete app group"
          yesButtonLoading={deletingAppGroup}
          onYes={
            deleteSuccess
              ? undefined
              : async () => {
                  await deleteGroup({ variables: { appGroupID } })

                  setDeleteSuccess(true)
                }
          }
          footerContent={
            !!deleteAppGroupError && (
              <ErrorMessage>
                Error deleting app group. Please contact support.
              </ErrorMessage>
            )
          }
        >
          {deleteSuccess ? (
            <p style={{ margin: 0 }}>App group successfully deleted.</p>
          ) : (
            <p style={{ margin: 0 }}>
              Are you sure you want to delete this app group? This will also
              delete apps associated with the group.
            </p>
          )}
        </Modal>
      )}
    </>
  )
}

const TrackEditAppDestinations = () => {
  const isMobile = useMobile(769)

  const { data: deepLinkServicesData } = useQuery(listDeepLinkServices)
  const { data: appGroupsData, loading: fetchingAppGroups } = useQuery(
    listAppGroups,
  )

  const [showAddAppGroupModal, setShowAddAppGroupModal] = useState(false)

  const deepLinkServices = useMemo(() => {
    if (!deepLinkServicesData) return []

    return deepLinkServicesData.track.deepLinkQueries.listDeepLinkServices
  }, [deepLinkServicesData])

  const appGroups = useMemo(() => {
    if (!appGroupsData) return []

    return appGroupsData.track.deepLinkQueries.listAppGroups
  }, [appGroupsData])

  return (
    <>
      <SiteWrapper>
        <Layout width={1200}>
          <Intro title="Edit app destinations">
            <p style={{ marginBottom: 0 }}>
              Add, remove and edit app destinations for mobile app deep linking
              and tracking.{' '}
              <Link
                type="arrowForward"
                href="https://support.uplifter.ai/hc/en-us/articles/20854251291165-How-to-create-deep-links-to-mobile-applications"
              >
                Set up app links for the first time
              </Link>
            </p>
          </Intro>
          {isMobile || (!fetchingAppGroups && appGroups.length === 0) ? (
            <Button
              variant="secondary"
              onPress={async () => setShowAddAppGroupModal(true)}
            >
              Add app
            </Button>
          ) : (
            <div className={styles.accordionsHeading}>
              <div className={styles.appGroupHeading}>
                <Heading type={3} align="left" style={{ marginLeft: -16 }}>
                  <Tooltip
                    id="app-group-tooltip"
                    useIcon
                    tooltipMessage={
                      <p>
                        User-friendly name to describe the Android/iOS app group
                        users select when creating app links.
                      </p>
                    }
                  >
                    App group
                  </Tooltip>
                </Heading>
                <Button
                  variant="secondary"
                  onPress={async () => setShowAddAppGroupModal(true)}
                >
                  Add
                </Button>
              </div>
              <Heading type={3} align="left">
                <Tooltip
                  id="default-fallback-url-tooltip"
                  useIcon
                  tooltipMessage={
                    <p>
                      The default URL users will go to if they click on an app
                      link on a desktop device.
                    </p>
                  }
                >
                  Default fallback URL
                </Tooltip>
              </Heading>
            </div>
          )}
          {fetchingAppGroups ? (
            <Preloader />
          ) : (
            <>
              {appGroups.length > 0 && (
                <Accordion
                  id="appDestinations"
                  headerClassName={styles.accordionItemHeader}
                  accordionItems={appGroups.map((appGroup) => {
                    return {
                      key: appGroup.appGroupID,
                      header: {
                        title: (
                          <EditableAppGroupField
                            appGroup={appGroup}
                            field="appGroupName"
                          />
                        ),
                        rightContent: (
                          <EditableAppGroupField
                            appGroup={appGroup}
                            field="redirectWeb"
                          />
                        ),
                      },
                      expandedContent: <AppAccordionItem appGroup={appGroup} />,
                    }
                  })}
                />
              )}
            </>
          )}
        </Layout>
      </SiteWrapper>
      {showAddAppGroupModal && (
        <AddAppGroupModal
          setShowAddAppGroupModal={setShowAddAppGroupModal}
          deepLinkServices={deepLinkServices}
        />
      )}
    </>
  )
}

export default TrackEditAppDestinations
