import React, { useEffect, useMemo, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import classNames from 'classnames'
import { nanoid } from 'nanoid'

import Button from './button'
import { FormField, FormLabel, FormRow } from './form'
import { RadioGroup } from './input-v2'
import { CustomLinkFull } from './custom-link-fields'
import { RequestShortLinksModal } from './upgrade-modals'
import { linkOrCode } from '../api/apollo/variables'
import { messages } from '../core/constants'
import { saveUserData } from '../helpers/local-client'
import { CustomLinkAliasDetails } from '../helpers/custom-links'
import { maxBatchShortLinks } from '../helpers/track-module'
import { LinkType } from '../helpers/track-create'
import useLogAction from '../hooks/useLogAction'
import styles from '../styles/track-create-submit.module.scss'
import { GetCampaignCodeGeneratorQuery } from '../__gql-types__/graphql'

interface TrackCreateSubmitFieldsProps {
  canUseLinkType: boolean
  validationChecks:
    | GetCampaignCodeGeneratorQuery['campaignCodeGenerator']['validationChecks']
    | null
  linkType: LinkType
  setLinkType: React.Dispatch<React.SetStateAction<LinkType>>
  customLinkAliasDetails: CustomLinkAliasDetails
  setCustomLinkAliasDetails: React.Dispatch<
    React.SetStateAction<CustomLinkAliasDetails>
  >
  linkCount?: number
  submitLoading?: boolean
  /** Allows submit button to be clickable, showing errors */
  softDisable?: boolean
  submitDisabled?: boolean
  children?: React.ReactNode
}

/** Submit button for Track>Create forms, plus shortLink fields if required */
const TrackCreateSubmitFields = ({
  canUseLinkType,
  validationChecks,
  linkType,
  setLinkType,
  customLinkAliasDetails,
  setCustomLinkAliasDetails,
  linkCount = 1,
  submitLoading,
  softDisable,
  submitDisabled,
  children,
}: TrackCreateSubmitFieldsProps) => {
  const referToLinks = useReactiveVar(linkOrCode)

  const logAction = useLogAction()

  const [showShortLinkModal, setShowShortLinkModal] = useState(false)

  // Options and order for URL link presentation: 'shortLink' or 'basic'
  const shortLinkOptions = useMemo(() => {
    const options = [
      {
        label: (
          <>
            Short link <span className={styles.recommended}>(recommended)</span>
          </>
        ),
        value: 'shortLink',
      },
      {
        label: 'Basic link',
        value: 'basic',
      },
    ]

    if (!canUseLinkType) {
      options.reverse()

      return options
    }

    const forceShortLinkRule = validationChecks?.find(
      (check) => check.name === 'FORCE_SHORT_LINK',
    )

    if (
      forceShortLinkRule &&
      forceShortLinkRule.enabled &&
      forceShortLinkRule.value
    ) {
      const shortLinkValue = JSON.parse(forceShortLinkRule.value)

      const selectedRule = shortLinkValue.find((option: any) => option.selected)
        .optionValue

      switch (selectedRule) {
        case 'force-short-links':
        case 'force-long-links':
          return []
        case 'recommend-long-links':
          return [
            {
              label: (
                <>
                  Basic link{' '}
                  <span className={styles.recommended}>(recommended)</span>
                </>
              ),
              value: 'basic',
            },
            {
              label: 'Short link',
              value: 'shortLink',
            },
          ]
        default:
          break
      }
    }

    return options
  }, [canUseLinkType, validationChecks])

  useEffect(() => {
    if (linkCount > 1 && !customLinkAliasDetails?.isBatch) {
      setCustomLinkAliasDetails((curr) => ({
        ...curr,
        key: nanoid(),
        isBatch: true,
      }))
    } else if (linkCount === 1 && customLinkAliasDetails?.isBatch) {
      setCustomLinkAliasDetails((curr) => ({
        ...curr,
        key: nanoid(),
        isBatch: false,
      }))
    }
  }, [linkCount, customLinkAliasDetails?.isBatch])

  let tooltip = ''

  if (linkType === 'shortLink') {
    tooltip = messages.customiseShortLink
  } else if (linkType === 'appLink') {
    tooltip = messages.customiseDeepLink
  }

  return (
    <>
      {/* First field should only be visible if not using appLinks and user can choose short/basic link type */}
      {shortLinkOptions.length > 0 && linkType !== 'appLink' && (
        <FormRow
          heading="Presentation"
          includePaddingBottom={linkType === 'shortLink'}
        >
          <FormLabel id="link-type" tooltip={messages.linkTypes}>
            Link type
          </FormLabel>
          <FormField>
            <RadioGroup
              groupName="link-type"
              horizontal
              options={shortLinkOptions}
              optionsClassName={styles.radioButton}
              selectedValue={linkType}
              onChange={(option) => {
                // Block short link if not available on workspace
                if (option === 'shortLink' && !canUseLinkType) {
                  if (window.dataLayer && window.dataLayer.push) {
                    window.dataLayer.push({
                      event: 'click-shortlink-upgrade-blocker',
                    })
                  }

                  logAction({
                    variables: {
                      action: 'click-shortlink-upgrade-blocker',
                      websiteSection: 'track',
                      pagePath: window.location.pathname,
                      functionName: 'clickUpgrade',
                      extra: 'form',
                    },
                  })

                  setShowShortLinkModal(true)

                  return
                }

                setLinkType(option as 'shortLink' | 'basic')
                saveUserData({ linkType: option })
              }}
            />
          </FormField>
        </FormRow>
      )}
      <FormRow
        heading={
          linkType === 'appLink' ||
          (linkType === 'shortLink' && shortLinkOptions.length === 0)
            ? 'Presentation'
            : undefined
        }
        className={
          !linkType ||
          (linkType === 'basic' && shortLinkOptions.length === 0) ||
          (linkType === 'shortLink' && shortLinkOptions.length > 0)
            ? styles.noPaddingTop
            : undefined
        }
      >
        <FormLabel id="custom-link" tooltip={tooltip}>
          {linkType !== 'basic' && (
            <>{linkType === 'shortLink' ? 'Short' : 'App'} link</>
          )}
        </FormLabel>
        <FormField>
          {linkType !== 'basic' && (
            <div className={styles.linkInputWrapper}>
              <CustomLinkFull
                key={customLinkAliasDetails?.key || 'custom-link'}
                customLinkType={linkType}
                // We don't know how many links will be created until the button is pressed. So request the max allowed if > 1
                batchCount={linkCount > 1 ? maxBatchShortLinks : 1}
                domainIsEditable={linkType !== 'appLink'}
                showCustomiseMessage
                onAliasChange={(alias, status, isCustom) =>
                  setCustomLinkAliasDetails((curr) => ({
                    ...curr,
                    alias,
                    status,
                    isCustom: isCustom || false,
                  }))
                }
              />
            </div>
          )}
          <Button
            type="submit"
            className={classNames(styles.submitButton, {
              [styles.softDisableButton]: softDisable,
            })}
            loading={submitLoading}
            isDisabled={
              submitDisabled ||
              ['invalid', 'refetching', 'validating'].includes(
                customLinkAliasDetails?.status || '',
              )
            }
          >
            {linkCount > 1
              ? `Review ${referToLinks}s`
              : `Create ${referToLinks}`}
          </Button>
          {children}
        </FormField>
      </FormRow>
      {showShortLinkModal && (
        <RequestShortLinksModal onHideModal={setShowShortLinkModal} />
      )}
    </>
  )
}

export default TrackCreateSubmitFields
