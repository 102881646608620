import axios from 'axios'

import { GRAPHQL_API_BASE_DOMAIN } from '../constants'
import { getToken, setToken } from '../../helpers'

type DataResponse<T extends {}> = T & {
  token?: string
  message?: string
  error?: string
}

type ApiResponse<T extends {}> = T & {
  data?: DataResponse<T>
  status?: number
  message?: string
  error?: any
}

interface FormattedApiResponse<T extends {}> {
  res: {
    data?: DataResponse<T> | any
    status?: number
    message?: string
    error?: any
  }
  success: boolean
}

function formatResponseAndRefreshToken<T extends {}>(
  res: ApiResponse<T>,
): FormattedApiResponse<T> {
  const formattedRes = {
    success: false,
    res,
  }

  if (
    res?.error?.response?.data?.error !== 'EXPIRED_TOKEN' &&
    res?.data?.error !== 'EXPIRED_TOKEN' &&
    res?.status !== 500 &&
    res?.status !== 403
  ) {
    formattedRes.success = true
  }

  if (res?.data?.token) {
    const { token } = res.data
    setToken(token)
  }

  if (res?.data?.token) {
    const { token } = res.data
    setToken(token)
  }

  if (res?.message) {
    formattedRes.res.message = res.message
  }

  return formattedRes
}

interface GetRequest {
  endpoint: string
  token?: string
  data?: { [key: string]: any }
  options?: { [key: string]: any }
}

interface Headers {
  [key: string]: any
}

async function makeGet({ endpoint, data, options }: GetRequest, type = '') {
  const token = getToken()
  let headers: Headers = {}
  if (options) headers = { ...headers, ...options }

  if (
    process.env.REACT_APP_STAGING_OVERRIDE === 'true' &&
    process.env.REACT_APP_NODE_ENV !== 'production'
  ) {
    headers['override-staging-database'] = 'true'
  }

  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  try {
    const res = await axios({
      ...data,
      ...options,
      method: 'get',
      url: `${GRAPHQL_API_BASE_DOMAIN}${type}${endpoint}`,
      headers,
    })

    return res
  } catch (e) {
    return {
      error: e,
      // @ts-ignore
      status: e?.response?.status || 500,
    }
  }
}

interface PostRequest {
  endpoint: string
  token?: string
  data?: { [key: string]: any }
  options?: { [key: string]: any }
}

async function makePost(
  { endpoint, data = {}, options = {} }: PostRequest,
  type = '',
): Promise<any> {
  const token = getToken()
  let headers: Headers = {}

  if (options) headers = { ...headers, ...options }

  if (
    process.env.REACT_APP_STAGING_OVERRIDE === 'true' &&
    process.env.REACT_APP_NODE_ENV !== 'production'
  ) {
    headers['override-staging-database'] = 'true'
  }

  if (token) {
    headers.Authorization = `Bearer ${token}`
  }
  try {
    const res = await axios.post(
      `${GRAPHQL_API_BASE_DOMAIN}${type}${endpoint}`,
      data,
      {
        ...options,
        headers,
      },
    )

    return res
  } catch (e) {
    return {
      // @ts-ignore
      status: e?.response?.status || 500,
      // @ts-ignore
      message: e?.response?.data?.message,
      error: e,
    }
  }
}

// Progressively moving to use this module rather than api-client
export default function Make(type = '') {
  return {
    get: async (all: GetRequest) =>
      formatResponseAndRefreshToken(await makeGet({ ...all }, type)),
    post: async <T extends {}>(all: PostRequest) =>
      formatResponseAndRefreshToken<T>(await makePost({ ...all }, type)),
  }
}
