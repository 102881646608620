import React, { forwardRef } from 'react'
import classNames from 'classnames'

import { Label } from './input'
import Loader from './loader'
import styles from '../styles/form.module.scss'

interface FormLabelProps {
  id?: string
  className?: string
  loading?: boolean
  disabled?: boolean
  showRequired?: boolean
  optional?: string
  tooltip?: React.ReactElement | string
  tooltipClickable?: boolean
  children?: React.ReactNode
}

export const FormLabel = ({
  id,
  className,
  loading,
  disabled,
  showRequired,
  optional,
  tooltip,
  tooltipClickable,
  children,
}: FormLabelProps) => {
  return (
    <div className={classNames(className, styles.labelSlot)}>
      {loading ? (
        <Loader className={styles.loader} height="36px" />
      ) : (
        <Label
          id={id}
          className={disabled ? styles.labelDisabled : undefined}
          showRequired={showRequired}
          optional={optional}
          tooltip={tooltip}
          tooltipClickable={tooltipClickable}
        >
          {children}
        </Label>
      )}
    </div>
  )
}

interface FormFieldProps {
  className?: string
  loading?: boolean
  children: React.ReactNode
}

export const FormField = ({ className, loading, children }: FormFieldProps) => {
  return (
    <div className={classNames(className, styles.fieldSlot)}>
      {loading && <Loader className={styles.loader} height="35px" />}
      {children}
    </div>
  )
}

interface FormRowProps {
  className?: string
  heading?: string
  includePaddingBottom?: boolean
  bottomBorder?: boolean
  children?: React.ReactNode
}

export const FormRow = forwardRef<HTMLDivElement, FormRowProps>(
  (
    { className, heading, includePaddingBottom, bottomBorder, children },
    ref,
  ) => {
    return (
      <>
        {heading && (
          <div className={styles.formHeading}>
            <span>{heading}</span>
          </div>
        )}
        <div
          ref={ref}
          className={classNames(className, styles.formRow, {
            [styles.paddingBottom]: includePaddingBottom,
            [styles.bottomBorder]: bottomBorder,
          })}
        >
          {children}
        </div>
      </>
    )
  },
)

interface FormLoadingProps {
  rowCount?: number
  includeBorders?: boolean
  children: React.ReactNode
}

export const FormLoading = ({
  rowCount = 4,
  includeBorders,
  children,
}: FormLoadingProps) => {
  return new Array(rowCount).fill(1).map((_, index, arr) => {
    const isLast = index === arr.length - 1

    return (
      <FormRow
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        includePaddingBottom={includeBorders}
        bottomBorder={includeBorders && !isLast}
      >
        <FormLabel loading />
        <FormField loading className={isLast ? styles.lastLoader : undefined}>
          {isLast && children}
        </FormField>
      </FormRow>
    )
  })
}
