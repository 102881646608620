import React, { useEffect, useMemo, useState } from 'react'

import EloquaLogo from '../assets/logos/eloqua-logo.png'
import Button from '../components/button'
import ConnectorBox from '../components/connector-template'
import HeaderPanel, { Panel } from '../components/header-panel'
import { SearchInput } from '../components/input'
import Intro from '../components/intro'
import Layout from '../components/layout'
import RequestNewConnectorModal from '../components/request-new-connector-modal'
import SiteWrapper from '../components/site-wrapper'
import integrationsData, { integrationCategories } from '../integrations/data'
import styles from '../styles/connect.module.scss'

// ! Making changes?
// Do any of them need to be applied to the public site version of this content?
// https://uplifter.ai/integrations
// https://github.com/uplifter-limited/public-site-connect-tiles
export default function ConnectPage() {
  const [searchType, setSearchType] = useState('any')
  const [searchTerm, setSearchTerm] = useState('')
  const [requestConnectorModal, setRequestConnectorModal] = useState(false)
  const [requestConnectorDetails, setRequestConnectorDetails] = useState<{
    connectorName?: string
    connectorLogo?: string
  }>({})

  // Eloqua is special - it uses basic auth instead of OAuth
  // Users must request support when setting up the integration
  // Which is handled in this effect
  // Only support users can see the Settings tab in the modal
  useEffect(() => {
    const query = new URLSearchParams(window.location.search)

    const connectorRequest = query.get('connectorRequest')

    if (connectorRequest && connectorRequest === 'eloqua') {
      setRequestConnectorModal(true)
      setRequestConnectorDetails({
        connectorName: 'eloqua',
        connectorLogo: EloquaLogo,
      })
    }
  }, [window])

  const searchTypeList = useMemo(() => {
    return [
      { name: 'All', value: 'any' },
      ...integrationCategories.map((category) => ({
        name: category,
        value: category,
      })),
    ]
  }, [])

  return (
    <>
      <SiteWrapper>
        <Layout>
          <Intro title="Connect" className={styles.introWrapper}>
            <p>
              Integrate with other applications for better data, workflows and
              insights.
            </p>
          </Intro>
          <HeaderPanel className={styles.headerPanel}>
            <Panel className={styles.searchPanel}>
              <SearchInput
                value={searchTerm}
                selectValue={searchType}
                onChange={(value) => setSearchTerm(value || '')}
                searchTypeList={searchTypeList}
                onChangeSearchType={(type) => setSearchType(type)}
              />
            </Panel>
            <Panel className={styles.requestPanel}>
              <Button onPress={() => setRequestConnectorModal(true)}>
                Request new connector
              </Button>
            </Panel>
          </HeaderPanel>
          <div className={styles.connectorBoxesContainer}>
            {integrationsData.map((connectionData) => {
              if (searchTerm) {
                // Fuzzy search
                const searchPattern = searchTerm
                  .replace(/[^a-zA-Z0-9]/g, '')
                  .split('')
                  .join('.*')

                if (
                  connectionData.title
                    .toLowerCase()
                    .match(new RegExp(searchPattern, 'i')) === null
                )
                  return null
              }

              if (searchType !== 'any') {
                const tagFound = connectionData.tags.find(
                  (tag) => tag === searchType,
                )

                if (!tagFound) return null
              }

              return (
                <ConnectorBox
                  key={connectionData.title}
                  connectionData={connectionData}
                />
              )
            })}
          </div>
        </Layout>
      </SiteWrapper>
      {requestConnectorModal && (
        <RequestNewConnectorModal
          toggleActive={setRequestConnectorModal}
          connectorName={requestConnectorDetails.connectorName}
          connectorLogo={requestConnectorDetails.connectorLogo}
        />
      )}
    </>
  )
}
