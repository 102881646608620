import React, { useState, useEffect } from 'react'
import styles from '../styles/anomaly-breakdown.module.scss'
import AnomalyBreakdownTable from './anomaly-breakdown-table'

interface Props {
  dimensions: AnomalyBreakdownDimension[]
  id: string
  metric: string
  onMetricClick?: any
  selected: AnomalyBreakdownDimension[]
  increaseSentimentPositive: boolean
  viewOnly?: boolean
}

// export function transformAnomaly(anomaly) {
//   return anomaly.dimensions.map((cause: any): any => ({
//     ...cause,
//     // Makes no sense... Why was it here?
//     // ...anomaly.causes[index],
//   }))
// }

export default function Breakdown({
  dimensions,
  id,
  metric,
  onMetricClick,
  selected,
  increaseSentimentPositive,
  viewOnly = false,
}: Props): React.ReactElement {
  const [causes, setFilteredAnomaly]: [any, any] = useState(dimensions)

  useEffect((): void => {
    setFilteredAnomaly([...dimensions])
  }, [dimensions, setFilteredAnomaly])

  return (
    <div className={styles.container}>
      <AnomalyBreakdownTable
        increaseSentimentPositive={increaseSentimentPositive}
        metric={metric}
        causes={causes}
        selected={selected}
        id={id}
        viewOnly={viewOnly}
        onMetricClick={onMetricClick}
      />
    </div>
  )
}
