import React from 'react'
import { Link } from 'react-router-dom'

import { Heading } from './typography'
import Loader from './loader'
import styles from '../styles/anomaly-breakdown.module.scss'
import SiteWrapper from './site-wrapper'
import Layout from './layout'
import TwoColumns, { Column, OuterBox, InnerBox } from './two-columns'
import Footer from './footer'

interface Props {
  isMobile: boolean
  authenticated?: boolean
}

export default function AnomalyBreakdownPageLoader({
  isMobile,
  authenticated = false,
}: Props): React.ReactElement {
  return (
    <SiteWrapper noFooter>
      <Layout type="full-screen" width={1200}>
        <TwoColumns className={styles.columns}>
          <Column main equal transparent>
            {authenticated && (
              <Link className={styles.backButton} to="/explain/">
                All anomalies
              </Link>
            )}
            {!authenticated && (
              <Link className={styles.backButton} to="/login">
                Login to explore
              </Link>
            )}
            <Heading type={1} align="left">
              Loading anomaly breakdown
            </Heading>
            <Loader height="40px" />
            <Loader height="20px" />
            <Loader height="40px" />
            <Loader height="40px" />
            <Loader height="40px" />
            <Loader height="40px" />
            <Loader height="40px" />
            <Loader height="40px" />
            <Loader height="40px" />
            <Loader height="40px" />
            <Loader height="40px" />
            <Loader height="40px" />
            <Loader height="40px" />
            {!isMobile && <Footer className={styles.footer} />}
          </Column>
          <Column side equal transparent>
            <OuterBox>
              <InnerBox className={styles.topBox}>
                <Loader height="40px" />
                <Loader height="10px" />
                <Loader height="40px" />
                <Loader height="300px" />
                <Loader height="300px" />
                <Loader height="20px" />
                <Loader height="300px" />
                <Loader height="20px" />
                <Loader height="300px" />
              </InnerBox>
            </OuterBox>
            <div className={styles.bottomPanel}>
              {isMobile && <Footer className={styles.footer} />}
            </div>
          </Column>
        </TwoColumns>
      </Layout>
    </SiteWrapper>
  )
}
