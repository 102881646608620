import React, { useMemo } from 'react'
import { useQuery, useReactiveVar } from '@apollo/client'

import { Preloader } from './loader'
import {
  defaultEnterpriseFields,
  EnterpriseOrgForm,
} from './settings-enterprise-org-form'
import { currentUserDetails } from '../api/apollo/variables'
import { getEnterpriseBillingDetail } from '../api/graphql/company-client'
import { isNonWhitelabelSupportUser } from '../helpers'
import styles from '../styles/enterprise-org-settings.module.scss'

interface EnterpriseOrgSettingsProps {
  className?: string
}

export default function EnterpriseOrgSettings({
  className,
}: EnterpriseOrgSettingsProps) {
  const { userPermission } = useReactiveVar(currentUserDetails)

  const { data: enterpriseBillingDetailData, error } = useQuery(
    getEnterpriseBillingDetail,
  )

  const enterpriseBillingDetail = useMemo(() => {
    if (!enterpriseBillingDetailData) return null

    return enterpriseBillingDetailData.accountSettings.enterpriseBillingDetail
  }, [enterpriseBillingDetailData])

  return (
    <div className={className}>
      <>
        {!error && !enterpriseBillingDetail && (
          <Preloader
            style={{ width: 60, height: 40, marginTop: 50, marginBottom: 50 }}
          />
        )}
        {error && (
          <p className={styles.error}>
            There has been problem loading enterprise data for this account.
          </p>
        )}
        {enterpriseBillingDetail && (
          <>
            <h3>
              Contract details for - {enterpriseBillingDetail.companyName}
            </h3>
            <EnterpriseOrgForm
              includeWhitelabelField={isNonWhitelabelSupportUser(
                userPermission,
              )}
              fields={{
                ...defaultEnterpriseFields,
                ...enterpriseBillingDetail,
                origDataSource:
                  enterpriseBillingDetail.origDataSource ||
                  defaultEnterpriseFields.origDataSource,
                totalContractValue: parseInt(
                  enterpriseBillingDetail.totalContractValue,
                  10,
                ),
              }}
            />
          </>
        )}
      </>
    </div>
  )
}
