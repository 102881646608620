import React, { useEffect, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import moment from 'moment'
import classNames from 'classnames'

import {
  explainAnomaliesDataReactive,
  explainAnomalyDataReactive,
  explainDataReactive,
  explainDimensionsDataReactive,
} from '../api/apollo/legacy-reducers'
import { currentUserDetails, loggedInState } from '../api/apollo/variables'
import { TagsWrapper, Tag } from '../components/add-multi-values-tags'
import Breakdown from '../components/anomaly-breakdown'
import AnomalyBreakdownPageLoader from '../components/anomaly-breakdown-page-loader'
import AnomalyDiff from '../components/anomaly-diff'
import Button from '../components/button'
import CausesAndSolutionsCarousels from '../components/causes-and-solutions-carousels'
import CustomCauseInput from '../components/custom-cause-input'
import Footer from '../components/footer'
import Graph from '../components/graph'
import Layout from '../components/layout'
import Link from '../components/link'
import ShareAnomalyButton from '../components/share-anomaly-button'
import SiteWrapper from '../components/site-wrapper'
import Tooltip from '../components/tooltip'
import TwoColumns, {
  Column,
  OuterBox,
  InnerBox,
} from '../components/two-columns'
import {
  getItemByKeyValue,
  getDimensionTitle,
  removeByKeyValue,
  getUrlQuery,
} from '../helpers'
import useLogAction from '../hooks/useLogAction'
import useMobile from '../hooks/useMobile'
import useSwitchWorkspace from '../hooks/useSwitchWorkspace'
import styles from '../styles/anomaly-breakdown.module.scss'

interface Props {
  match: any
}

export default function AnomalyBreakdown({ match }: Props) {
  const logAction = useLogAction()

  const { authenticated } = useReactiveVar(loggedInState)

  const { workspaceID } = useReactiveVar(currentUserDetails)

  const explainData = useReactiveVar(explainDataReactive)
  const explainDimensionsData = useReactiveVar(explainDimensionsDataReactive)
  const explainAnomaliesData = useReactiveVar(explainAnomaliesDataReactive)
  const explainAnomalyData = useReactiveVar(explainAnomalyDataReactive)

  const { switchWorkspace } = useSwitchWorkspace()

  const query = getUrlQuery()

  const [graphWidth, setGraphWidth] = useState(0)
  const [anomalyUpdated, setAnomalyUpdated] = useState(false)
  const [accountSwitched, setAccountSwitched] = useState(false)

  const { metricID, anomDate } = match.params

  const isMobile = useMobile(769)
  const data = explainAnomaliesData[metricID]
    ? explainAnomaliesData[metricID]
    : null
  const id = `${metricID}/${anomDate}`

  // ! V1 endpoint no longer used
  // const anomDateM = moment(anomDate, 'DD-MM-YYYY')
  // const anomDateFormatted = anomDateM.format('YYYYMMDD')

  const {
    // anomalyDate,
    anomalyDateID,
    anomalyNotInteresting,
    selectedRelevantDimensions,
    accountName,
  } = explainAnomalyData?.description || {}

  useEffect(() => {
    const fetchData = async () => {
      if (!explainDimensionsData.gaDimensions) {
        // ! V1 endpoint no longer used
        // const res = await make.get({
        //   endpoint: 'get-ga-custom-dimensions',
        // })
        // explainDimensionsDataReactive({
        //   ...explainDimensionsData,
        //   gaDimensions: res.data || [],
        // })
      }

      if (explainData.pageLayout.length === 0) {
        // ! V1 endpoint no longer used
        // explainDataReactive({
        //   ...explainData,
        //   settings: {
        //     ...explainData.settings,
        //     loading: true,
        //   },
        // })
        // const req = await make.get({
        //   endpoint: 'get-explain-page-layout',
        // })
        // explainDataReactive({
        //   ...explainData,
        //   pageLayout: req?.res?.data?.pageLayout,
        //   settings: {
        //     ...req?.res?.data?.settings,
        //     loading: false,
        //   },
        // })
      }

      if (!data) {
        // ! V1 endpoint no longer used
        // const today = moment(new Date(Date.now()))
        // const daysToToday = Math.abs(anomDateM.diff(today, 'days'))
        // const t = !Number.isNaN(daysToToday) && daysToToday >= 15 ? 15 : daysToToday
        // const f = 30 - t
        // const from = moment(anomDate, 'DD-MM-YYYY')
        //   .subtract(f, 'days')
        //   .format('YYYYMMDD')
        // const to = moment(anomDate, 'DD-MM-YYYY')
        //   .add(t, 'days')
        //   .format('YYYYMMDD')
        // if (!metricID) return
        // try {
        //   // Start search (loading)
        //   explainAnomaliesDataReactive({
        //     ...explainAnomaliesData,
        //     [metricID]: {
        //       ...defaultAnomalies,
        //       callData: {
        //         breakdownLoading: [],
        //         loading: true,
        //         error: false,
        //       },
        //       metricID,
        //     },
        //   })
        //   const res = await make.get({
        //     endpoint: `get-range/${metricID}/${from}/${to}`,
        //   })
        //   explainAnomaliesDataReactive({
        //     ...explainAnomaliesData,
        //     [metricID]: {
        //       ...defaultAnomalies,
        //       ...explainAnomaliesData[metricID],
        //       callData: {
        //         breakdownLoading: [],
        //         loading: false,
        //         error: false,
        //       },
        //       lastRange: {
        //         ...explainAnomaliesData[metricID].lastRange,
        //         from,
        //         to,
        //         selector,
        //       },
        //     },
        //   })
        // } catch {
        //   explainAnomaliesDataReactive({
        //     ...explainAnomaliesData,
        //     [metricID]: {
        //       ...defaultAnomalies,
        //       callData: {
        //         breakdownLoading: [],
        //         loading: false,
        //         error: true,
        //       },
        //       metricID,
        //     },
        //   })
        // }
      }

      // ! V1 endpoint no longer used
      // // Set loading state
      // explainAnomalyDataReactive({ description: null })

      // const req = await make.get({
      //   endpoint: `get-anomaly-date-description/${metricID}/${anomDateFormatted}`,
      // })
      // explainAnomalyDataReactive({ description: req?.res?.data || null })
    }

    fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      // ! V1 endpoint no longer used
      // explainCauseDataReactive({ ...explainCauseData, loading: true })
      // const req = await make.get({
      //   endpoint: `generate-recommendations/${anomalyDateID}`,
      // })
      // explainCauseDataReactive({
      //   loading: false,
      //   recommendedCauses: [...req?.res?.data.recommendedCauses],
      // })
    }

    if (anomalyDateID) {
      fetchData()
    }
  }, [anomalyDateID, selectedRelevantDimensions])

  useEffect(() => {
    const accId = query?.get('accountID')

    if (!accId || !workspaceID || accountSwitched) {
      return
    }

    if (workspaceID === accId) {
      setAccountSwitched(true)

      return
    }

    logAction({
      variables: {
        action: 'email-click-through-anomaly',
        extra: JSON.stringify({ metric: metricID, anomDate }),
        websiteSection: 'anomaly',
        functionName: 'switchWorkspace',
        pagePath: window.location.pathname,
      },
    })

    switchWorkspace(accId)
  }, [query, workspaceID])

  const isLoading =
    !explainAnomalyData ||
    !explainAnomalyData.description ||
    // anomDateFormatted !== anomalyDate ||
    data === null

  useEffect(() => {
    if (!anomalyUpdated && !isLoading) {
      const fDate = moment(anomDate, 'DD-MM-YYYY').format('DD/MM/YYYY')
      const found = getItemByKeyValue(data.anomalyRange, 'date', fDate)

      if (found === -1) {
        setAnomalyUpdated(true)

        // ! V1 endpoint no longer used
        // const {
        //   anomalyDate,
        //   anomalyNotInteresting,
        //   explainedAnomaly,
        //   selectedRelevantDimensions,
        //   userSuppliedAction,
        //   userSuppliedCause,
        //   userSuppliedEffort,
        //   userSuppliedBenefit,
        // } = {
        //   ...explainAnomalyData.description,
        // }

        // const anomalyRange =
        //   found === -1
        //     ? [
        //         ...explainAnomaliesData[metricID].anomalyRange,
        //         { ...createAnomalyRangeObject(action.payload) },
        //       ]
        //     : explainAnomaliesData[metricID].anomalyRange.map((item) => {
        //         if (item.date === fDate) {
        //           return {
        //             ...item,
        //             anomalyNotInteresting,
        //             explainedAnomaly,
        //             selectedRelevantDimensions,
        //             userSuppliedAction,
        //             userSuppliedCause,
        //             userSuppliedEffort,
        //             userSuppliedBenefit,
        //           }
        //         }
        //         return item
        //       })
        // explainAnomaliesDataReactive({
        //   ...explainAnomaliesData,
        //   [metricID]: {
        //     ...explainAnomaliesData[metricID],
        //     anomalyRange,
        //   },
        // })
      }
    }
  }, [data, explainAnomalyData])

  if (isLoading) {
    return (
      <AnomalyBreakdownPageLoader
        isMobile={isMobile}
        authenticated={authenticated}
      />
    )
  }

  const updateStatus = (anom: any) => {
    if (explainAnomalyData && explainAnomalyData.description !== null) {
      // ! V1 endpoint no longer used
      // const req = await make.post({
      //   endpoint: 'update-anomaly-date-description',
      //   data: {
      //     ...explainAnomalyData.description,
      //     ...anom,
      //   },
      // })
      // explainAnomalyDataReactive({
      //   description: { ...explainAnomalyData, ...req?.res?.data },
      // })
      // const {
      //   anomalyDate,
      //   anomalyNotInteresting,
      //   explainedAnomaly,
      //   selectedRelevantDimensions,
      //   userSuppliedAction,
      //   userSuppliedCause,
      //   userSuppliedEffort,
      //   userSuppliedBenefit,
      // } = {
      //   ...explainAnomalyData.description,
      //   ...anom,
      // }
      // const fDate = moment(anomalyDate, 'YYYYMMDD').format('DD/MM/YYYY')
      // const found = getItemByKeyValue(
      //   explainAnomaliesData[metricID].anomalyRange,
      //   'date',
      //   fDate,
      // )
      // const anomalyRange =
      //   found === -1
      //     ? [
      //         ...explainAnomaliesData[metricID].anomalyRange,
      //         { ...createAnomalyRangeObject(action.payload) },
      //       ]
      //     : explainAnomaliesData[metricID].anomalyRange.map((item) => {
      //         if (item.date === fDate) {
      //           return {
      //             ...item,
      //             anomalyNotInteresting,
      //             explainedAnomaly,
      //             selectedRelevantDimensions,
      //             userSuppliedAction,
      //             userSuppliedCause,
      //             userSuppliedEffort,
      //             userSuppliedBenefit,
      //           }
      //         }
      //         return item
      //       })
      // explainAnomaliesDataReactive({
      //   ...explainAnomaliesData,
      //   [metricID]: {
      //     ...explainAnomaliesData[metricID],
      //     anomalyRange
      //   }
      // })
    }
  }

  const {
    increaseSentimentPositive,
    userSuppliedCause,
    userSuppliedAction,
    userSuppliedBenefit,
    userSuppliedEffort,
  } = explainAnomalyData.description || {}

  const rightEmpty =
    !authenticated &&
    (!selectedRelevantDimensions || selectedRelevantDimensions.length === 0) &&
    userSuppliedCause === '' &&
    userSuppliedAction === '' &&
    userSuppliedBenefit === '' &&
    userSuppliedEffort === ''

  return (
    <SiteWrapper noFooter>
      <Layout type="full-screen" width={1200}>
        <TwoColumns className={styles.columns}>
          <Column main equal transparent>
            <div className={styles.topPanel}>
              {authenticated && (
                <Link className={styles.backButton} href="/explain">
                  All anomalies
                </Link>
              )}
              {!authenticated && (
                <Link className={styles.backButton} href="/login">
                  Login to explore
                </Link>
              )}
              {authenticated && (
                <>
                  {anomalyNotInteresting ? (
                    <Button
                      variant="secondary"
                      onPress={() =>
                        updateStatus({
                          anomalyNotInteresting: false,
                        })
                      }
                    >
                      <Tooltip
                        id={`${anomalyDateID}-interesting-tooltip`}
                        tooltipMessage="This will show the anomaly on the graph. This also helps our model to learn."
                      >
                        Interesting
                      </Tooltip>
                    </Button>
                  ) : (
                    <Button
                      variant="secondary"
                      color="blue"
                      onPress={() =>
                        updateStatus({
                          anomalyNotInteresting: true,
                        })
                      }
                    >
                      <Tooltip
                        id={`${anomalyDateID}-not-interesting-tooltip`}
                        tooltipMessage="This will hide the anomaly from graph. This also helps our model to learn."
                      >
                        Not interesting
                      </Tooltip>
                    </Button>
                  )}
                </>
              )}
              <h1 className={styles.accountName}>Anomaly for {accountName}</h1>
              <h2 className={styles.pageTitle}>
                <AnomalyDiff
                  arrowOnly
                  invertPolarity={!increaseSentimentPositive}
                  actual={
                    explainAnomalyData.description?.metricActualValue || 0
                  }
                  expected={
                    explainAnomalyData.description?.metricExpectedValue || 0
                  }
                  compact
                  largeIcon
                />
                <span
                  className={styles.rowTitleDate}
                  dangerouslySetInnerHTML={{
                    __html: moment(anomDate, 'DD-MM-YYYY')
                      .format('ddd Do MMM YYYY')
                      .replace(/(\d)(st|nd|rd|th)/g, '$1<sup>$2</sup>'),
                  }}
                />
                <AnomalyDiff
                  inline
                  roundNumber
                  noIcon
                  invertPolarity={!increaseSentimentPositive}
                  actual={
                    explainAnomalyData.description?.metricActualValue || 0
                  }
                  expected={
                    explainAnomalyData.description?.metricExpectedValue || 0
                  }
                />
                {explainAnomalyData.description && (
                  <span className={styles.rowTitleDirection}>
                    {explainAnomalyData.description?.metricActualValue >
                    explainAnomalyData.description?.metricExpectedValue
                      ? 'more'
                      : 'less'}{' '}
                  </span>
                )}
                <span className={styles.rowTitleName}>
                  {explainAnomalyData.description?.displayName}{' '}
                </span>
                <span className={styles.rowTitleDirection}>than expected </span>
              </h2>
            </div>
            <div
              ref={(element) => {
                if (element) {
                  setGraphWidth(element.offsetWidth)
                }
              }}
              className={styles.mainContent}
            >
              {data && (
                <Graph
                  noInterraction
                  increaseSentimentPositive={increaseSentimentPositive}
                  // @ts-ignore
                  data={{
                    ...data,
                    anomalyRange: [
                      getItemByKeyValue(
                        data.anomalyRange,
                        'date',
                        moment(anomDate, 'DD-MM-YYYY').format('DD/MM/YYYY'),
                      ),
                    ],
                  }}
                  metricID={metricID}
                  width={graphWidth}
                  metricName={explainAnomalyData.description?.displayName || ''}
                />
              )}
              <Breakdown
                increaseSentimentPositive={
                  typeof increaseSentimentPositive === 'boolean'
                    ? increaseSentimentPositive
                    : true
                }
                metric={metricID}
                dimensions={explainAnomalyData.description?.dimensions || []}
                id={id}
                viewOnly={!authenticated}
                selected={selectedRelevantDimensions || []}
                onMetricClick={({ a, e, mDN, t, add }) => {
                  let copyDims = Array.isArray(selectedRelevantDimensions)
                    ? selectedRelevantDimensions.concat()
                    : []
                  const found = getItemByKeyValue(
                    selectedRelevantDimensions,
                    'mDN',
                    mDN,
                  )
                  if (add && found === -1) {
                    copyDims.push({ a, e, mDN, t })
                  } else if (!add && found !== -1) {
                    copyDims = removeByKeyValue(
                      selectedRelevantDimensions || [],
                      'mDN',
                      mDN,
                    )
                  } else {
                    return
                  }
                  updateStatus({
                    selectedRelevantDimensions: copyDims,
                  })
                }}
              />
            </div>
            {!isMobile && <Footer className={styles.footer} />}
          </Column>
          <Column side equal transparent>
            <OuterBox
              className={classNames({ [styles.emptyColumn]: rightEmpty })}
            >
              {!rightEmpty && (
                <>
                  <InnerBox className={styles.topBox}>
                    <div className={styles.titleWithButton}>
                      <h1 className={styles.topTitle}>Insights</h1>
                      {authenticated && <ShareAnomalyButton />}
                    </div>
                    <h3 className={styles.dimensionsTitle}>
                      Interesting dimensions
                    </h3>
                    {Array.isArray(selectedRelevantDimensions) &&
                      selectedRelevantDimensions.length > 0 && (
                        <TagsWrapper noBorder className={styles.tagsWrapper}>
                          <>
                            {selectedRelevantDimensions.map((item) => {
                              return (
                                <Tag key={item.mDN} type="blue">
                                  <AnomalyDiff
                                    invertPolarity={!increaseSentimentPositive}
                                    arrowOnly
                                    actual={item.a}
                                    expected={item.e}
                                  />
                                  <span>
                                    {getDimensionTitle(
                                      item.mDN,
                                      explainDimensionsData.gaDimensions,
                                    )}
                                  </span>
                                </Tag>
                              )
                            })}
                          </>
                        </TagsWrapper>
                      )}
                    <CustomCauseInput viewOnly={!authenticated} />
                  </InnerBox>
                  <div className={styles.bottomPanel}>
                    <InnerBox>
                      <h2 className={styles.panelTitle}>
                        Auto-generated by uplifter
                      </h2>
                      <CausesAndSolutionsCarousels viewOnly={!authenticated} />
                    </InnerBox>
                  </div>
                </>
              )}
            </OuterBox>
            <div className={styles.bottomPanel}>
              {isMobile && <Footer className={styles.footer} />}
            </div>
          </Column>
        </TwoColumns>
      </Layout>
    </SiteWrapper>
  )
}
