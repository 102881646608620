import React from 'react'
import numeral from 'numeraljs'
import classNames from 'classnames'

import upArrow from '../assets/up-arrow.svg'
import upArrowRed from '../assets/up-arrow-red.svg'
import downArrow from '../assets/down-arrow.svg'
import downArrowGreen from '../assets/down-arrow-green.svg'
import neutralArrow from '../assets/neutral-arrow.svg'
import { secondsToHms } from '../helpers/dates'
import styles from '../styles/anomaly-diff.module.scss'

interface DiffProps {
  actual: number
  expected: number
  invertPolarity: boolean
  roundNumber: boolean
}

export function formatDiff({
  actual,
  expected,
  invertPolarity,
  roundNumber,
}: DiffProps) {
  let diff = actual - expected
  let percentage = '0%'
  const statusValues = {
    up: ['+', invertPolarity ? upArrowRed : upArrow],
    down: ['-', invertPolarity ? downArrowGreen : downArrow],
    neutral: ['', neutralArrow],
  }

  const serveRes = (status: 'up' | 'down' | 'neutral') => [
    `${statusValues[status][0]}${
      roundNumber
        ? numeral(diff).format('0,0a')
        : numeral(diff).format('0,0.[0]a')
    }`,
    percentage,
    status,
    statusValues[status][1],
  ]

  if (diff === 0) {
    return serveRes('neutral')
  }

  if (actual > expected && expected === 0) {
    percentage = '+∞%'
    return serveRes('up')
  }

  if (actual > expected) {
    percentage = `+${numeral(Math.abs((diff / expected) * 100)).format(
      '0,0a',
    )}%`
    return serveRes('up')
  }

  if (expected > actual) {
    diff = expected - actual
    percentage =
      actual === 0
        ? '-∞%'
        : `-${numeral(Math.abs((diff / actual) * 100)).format('0,0a')}%`
  }

  return serveRes('down')
}

interface Props {
  actual: number
  expected: number
  showOnMobile?: boolean
  compact?: boolean
  minimal?: boolean
  arrowOnly?: boolean
  largeIcon?: boolean
  className?: string
  invertPolarity?: boolean
  noIcon?: boolean
  roundNumber?: boolean
  inline?: boolean
  usePercentage?: string | number
  showLabelWhenZero?: boolean
  noValueChange?: boolean
  valueChangeOnly?: boolean
  showDurationUnits?: boolean
}

export default function AnomalyDiff({
  actual,
  expected,
  showOnMobile = true,
  compact = false,
  minimal = false,
  arrowOnly = false,
  largeIcon = false,
  noIcon = false,
  invertPolarity = false,
  className,
  roundNumber = false,
  inline = false,
  usePercentage,
  showLabelWhenZero = false,
  noValueChange = false,
  valueChangeOnly = false,
  showDurationUnits = false,
}: Props) {
  const [diff, percentage, status, icon] = formatDiff({
    actual,
    expected,
    invertPolarity,
    roundNumber,
  })

  const valToShow = showDurationUnits
    ? `${parseInt(diff, 10) < 0 ? '-' : '+'}${secondsToHms(
        Math.abs(parseInt(diff, 10)),
      )}`
    : diff

  const c = classNames(className, styles.container, {
    [styles.invertPolarity]: invertPolarity,
    [styles.hideOnMobile]: !showOnMobile,
    [styles.compact]: compact,
    [styles.minimal]: minimal,
    [styles.arrowOnly]: arrowOnly,
    [styles.noIcon]: noIcon,
    [styles.inline]: inline,
    [styles.showLabelWhenZero]: showLabelWhenZero && status === 'neutral',
    [styles.showBrackets]: !valueChangeOnly,
  })

  let displayPercentage = `${usePercentage || percentage}`
  if (valueChangeOnly) {
    displayPercentage = displayPercentage.replace(/(-)|(\+)/gi, '')
  }

  return (
    <span className={c}>
      <img
        src={icon}
        className={classNames(styles.infoIcon, {
          [styles.largeIcon]: largeIcon,
        })}
        alt="open icon"
      />
      <span className={styles.numbers} data-type={status}>
        {!valueChangeOnly && <span>{valToShow}</span>}
        {!noValueChange && (
          <span className={styles.percentage}>{displayPercentage}</span>
        )}
      </span>
    </span>
  )
}
