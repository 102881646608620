import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import classNames from 'classnames'
import _ from 'lodash'
import numeral from 'numeraljs'

import Button, { ClearButton } from './button'
import { BetaLabel } from './counter'
import { FormField, FormLabel, FormLoading, FormRow } from './form'
import { RadioGroup } from './input-v2'
import Link from './link'
import NoDataMessage from './no-data-message'
import Tooltip from './tooltip'
import AppFields from './track-create-app-fields'
import {
  CreateLinkAnchorWarningModal,
  CreateLinkInvalidLengthModal,
  CreateLinkNoUrlModal,
  CreateShortLinkNoUrlModal,
} from './track-create-form-warning-modals'
import LandingPageField from './track-create-landing-page-field'
import GeneratorParameterFields from './track-create-parameter-fields'
import TrackCreatePreviewLinksTable from './track-create-preview-links-table'
import TrackCreateSubmitFields from './track-create-submit'
import { InnerBox, OuterBox } from './two-columns'
import { BoxedText, ErrorMessage } from './typography'
import { RequestAppLinksModal } from './upgrade-modals'
import {
  currentUserDetails,
  showAppDeepLinkContent,
} from '../api/apollo/variables'
import { supportEmail } from '../core/constants'
import { maxBatchShortLinks } from '../helpers/track-module'
import {
  FullLinkResult,
  getTotalPossibleCombinations,
  HardWarningModalType,
  PreparedLinkResult,
  prepareLinkCombinations,
  SoftWarning,
  UpdateFormValuesVars,
  WebLinkForm,
} from '../helpers/track-create'
import useCustomLinks from '../hooks/useCustomLinks'
import useSubscriptionLevel from '../hooks/useSubscriptionLevel'
import { UpdateFormOptions } from '../hooks/useTrackCreateSavedValues'
import useTrackCreateFormSubmit from '../hooks/useTrackCreateSubmit'
import styles from '../styles/track-create-form.module.scss'
import { GetCampaignCodeGeneratorQuery } from '../__gql-types__/graphql'

interface ClearFormButtonProps {
  className?: string
  onPress: () => void
}

const ClearFormButton = ({ className, onPress }: ClearFormButtonProps) => {
  return (
    <ClearButton
      className={classNames(className, styles.clearAllButton)}
      iconAfter
      onPress={onPress}
    >
      Clear all
    </ClearButton>
  )
}

interface TrackCreateFormWebProps {
  generatedStructure:
    | GetCampaignCodeGeneratorQuery['campaignCodeGenerator']
    | null
  loadingGenerator?: boolean
  generatorError?: boolean
  formValues: WebLinkForm
  updateFormValues: (
    newValues: UpdateFormValuesVars,
    options?: UpdateFormOptions,
  ) => void
  formSubmissionState: {
    softDisable: boolean
    fieldsWithErrors: string[]
    showErrorMessages: boolean
  }
  setFormSubmissionState: React.Dispatch<
    React.SetStateAction<{
      softDisable: boolean
      fieldsWithErrors: string[]
      showErrorMessages: boolean
    }>
  >
  /** Used to set links that should appear in recentlyCreated section */
  setNewLinks: React.Dispatch<React.SetStateAction<string | string[]>>
  /** Used in error message when user tries to create too many links */
  switchToBulkCsv: () => void
}

const TrackCreateFormWeb = ({
  generatedStructure,
  loadingGenerator,
  generatorError,
  formValues,
  updateFormValues,
  formSubmissionState,
  setFormSubmissionState,
  setNewLinks,
  switchToBulkCsv,
}: TrackCreateFormWebProps) => {
  const { validationChecks } = generatedStructure || {}

  const {
    softDisable,
    fieldsWithErrors,
    showErrorMessages,
  } = formSubmissionState

  const { workspaceID } = useReactiveVar(currentUserDetails)

  const showAppContent = useReactiveVar(showAppDeepLinkContent)

  const formRef = useRef<HTMLFormElement>(null)

  const { isEnterprise } = useSubscriptionLevel()

  const { updateSelectedDomain } = useCustomLinks()

  const {
    linkType,
    setLinkType,
    canUseCustomLinks,
    selectedCustomLinkDomain,
    customLinkAliasDetails,
    setCustomLinkAliasDetails,
    submitForm,
    createLinks,
    createLinkWarning,
    setCreateLinkWarning,
    createLinkLoading,
    uplifterIdLoading,
    uniqueIDError,
    createLinkError,
  } = useTrackCreateFormSubmit({
    formSubmissionState,
    setFormSubmissionState,
    generatedStructure,
    useAppLinks: formValues.linkTo === 'app',
  })

  // Use this to count eligible links in the form
  const [totalPossibleCombinations, setTotalPossibleCombinations] = useState(1)
  const [preparedLinks, setPreparedLinks] = useState<PreparedLinkResult[]>([])

  // Used in preview modal, only set onSubmit
  const [linksToPreview, setLinksToPreview] = useState<FullLinkResult[]>([])
  const [showLinksPreviewModal, setShowLinksPreviewModal] = useState(false)

  // Used to ensure submit button isn't triggered by multiple clicks in quick succession
  const [codeCreatedTimeout, setCodeCreatedTimeout] = useState(false)

  // Used if account is not enterprise and user tries to create an app link
  const [showAppLinksUpgradeModal, setShowAppLinksUpgradeModal] = useState(
    false,
  )

  const showClearButton = useMemo(() => {
    if (formValues.url.length > 0 && formValues.url[0] !== '') {
      return true
    }

    if (
      formValues.linkTo === 'app' &&
      (formValues.appValues?.appGroupID || formValues.appValues?.appScreen)
    ) {
      return true
    }

    return Object.values(formValues.generatorParameterValues).some(
      (paramValue) => paramValue && paramValue.length > 0,
    )
  }, [formValues])

  // Landing page field should never be optional if isApp
  const showLandingPageField = useMemo(() => {
    if (formValues.linkTo === 'app') return true

    const showLandingCheck = validationChecks?.find(
      (check) => check.name === 'SHOW_LANDING_PAGE',
    )?.enabled

    return typeof showLandingCheck === 'boolean' ? showLandingCheck : true
  }, [formValues.linkTo, validationChecks])

  // Set linkTo to 'url' if it is currently app and user is not Enterprise
  useEffect(() => {
    if ((showAppContent && isEnterprise) || formValues.linkTo === 'url') {
      return
    }

    const showLandingCheck = validationChecks?.find(
      (check) => check.name === 'SHOW_LANDING_PAGE',
    )?.enabled

    updateFormValues(
      {
        linkTo: 'url',
        // Remove URL value for web form if hidden
        url: showLandingCheck === false ? [] : formValues.url,
        // Remove app values for URL form, else don't update
        appValues: null,
        generatorParameterValues: formValues.generatorParameterValues,
      },
      { resetSubmissionState: true },
    )
  }, [showAppContent, isEnterprise, formValues.linkTo, validationChecks])

  // Recalculate prepared links whenever a form value changes and the form is ready to submit
  useEffect(() => {
    if (!generatedStructure) return

    if (softDisable) {
      setTotalPossibleCombinations(1)
      setPreparedLinks([])
      return
    }

    const totalCombinations = getTotalPossibleCombinations(formValues)

    setTotalPossibleCombinations(totalCombinations)

    // Preparing link combinations based on generator rules is a heavy operation
    // Only do this if possible combinations is less than maxBatchShortLinks
    if (totalCombinations < maxBatchShortLinks) {
      setPreparedLinks(
        prepareLinkCombinations(formValues, generatedStructure, workspaceID),
      )
    }
  }, [
    workspaceID,
    generatedStructure,
    softDisable,
    formValues.url,
    formValues.generatorParameterValues,
  ])

  const clearForm = useCallback(() => {
    // Set all form values back to empty
    const generatorParameterValues = {}
    Object.keys(formValues.generatorParameterValues).forEach((param) => {
      generatorParameterValues[param] = []
    })

    updateFormValues(
      {
        linkTo: formValues.linkTo,
        url: [],
        appValues: null,
        generatorParameterValues,
      },
      { resetSubmissionState: true },
    )
  }, [updateFormValues, formValues.linkTo, formValues.generatorParameterValues])

  const onSubmit = useCallback(
    async (formVals: WebLinkForm, ignoreWarnings?: SoftWarning[]) => {
      const { success, fullLinks } = await submitForm(formVals, ignoreWarnings)

      // Scroll to first errored field if any
      if (!success) {
        const errorFields = formRef.current?.querySelectorAll(
          "div[class*='error']",
        )

        if (errorFields && errorFields.length > 0) {
          errorFields[0].scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
        }

        return
      }

      // Do not create links if >1 - preview them instead
      if (fullLinks.length > 1) {
        setLinksToPreview(fullLinks)
        setShowLinksPreviewModal(true)

        return
      }

      const useShortLinks =
        formVals.linkTo === 'app' || linkType === 'shortLink'

      const previewLink = { ...fullLinks[0] }

      // Add short link ID here
      if (useShortLinks) {
        // eslint-disable-next-line prefer-destructuring
        previewLink.shortLinkID = customLinkAliasDetails.alias
      }

      const { success: createSuccess } = await createLinks(
        [previewLink],
        linkType === 'appLink' && formVals.appValues
          ? {
              appGroupID: formVals.appValues.appGroupID,
              redirectContext: [formVals.appValues.appScreen],
              // Should this be fC, lP or link?
              fallbackUrl: previewLink.fC,
            }
          : undefined,
      )

      if (createSuccess) {
        setNewLinks(
          useShortLinks
            ? `https://${selectedCustomLinkDomain.domainValue}/${previewLink.shortLinkID}`
            : previewLink.fC,
        )
      }
    },
    [
      linkType,
      customLinkAliasDetails,
      submitForm,
      createLinks,
      formRef.current,
      selectedCustomLinkDomain,
    ],
  )

  if (generatorError) {
    return (
      <OuterBox className={styles.outerBox}>
        <InnerBox>
          <NoDataMessage
            errorMsg={
              <>
                <p>Failed to retrieve link generator parameters.</p>
                <p>
                  <Link type="arrowForward" href={`mailto:${supportEmail}`}>
                    Contact {supportEmail}
                  </Link>
                </p>
              </>
            }
            showSupportLink={false}
          />
        </InnerBox>
      </OuterBox>
    )
  }

  if (loadingGenerator || uplifterIdLoading) {
    return (
      <OuterBox className={styles.outerBox}>
        <InnerBox>
          <FormLoading rowCount={6} includeBorders>
            <Button isDisabled style={{ width: 'fit-content' }}>
              Fetching link builder
            </Button>
          </FormLoading>
        </InnerBox>
      </OuterBox>
    )
  }

  return (
    <>
      <OuterBox className={styles.outerBox}>
        <InnerBox className={styles.innerBox}>
          <form
            ref={formRef}
            className={styles.trackCreateForm}
            onSubmit={async (e) => {
              e.preventDefault()

              if (codeCreatedTimeout) return

              setCodeCreatedTimeout(true)

              await onSubmit(formValues)

              window.setTimeout(() => setCodeCreatedTimeout(false), 800)
            }}
          >
            {showAppContent ? (
              <FormRow className={styles.topFormRow}>
                <FormLabel
                  id="link-to"
                  tooltip={
                    <>
                      <p>
                        What is your link going to open on the users device?
                      </p>
                      <p>
                        <strong>Website URL:</strong> A URL in their browser.
                      </p>
                      <p>
                        <strong>App:</strong> If the user is on an Android/iOS
                        device, the link will take them to your app. If they
                        don't have the app installed, they will be taken to the
                        app's page on the app store.
                      </p>
                    </>
                  }
                >
                  Link to
                </FormLabel>
                <FormField>
                  <div className={styles.topFormRowField}>
                    <RadioGroup
                      groupName="linkTo"
                      horizontal
                      optionsClassName={styles.radioButton}
                      selectedValue={formValues.linkTo || 'url'}
                      options={[
                        {
                          label: 'Website URL',
                          value: 'url',
                        },
                        {
                          label: (
                            <div className={styles.appRadio}>
                              App{' '}
                              <Tooltip
                                id="app-link-beta-tooltip"
                                className={styles.betaTooltip}
                                clickable
                                tooltipMessage={
                                  <p>
                                    New feature in testing. Email{' '}
                                    <Link href={supportEmail}>
                                      {supportEmail}
                                    </Link>{' '}
                                    if you spot a bug or have feedback.
                                  </p>
                                }
                              >
                                <BetaLabel className={styles.betaPill} />
                              </Tooltip>
                            </div>
                          ),
                          value: 'app',
                        },
                      ]}
                      onChange={(option) => {
                        // App links are enterprise only
                        if (!isEnterprise && option === 'app') {
                          setShowAppLinksUpgradeModal(true)
                          return
                        }

                        if (option === 'url') {
                          updateSelectedDomain('default', 'appLink')
                        }

                        // The URL value must be reset when it's hidden from the form on 'url' version
                        // It should not be a part of the submission form
                        const showLandingCheck = validationChecks?.find(
                          (check) => check.name === 'SHOW_LANDING_PAGE',
                        )?.enabled

                        updateFormValues(
                          {
                            linkTo: option as 'url' | 'app',
                            // Remove URL value for web form if hidden
                            url:
                              option === 'url' && showLandingCheck === false
                                ? []
                                : formValues.url,
                            // Remove app values for URL form, else don't update
                            appValues: option === 'url' ? null : undefined,
                            generatorParameterValues:
                              formValues.generatorParameterValues,
                          },
                          { resetSubmissionState: true },
                        )
                      }}
                    />
                    {showClearButton && <ClearFormButton onPress={clearForm} />}
                  </div>
                </FormField>
              </FormRow>
            ) : (
              <>
                {showClearButton && (
                  <ClearFormButton
                    // Has to be absolutely positioned when app row is not shown - top row becomes landing page field and that must be full width
                    className={styles.floatingClearAllButton}
                    onPress={clearForm}
                  />
                )}
              </>
            )}
            {formValues.linkTo === 'app' && (
              <AppFields
                appValues={formValues.appValues}
                appGroupIDError={
                  showErrorMessages &&
                  formSubmissionState.fieldsWithErrors.includes('appGroupID')
                }
                appScreenError={
                  showErrorMessages &&
                  formSubmissionState.fieldsWithErrors.includes('appScreen')
                }
                updateFormValues={updateFormValues}
              />
            )}
            <LandingPageField
              showField={showLandingPageField}
              isApp={formValues.linkTo === 'app'}
              validationChecks={generatedStructure?.validationChecks || null}
              savedValue={formValues.url}
              onChange={(newVal, options) => {
                updateFormValues({ url: newVal }, options)
              }}
              submitError={
                showErrorMessages && fieldsWithErrors.includes('landingPage')
              }
            />
            <div style={{ paddingBottom: 16 }}>
              <GeneratorParameterFields
                generatedStructure={generatedStructure}
                formValues={formValues.generatorParameterValues}
                onChange={(fieldID, newVal, options) => {
                  updateFormValues(
                    {
                      generatorParameterValues: {
                        [fieldID]: newVal || [],
                      },
                    },
                    options,
                  )
                }}
                showErrorMessages={showErrorMessages}
                fieldsWithErrors={fieldsWithErrors}
              />
            </div>
            <TrackCreateSubmitFields
              canUseLinkType={canUseCustomLinks}
              validationChecks={generatedStructure?.validationChecks || null}
              linkType={linkType}
              setLinkType={setLinkType}
              customLinkAliasDetails={customLinkAliasDetails}
              setCustomLinkAliasDetails={setCustomLinkAliasDetails}
              linkCount={preparedLinks.length || 1}
              submitLoading={createLinkLoading}
              softDisable={!createLinkLoading && softDisable}
              submitDisabled={
                codeCreatedTimeout ||
                (!softDisable && preparedLinks.length === 0) ||
                !!uniqueIDError ||
                totalPossibleCombinations > maxBatchShortLinks
              }
            >
              {totalPossibleCombinations <= maxBatchShortLinks &&
                preparedLinks.length > 1 &&
                preparedLinks.length <= maxBatchShortLinks &&
                !softDisable && (
                  <p className={styles.validCombinationsNote}>
                    {numeral(preparedLinks.length).format('0,0')} valid
                    combinations for you to review.
                  </p>
                )}
              {totalPossibleCombinations > maxBatchShortLinks && (
                <ErrorMessage>
                  You are trying to create up to{' '}
                  {numeral(totalPossibleCombinations).format('0,0')} links.
                  Please reduce this to{' '}
                  {numeral(maxBatchShortLinks).format('0, 0')} or less. To
                  create more, try the{' '}
                  <BoxedText>
                    <Button
                      variant="text"
                      color="blue"
                      className={styles.bulkCsvButton}
                      onPress={switchToBulkCsv}
                    >
                      Bulk CSV
                    </Button>
                  </BoxedText>{' '}
                  tab.
                </ErrorMessage>
              )}
              {showErrorMessages && fieldsWithErrors.length > 0 && (
                <ErrorMessage>
                  Please have a value for every required parameter.
                </ErrorMessage>
              )}
              {uniqueIDError && (
                <ErrorMessage>
                  Error generating a unique link ID. Please reload the page and
                  try again, or contact{' '}
                  <Link href={supportEmail}>{supportEmail}</Link>.
                </ErrorMessage>
              )}
              {createLinkError && (
                <ErrorMessage>
                  {preparedLinks.length === 0
                    ? 'No valid links to create.'
                    : `Error creating link${
                        preparedLinks.length === 1 ? '' : 's'
                      },
                  please refresh and try again.`}
                </ErrorMessage>
              )}
              {customLinkAliasDetails.error && (
                <ErrorMessage>
                  There was an error creating your{' '}
                  {linkType === 'shortLink' ? 'short' : 'deep'} link. Please
                  refresh it and try again.
                </ErrorMessage>
              )}
            </TrackCreateSubmitFields>
          </form>
        </InnerBox>
      </OuterBox>
      {showLinksPreviewModal && linksToPreview.length > 0 && (
        <TrackCreatePreviewLinksTable
          generatedStructure={generatedStructure}
          setIsOpen={setShowLinksPreviewModal}
          linksToPreview={linksToPreview}
          setLinksToPreview={setLinksToPreview}
          linkType={linkType}
          appValues={formValues.appValues}
          customLinkAliasDetails={customLinkAliasDetails}
          setCustomLinkAliasDetails={setCustomLinkAliasDetails}
          onSubmit={async (linksToCreate, bulkStart, editedLinkAliases) => {
            const useShortLinks =
              formValues.linkTo === 'app' || linkType === 'shortLink'

            const appLinkValues =
              linkType === 'appLink' && formValues.appValues
                ? {
                    appGroupID: formValues.appValues.appGroupID,
                    redirectContext: [formValues.appValues.appScreen],
                    // Should this be fC, lP or link?
                    fallbackUrl: linksToCreate[0].fC,
                  }
                : undefined

            const uneditedLinks = _.cloneDeep(linksToCreate)
            const editedLinks: FullLinkResult[] = []

            // Change alias values for edited links
            // These will be in a secondary createLinks call, not using the bulkStart value
            if (
              editedLinkAliases &&
              Object.keys(editedLinkAliases).length > 0
            ) {
              Object.keys(editedLinkAliases).forEach((fC) => {
                const editedLinkIndex = uneditedLinks.findIndex(
                  (link) => link.fC === fC,
                )

                // Might happen if an alias is edited but not selected
                if (editedLinkIndex === -1) return

                const editedLink = uneditedLinks.splice(editedLinkIndex, 1)

                editedLinks.push({
                  ...editedLink[0],
                  shortLinkID: editedLinkAliases[fC].alias,
                })
              })
            }

            // Artificially add the batch suffixes to the unedited links
            const updatedFullLinks: FullLinkResult[] = uneditedLinks.map(
              (uneditedLink, uneditedLinkIndex) => ({
                ...uneditedLink,
                shortLinkID: useShortLinks
                  ? `${uneditedLink.shortLinkID}-0x${(
                      uneditedLinkIndex + 1
                    ).toString(16)}`
                  : uneditedLink.shortLinkID,
              }),
            )

            let fullSuccess = false

            // Do edited links first to prevent weird alias changes in the modal
            if (editedLinks.length > 0) {
              const { success: individualLinksSuccess } = await createLinks(
                editedLinks,
                appLinkValues,
              )
              fullSuccess = individualLinksSuccess

              updatedFullLinks.push(...editedLinks)
            } else {
              fullSuccess = true
            }

            if (fullSuccess && uneditedLinks.length > 0) {
              const { success } = await createLinks(
                uneditedLinks,
                appLinkValues,
                bulkStart,
              )

              fullSuccess = success
            }

            if (fullSuccess) {
              setNewLinks(
                useShortLinks
                  ? updatedFullLinks.map(
                      ({ shortLinkID }) =>
                        `https://${selectedCustomLinkDomain.domainValue}/${shortLinkID}`,
                    )
                  : updatedFullLinks.map(({ fC }) => fC),
              )

              setLinksToPreview([])
              setShowLinksPreviewModal(false)
            }
          }}
          createLinkError={createLinkError}
          customLinkError={customLinkAliasDetails.error}
        />
      )}
      {createLinkWarning?.type === 'has-anchor' && (
        <CreateLinkAnchorWarningModal
          createLinkWarning={createLinkWarning}
          setCreateLinkWarning={setCreateLinkWarning}
          buildLinks={async (alreadyIgnoredWarnings) => {
            const warningsToIgnore = alreadyIgnoredWarnings || []

            warningsToIgnore.push('has-anchor')

            await onSubmit(formValues, [...new Set(warningsToIgnore)])
          }}
        />
      )}
      {createLinkWarning?.type === 'no-url' && (
        <CreateLinkNoUrlModal
          createLinkWarning={createLinkWarning}
          setCreateLinkWarning={setCreateLinkWarning}
          buildLinks={async (alreadyIgnoredWarnings) => {
            const warningsToIgnore = alreadyIgnoredWarnings || []

            warningsToIgnore.push('no-url')

            await onSubmit(formValues, [...new Set(warningsToIgnore)])
          }}
        />
      )}
      {createLinkWarning?.type === 'no-url-shortlink' && (
        <CreateShortLinkNoUrlModal
          createLinkWarning={createLinkWarning}
          setCreateLinkWarning={setCreateLinkWarning}
          buildLinks={async (alreadyIgnoredWarnings) => {
            const warningsToIgnore = alreadyIgnoredWarnings || []

            warningsToIgnore.push('no-url-shortlink')

            await onSubmit(formValues, [...new Set(warningsToIgnore)])
          }}
        />
      )}
      {['invalid-query-length', 'invalid-landing-page-length'].indexOf(
        createLinkWarning?.type || '',
      ) > -1 && (
        <CreateLinkInvalidLengthModal
          createLinkWarning={createLinkWarning as HardWarningModalType}
          setCreateLinkWarning={setCreateLinkWarning}
        />
      )}
      {showAppLinksUpgradeModal && (
        <RequestAppLinksModal onHideModal={setShowAppLinksUpgradeModal} />
      )}
    </>
  )
}

export default TrackCreateFormWeb
