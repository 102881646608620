import React from 'react'
import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { ApolloProvider } from '@apollo/client'
import { Helmet } from 'react-helmet'

import * as serviceWorker from './serviceWorker'
import './core/core.scss'
import Router from './router'
import { SENTRY_ENV, TOKEN } from './api/constants'
import client from './api/apollo'
import { getLocalItem } from './helpers/local-client'
import {
  brandName,
  paddleVendorID,
  usePaddleSandboxAccount,
} from './core/constants'
import uplifterFavicon from './assets/logos/favicon.ico'
import uptimalFavicon from './assets/logos/uptimal-favicon.png'

declare global {
  interface Window {
    Trustpilot: any
    Paddle: any
    clarity: any
    dataLayer: Object[]
  }
}

// Sentry Session Replay: https://docs.sentry.io/platforms/javascript/guides/react/session-replay/
Sentry.init({
  dsn:
    'https://58608893bfe5410cb28d9abee03098b5@o273852.ingest.sentry.io/5402550',
  environment: SENTRY_ENV,
  release: `uplifter@${process.env.REACT_APP_VERSION}`,
  // Sentry's Session replay interferes with our strict CSP (See headers below)
  // Self-hosting the workier script is a possible option if Session Replay is needed
  // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#using-a-custom-compression-worker
  // integrations: [
  //   new Sentry.Replay({
  //     // Additional SDK configuration goes in here, for example:
  //     maskAllText: false,
  //     // https://docs.sentry.io/platforms/javascript/guides/react/session-replay/privacy/
  //     maskAllInputs: true,
  //     blockAllMedia: true,
  //     networkDetailAllowUrls: [
  //       'https://gql-s.uplifter.tech/graphql',
  //       'https://gql.uplifter.tech/graphql',
  //     ],
  //   }),
  // ],
  // replaysSessionSampleRate: 0.1,
  // replaysOnErrorSampleRate: 1.0,
  ignoreErrors: [
    'Non-Error promise rejection captured',
    'ResizeObserver loop limit exceeded',
  ],
})

const userToken = getLocalItem(TOKEN)
if (userToken) {
  Sentry.setUser({ id: userToken })
}

// Set up Paddle
if (window.Paddle) {
  if (usePaddleSandboxAccount) {
    window.Paddle.Environment.set('sandbox')
  }
  window.Paddle.Setup({ vendor: paddleVendorID })
}

const App = () => (
  <>
    <Helmet>
      <title>{brandName}</title>
      {brandName === 'Uplifter' ? (
        <link rel="shortcut icon" href={uplifterFavicon} />
      ) : (
        <link rel="shortcut icon" href={uptimalFavicon} />
      )}
    </Helmet>
    {/* New Apollo store */}
    <ApolloProvider client={client}>
      <Router />
    </ApolloProvider>
  </>
)
const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)
root.render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
