import React, { useMemo, useState } from 'react'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { useHistory } from 'react-router-dom'

import Button from './button'
import Input from './input'
import Link from './link'
import { currentUserDetails } from '../api/apollo/variables'
import {
  createPublicAPIToken,
  getPublicAPIToken,
} from '../api/graphql/company-client'
import { supportEmail } from '../core/constants'
import { isAdminUser } from '../helpers'
import useLogAction from '../hooks/useLogAction'
import useSubscriptionLevel from '../hooks/useSubscriptionLevel'
import styles from '../styles/open-api.module.scss'

export default function PublicAPI() {
  const { isEnterprise } = useSubscriptionLevel()

  const logAction = useLogAction()

  const history = useHistory()

  const { userPermission } = useReactiveVar(currentUserDetails)

  const { data: apiTokenData } = useQuery(getPublicAPIToken)
  const [createToken] = useMutation(createPublicAPIToken, {
    refetchQueries: [getPublicAPIToken, 'GetPublicAPIToken'],
  })

  const [copied, setCopied] = useState(false)

  const apiToken = useMemo(() => {
    if (!apiTokenData) return ''

    return apiTokenData.currentCompany.publicApiKey
  }, [apiTokenData])

  return (
    <>
      <div className={styles.container}>
        <h3>Your API token</h3>

        {isEnterprise ? (
          <>
            {isAdminUser(userPermission) ? (
              <div>
                <p>
                  Use this API to interact with data stored by us. Find
                  documentation on authentication{' '}
                  <Link href="https://access.uplifter.tech/docs">here</Link> or
                  contact:{' '}
                  <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>.
                </p>

                {!apiToken ? (
                  <Button
                    onPress={() => {
                      createToken()

                      logAction({
                        variables: {
                          action: 'generate-public-api-key',
                          pagePath: '/settings',
                          websiteSection: 'Settings',
                          functionName: 'generatePublicApiKey',
                          extra: 'publicAPI',
                        },
                      })
                    }}
                  >
                    Generate token
                  </Button>
                ) : (
                  <div className={styles.flex}>
                    <Input name="API token" value={apiToken} disabled />
                    <Button
                      style={{ height: 40 }}
                      onPress={() => {
                        setCopied(true)
                        navigator.clipboard.writeText(apiToken)
                      }}
                    >
                      {copied ? 'Copied' : 'Copy'}
                    </Button>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <p>
                  Contact your admin user to obtain your API key, or email{' '}
                  <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>{' '}
                  for help.
                </p>
              </div>
            )}
          </>
        ) : (
          <>
            <p>
              If you'd like access to our API, please upgrade to an enterprise
              plan.
            </p>
            <Button
              onPress={() => {
                logAction({
                  variables: {
                    action: 'click-upgrade-button',
                    pagePath: '/settings',
                    websiteSection: 'Settings',
                    functionName: 'publicAPI',
                    extra: 'publicAPI',
                  },
                })

                history.push('/upgrade')
              }}
            >
              View plans
            </Button>
          </>
        )}
      </div>
    </>
  )
}
