import React from 'react'

export const ArrowPrev = ({
  color = '#e61969',
}: {
  color?: string
}): React.ReactElement<React.ReactSVGElement> => (
  <svg
    viewBox="0 0 52 87"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
  >
    <path
      d="M49.12 72.12L20.21 43.21 49.12 14.3a8.375 8.375 0 002.452-5.92c0-4.593-3.779-8.372-8.372-8.372-2.22 0-4.351.883-5.92 2.452L2.45 37.29l.3-.29-.3.29-.29.3-.11.13-.16.18-.14.18c-.034.049-.07.096-.11.14l-.14.21-.09.12-.14.22-.08.13-.13.22s0 .09-.07.14a2.096 2.096 0 00-.11.21c-.033.072 0 .1-.08.16l-.09.19a1.44 1.44 0 01-.08.19 1.192 1.192 0 01-.07.17c0 .07 0 .14-.08.22v.15c0 .08 0 .16-.08.24a.706.706 0 010 .13c-.057.101-.12.198-.19.29a.482.482 0 000 .12 2.247 2.247 0 00-.07.28c.005.04.005.08 0 .12v.28a.547.547 0 000 .14 2.255 2.255 0 000 .26v1.64c-.005.08-.005.16 0 .24a1.206 1.206 0 000 .17 2.085 2.085 0 000 .25v.15a2.085 2.085 0 000 .25.752.752 0 000 .15c.015.081.035.161.06.24.025.079 0 .1 0 .16.028.068.051.139.07.21l.06.18c0 .06 0 .13.08.19.022.068.049.135.08.2l.06.16c.037.071.071.145.1.22a.814.814 0 01.07.13l.12.24.06.11.15.25a.44.44 0 00.05.09c.06.08.11.17.16.25l.06.09.18.24a.855.855 0 00.07.1l.27.33.18.2.15.16.26.27-.13-.13 34.98 34.81a8.375 8.375 0 005.92 2.452c4.593 0 8.372-3.779 8.372-8.372 0-2.22-.883-4.351-2.452-5.92z"
      fill={color}
      fillRule="nonzero"
    />
  </svg>
)

export const ArrowNext = ({
  color = '#e61969',
}: {
  color?: string
}): React.ReactElement<React.ReactSVGElement> => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.57 86.41">
    <path
      d="M77.63,71.63,86,80l-8.37,8.37L57.09,108.91a8.37,8.37,0,0,0,11.84,11.84l34.83-34.83-.3.29.3-.29.29-.3.11-.13.16-.18.14-.18a1.34,1.34,0,0,1,.11-.14l.14-.21.09-.12.14-.22.08-.13.13-.22s0-.09.07-.14a2.12,2.12,0,0,0,.11-.21s0-.1.08-.16l.09-.19a1.54,1.54,0,0,1,.08-.19,1.22,1.22,0,0,1,.07-.17c0-.07,0-.14.08-.22l0-.15c0-.08,0-.16.08-.24a.65.65,0,0,1,0-.13A2.68,2.68,0,0,1,106,82a.5.5,0,0,0,0-.12,2.29,2.29,0,0,0,.07-.28.5.5,0,0,1,0-.12c0-.09,0-.18,0-.28a.59.59,0,0,0,0-.14,2.3,2.3,0,0,0,0-.26c0-.06,0-.13,0-.19s0-.14,0-.22,0-.23,0-.34v-.13c0-.12,0-.24,0-.35s0-.13,0-.2,0-.14,0-.21a2,2,0,0,0,0-.24,1.11,1.11,0,0,0,0-.17,2.17,2.17,0,0,0,0-.25l0-.15a2.21,2.21,0,0,0,0-.25.83.83,0,0,0,0-.15,2.1,2.1,0,0,0-.06-.24s0-.1,0-.16a1.72,1.72,0,0,1-.07-.21l-.06-.18c0-.06,0-.13-.08-.19a1.67,1.67,0,0,0-.08-.2l-.06-.16a2.14,2.14,0,0,1-.1-.22.83.83,0,0,1-.07-.13l-.12-.24-.06-.11-.15-.25a.41.41,0,0,0-.05-.09c-.06-.08-.11-.17-.16-.25l-.06-.09-.18-.24a.86.86,0,0,0-.07-.1l-.18-.22-.09-.11-.18-.2-.15-.16-.26-.27.13.13L68.93,39.25A8.37,8.37,0,0,0,57.09,51.09Z"
      fill={color}
      fillRule="nonzero"
      transform="translate(-54.64 -36.8)"
    />
  </svg>
)
