import React, { useEffect, useMemo, useState } from 'react'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { useLocation } from 'react-router-dom'
import _ from 'lodash'

import {
  createMondayBoard,
  deleteMondayIntegration,
  getIntegrationsStatus,
  getMondayIDs,
  getMondayWorkspaces,
  saveMondayOauth,
} from '../api/graphql/integrations-client'
import Button from '../components/button'
import Link from '../components/link'
import { Preloader } from '../components/loader'
import SelectBox from '../components/select-box'
import { supportEmail } from '../core/constants'
import styles from '../styles/integrations-settings.module.scss'

interface ConnectorLocationState {
  connectorID: string
  code: string
  state: string
}

interface MondayWorkspace {
  id: string
  name: string
  description?: string | null
}

const MondayConnector = () => {
  const { state: locationState } = useLocation<ConnectorLocationState>()

  const mondayCode =
    !locationState || locationState.connectorID !== 'monday'
      ? null
      : (locationState.code as string) || null

  const { data: connectorData, loading: loadingConnectorStatus } = useQuery(
    getIntegrationsStatus,
  )

  // Returns workspaces available on newly connected account
  const [
    saveOauth,
    { data: saveOauthData, loading: savingOauthData, error: errorSavingOauth },
  ] = useMutation(saveMondayOauth)

  // monday.com is already connected - fetch connection info
  const [
    getMondayIntegrationData,
    { data: mondayIntegrationData, loading: loadingIntegrationData },
  ] = useLazyQuery(getMondayIDs)

  // Returns workspaces available, same as 'saveOauth', but as a query
  // Used when oauth has already been saved ('pendingBoardCreation')
  const [
    getWorkspaces,
    {
      data: workspacesData,
      loading: loadingWorkspaces,
      error: errorFetchingWorkspaces,
    },
  ] = useLazyQuery(getMondayWorkspaces)

  const [
    createBoard,
    { loading: creatingBoard, error: errorCreatingBoard },
  ] = useMutation(createMondayBoard)

  const [deleteConnection, { loading: deletingConnection }] = useMutation(
    deleteMondayIntegration,
  )

  // Check if the connector is already active
  // 'active' | 'pendingBoardCreation' | 'pendingAuth' | 'inactive'
  const connectorStatus = useMemo(() => {
    if (!connectorData) return 'inactive'

    return connectorData.currentCompany.mondayIntegrationStatus
  }, [connectorData])

  // If active, fetch full connector details
  // If pending auth, save oauth details from locationState
  useEffect(() => {
    if (connectorStatus === 'active') {
      getMondayIntegrationData()
    } else if (connectorStatus === 'pendingAuth' && mondayCode) {
      saveOauth({
        variables: { code: mondayCode },
      })
    } else if (connectorStatus === 'pendingBoardCreation') {
      getWorkspaces()
    }
  }, [connectorStatus, mondayCode])

  // List all workspaces found in monday.com account
  const mondayWorkspaces = useMemo(() => {
    if (!saveOauthData && !workspacesData) return []

    const fullWorkspaces: MondayWorkspace[] = []

    if (saveOauthData) {
      fullWorkspaces.push(
        ...saveOauthData.userAccountSettings.mondayMutations.saveMondayAuth.mondayWorkspaces.map(
          (workspace) => {
            return {
              id: workspace.mondayWorkspaceId,
              name: workspace.mondayWorkspaceName,
              description: workspace.mondayWorkspaceDescription,
            }
          },
        ),
      )
    } else if (workspacesData) {
      fullWorkspaces.push(
        ...workspacesData.accountSettings.mondayQueries.getWorkspaces.mondayWorkspaces.map(
          (workspace) => {
            return {
              id: workspace.mondayWorkspaceId,
              name: workspace.mondayWorkspaceName,
              description: workspace.mondayWorkspaceDescription,
            }
          },
        ),
      )
    }

    return fullWorkspaces
  }, [saveOauthData, workspacesData])

  // Full details of active connection
  const mondayConnectionData = useMemo(() => {
    if (!mondayIntegrationData) return null

    return mondayIntegrationData.accountSettings.mondayQueries.getMondayIds
  }, [mondayIntegrationData])

  const [
    selectedWorkspace,
    setSelectedWorkspace,
  ] = useState<MondayWorkspace | null>(null)

  if (
    loadingConnectorStatus ||
    loadingIntegrationData ||
    savingOauthData ||
    loadingWorkspaces
  ) {
    return <Preloader />
  }

  if (errorFetchingWorkspaces || errorSavingOauth || errorCreatingBoard) {
    return (
      <p className={styles.errorMsg}>
        There was an error connecting to your monday.com account. Please contact
        support (<Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>
        ).
      </p>
    )
  }

  return (
    <div>
      {connectorStatus !== 'active' && mondayWorkspaces.length > 0 ? (
        <>
          <p>
            Select a workspace and we'll create a new board to hold new
            parameter requests.
          </p>
          <SelectBox
            labelKey="name"
            valueKey="name"
            placeholder="Select workspace"
            value={selectedWorkspace}
            options={mondayWorkspaces}
            onChange={(newValue) => {
              if (!newValue) return

              setSelectedWorkspace(newValue)
            }}
          />
          <Button
            loading={creatingBoard}
            isDisabled={!selectedWorkspace}
            onPress={async () => {
              if (!selectedWorkspace) return

              await createBoard({
                variables: {
                  workspaceID: selectedWorkspace.id,
                  workspaceName: selectedWorkspace.name,
                },
                refetchQueries: [getIntegrationsStatus],
              })
            }}
          >
            Create board
          </Button>
        </>
      ) : (
        <>
          {(['active', 'inactive'].indexOf(connectorStatus) > -1 ||
            !mondayCode) && (
            <>
              {connectorStatus === 'active' && (
                <p>Your account is connected to monday.com.</p>
              )}
              <p>Track requests are sent to:</p>
              <ul>
                <li>
                  <strong>Workspace:</strong>{' '}
                  {mondayConnectionData ? (
                    <>
                      {mondayConnectionData.mondayWorkspaceName} (
                      {mondayConnectionData.mondayWorkspaceId})
                    </>
                  ) : (
                    '(Not connected)'
                  )}
                </li>
                <li>
                  <strong>Board:</strong>{' '}
                  {mondayConnectionData ? (
                    <Link
                      href={`https://${mondayConnectionData.mondayUrlSlug}.monday.com/boards/${mondayConnectionData.mondayBoardId}`}
                    >
                      {mondayConnectionData.mondayBoardName} (
                      {mondayConnectionData.mondayBoardId})
                    </Link>
                  ) : (
                    '(Not connected)'
                  )}
                </li>
              </ul>
              <p>
                You can approve or reject new parameter requests directly in
                monday.com.
              </p>
              <Button
                variant="secondary"
                loading={deletingConnection}
                isDisabled={!mondayConnectionData}
                onPress={async () => {
                  await deleteConnection({
                    refetchQueries: [getIntegrationsStatus],
                  })
                }}
              >
                Remove connection
              </Button>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default MondayConnector
