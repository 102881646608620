import { gql } from '@apollo/client'
import { customAlphabet } from 'nanoid'

import { forceCacheRefresh } from './onboarding-client'
import client from '../apollo'
import { nanoIdCharacters } from '../constants'

/**
 * Aliases can't be used in type gen or hooks.
 * Must mutate directly on the client
 */
export const addUsersToAccountsBulk = async ({
  users,
  accountIDs,
  permissionLevelList,
}: {
  users: string[]
  accountIDs: string[]
  permissionLevelList: PermissionLevel[]
}) => {
  let addMutation = ``
  const alias = customAlphabet(nanoIdCharacters, 10)

  users.forEach((user) => {
    addMutation += `
      ${alias()}:addUserToAccountBulk(
        accountIDList: ${JSON.stringify(accountIDs)},
        email: "${user}",
        permissionLevelList: ${JSON.stringify(permissionLevelList)},
      ) {
          userID
          email
          inviteOpen
          userAccountProfiles {
            accountID
            accountName
            created
            expired
            inviteOpen
            lastLogin
            userEmail
            userID
            userPermission
          }
      }
    `
  })

  return client.mutate({
    mutation: gql`
        mutation AddUsersToAccountsBulk {
          userAccountSettings {
            ${addMutation}
          }
        }
      `,
    // Need to update the existing user list in cache
    update(cache, { data }) {
      if (!data) return

      accountIDs.forEach((accountID) => {
        const cacheItemID = `Account:{"accountID":"${accountID}"}`

        const { userAccountSettings } = data

        const userObjectKeys = Object.keys(userAccountSettings).filter(
          (key) => key !== '__typename',
        )

        const newUserAccountProfiles: {
          created: string
          expired: string
          inviteOpen: boolean
          lastLogin: string
          userEmail: string
          userID: string
          userPermission: string
        }[] = []

        // Each userObject represents a user (email)
        userObjectKeys.forEach((userObjectKey) => {
          const addedAccountData =
            userAccountSettings[userObjectKey]?.userAccountProfiles?.find(
              (profile) => profile.accountID === accountID,
            ) || null

          if (addedAccountData) {
            const {
              created,
              expired,
              inviteOpen,
              lastLogin,
              userEmail,
              userID,
              userPermission,
            } = addedAccountData

            newUserAccountProfiles.push({
              created,
              expired,
              inviteOpen,
              lastLogin,
              userEmail,
              userID,
              userPermission,
            })
          }
        })

        if (newUserAccountProfiles.length > 0) {
          cache.modify({
            id: cacheItemID,
            fields: {
              userAccountProfiles: (currentUserAccountProfiles) => {
                return [
                  ...currentUserAccountProfiles,
                  ...newUserAccountProfiles,
                ]
              },
            },
          })
        }
      })
    },
  })
}

export const removeUserFromAllWorkspaces = async (
  userID: string,
  workspaceIDs: string[],
) => {
  let removeMutations = ``
  const alias = customAlphabet(nanoIdCharacters, 10)

  workspaceIDs.forEach((workspaceID) => {
    removeMutations += `
      ${alias()}:removeUser(
        accountID: "${workspaceID}"
        userID: "${userID}"
      ) {
        accountID
        userAccountProfiles {
          created
          expired
          inviteOpen
          lastLogin
          userEmail
          userID
          userPermission
        }
      }
    `
  })

  return client.mutate({
    mutation: gql`
        mutation RemoveUserFromAllWorkspaces {
          userAccountSettings {
            ${removeMutations}
          }
        }
      `,
    update(cache, { data }) {
      if (!data) return

      workspaceIDs.forEach((workspaceID) => {
        const cacheItemID = `Account:{"accountID":"${workspaceID}"}`

        cache.modify({
          id: cacheItemID,
          fields: {
            userAccountProfiles: (existingUserAccountProfiles) => {
              return existingUserAccountProfiles.filter(
                (accountProfile) => accountProfile.userID !== userID,
              )
            },
          },
        })
      })
    },
  })
}

/**
 * For updating onboarding status, mutations are the same but field names differ slightly
 */
export const updateCachedOnboardingSectionsProgress = async (
  accountID: string,
  type: 'user' | 'account',
  data: {
    onboardingSectionID: string
    sectionCompleted: boolean
    sectionSkipped: boolean
    sectionSkippedBefore: boolean
  }[],
) => {
  const typeName =
    type === 'user' ? 'userOnboardingProgress' : 'accountOnboardingProgress'

  client.writeQuery({
    query: gql`
        mutation UpdateOnboardingSections {
          ${typeName} {
            ${`${typeName}List`} {
              onboardingSectionID
              sectionCompleted
              sectionSkipped
              sectionSkippedBefore
            }
          }
        }
      `,
    data: {
      [typeName]: {
        __typename: type === 'user' ? 'UserOnboarding' : 'AccountOnboarding',
        [`${typeName}List`]: data,
      },
    },
  })

  await forceCacheRefresh(accountID, type === 'account')
}
