import { useState } from 'react'
import useEvent from './useEvent'

export default function useResize(type = 'width'): number {
  const [size, setSize] = useState(
    type === 'width' ? window.innerWidth : window.innerHeight,
  )

  useEvent('resize', () => {
    const newSize = type === 'width' ? window.innerWidth : window.innerHeight
    if (size !== newSize) {
      setSize(newSize)
    }
  })

  return size
}
