import React from 'react'
import classNames from 'classnames'
import { nanoid } from 'nanoid'
import numeral from 'numeraljs'

import Button from './button'
import { ButtonRow } from './button-row'
import { SelectBoxSimple } from './select-box'
import styles from '../styles/pagination.module.scss'

interface PageProps {
  page?: number
  activePage?: number
  onChange?: any
  label?: string
  className?: string
  disabled?: boolean
}

const Page = ({
  page,
  activePage,
  onChange,
  disabled,
  label,
  className,
}: PageProps) => {
  return (
    <div
      className={classNames(className, styles.paginationElement, {
        [styles.activePagination]: page === activePage,
      })}
    >
      <Button isDisabled={disabled} color="none" onPress={() => onChange(page)}>
        {label || numeral(page).format('0,0')}
      </Button>
    </div>
  )
}

interface RowsPerPageProps {
  activePage: number
  rowsPerPage: number
  maxRowsPerPage?: 25 | 50 | 100 | 150
  totalRows: number
  onChange: (newRowsPerPage: number) => void
}

const RowsPerPage = ({
  activePage,
  rowsPerPage,
  maxRowsPerPage = 150,
  totalRows,
  onChange,
}: RowsPerPageProps) => {
  if (totalRows < 10) {
    return null
  }

  const firstItemIndex = rowsPerPage ? rowsPerPage * (activePage - 1) + 1 : 1

  return (
    <span className={styles.rowsPerPageWrapper}>
      <span className={styles.rowsPerPageSummary}>
        {numeral(firstItemIndex).format('0,0')}-
        {numeral(Math.min(totalRows, firstItemIndex + rowsPerPage - 1)).format(
          '0,0',
        )}{' '}
        of {numeral(totalRows).format('0,0')}
      </span>
      <SelectBoxSimple
        value={rowsPerPage.toString()}
        onChange={(newValue) => onChange(parseInt(newValue, 10))}
      >
        <option value="10">10</option>
        {maxRowsPerPage >= 25 && totalRows > 10 && (
          <option value="25">25</option>
        )}
        {maxRowsPerPage >= 50 && totalRows > 25 && (
          <option value="50">50</option>
        )}
        {maxRowsPerPage >= 100 && totalRows > 50 && (
          <option value="100">100</option>
        )}
        {maxRowsPerPage >= 150 && totalRows > 100 && (
          <option value="150">150</option>
        )}
      </SelectBoxSimple>
    </span>
  )
}

interface PaginationProps {
  pages: number
  activePage: number
  onChange: (newPage: number) => void
  rowsPerPageData?: {
    rowsPerPage: number
    maxRowsPerPage?: 25 | 50 | 100 | 150
    totalRows: number
    onChange: (newRowsPerPage: number) => void
  }
  className?: string
}

export default function Pagination({
  pages,
  activePage,
  onChange,
  rowsPerPageData,
  className,
}: PaginationProps) {
  let leftPart =
    activePage === 1 ? [1, 2, 3] : [activePage - 1, activePage, activePage + 1]

  let showMiddle = true

  if (activePage + 3 >= pages - 2) {
    leftPart = [pages - 6, pages - 5, pages - 4, pages - 3]
    showMiddle = false
  }

  const rightPart = [pages - 2, pages - 1, pages]

  return (
    <ButtonRow className={classNames(className, styles.paginationRow)}>
      {pages > 1 && (
        <div className={classNames(styles.paginationWrapper)}>
          <Button
            variant="secondary"
            isDisabled={activePage === 1}
            onPress={() => onChange(1)}
          >
            First
          </Button>
          <div className={styles.paginationMiddle}>
            <Button
              color="none"
              isDisabled={activePage === 1}
              className={styles.previousButton}
              onPress={() => onChange(activePage - 1)}
            />
            <div className={styles.paginationMiddleMobile}>
              <span>
                {activePage}/{pages}
              </span>
            </div>
            <div className={styles.paginationMiddlePages}>
              {leftPart.map((page: number) => {
                if (page <= 0) {
                  return null
                }
                return (
                  <Page
                    key={nanoid()}
                    page={page}
                    activePage={activePage}
                    onChange={onChange}
                  />
                )
              })}
              {showMiddle && (
                <div className={styles.paginationElement}>
                  <Button isDisabled>...</Button>
                </div>
              )}
              {rightPart.map((page: number) => {
                if (page <= 0) {
                  return null
                }
                return (
                  <Page
                    key={nanoid()}
                    page={page}
                    activePage={activePage}
                    onChange={onChange}
                  />
                )
              })}
            </div>
            <Button
              color="none"
              isDisabled={activePage === pages}
              className={styles.nextButton}
              onPress={() => onChange(activePage + 1)}
            />
          </div>
          <Button
            variant="secondary"
            isDisabled={activePage === pages}
            onPress={() => onChange(pages)}
          >
            Last
          </Button>
        </div>
      )}
      {rowsPerPageData && (
        <RowsPerPage activePage={activePage} {...rowsPerPageData} />
      )}
    </ButtonRow>
  )
}
