import { gql } from '../../__gql-types__/gql'

export const fetchGaAuthLink = gql(`
	query GetGAAuthLink($data: String!) {
		accountSettings {
			dataSourceQueries {
				gaAuthLink (state: $data)
			}
		}
	}
`)

export const handleGoogleOauthResponse = gql(`
  mutation HandleGoogleResponse (
      $code: String!
      $state: String!
    ) {
		userAccountSettings {
			googleAnalyticsMutations {
				handleGAAuthCallback(
					code: $code,
					state: $state
				) {
					creds
					state
				}
			}
		}
	}
`)

export const getAvailableGAAccounts = gql(`
	query GetAvailableGAAccounts (
		$credentials: String!
	) {
		accountSettings {
			dataSourceQueries {
				availableGAAccounts(creds: $credentials) {
					ga4AccountList {
						accountID
						accountName
						accountDisplayName
						properties {
							propertyID
							propertyName
							propertyDisplayName
						}
					}
					uaAccountList {
						accountID
						accountName
						accountDisplayName
						properties {
							propertyID
							propertyName
							propertyDisplayName
							views {
								viewID
								viewName
								viewDisplayName
							}
						}
					}
				}
			}
		}
	}
`)

export const connectGaAccount = gql(`
	mutation ConnectGAAccount (
		$accountID: String!
		$creds: String!
		$dataSourceDesc: GADescInput!
	) {
		userAccountSettings {
			connectAccountDatasourceGA (
				accountID: $accountID,
				creds: $creds,
				dataSourceDesc: $dataSourceDesc
			) {
				accountID
				accountName
				dataSource {
					kind
					connected
				}
			}
		}
	}
`)

export const connectGa4Account = gql(`
	mutation ConnectGA4Account (
		$accountID: String!
		$creds: String!
		$dataSourceDesc: GA4DescInput!
	) {
		userAccountSettings {
			connectAccountDatasourceGA4 (
				accountID: $accountID,
				creds: $creds,
				dataSourceDesc: $dataSourceDesc
			) {
				accountID
				accountName
				dataSource {
					kind
					connected
				}
			}
		}
	}
`)

export const reconnectGaAccount = gql(`
	mutation ReconnectGA4Account(
		$accountID: String!,
		$credentials: String!
	) {
		userAccountSettings {
			googleAnalyticsMutations {
				reconnectGAAccount(
					credentials: $credentials,
					accountID: $accountID
				)
			}
		}
	}
`)

export const fetchAdobeAuthLink = gql(`
	query GetAdobeAuthLink($data: String!) {
		accountSettings {
			dataSourceQueries {
				adobeAuthLink(state: $data)
			}
		}
	}
`)

export const handleAdobeOauthResponse = gql(`
	mutation HandleAdobeResponse (
      $code: String!
      $state: String!
    ) {
		userAccountSettings {
			adobeAnalyticsMutations {
				handleAAAuthCallback(
					code: $code,
					state: $state
				) {
					creds
					state
				}
			}
		}
	}
`)

export const getAvailableAAAccounts = gql(`
	query GetAvailableAAAccounts (
		$credentials: String!
	) {
		accountSettings {
			dataSourceQueries {
				availableAAAccounts (creds: $credentials) {
					aaAccountList {
          adobeCompanyName
          adobeGlobalCompanyId
          adobeImsOrgId
          reportSuites {
            adobeReportSuiteID
            adobeReportSuiteName
          }
        }
				}
			}
		}
	}
`)

export const connectAaAccount = gql(`
	mutation ConnectAAAccount (
		$accountID: String!
		$creds: String!
		$dataSourceDesc: AADescInput!
	) {
		userAccountSettings {
			connectAccountDatasourceAA(
				accountID: $accountID
				creds: $creds
				dataSourceDesc: $dataSourceDesc
			) {
				accountID
			}
		}
	}
`)

export const reconnectAaAccount = gql(`
	mutation ReconnectAAAccount(
		$accountID: String!,
		$credentials: String!
	) {
		userAccountSettings {
			adobeAnalyticsMutations {
				reconnectAAAccount(
					credentials: $credentials,
					accountID: $accountID
				)
			}
		}
	}
`)
