import React, {
  useEffect,
  useState,
  useMemo,
  Dispatch,
  SetStateAction,
  useRef,
} from 'react'
import { gql, useLazyQuery, useMutation, useReactiveVar } from '@apollo/client'
import moment from 'moment'
import classNames from 'classnames'
import numeral from 'numeraljs'
import { nanoid } from 'nanoid'

import { ButtonRow } from './button-row'
import Button, { CopyButton } from './button'
import DeleteButtonWithConfirmation from './delete-button-with-confirmation'
import Input, { Label } from './input'
import Link from './link'
import Loader, { LoadingLabel } from './loader'
import QRCodeModal from './qr-code-modal'
import ShareCampaignCodesModal, {
  ShareModalState,
} from './share-campaign-codes-button'
import Tooltip from './tooltip'
import { OuterBox, InnerBox } from './two-columns'
import { currentUserDetails, linkOrCode } from '../api/apollo/variables'
import { deleteBatchCodes } from '../api/graphql/track-actions-client'
import { getMinCodesQuick } from '../api/graphql/track-view-client'
import { GroupedCampaignCodes } from '../api/types'
import QRIcon from '../assets/icon-qr.svg'
import ShareIcon from '../assets/icon-share-white.svg'
import ShareIconHover from '../assets/icon-share.svg'
import {
  getCsvString,
  downloadSpecificCodes,
  buildMinCodesByUserList,
  getMultiCodesToCopy,
  defaultShortLinkDomain,
  getCustomDomainID,
  getDomain,
} from '../helpers/track-module'
import useCustomLinks from '../hooks/useCustomLinks'
import useLogAction from '../hooks/useLogAction'
import styles from '../styles/track-recently-created-links.module.scss'
import { DeleteBatchCodesMutationVariables } from '../__gql-types__/graphql'

const YESTERDAY = moment().subtract(1, 'days').startOf('day')

interface PreviousCodesListProps {
  grouped: GroupedCampaignCodes[]
  newLinks?: string | string[]
  onChecked: (cID: string[], status: boolean) => void
  checkedFields: string[]
  loading: boolean
  className?: string
  totalCodes: number
  setShowQrModal: (state: boolean) => void
  setQrLink: Dispatch<SetStateAction<string>>
  setDeleteError: Dispatch<SetStateAction<boolean>>
}

function PreviousCodesList({
  loading,
  grouped,
  newLinks,
  onChecked,
  checkedFields,
  className,
  totalCodes,
  setShowQrModal,
  setQrLink,
  setDeleteError,
}: PreviousCodesListProps): React.ReactElement {
  const { workspaceID } = useReactiveVar(currentUserDetails)
  const referToLinks = useReactiveVar(linkOrCode)

  const containerRef = useRef<HTMLUListElement>(null)

  const logAction = useLogAction()

  const {
    availableShortLinkDomains,
    availableAppLinkDomains,
  } = useCustomLinks()

  const [deleteCodes] = useMutation(deleteBatchCodes)

  const [codesCounter, setCodesCounter] = useState<null | number>(null)

  const firstCreatedNow =
    !!newLinks &&
    ((Array.isArray(newLinks) &&
      grouped.length > 0 &&
      newLinks.length === grouped[0].item.length) ||
      (typeof newLinks === 'string' &&
        grouped.length > 0 &&
        grouped[0].item &&
        (grouped[0].item[0].fullLink === newLinks ||
          grouped[0].item[0].shortLink === newLinks)))

  useEffect(() => {
    if (
      codesCounter === null ||
      (codesCounter !== null && codesCounter !== totalCodes)
    ) {
      setCodesCounter(totalCodes)
    }
  }, [firstCreatedNow, totalCodes])

  return (
    <>
      <Label heading>
        {loading ? (
          <LoadingLabel
            label="Retrieving links"
            className={styles.loadingHeader}
          />
        ) : (
          `Your recently created ${referToLinks}s`
        )}
      </Label>
      <ul ref={containerRef} className={classNames(className, styles.codeList)}>
        {loading ? (
          <li className={styles.codeListItem}>
            <Loader className={styles.loadingCodes} />
          </li>
        ) : (
          <>
            {grouped.length === 0 ? (
              <li
                className={classNames(styles.codeListItem, styles.noLinksFound)}
              >
                No recently created {referToLinks}s in last 30 days
              </li>
            ) : (
              <>
                {grouped.map(
                  (
                    group: GroupedCampaignCodes,
                    index: number,
                  ): React.ReactElement | null => {
                    const { item, time } = group

                    const groupID = item[0].codeID || nanoid()

                    let showTime = ''

                    const mT = moment(time)
                    const days = moment().diff(mT, 'days')
                    const isYesterday = mT.isSame(YESTERDAY, 'd')

                    if (firstCreatedNow && index === 0) {
                      showTime = 'just now'
                    } else if (isYesterday) {
                      showTime = 'yesterday'
                    } else if (days === 0) {
                      showTime = `at ${mT.format('h:mma')}`
                    } else {
                      showTime = `${mT.format('ddd Do, h:mma')}`
                    }

                    if (item.length === 1) {
                      let isSingleChecked = true
                      const cID = groupID || null
                      if (cID && checkedFields.indexOf(cID) === -1) {
                        isSingleChecked = false
                      }

                      const displayLink = item[0].fullLink.replace(
                        /(^https:\/\/www\.)|(^https:\/\/)|(^http:\/\/)/i,
                        '',
                      )

                      return (
                        <li
                          key={groupID}
                          className={classNames(styles.codeListItem, {
                            [styles.newlyCreatedLink]: showTime === 'just now',
                          })}
                        >
                          <Tooltip
                            id={`${groupID}-tooltip`}
                            className={styles.linkItemTooltipContainer}
                            tooltipClassName={styles.fullLinkTooltip}
                            clickable
                            maxWidth={300}
                            tooltipPosition="left"
                            tooltipMessage={<p>{item[0].fullLink}</p>}
                          >
                            <div className={styles.linkItem}>
                              <Input
                                type="checkbox"
                                id={groupID}
                                name="selectItem"
                                checked={isSingleChecked}
                                className={styles.linkCheckbox}
                                label=" "
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                  const {
                                    checked,
                                  } = e.target as HTMLInputElement
                                  if (cID) {
                                    onChecked([cID], checked)
                                  }
                                }}
                              />
                              <div className={styles.hoverable}>
                                {item[0].shortLink === '' ? (
                                  <span className={styles.linkCode}>
                                    {displayLink}
                                  </span>
                                ) : (
                                  <span className={styles.linkCode}>
                                    {item[0].shortLink}
                                  </span>
                                )}
                              </div>
                              <div className={styles.inlineDate}>
                                <span>{showTime}</span>
                              </div>
                            </div>
                          </Tooltip>
                          <div className={styles.buttonsContainer}>
                            <Button
                              variant="iconOnly"
                              className={styles.qrButton}
                              icon={{
                                src: QRIcon,
                                alt: 'QR code',
                              }}
                              onPress={() => {
                                const linkObj = item[0]

                                setQrLink(
                                  linkObj.shortLink || linkObj.fullLink || '',
                                )
                                setShowQrModal(true)
                              }}
                            />
                            <CopyButton
                              className={styles.copyButton}
                              value={
                                item[0].shortLink === ''
                                  ? item[0].fullLink
                                  : item[0].shortLink
                              }
                            />
                            <DeleteButtonWithConfirmation
                              containerRef={containerRef}
                              buttonClassName={styles.deleteButton}
                              confirmMessage="Delete link?"
                              onConfirm={async () => {
                                const deleteObject: DeleteBatchCodesMutationVariables = {
                                  codeIDList: [groupID],
                                }

                                const deepLinkServiceID =
                                  availableAppLinkDomains.find(
                                    ({ domainName }) =>
                                      item[0].shortLink.includes(domainName),
                                  )?.domainID || null

                                if (deepLinkServiceID) {
                                  deleteObject.deepLinkServiceID = deepLinkServiceID
                                } else {
                                  const found = availableShortLinkDomains.find(
                                    ({ domainName }) =>
                                      domainName ===
                                      (getCustomDomainID(
                                        getDomain(item[0].shortLink).replace(
                                          'https://',
                                          '',
                                        ),
                                      ) || defaultShortLinkDomain),
                                  )

                                  if (found) {
                                    deleteObject.customDomainID = getCustomDomainID(
                                      found.domainID,
                                    )
                                  }
                                }

                                const { errors } = await deleteCodes({
                                  variables: deleteObject,
                                  optimisticResponse: {
                                    track: {
                                      deleteCodes: [groupID],
                                      __typename: 'TrackMutations',
                                    },
                                  },
                                  update(cache, { data }) {
                                    if (data) {
                                      const {
                                        track: { deleteCodes: deletedCodes },
                                      } = data

                                      const cacheItemID = `MinimalCodeList:{"accountID":"${workspaceID}","linkID":""}`

                                      // Get full codeID list from cache
                                      const cachedData: {
                                        codeID: string[]
                                      } | null = cache.readFragment({
                                        id: cacheItemID,
                                        fragment: gql`
                                          fragment CodeID on MinimalCodeList {
                                            codeID
                                          }
                                        `,
                                      })

                                      if (cachedData) {
                                        const {
                                          codeID: cachedCodeIDs,
                                        } = cachedData

                                        // Get index of code to delete
                                        const existingCodeIndex = cachedCodeIDs.indexOf(
                                          deletedCodes[0],
                                        )

                                        if (existingCodeIndex !== undefined) {
                                          const spliceFn = (
                                            inputArray: string[],
                                          ) => {
                                            const outArray = [...inputArray]

                                            outArray.splice(
                                              existingCodeIndex,
                                              1,
                                            )

                                            return outArray
                                          }

                                          // Remove deleted code from cached item's fields
                                          cache.modify({
                                            id: cacheItemID,
                                            fields: {
                                              author(existingCodes = []) {
                                                return spliceFn(existingCodes)
                                              },
                                              codeDef(existingCodes = []) {
                                                return spliceFn(existingCodes)
                                              },
                                              codeID(existingCodes = []) {
                                                return spliceFn(existingCodes)
                                              },
                                              createdTime(existingCodes = []) {
                                                return spliceFn(existingCodes)
                                              },
                                              fullLink(existingCodes = []) {
                                                return spliceFn(existingCodes)
                                              },
                                              shortLink(existingCodes = []) {
                                                return spliceFn(existingCodes)
                                              },
                                              versionNumber(
                                                existingCodes = [],
                                              ) {
                                                return spliceFn(existingCodes)
                                              },
                                              totalCodes(existing = 0) {
                                                return Math.max(
                                                  existing -
                                                    deletedCodes.length,
                                                  0,
                                                )
                                              },
                                            },
                                          })
                                        }
                                      }
                                    }
                                  },
                                })

                                if (errors) {
                                  setDeleteError(true)
                                  return
                                }

                                logAction({
                                  variables: {
                                    action: 'bulk-delete-codes',
                                    functionName: 'bulkDelete',
                                    pagePath: '/track/create-links',
                                    websiteSection: 'track',
                                    extra: JSON.stringify(deleteObject),
                                  },
                                })
                              }}
                            />
                          </div>
                        </li>
                      )
                    }

                    const { code, all: toCopy } = getMultiCodesToCopy(item)

                    // At least one item in the list needs to be checked
                    const checkedItems = item.filter(
                      (i) => i.codeID && checkedFields.indexOf(i.codeID) !== -1,
                    )
                    const isMultipleChecked = checkedItems.length > 0

                    return (
                      <li
                        key={groupID}
                        className={classNames(styles.codeListItem, {
                          [styles.newlyCreatedLink]: showTime === 'just now',
                        })}
                      >
                        <div className={styles.linkItem}>
                          <Input
                            type="checkbox"
                            id={groupID}
                            name="selectItem"
                            checked={isMultipleChecked}
                            className={styles.linkCheckbox}
                            label=" "
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              const { checked } = e.target as HTMLInputElement
                              const cIDs = item
                                .map((i) => i.codeID || '')
                                .filter((c) => c !== '')
                              onChecked(cIDs, checked)
                            }}
                          />
                          <span className={styles.linkCode}>
                            {numeral(code.length).format('0,0')} links created{' '}
                          </span>
                          <div className={styles.inlineDate}>
                            <span>{showTime}</span>
                          </div>
                        </div>
                        <div className={styles.buttonsContainer}>
                          <CopyButton
                            className={styles.copyButton}
                            value={toCopy}
                          />
                          <DeleteButtonWithConfirmation
                            containerRef={containerRef}
                            buttonClassName={styles.deleteButton}
                            confirmMessage={`Delete ${item.length} links?`}
                            onConfirm={async () => {
                              const deleteObject: DeleteBatchCodesMutationVariables = {
                                codeIDList: item.map(({ codeID }) => codeID),
                              }

                              const deepLinkServiceID =
                                availableAppLinkDomains.find(({ domainName }) =>
                                  item[0].shortLink.includes(domainName),
                                )?.domainID || null

                              if (deepLinkServiceID) {
                                deleteObject.deepLinkServiceID = deepLinkServiceID
                              } else {
                                const found = availableShortLinkDomains.find(
                                  ({ domainName }) =>
                                    domainName ===
                                    (getCustomDomainID(
                                      getDomain(item[0].shortLink).replace(
                                        'https://',
                                        '',
                                      ),
                                    ) || defaultShortLinkDomain),
                                )

                                if (found) {
                                  deleteObject.customDomainID = getCustomDomainID(
                                    found.domainID,
                                  )
                                }
                              }

                              const { errors } = await deleteCodes({
                                variables: deleteObject,
                                optimisticResponse: {
                                  track: {
                                    deleteCodes: item.map(
                                      ({ codeID }) => codeID,
                                    ),
                                    __typename: 'TrackMutations',
                                  },
                                },
                                update(cache, { data }) {
                                  if (data) {
                                    const {
                                      track: { deleteCodes: deletedCodes },
                                    } = data

                                    const cacheItemID = `MinimalCodeList:{"accountID":"${workspaceID}","linkID":""}`

                                    // Get full codeID list from cache
                                    const cachedData: {
                                      codeID: string[]
                                    } | null = cache.readFragment({
                                      id: cacheItemID,
                                      fragment: gql`
                                        fragment CodeID on MinimalCodeList {
                                          codeID
                                        }
                                      `,
                                    })

                                    if (cachedData) {
                                      const {
                                        codeID: cachedCodeIDs,
                                      } = cachedData

                                      const existingCodeIndexes = deletedCodes.map(
                                        (value) => cachedCodeIDs.indexOf(value),
                                      )

                                      const spliceFn = (
                                        inputArray: string[],
                                      ) => {
                                        const outArray = inputArray.filter(
                                          (val, valIndex) =>
                                            !existingCodeIndexes.includes(
                                              valIndex,
                                            ),
                                        )

                                        return outArray
                                      }

                                      // Remove deleted code from cached item's fields
                                      cache.modify({
                                        id: cacheItemID,
                                        fields: {
                                          author(existingCodes = []) {
                                            return spliceFn(existingCodes)
                                          },
                                          codeDef(existingCodes = []) {
                                            return spliceFn(existingCodes)
                                          },
                                          codeID(existingCodes = []) {
                                            return spliceFn(existingCodes)
                                          },
                                          createdTime(existingCodes = []) {
                                            return spliceFn(existingCodes)
                                          },
                                          fullLink(existingCodes = []) {
                                            return spliceFn(existingCodes)
                                          },
                                          shortLink(existingCodes = []) {
                                            return spliceFn(existingCodes)
                                          },
                                          versionNumber(existingCodes = []) {
                                            return spliceFn(existingCodes)
                                          },
                                          totalCodes(existing = 0) {
                                            return Math.max(
                                              existing - deletedCodes.length,
                                              0,
                                            )
                                          },
                                        },
                                      })
                                    }
                                  }
                                },
                              })

                              if (errors) {
                                setDeleteError(true)
                                return
                              }

                              logAction({
                                variables: {
                                  action: 'bulk-delete-codes',
                                  functionName: 'bulkDelete',
                                  pagePath: '/track/create-links',
                                  websiteSection: 'track',
                                  extra: JSON.stringify(deleteObject),
                                },
                              })
                            }}
                          />
                        </div>
                      </li>
                    )
                  },
                )}
              </>
            )}
          </>
        )}
      </ul>
    </>
  )
}

interface RecentlyCreatedLinksProps {
  isStatic?: boolean
  newLinks?: string | string[]
}

export default function RecentlyCreatedLinks({
  isStatic,
  newLinks,
}: RecentlyCreatedLinksProps) {
  const { userEmail } = useReactiveVar(currentUserDetails)
  const referToLinks = useReactiveVar(linkOrCode)

  const [
    fetchUserLinks,
    { data: userLinksData, loading: loadingUsersCodesStatus },
  ] = useLazyQuery(getMinCodesQuick, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  })

  const [allCurrentCodeIds, setAllCurrentCodeIds] = useState<string[]>([])
  const [checkedFields, setCheckedFields] = useState<string[]>([])
  const [mounted, setMounted] = useState(false)
  const [showQrModal, setShowQrModal] = useState(false)
  const [qrLink, setQrLink] = useState('')
  const [deleteError, setDeleteError] = useState(false)

  const [shareModalState, setShareModalState] = useState<ShareModalState>({
    active: false,
    shared: false,
    typedValue: '',
    shareEmails: [],
    note: '',
    subject: '',
  })

  useEffect(() => {
    if (isStatic || !userEmail) return

    fetchUserLinks({
      variables: {
        dimensionFilter: {
          dimensionName: userEmail,
          dimensionParameterID: 'createdBy',
          dimensionOptions: [],
        },
        startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
        endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
        limit: 1000,
      },
    })
  }, [isStatic, userEmail])

  const orderedUserLinks = useMemo(() => {
    if (!userLinksData) return []

    return buildMinCodesByUserList(userLinksData.track.minCodesQuick)
  }, [userLinksData])

  // Group codes by their batch ID
  // Or by the time they were created
  const grouped: GroupedCampaignCodes[] = useMemo(() => {
    if (orderedUserLinks.length === 0) {
      return []
    }

    const chunked: {
      [key: string]: GroupedCampaignCodes
    } = {}

    orderedUserLinks.forEach((item) => {
      const { createdTime } = item
      const key = createdTime.slice(0, 19) // remove seconds

      if (Object.prototype.hasOwnProperty.call(chunked, key)) {
        chunked[key] = {
          item: [...chunked[key].item, item],
          time: createdTime,
        }
      } else {
        chunked[key] = {
          item: [item],
          time: createdTime,
        }
      }
    })

    const codesOrdered: GroupedCampaignCodes[] = []

    Object.keys(chunked)
      .slice(0, 10)
      .forEach((key: string) => {
        codesOrdered.push(chunked[key])
      })

    const allCodeIds: string[] = []

    codesOrdered.forEach(({ item }) => {
      item.forEach((i) => {
        if (i.codeID) allCodeIds.push(i.codeID)
      })
    })

    if (!mounted) {
      setMounted(true)
      setCheckedFields(allCodeIds)
      setAllCurrentCodeIds(allCodeIds)
    } else {
      const newIn = allCodeIds.filter(
        (i) => allCurrentCodeIds.indexOf(i) === -1,
      )

      const oldOut = allCurrentCodeIds.filter(
        (i) => allCodeIds.indexOf(i) === -1,
      )

      if (newIn.length > 0 || oldOut.length > 0) {
        const newFields = [
          ...checkedFields.filter((i) => oldOut.indexOf(i) === -1),
          ...newIn,
        ]
        setCheckedFields(newFields)
        setAllCurrentCodeIds(allCodeIds)
      }
    }

    return codesOrdered
  }, [orderedUserLinks])

  // Remove delete error after timeout
  useEffect(() => {
    if (deleteError) {
      setTimeout(() => {
        setDeleteError(false)
      }, 2000)
    }
  }, [deleteError])

  return (
    <>
      <OuterBox>
        <InnerBox>
          <PreviousCodesList
            setQrLink={(link) => setQrLink(link)}
            setShowQrModal={(state) => setShowQrModal(state)}
            totalCodes={orderedUserLinks.length}
            loading={loadingUsersCodesStatus}
            checkedFields={checkedFields}
            onChecked={(cIDs, checked) => {
              let useFields = [...checkedFields]
              cIDs.forEach((item) => {
                if (checked) {
                  if (checkedFields.indexOf(item) === -1) {
                    useFields = [...useFields, item]
                  }
                } else {
                  useFields = useFields.filter((cID) => !(item === cID))
                }
              })
              setCheckedFields(useFields)
            }}
            grouped={grouped}
            newLinks={newLinks}
            setDeleteError={setDeleteError}
          />
          {deleteError && (
            <p className={styles.deleteError}>
              Unable to delete codes. Please try again later.
            </p>
          )}
          <ButtonRow className={styles.actionButtons} centerAlign>
            <Link type="arrowForward" href="/track/view-links" newTab={false}>
              View all {referToLinks}s
            </Link>
            <span className={styles.buttonWrapper}>
              <Button
                variant="secondary"
                loading={loadingUsersCodesStatus}
                isDisabled={
                  loadingUsersCodesStatus ||
                  checkedFields.length === 0 ||
                  orderedUserLinks.length === 0
                }
                onPress={async () => {
                  const csv = getCsvString({
                    userEmail,
                    codes: orderedUserLinks,
                    selectedCodes: checkedFields,
                  })

                  await downloadSpecificCodes(csv)
                }}
              >
                Download
              </Button>
              <Button
                loading={loadingUsersCodesStatus}
                isDisabled={
                  loadingUsersCodesStatus ||
                  checkedFields.length === 0 ||
                  orderedUserLinks.length === 0
                }
                className={styles.shareButton}
                icon={{
                  src: ShareIcon,
                  alt: 'Share',
                  iconAfter: true,
                  hoverImg: ShareIconHover,
                  imgHeight: 18,
                }}
                onPress={() => {
                  setShareModalState({
                    active: true,
                    shared: false,
                    typedValue: '',
                    shareEmails: [],
                    note: '',
                    subject: '',
                  })
                }}
              >
                Share
              </Button>
            </span>
          </ButtonRow>
        </InnerBox>
      </OuterBox>
      {showQrModal && (
        <QRCodeModal code={qrLink} setShowModal={setShowQrModal} />
      )}
      {shareModalState.active && checkedFields.length > 0 && (
        <ShareCampaignCodesModal
          selectedCodes={checkedFields}
          shareModalState={shareModalState}
          setShareModalState={setShareModalState}
        />
      )}
    </>
  )
}
