import React, { useState, useMemo } from 'react'
import { useReactiveVar } from '@apollo/client'
import classNames from 'classnames'
import moment from 'moment'

import AnomalyDiff from './anomaly-diff'
import Button from './button'
import Row, { Slot } from './row'
import {
  explainAnomalyDataReactive,
  explainDimensionsDataReactive,
} from '../api/apollo/legacy-reducers'
import explainTrafficIcon from '../assets/explain-traffic-icon.svg'
import explainContentIcon from '../assets/explain-content-icon.svg'
import explainUserIcon from '../assets/explain-user-icon.svg'
import Filter, { FilterTypes } from './anomaly-filter'
import { getItemByKeyValue, getDimensionTitle } from '../helpers'
import useMobile from '../hooks/useMobile'
import styles from '../styles/anomaly-breakdown-table.module.scss'

export function AnomalyType({
  type,
}: {
  type: null | 'explained' | 'not-interesting'
}): React.ReactElement {
  switch (type) {
    case 'explained':
      return (
        <p className={classNames(styles.status, styles.statusHasInsights)}>
          Insights
        </p>
      )
    case 'not-interesting':
      return (
        <p className={classNames(styles.status, styles.statusNotInteresting)}>
          Not interesting
        </p>
      )
    default:
      return (
        <p className={classNames(styles.status, styles.statusUnexplained)}>
          Unexplained anomaly
        </p>
      )
  }
}

interface AnomalyBreakdownRowProps {
  item: Anomaly
  onMetricClick?: any
  active?: boolean
  highlighted?: boolean
  type: null | 'explained' | 'not-interesting'
  invertPolarity?: boolean
  metricName: string
}

export function AnomalyBreakdownRow({
  item,
  onMetricClick,
  active = false,
  highlighted = false,
  type,
  invertPolarity = false,
  metricName,
}: AnomalyBreakdownRowProps): React.ReactElement {
  const { actual, expected, date } = item
  const fDate = moment(date, 'DD/MM/YYYY').format('ddd Do MMM YYYY')

  const c = classNames(styles.container, {
    [styles.active]: active,
    [styles.highlighted]: highlighted,
  })
  return (
    <>
      <div className={c}>
        <div
          className={classNames(styles.listingRow, styles.listingRowColumns)}
          onClick={() => {
            if (onMetricClick) {
              onMetricClick()
            }
          }}
          role="presentation"
          tabIndex={-1}
        >
          <div className={styles.column}>
            <AnomalyDiff
              arrowOnly
              invertPolarity={invertPolarity}
              actual={actual}
              expected={expected}
              compact
              largeIcon
            />
            <p className={styles.rowTitle}>
              <span
                className={styles.rowTitleDate}
                dangerouslySetInnerHTML={{
                  __html: fDate.replace(
                    /(\d)(st|nd|rd|th)/g,
                    '$1<sup>$2</sup>',
                  ),
                }}
              />
            </p>
            <AnomalyDiff
              inline
              roundNumber
              noIcon
              invertPolarity={invertPolarity}
              actual={actual}
              expected={expected}
            />
            <p className={styles.rowTitle}>
              <span className={styles.rowTitleDirection}>
                {actual > expected ? 'more' : 'less'}{' '}
              </span>
              <span className={styles.rowTitleName}>{metricName} </span>
              <span className={styles.rowTitleDirection}>than expected </span>
            </p>
          </div>
          <div className={styles.column}>
            <AnomalyType type={type} />
          </div>
        </div>
      </div>
    </>
  )
}

interface AnomalyBreakdownTableListingRowProps {
  a: number
  e: number
  mDN: string
  t?: null | FilterTypes
  metric: string
  metricName: string
  onMetricClick?: any
  gaDimensions?: any
  setSelected: boolean
  invertPolarity?: boolean
  viewOnly?: boolean
}

const typeIcons = {
  all: '',
  user: explainUserIcon,
  content: explainContentIcon,
  traffic: explainTrafficIcon,
}

export function AnomalyBreakdownTableListingRow({
  a: actual,
  e: expected,
  mDN,
  t: type,
  metric,
  onMetricClick,
  metricName,
  gaDimensions = null,
  setSelected,
  invertPolarity = false,
  viewOnly = false,
}: AnomalyBreakdownTableListingRowProps) {
  const [active, setActive] = useState(setSelected)

  const c = classNames(styles.container, {
    [styles.active]: active,
  })

  return (
    <div className={c}>
      <div
        className={classNames(styles.listingRow, styles.listingRowWithStar, {
          [styles.viewOnly]: viewOnly,
        })}
        onClick={() => {
          if (viewOnly) return

          if (onMetricClick) {
            onMetricClick({
              a: actual,
              e: expected,
              mDN,
              metric,
              t: type,
              add: !active,
            })
          }
          setActive((s: boolean) => !s)
        }}
        role="presentation"
        tabIndex={-1}
      >
        {type && (
          <img
            src={typeIcons?.[type] || ''}
            className={styles.explainIcon}
            alt="explain icon"
          />
        )}
        <AnomalyDiff
          invertPolarity={invertPolarity}
          actual={actual}
          expected={expected}
          compact
          largeIcon
        />
        <p className={styles.rowTitle}>
          {metricName} <span className={styles.divider}>where</span>{' '}
          {getDimensionTitle(mDN, gaDimensions)}
        </p>
      </div>
    </div>
  )
}

interface AnomalyBreakdownTableProps {
  id: string
  causes?: any
  metric: string
  onMetricClick?: any
  selected: AnomalyBreakdownDimension[]
  increaseSentimentPositive: boolean
  viewOnly?: boolean
}

export default function AnomalyBreakdownTable({
  causes,
  id,
  metric,
  onMetricClick,
  selected,
  increaseSentimentPositive,
  viewOnly,
}: AnomalyBreakdownTableProps) {
  const explainDimensionsData = useReactiveVar(explainDimensionsDataReactive)
  const explainAnomalyData = useReactiveVar(explainAnomalyDataReactive)

  const isMobile = useMobile(769)

  const [filter, setFilter] = useState<FilterTypes>('all')
  const [showCount, setShowCount] = useState(isMobile ? 5 : 20)

  const nextCauses = useMemo(
    () => {
      const result = causes.filter((cause: AnomalyBreakdownDimension) => {
        //  exclude non-tracked anomalies
        if (cause.a === 0 && cause.e === 0) {
          return false
        }
        if (filter === 'all') return true
        if (filter === cause.t) {
          return true
        }
        return false
      })
      if (selected && selected.length > 0) {
        return result
          .filter((item) => {
            return (
              getItemByKeyValue(selected, 'mDN', item.mDN, false, true) !== -1
            )
          })
          .concat(
            result.filter((item) => {
              return (
                getItemByKeyValue(selected, 'mDN', item.mDN, false, true) === -1
              )
            }),
          )
      }
      return result
    },
    // eslint-disable-next-line
    [filter, causes, selected],
  )

  return (
    <div>
      <div className={styles.upperRow}>
        <p className={styles.para}>
          {viewOnly
            ? 'Interesting/top dimensions changes'
            : 'Select interesting dimensions'}
        </p>
        <Filter setFilter={setFilter} filter={filter} showIcon={false} tabbed />
      </div>
      {nextCauses.slice(0, showCount).map((cause: any) => {
        const setSelected =
          selected && getItemByKeyValue(selected, 'mDN', cause.mDN) !== -1
        return (
          <AnomalyBreakdownTableListingRow
            viewOnly={viewOnly}
            key={`${cause.mDN}${setSelected}`}
            {...cause}
            metric={metric}
            metricName={explainAnomalyData?.description?.displayName}
            id={id}
            setSelected={setSelected}
            onMetricClick={onMetricClick}
            gaDimensions={explainDimensionsData.gaDimensions}
            invertPolarity={!increaseSentimentPositive}
          />
        )
      })}
      {showCount < nextCauses.length && (
        <Row>
          <Slot width={200}>
            <Button
              onPress={() =>
                setShowCount(isMobile ? showCount + 5 : nextCauses.length)
              }
            >
              Load more ({showCount.toString()} of{' '}
              {nextCauses.length.toString()})
            </Button>
          </Slot>
        </Row>
      )}
    </div>
  )
}
