import client from '../apollo'
import { gql } from '../../__gql-types__/gql'

export const forceCacheRefresh = async (
  accountID: string,
  applyToAccount: boolean,
) => {
  const mutation = gql(`
    mutation ForceCacheRefresh($accID: String, $applyToAccount: Boolean!) {
      forceCacheRefresh(
        task: "refresh_onboarding_cache"
        applyToAccount: $applyToAccount
        accountID: $accID
      )
    }
  `)

  return client.mutate({
    mutation,
    variables: { accID: accountID, applyToAccount },
  })
}

export const getOnboardingSectionsProgress = gql(`
  query GetOnboardingSections {
    userOnboardingProgress {
      userOnboardingProgressList {
        onboardingSectionID
        sectionCompleted
        sectionSkipped
        sectionSkippedBefore
      }
    }
    accountOnboardingProgress {
      accountOnboardingProgressList {
        onboardingSectionID
        sectionCompleted
        sectionSkipped
        sectionSkippedBefore
      }
    }
  }
`)

export const sendFeedback = gql(`
  mutation SendFeedback(
      $message: String!
      $page: String!
      $feedbackContext: FeedbackContext!
    ) {
      userAccountSettings {
        sendFeedback(
          message: $message
          page: $page
          feedbackContext: $feedbackContext
        ) {
          userID
          email
          fullName
          curAccountID
        }
      }
    }
`)
