import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTransition, animated } from 'react-spring'

import styles from '../styles/carousel.module.scss'

interface Props extends React.AllHTMLAttributes<any> {
  items: any[]
  currentProgress: number
  width?: number
  style?: any
}

export default function Carousel({
  items,
  currentProgress,
  width,
  style = {},
}: Props): React.ReactElement {
  const history = useHistory()

  const [storeItems] = useState(items)

  const transitions = useTransition(
    storeItems[currentProgress],
    (item: any): string => item.key,
    {
      from: { transform: `translate3d(${100}vw,0,0)`, margin: '0 auto' },
      enter: { transform: 'translate3d(0vw,0,0)', margin: '0 auto' },
      leave: { transform: `translate3d(-${100}vw,0,0)`, margin: '0 auto' },
    },
  )

  return (
    <div className={styles.container} {...{ style }}>
      {transitions.map(
        ({ item, props, key }): React.ReactElement => (
          <animated.div key={key} style={props} className={styles.items}>
            <item.component history={history} {...{ width, style }} />
          </animated.div>
        ),
      )}
    </div>
  )
}
