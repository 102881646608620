import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import Button from './button'
import Input from './input'
import Link from './link'
import SiteLogo, { LogoTagline } from './site-logo'
import useAuthenticate from '../hooks/useAuthenticate'
import styles from '../styles/forgot-password.module.scss'

export default function ForgotPasswordForm(): React.ReactElement {
  const { resetPasswordRequest } = useAuthenticate()

  const history = useHistory()

  const [email, setEmail] = useState('')

  return (
    <div>
      <SiteLogo link className={styles.svg} />
      <LogoTagline />
      <div className={styles.divider} />
      <form
        onSubmit={async (e: React.SyntheticEvent) => {
          e.preventDefault()

          await resetPasswordRequest(email)
        }}
      >
        <Input
          type="email"
          name="email"
          label="Email"
          onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
            setEmail(event.target.value)
          }
          value={email}
          required
        />
        <Button type="submit">Request password reset</Button>
        <div className={styles.divider} />
        <h2>Don't have an account?</h2>
        <Button
          color="grey"
          style={{ marginBottom: 16 }}
          onPress={() => history.push('/create-account')}
        >
          Sign up here
        </Button>
        <Link
          className={styles.link}
          href="https://support.uplifter.ai/hc/en-us/articles/360018765078-I-can-t-login-to-Uplifter"
        >
          Need help logging in?
        </Link>
      </form>
    </div>
  )
}
