import React from 'react'
import { useReactiveVar } from '@apollo/client'
import { useHistory } from 'react-router-dom'

import Button from './button'
import Message from './login-message'
import SiteLogo from './site-logo'
import { Heading } from './typography'
import { passwordState } from '../api/apollo/variables'
import { supportEmail } from '../core/constants'
import styles from '../styles/forgot-password.module.scss'

export default function Result(): React.ReactElement {
  const currentPasswordState = useReactiveVar(passwordState)

  const history = useHistory()

  if (currentPasswordState.resetPassword.success) {
    return (
      <div>
        <SiteLogo link className={styles.svg} />
        <Heading type={2}>Your new password has been set</Heading>
        <Button className={styles.link} onPress={() => history.push('/')}>
          Login
        </Button>
      </div>
    )
  }

  return (
    <div>
      <SiteLogo link className={styles.svg} />
      <h2>Your password has not been updated.</h2>
      <div className={styles.divider} />
      <Message
        message={`Please try [resetting your password](/forgot-password) again.  
If you are still experiencing issues, please contact us by emailing [${supportEmail}](mailto:${supportEmail}).`}
      />
    </div>
  )
}
