import React from 'react'
import ReactMarkdown from 'react-markdown'
import classNames from 'classnames'

import styles from '../styles/sso.module.scss'

interface MessageProps {
  className?: string
  message: string
}

export default function Message({
  className,
  message,
}: MessageProps): React.ReactElement {
  return (
    <div className={classNames(className, styles.warningMessage)}>
      <ReactMarkdown source={message} />
    </div>
  )
}
