import { gql } from '../../__gql-types__/gql'

export const getCompanyUsers = gql(`
	query GetCompanyUsers {
		currentCompany {
			accountCount
			accountList {
				accountID
				accountName
				userAccountProfiles {
					created
					expired
					inviteOpen
					lastLogin
					userEmail
					userID
					userPermission
				}
			}
			companyID
			companyName
			userCount
			userLimit
		}
	}	
`)

export const addUserToAccountBulk = gql(`
	mutation AddUserToAccountBulk (
		$accountIDList: [String!]!
		$email: String!
		$permissionLevelList: [String!]!
	) {
		userAccountSettings {
			addUserToAccountBulk(
				accountIDList: $accountIDList
				email: $email
				permissionLevelList: $permissionLevelList
			) {
				email
				userID
				inviteOpen
				userAccountProfiles {
					created
					expired
					inviteOpen
					lastLogin
					userID
					userEmail
					userPermission
				}
			}
		}
	}	
`)

export const revokeUserInvite = gql(`
	mutation RevokeUserInvite($userID: String!) {
		userAccountSettings {
			revokeUserInvite(userID: $userID)
		}
	}
`)

export const updateUserPermissions = gql(`
	mutation UpdateUserPermissions(
		$accountID: String!
		$userID: String!
		$permissionLevel: String!
	) {
		userAccountSettings {
			updateUserPermissionLevel(
				accountID: $accountID
				permissionLevel: $permissionLevel
				userID: $userID
			) {
				email
				userID
			}
		}
	}
`)

export const removeExistingUser = gql(`
	mutation RemoveUser(
		$accountID: String!,
		$userID: String!
	) {
		userAccountSettings {
			removeUser(
				accountID: $accountID,
				userID: $userID
			) {
				accountID
				userAccountProfiles {
					created
					expired
					inviteOpen
					lastLogin
					userEmail
					userID
					userPermission
				}
			}
		}
	}
`)
