import React, { useEffect, useMemo, useState } from 'react'
import { useLazyQuery, useReactiveVar } from '@apollo/client'

import { currentUserDetails, linkOrCode } from '../api/apollo/variables'
import { getCampaignCodeGenerator } from '../api/graphql/track-create-client'
import BulkImport from '../components/bulk-import'
import ButtonTabs from '../components/button-tabs'
import TrackCreateCloneAndEdit from '../components/track-create-clone-and-edit'
import CampaignIntroContent from '../components/campaign-intro-content'
import Intro from '../components/intro'
import Layout from '../components/layout'
import Link from '../components/link'
import { NewEmailHTMLPreview } from '../components/new-email-html-preview'
import RequestField from '../components/request-field'
import SiteWrapper from '../components/site-wrapper'
import TrackCreateFormEmail from '../components/track-create-form-email'
import TrackCreateFormWeb from '../components/track-create-form-web'
import RecentlyCreatedLinks from '../components/track-recently-created-links'
import { ShowLatestCode } from '../components/track-show-latest-link'
import TwoColumns, { Column } from '../components/two-columns'
import {
  getTrackCreateFormData,
  isSavedFormType,
  saveTrackCreateTab,
  trackCreateTabs,
} from '../helpers/track-create'
import useLogAction from '../hooks/useLogAction'
import useOnboarding from '../hooks/useOnboarding'
import useTrackCreateSavedValues from '../hooks/useTrackCreateSavedValues'
import styles from '../styles/track-create.module.scss'

interface TrackCreateIntroProps {
  hasCreatedCode?: boolean
}

export function TrackCreateIntro({
  hasCreatedCode = false,
}: TrackCreateIntroProps) {
  const referToLinks = useReactiveVar(linkOrCode)

  return (
    <Intro title={`Create ${referToLinks}s`}>
      <CampaignIntroContent firstTime={!hasCreatedCode}>
        <p>
          Create {referToLinks}s here to help you track and understand campaign
          traffic. &nbsp;
          <Link type="arrowForward" href="/track/learn">
            Learn more
          </Link>
        </p>
      </CampaignIntroContent>
    </Intro>
  )
}

export default function TrackCreate() {
  const { workspaceID, userID } = useReactiveVar(currentUserDetails)

  const logAction = useLogAction()

  const {
    fullOnboardingSections: { user: userOnboardingSections },
  } = useOnboarding()

  const [
    getGenerator,
    { data: generatorData, loading: loadingGenerator, error: generatorError },
  ] = useLazyQuery(getCampaignCodeGenerator)

  // Wait for account ID so generator can be cached
  useEffect(() => {
    if (!workspaceID) return

    getGenerator()
  }, [workspaceID])

  const generatedStructure = useMemo(() => {
    if (!generatorData) return null

    return generatorData.campaignCodeGenerator
  }, [generatorData])

  const {
    formValues,
    updateFormValues,
    formSubmissionState,
    setFormSubmissionState,
  } = useTrackCreateSavedValues(generatedStructure)

  const [currentTab, setCurrentTab] = useState<keyof typeof trackCreateTabs>(
    'web',
  )
  const [previousEmailHtml, setPreviousEmailHtml] = useState('')

  // Set initial tab based on localStorage
  // And populate email HTML
  useEffect(() => {
    if (!userID || !workspaceID || !generatedStructure) return

    const savedFormData = getTrackCreateFormData(workspaceID)

    const { active: activeTab } = savedFormData.options

    setCurrentTab(isSavedFormType(activeTab) ? activeTab : 'web')

    // Update form with saved data if present
    if (isSavedFormType(activeTab)) {
      updateFormValues(activeTab, savedFormData[activeTab], {
        resetSubmissionState: true,
      })
    }

    if (activeTab === 'email') {
      const { generatedEmailHtml: prevEmail } =
        savedFormData?.email?.emailFields || {}

      setPreviousEmailHtml(prevEmail || '')
    }
  }, [userID, workspaceID, generatedStructure])

  // Check if onboarding step is completed
  const hasCreatedCode = useMemo(() => {
    const createCampaignLinkSection = userOnboardingSections.find(
      (section) => section.onboardingSectionID === 'createCampaignLink',
    )

    return !!(
      createCampaignLinkSection && createCampaignLinkSection.sectionCompleted
    )
  }, [userOnboardingSections])

  // Used for previewing links on success
  const [newLinks, setNewLinks] = useState<string | string[]>('')
  const [newEmailHtml, setNewEmailHtml] = useState<GeneratedEmailHTML | null>(
    null,
  )
  const [isIntegrationEmail, setIsIntegrationEmail] = useState(false)

  return (
    <SiteWrapper>
      <Layout width={1200}>
        <TrackCreateIntro hasCreatedCode={hasCreatedCode} />
        <TwoColumns>
          <Column main>
            <div>
              <ButtonTabs
                className={styles.buttonTabs}
                selected={Object.keys(trackCreateTabs).indexOf(currentTab)}
                isTopOfBox
                tabsLabels={Object.values(trackCreateTabs)}
                type="tabs"
                onChange={(index) => {
                  const formTab = Object.keys(trackCreateTabs)[index]

                  setCurrentTab(formTab as keyof typeof trackCreateTabs)

                  // Update form values with saved values if on a tab that saves data
                  if (isSavedFormType(formTab)) {
                    saveTrackCreateTab(workspaceID, formTab)

                    const savedFormData = getTrackCreateFormData(workspaceID)

                    updateFormValues(formTab, savedFormData[formTab], {
                      switchFormType: true,
                      resetSubmissionState: true,
                    })
                  }

                  logAction({
                    variables: {
                      action: 'track-create-change-tab',
                      pagePath: '/track/create-links',
                      functionName: 'changeTab',
                      websiteSection: 'track',
                      extra: formTab,
                    },
                  })
                }}
              >
                <TrackCreateFormWeb
                  generatedStructure={generatedStructure}
                  loadingGenerator={loadingGenerator}
                  generatorError={!!generatorError}
                  formValues={formValues.web}
                  updateFormValues={(newValues, options) =>
                    updateFormValues('web', newValues, options)
                  }
                  formSubmissionState={formSubmissionState}
                  setFormSubmissionState={setFormSubmissionState}
                  setNewLinks={setNewLinks}
                  switchToBulkCsv={() => setCurrentTab('bulkCSV')}
                />
                <TrackCreateCloneAndEdit key="generator-clone" />
                <BulkImport
                  key="generator-bulk"
                  hasCreatedCode={hasCreatedCode}
                />
                <TrackCreateFormEmail
                  generatedStructure={generatedStructure}
                  loadingGenerator={loadingGenerator}
                  generatorError={!!generatorError}
                  formValues={formValues.email}
                  updateFormValues={(newValues, options) =>
                    updateFormValues('email', newValues, options)
                  }
                  formSubmissionState={formSubmissionState}
                  setFormSubmissionState={setFormSubmissionState}
                  onCreateLinks={({
                    fullLinks,
                    customLinkDomain,
                    useShortLinks,
                    emailSource,
                    createdEmailHtml,
                  }) => {
                    setNewLinks(
                      useShortLinks
                        ? fullLinks.map(
                            ({ shortLinkID }) =>
                              `https://${customLinkDomain}/${shortLinkID}`,
                          )
                        : fullLinks.map(({ fC }) => fC),
                    )

                    setNewEmailHtml(createdEmailHtml)
                    setIsIntegrationEmail(emailSource !== 'emailHtml')
                  }}
                />
              </ButtonTabs>
            </div>

            {/* QR code preview for single link */}
            {currentTab === 'web' &&
              typeof newLinks === 'string' &&
              newLinks !== '' && <ShowLatestCode newLink={newLinks} />}

            {/* Most recently generated email HTML. If created in current session, highlights where links were changed */}
            {currentTab === 'email' && (newEmailHtml || previousEmailHtml) && (
              <NewEmailHTMLPreview
                newEmailHtml={newEmailHtml}
                previousEmailHtml={previousEmailHtml}
                isIntegrationEmail={isIntegrationEmail}
              />
            )}
          </Column>
          <Column side fixed>
            <RecentlyCreatedLinks newLinks={newLinks} />
            <RequestField />
          </Column>
        </TwoColumns>
      </Layout>
    </SiteWrapper>
  )
}
