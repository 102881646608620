import { gql } from '../../__gql-types__/gql'

export const getPreferredHomepage = gql(`
  query GetPreferredHomepage {
    currentAccount {
			accountID
			trackAvailable
			reportAvailable
			auditAvailable
			explainAvailable
			connectAvailable
		}
		currentUser {
			userID
			preferredHomepage
		}
  }
`)

export const getUserInfo = gql(`
	query GetUserInfo {
		currentUser {
			curAccountID
			currentAccountPermission
			email
			firstName
			lastName
			profileImgLink
			userID
			userName
			whitelabelAdmin
		}
		currentAccount {
			accountID
			accountName
			homepage
			companyID
			companyName
			created
			isDemo
			trackAvailable
  		reportAvailable
  		explainAvailable
			connectAvailable
		}
	}
`)

export const getProfileInfo = gql(`
	query GetProfileInfo {
		currentUser {
			email
			telNum
			firstName
			lastName
			userName
			userID
			publicJobTitle
			profileImgLink
			acceptedMarketing
		}
	}
`)

export const getUserAccounts = gql(`
	query GetUserAccounts {
		currentUser {
			userID
			userAccountProfiles {
				accountID
				accountName
				companyID
				companyName
				userPermission
				homepage
			}
		}
	}
`)

export const updateProfileInfo = gql(`
	mutation UpdateUserProfile(
		$username: String
		$phone: String
		$lastName: String
		$jobTitle: String
		$firstName: String
	) {
		userAccountSettings {
			updateUser(
				username: $username
				phone: $phone
				lastName: $lastName
				jobTitle: $jobTitle
				firstName: $firstName
			) {
				userID
				firstName
				lastName
				userName
				publicJobTitle
				email
				telNum
			}
		}
	}
`)

export const updatePreferredHomepage = gql(`
	mutation UpdateUserHomepage($newPage: String!) {
		updateUserHomepage(preferredHomepage: $newPage) {
			userID
			preferredHomepage
		}
	}
`)

export const updateMarketingPermissions = gql(`
	mutation UpdateMarketingPermission($allowMarketing: Boolean!) {
		userAccountSettings {
			updateMarketingPermission(allowMarketing: $allowMarketing) {
				userID
				acceptedMarketing
			}
		}
	}
`)

export const getEmailNotifications = gql(`
	query GetEmailNotifications {
		currentUser {
			userAccountProfiles {
				accountID
				explainEmail
				reportEmail
				trackEmail
			}
			userID
		}
	}
`)

export const toggleEmailNotifications = gql(`
	mutation ToggleEmailNotifications (
		$accountID: String!,
		$explain: Boolean,
		$report: Boolean,
		$track: Boolean
	) {
		userAccountSettings {
			toggleEmailNotifications(
				accountID: $accountID
				report: $report
				track: $track
				explain: $explain
			) {
				userID
				userAccountProfiles {
					accountID
					trackEmail
					reportEmail
					explainEmail
				}
			}
		}
	}
`)
