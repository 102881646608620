import React from 'react'
import { useReactiveVar } from '@apollo/client'
import classNames from 'classnames'

import Button from './button'
import Link from './link'
import { currentUserDetails, loggedInState } from '../api/apollo/variables'
import { logout } from '../api/REST/auth-client'
import styles from '../styles/footer.module.scss'

interface Props {
  className?: string
}

export default function Footer({ className }: Props): React.ReactElement {
  const { authenticated } = useReactiveVar(loggedInState)
  const { userEmail, userPermission } = useReactiveVar(currentUserDetails)

  const showLoggedIn = authenticated && userEmail

  return (
    <footer className={classNames(styles.container, className)}>
      {showLoggedIn && userEmail && (
        <p>
          <>
            Logged in as <strong>{userEmail}</strong>
            {userPermission !== 'regular' ? ` (${userPermission})` : ''}.
          </>{' '}
          <Button variant="text" color="blue" href="/login" onPress={logout}>
            Logout
          </Button>
        </p>
      )}
      {!showLoggedIn && (
        <p className={styles.links}>
          <Link href="https://support.uplifter.ai/hc/en-us">Help centre</Link>
        </p>
      )}
      <div className={styles.rightColumn}>
        {showLoggedIn && (
          <p className={styles.links}>
            <Link href="https://support.uplifter.ai/hc/en-us">Help Centre</Link>
          </p>
        )}
        <p className={styles.links}>
          <Link href="https://uplifter.ai/privacy">Privacy Statement</Link>
        </p>
        <p>
          © {new Date().getFullYear()}
          {' - '}
          {'Uplifter Ltd. '}
        </p>
      </div>
    </footer>
  )
}
