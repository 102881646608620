import { getItemByKeyValue } from '.'
import { GeneratorParameterValues } from './track-create'
import { getAnchorFromString } from './track-module'
import { RecentlyValidatedUrl } from '../api/apollo/variables'
import { CodeType } from '../api/types'
import { AvailableDomain } from '../hooks/useCustomLinks'
import {
  DimensionFilter,
  GetCampaignCodeGeneratorQuery,
  GetMinCodesQuickQuery,
  GetStoredCodesStatsQuickQuery,
  GetUrlValidationStatusQuery,
  SelectField,
} from '../__gql-types__/graphql'

export const minimumSearchTypeList = [
  { name: 'All', value: 'any' },
  {
    name: 'Created',
    value: 'createdTime',
  },
  {
    name: 'Created by',
    value: 'createdBy',
  },
  {
    name: 'Short link',
    value: 'shortLink',
  },
  {
    name: 'Destination with parameters',
    value: 'fullLink',
  },
]

// Used for bidirectional sorting of status column
export const statusItems = ['Live', 'Ended', 'New', 'Unused']

export interface TrackViewFilterProps {
  codeIDList?: string[]
  activePage: number
  rowsPerPage: number
  sortBy: string
  sortDirection?: 'ASC' | 'DESC'
  searchType: string
  searchTerm: string
  filterByCurrentUser?: boolean
  bidirectionalSortKey?: string
  isMetric?: boolean
}

export interface RefetchCodesQueryVars {
  codeIDList?: string[]
  dimensionFilter?: DimensionFilter
  filterByCurrentUser?: boolean
  limit?: number
  offset?: number
  orderBy?: {
    sortDirection: 'ASC' | 'DESC'
    sortField: string
  }
}

export const filterToMinCodesVars = ({
  codeIDList,
  activePage,
  rowsPerPage,
  sortBy,
  sortDirection,
  searchType,
  searchTerm,
  filterByCurrentUser,
}: TrackViewFilterProps): RefetchCodesQueryVars => {
  return {
    codeIDList,
    dimensionFilter: {
      dimensionName: searchTerm,
      dimensionParameterID: searchType,
      dimensionOptions: [],
    },
    filterByCurrentUser,
    limit: rowsPerPage,
    offset: (activePage - 1) * rowsPerPage,
    orderBy: {
      sortDirection: sortDirection || 'DESC',
      sortField: sortBy,
    },
  }
}

export const getEarliestDataDate = (
  codesMetricData?: GetStoredCodesStatsQuickQuery,
) => {
  let earliestDataDate: number | null = null

  const earliestClickDate = codesMetricData
    ? codesMetricData.track.storedCodeStatsQuick.earliestClickDate
    : null

  const earliestMetricDate = codesMetricData
    ? codesMetricData.track.storedCodeStatsQuick.earliestMetricDate
    : null

  if (codesMetricData) {
    const clickDate =
      earliestClickDate && earliestClickDate !== 'None'
        ? Date.parse(earliestClickDate)
        : null
    const metricDate =
      earliestMetricDate && earliestMetricDate !== 'None'
        ? Date.parse(earliestMetricDate)
        : null

    if (clickDate && metricDate) {
      earliestDataDate = Math.min(clickDate, metricDate)
    } else if (clickDate) {
      earliestDataDate = clickDate
    } else if (metricDate) {
      earliestDataDate = metricDate
    }
  }

  return earliestDataDate
}

export interface MinimalParamDetails {
  fieldID: string
  fieldName: string
  helpText: string
  hideInTrackView?: boolean | null
  selectFields?: SelectField[] | null
}

export const getWorkspaceParams = (
  workspaceID = '',
  generatorData?: GetCampaignCodeGeneratorQuery,
): MinimalParamDetails[] => {
  if (!generatorData) return []

  // Do not include fields blocked for the current workspace
  return generatorData.campaignCodeGenerator.paramDefs
    .filter(({ parameterDependsOn }) => {
      if (
        parameterDependsOn &&
        parameterDependsOn.parentFieldID === 'account' &&
        parameterDependsOn.parentOptionIDs.indexOf(workspaceID) === -1
      ) {
        return false
      }
      return true
    })
    .map(({ fieldID, fieldName, helpText, hideInTrackView, selectFields }) => ({
      fieldID,
      fieldName,
      helpText,
      hideInTrackView,
      selectFields,
    }))
}

export const buildSearchTypeList = (params: MinimalParamDetails[]) => {
  const searchTypeList = [...minimumSearchTypeList]

  searchTypeList.push(
    ...params.map(({ fieldID, fieldName }) => ({
      name: fieldName,
      value: fieldID,
    })),
  )

  return searchTypeList
}

export interface MetricDropdownItem {
  metricID: string
  displayName: string
  helpText?: string
}

export interface TableLinkWithData {
  linkID: string
  deepLinkServiceID: string | null
  createdInfo: {
    createdTime: string
    author: string
    versionNumber: number
  }
  hasNoLandingPage?: boolean
  fullLink: string
  shortLink: string | null
  params: {
    paramID: string
    paramName: string
    paramValue: string
    isHidden: boolean
  }[]
  uplifterIDValue?: string
  status?: CodeType | null
  linkValidation?: Pick<
    RecentlyValidatedUrl,
    | 'badUrl'
    | 'noAnalyticsTag'
    | 'redirectedLandingPage'
    | 'slowLandingPage'
    | 'statusCode'
  > | null
  metricData?: {
    metricID: string
    metricName: string
    metricValue: number
    units: string
    isHidden: boolean
  }[]
}

export interface HeaderColumnProps {
  key: string
  name: string
  tooltipMsg?: React.ReactNode
  bidirectionalSortKeys?: string[]
  isMetric?: boolean
}

export const minimumTableHeaders: HeaderColumnProps[] = [
  {
    key: 'createdTime',
    name: 'Created',
  },
  {
    key: 'fullLink',
    name: 'Destination with parameters',
    tooltipMsg:
      "The full website URL which will be seen in the visitor's browser. The symbol to the right shows its status after we checked the landing page with a bot.",
  },
]

interface BuildTableLinksProps {
  params: MinimalParamDetails[]
  minCodes?: GetMinCodesQuickQuery['track']['minCodesQuick']
  codeStats?: GetStoredCodesStatsQuickQuery['track']['storedCodeStatsQuick']
  codeValidationData?: GetUrlValidationStatusQuery['track']['trackValidationResults']
  selectedMetrics: MetricDropdownItem[]
  masterPrefix?: string
  uplifterIdPrefix?: string
  availableAppLinkDomains: AvailableDomain[]
}

export const buildTableHeaders = ({
  params,
  selectedMetrics = [],
  showShortLinkColumn = true,
  showLinkStatusColumn = true,
}: Pick<BuildTableLinksProps, 'params' | 'selectedMetrics'> & {
  showShortLinkColumn?: boolean
  showLinkStatusColumn?: boolean
}) => {
  // Build table headers from params and metrics
  const tableHeaderColumns = [...minimumTableHeaders]

  if (showShortLinkColumn) {
    // Should be the second column
    tableHeaderColumns.splice(1, 0, {
      key: 'shortLink',
      name: 'Short link',
      tooltipMsg:
        'A short, memorable link that redirects to the destination with parameters, capturing valuable clickthrough data.\n\nWhen available, use this link rather than the destination with parameters.',
    })
  }

  if (showLinkStatusColumn) {
    tableHeaderColumns.push({
      key: 'status',
      name: 'Link status',
      // 'Updated every hour' used to be italicised but that's hard to do while having a 'Learn more' link added to the message. See `track-view-table.tsx`
      tooltipMsg:
        'Shows if this link has received either short link clickthroughs and/or onsite analytics metrics (Updated every hour).',
      bidirectionalSortKeys: statusItems,
      isMetric: true,
    })
  }

  if (selectedMetrics.length > 0) {
    tableHeaderColumns.push(
      ...selectedMetrics.map(({ metricID, displayName, helpText }) => ({
        key: metricID,
        name: displayName,
        tooltipMsg: helpText,
        isMetric: true,
      })),
    )
  }

  tableHeaderColumns.push(
    ...params
      .filter(({ hideInTrackView }) => !hideInTrackView)
      .map((param) => ({
        key: param.fieldID,
        name: param.fieldName,
        tooltipMsg: param.helpText,
      })),
  )

  return tableHeaderColumns
}

export const buildTableLinks = ({
  params,
  minCodes,
  codeStats,
  codeValidationData,
  selectedMetrics = [],
  masterPrefix = '?',
  uplifterIdPrefix,
  availableAppLinkDomains,
}: BuildTableLinksProps) => {
  const paramIDs = params.map(({ fieldID }) => fieldID)
  const selectedMetricIDs = selectedMetrics?.map(({ metricID }) => metricID)

  // Build table rows
  // Should have same number of params and metrics as header
  const tableRows: TableLinkWithData[] = []

  if (minCodes) {
    const {
      codeID,
      createdTime,
      author,
      versionNumber,
      fullLink,
      shortLink,
      minGenDef,
      codeDef,
    } = minCodes

    codeID.forEach((linkID, linkIndex) => {
      let deepLinkServiceID: string | null = null

      if (shortLink) {
        deepLinkServiceID =
          availableAppLinkDomains.find(({ domainValue }) =>
            shortLink[linkIndex].includes(domainValue),
          )?.domainID || null
      }

      const linkData: TableLinkWithData = {
        linkID,
        deepLinkServiceID,
        createdInfo: {
          createdTime: createdTime[linkIndex],
          author: author[linkIndex],
          versionNumber: versionNumber[linkIndex],
        },
        fullLink: fullLink[linkIndex],
        hasNoLandingPage: fullLink[linkIndex].indexOf(masterPrefix) === 0,
        shortLink: shortLink ? shortLink[linkIndex] : null,
        params: minGenDef.map(({ paramID, paramName }, paramIndex) => {
          const hideParamFromTable = params.find((p) => p.fieldID === paramID)
            ?.hideInTrackView

          return {
            paramID,
            paramName,
            paramValue: codeDef[linkIndex][paramIndex],
            // Hide params not shown for the workspace
            isHidden: hideParamFromTable || !paramIDs.includes(paramID),
          }
        }),
      }

      if (uplifterIdPrefix) {
        const splitVals = fullLink[linkIndex].split(uplifterIdPrefix)

        // ID prefix exists in link
        if (splitVals.length >= 2) {
          // Only thing after ID will be the anchor
          // Remove anchor from string
          const useAnchor = getAnchorFromString(fullLink[linkIndex])

          linkData.uplifterIDValue = splitVals[splitVals.length - 1].replace(
            useAnchor,
            '',
          )
        }
      }

      if (codeValidationData) {
        const fullValidation = codeValidationData[linkIndex]
        linkData.linkValidation = fullValidation
          ? {
              statusCode: fullValidation.statusCode,
              badUrl: fullValidation.badUrl,
              noAnalyticsTag: fullValidation.noAnalyticsTag,
              redirectedLandingPage: fullValidation.redirectedLandingPage,
              slowLandingPage: fullValidation.slowLandingPage,
            }
          : null
      }

      if (codeStats) {
        linkData.status = codeStats.status
          ? (codeStats.status[linkIndex] as CodeType)
          : null

        linkData.metricData = codeStats.metricValues.map(
          ({ metricID, displayName, totalMetricValues, units }) => ({
            metricID,
            metricName: displayName,
            metricValue: totalMetricValues[linkIndex],
            units,
            isHidden: selectedMetricIDs?.indexOf(metricID) === -1,
          }),
        )
      }

      tableRows.push(linkData)
    })
  }

  return tableRows
}

export const cloneAndEditBuildForm = (
  links: TableLinkWithData[],
  selectedLinks: string[],
  masterPrefix = '?',
  paramDefs: MinimalParamDetails[],
) => {
  const result: {
    urls: string[]
    generatorParameterValues: GeneratorParameterValues
  } = {
    urls: [],
    generatorParameterValues: {},
  }

  const fullSelectedLinks = links.filter(({ linkID }) =>
    selectedLinks.includes(linkID),
  )

  fullSelectedLinks.forEach(({ fullLink, params }) => {
    const linkAnchor = getAnchorFromString(fullLink)

    const linkWithoutParams = fullLink.split(masterPrefix)

    const linkToUse = `${
      linkWithoutParams.length > 0 ? linkWithoutParams[0] : ''
    }${linkAnchor}`

    if (result.urls.indexOf(linkToUse) === -1) {
      result.urls.push(linkToUse)
    }

    params.forEach(({ paramID, paramValue }) => {
      if (!paramValue) return

      if (!result.generatorParameterValues[paramID]) {
        result.generatorParameterValues[paramID] = []
      }

      const paramDefValue = getItemByKeyValue(paramDefs, 'fieldID', paramID)

      let valueExists = false
      let useValue = paramValue

      if (paramDefValue !== -1) {
        valueExists = true
        const { selectFields } = paramDefValue

        if (selectFields) {
          const selectFieldValueFound = getItemByKeyValue(
            selectFields,
            'optionName',
            useValue,
          )

          valueExists = selectFieldValueFound !== -1

          if (!valueExists) return

          useValue = selectFieldValueFound.optionID
        }
      }

      if (result.generatorParameterValues[paramID].indexOf(useValue) === -1) {
        result.generatorParameterValues[paramID].push(useValue)
      }
    })
  })

  return result
}
