import React, { useEffect } from 'react'

import { LoadingLogo } from '../components/loader'
import { setToken } from '../helpers'

/* **************************************************************** */
/* Old types used in V1 API                                         */
/* **************************************************************** */

// interface GetSharingLinkRedirect {
//   shareLinkID: string
// }

/* **************************************************************** */
/* **************************************************************** */

interface Props {
  match: any
}

export function SetToken({ token }: { token: string }): void {
  setToken(token)

  // @ts-ignore
  if (window.dataLayer && window.dataLayer.push) {
    // @ts-ignore
    window.dataLayer.push({ event: 'share', token })
  }
}
export default function Share({ match }: Props): React.ReactElement {
  const { shareLinkID } = match.params

  useEffect(() => {
    // eslint-disable-next-line
    ;(async function getSharingDetails() {
      // ! V1 endpoint no longer used
      // const req = await make.get({
      //   endpoint: `get-sharing-link-redirect/${shareLinkID}`,
      // })
      // const { redirectLink } = result
      // const splitLink = redirectLink.split('&token=')
      // const token = splitLink.length === 2 ? splitLink[1] : null
      // Has token indicates public link
      // if (token) {
      // const existingToken = getToken()
      // if (!existingToken) {
      //   SetToken({ token })
      //   window.location = redirectLink
      // } else {
      //   // check for validity with logged-in call
      //   // eslint-disable-next-line
      //   ;(async function isLogged() {
      //     const valid = await isLoggedIn()
      //     if (!valid) {
      //       SetToken({ token })
      //     }
      //     window.location = redirectLink
      //   })()
      // }
      // } else {
      // window.location = redirectLink
      // }
    })()
  }, [shareLinkID])

  return <LoadingLogo fullScreen />
}
