import React, { useMemo } from 'react'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'

import ChangeInput from './change-input'
import SubscriptionComparison from './subscription-comparison'
import { currentUserDetails } from '../api/apollo/variables'
import {
  getCompanyDetails,
  updateCompanyInternalContact,
  updateCompanyName,
} from '../api/graphql/company-client'
import { getUserAccounts, getUserInfo } from '../api/graphql/user-client'
import { emailRegex } from '../helpers/forms'
import useSubscriptionLevel from '../hooks/useSubscriptionLevel'
import styles from '../styles/company-billing-and-details.module.scss'

interface CompanyBillingAndDetailsProps {
  className?: string
}

export default function CompanyBillingAndDetails({
  className,
}: CompanyBillingAndDetailsProps) {
  const { companyName, workspaceID } = useReactiveVar(currentUserDetails)

  const { isEnterprise, isMicrosoftMarketplace } = useSubscriptionLevel()

  const { data: companyData } = useQuery(getCompanyDetails)
  const [updateInternalContact] = useMutation(updateCompanyInternalContact)
  const [updateOrgName] = useMutation(updateCompanyName)

  const internalContactEmail = useMemo(() => {
    if (!companyData) return null

    return companyData.currentCompany.internalContactEmail
  }, [companyData])

  return (
    <div className={className}>
      {/* MS Marketplace subscribers should be able to see subscription details */}
      {/* They can downgrade their enterprise accounts without involvement from Uplifter support */}
      {(!isEnterprise || isMicrosoftMarketplace) && (
        <>
          <h3>Plan details</h3>
          <SubscriptionComparison
            showCurrent
            showSubscriptionDetails
            showDeleteAccount={!isEnterprise}
          />
        </>
      )}
      <h3>Company details</h3>
      <div className={styles.subsection}>
        <div className={styles.fieldRow}>
          <ChangeInput
            onClick={async (newName) => {
              const response = newName !== ''
              if (!response || workspaceID === '') return 'Not valid'

              const { data } = await updateOrgName({
                variables: {
                  newName,
                },
                refetchQueries: [getUserInfo, getUserAccounts],
              })

              if (data) {
                return 'Updated'
              }
              return 'Failed'
            }}
            tooltip="This is the name of your organisation or company."
            label="Company name"
            type="text"
            name="companyName"
            initialValue={companyName}
          />
          <ChangeInput
            tooltip="Your organisation's internal support contact. Will be shown on the Welcome page for all users."
            label="Internal contact email"
            type="text"
            name="internalContactEmail"
            initialValue={internalContactEmail || ''}
            onClick={async (newEmail) => {
              if (
                newEmail === '' ||
                !emailRegex.test(newEmail) ||
                workspaceID === ''
              )
                return 'Not valid'

              const { data } = await updateInternalContact({
                variables: {
                  newEmail,
                },
              })

              if (data) return 'Updated'

              return 'Failed'
            }}
          />
        </div>
      </div>
    </div>
  )
}
