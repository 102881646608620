import React from 'react'

import Link from './link'
import Modal from './modal'
import Tooltip from './tooltip'
import ObservepointLogo from '../assets/logos/observepoint-logo.png'
import { copyString } from '../helpers'
import useLogAction from '../hooks/useLogAction'
import styles from '../styles/observepoint-modal.module.scss'

export interface ObservepointModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  link: string
}

export default function ObservepointModal({
  setShowModal,
  link,
}: ObservepointModalProps) {
  const logAction = useLogAction()

  return (
    <Modal
      setIsOpen={setShowModal}
      modalHeader="Scan page for issues"
      yesText={
        <Tooltip
          id="scan-page-tooltip"
          tooltipMessage="Link to scan will be copied to your clipboard."
          className={styles.scanTooltip}
        >
          Scan page
        </Tooltip>
      }
      onYes={async () => {
        logAction({
          variables: {
            action: 'click-scan-page-with-observepoint',
            functionName: 'observePointScan',
            pagePath: window.location.pathname,
            websiteSection: 'track',
            extra: link,
          },
        })

        copyString(link)

        window.open(
          `https://www.observepoint.com/sample-audit/?utm_source=uplifter&utm_medium=partner&pageToScan=${encodeURIComponent(
            link,
          )}`,
          '_blank',
        )
      }}
    >
      <>
        <img
          src={ObservepointLogo}
          alt="Observepoint"
          className={styles.observepointLogo}
        />
        <p>
          Have you checked your end-to-end user journey? Don't go live until you
          do.
        </p>
        <p>
          Click the button below to copy your link and get a full ObservePoint
          check for missing pages, redirects, broken tags, and non-compliant
          cookies.
        </p>
        <p>
          ObservePoint is the world's #1 digital marketing governance platform,
          and an Uplifter partner.
        </p>
        <p>
          <strong>Page to scan:</strong>
          <br />
          <Link href={link} className={styles.link}>
            {link}
          </Link>
        </p>
      </>
    </Modal>
  )
}
