import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import moment from 'moment'

import { currentUserDetails } from '../api/apollo/variables'
import {
  addOktaDomain,
  deleteOktaDomain,
  getOktaDomains,
  updateOktaDomain,
} from '../api/graphql/integrations-client'
import { ButtonRow } from '../components/button-row'
import Button from '../components/button'
import Input, { ClickEditInput, Label } from '../components/input'
import Link from '../components/link'
import { Preloader } from '../components/loader'
import { FieldSlot, FormRow, LabelSlot } from '../components/row'
import Tooltip from '../components/tooltip'
import { brandName, supportEmail } from '../core/constants'
import { isValidUrl } from '../helpers'
import styles from '../styles/integrations-settings.module.scss'
import { OktaDomain } from '../__gql-types__/graphql'

interface OktaExistingTenantProps {
  domain: OktaDomain
  last?: boolean
}

const OktaExistingTenant = ({
  domain,
  last = false,
}: OktaExistingTenantProps) => {
  const [
    updateTenant,
    { loading: updatingTenant, error: errorUpdating },
  ] = useMutation(updateOktaDomain)
  const [
    deleteTenant,
    { loading: deletingTenant, error: errorDeleting },
  ] = useMutation(deleteOktaDomain)

  return (
    <div className={styles.existingOktaTenants}>
      {updatingTenant ? (
        <Preloader />
      ) : (
        <>
          <ClickEditInput
            id="tenantName"
            className={styles.editTenantField}
            value={domain.tenantName}
            onChange={async (val) => {
              await updateTenant({
                variables: {
                  tenantName: val,
                  clientID: domain.clientID,
                  emailDomain: domain.emailDomain,
                },
                refetchQueries: [getOktaDomains],
              })
            }}
          />
          <ul style={{ margin: 0 }}>
            <li>
              <strong>Email domain:</strong> {domain.emailDomain}
            </li>
            <li>
              <strong>Client ID:</strong> {domain.clientID}
            </li>
            <li>
              <strong>Login link:</strong> {domain.loginLink}
            </li>
          </ul>
          <ButtonRow>
            <em>Created {moment(domain.dateCreated).format('DD/MM/YYYY')}</em>
            <Button
              variant="secondary"
              className={styles.deleteTenantBtn}
              loading={deletingTenant}
              onPress={async () => {
                await deleteTenant({
                  variables: {
                    clientID: domain.clientID,
                    emailDomain: domain.emailDomain,
                  },
                  refetchQueries: [getOktaDomains],
                })
              }}
            >
              Delete
            </Button>
          </ButtonRow>
          {(errorUpdating || errorDeleting) && (
            <p className={styles.errorMsg}>
              Unable to update your Okta account. Please try again later or
              contact support (
              <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>
              ).
            </p>
          )}
        </>
      )}
      {!last && <div className={styles.divider} />}
    </div>
  )
}

interface OktaDomainDetails {
  clientID: string
  clientSecret: string
  emailDomain: string
  tenantName: string
  tenantDomain: string
  duplicateTenantName: boolean
  invalidTenantDomain: boolean
}

const OktaConnector = () => {
  const { userEmail } = useReactiveVar(currentUserDetails)

  const {
    data: oktaDomainsData,
    loading: oktaLoading,
    error: errorFetchingOktaDomains,
  } = useQuery(getOktaDomains)
  const [
    addDomain,
    { loading: addingNewDomain, error: errorAddingOktaDomain },
  ] = useMutation(addOktaDomain)

  const oktaDomains = useMemo(() => {
    if (!oktaDomainsData) return []

    return oktaDomainsData.currentCompany.oktaDomainList.oktaDomains
  }, [oktaDomainsData])

  const [oktaDomainDetails, setOktaDomainDetails] = useState<OktaDomainDetails>(
    {
      clientID: '',
      clientSecret: '',
      emailDomain: '',
      tenantName: oktaDomains.find((dom) => dom.tenantName === 'Okta tenant')
        ? ''
        : 'Okta tenant',
      tenantDomain: '',
      duplicateTenantName: false,
      invalidTenantDomain: false,
    },
  )

  // Set initial value for email domain, based on userEmail
  useEffect(() => {
    if (userEmail) {
      const accountEmailDomain = userEmail.split('@').pop()

      if (accountEmailDomain)
        setOktaDomainDetails({
          ...oktaDomainDetails,
          emailDomain: accountEmailDomain,
        })
    }
  }, [userEmail])

  const addNewTenant = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      const {
        clientID,
        clientSecret,
        emailDomain,
        tenantName,
        tenantDomain,
      } = oktaDomainDetails

      if (!isValidUrl(tenantDomain)) {
        setOktaDomainDetails({
          ...oktaDomainDetails,
          invalidTenantDomain: true,
        })
        return
      }

      const tenantDomainNoSlash = tenantDomain.endsWith('/')
        ? tenantDomain.slice(0, -1)
        : tenantDomain

      await addDomain({
        variables: {
          clientID,
          clientSecret,
          emailDomain,
          tenantName,
          tenantDomain: tenantDomainNoSlash,
        },
        refetchQueries: [getOktaDomains],
      })

      setOktaDomainDetails({
        clientID: '',
        clientSecret: '',
        emailDomain: '',
        tenantName: '',
        tenantDomain: '',
        duplicateTenantName: false,
        invalidTenantDomain: false,
      })
    },
    [oktaDomainDetails],
  )

  if (oktaLoading) {
    return <Preloader />
  }

  return (
    <>
      {oktaDomains.length > 0 ? (
        <>
          <p>
            <strong>
              Your Okta account is connected. Connected email domains shown
              below.
            </strong>
          </p>
          <div className={styles.divider} />
          {oktaDomains.map((domain, index) => {
            return (
              <OktaExistingTenant
                key={domain.clientID}
                domain={domain}
                last={index === oktaDomains.length - 1}
              />
            )
          })}
          <div className={styles.divider} />
        </>
      ) : (
        <p>
          If you use Okta as your authentication provider, you can add Okta
          tenants here to use SSO with {brandName}.{' '}
          <Link href="https://support.uplifter.ai/hc/en-us/articles/10987066252061-Using-Okta-for-Single-Sign-On-SSO-">
            Full instructions here
          </Link>
          .
        </p>
      )}
      {errorFetchingOktaDomains || errorAddingOktaDomain ? (
        <p className={styles.errorMsg}>
          There was an error connecting to your Okta account. Please try again
          later or contact support (
          <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>
          ).
        </p>
      ) : (
        <>
          {addingNewDomain ? (
            <Preloader />
          ) : (
            <>
              <h4>Add a new email domain</h4>
              <form id="okta-connect-form" onSubmit={addNewTenant}>
                <FormRow className={styles.addTenantFormRow}>
                  <LabelSlot noPadding>
                    <Label id="clientID">
                      <Tooltip
                        id="client-id-tooltip"
                        useIcon
                        tooltipMessage="The client ID shown for your Okta tenant."
                      >
                        Client ID
                      </Tooltip>
                    </Label>
                  </LabelSlot>
                  <FieldSlot noPadding>
                    <Input
                      name="clientID"
                      type="text"
                      label="Client ID"
                      required
                      value={oktaDomainDetails.clientID}
                      onValueChange={(val) =>
                        setOktaDomainDetails({
                          ...oktaDomainDetails,
                          clientID: val,
                        })
                      }
                    />
                  </FieldSlot>
                </FormRow>
                <FormRow className={styles.addTenantFormRow}>
                  <LabelSlot noPadding>
                    <Label id="clientSecret">
                      <Tooltip
                        id="client-secret-tooltip"
                        useIcon
                        tooltipMessage="The client secret shown for your Okta tenant."
                      >
                        Client Secret
                      </Tooltip>
                    </Label>
                  </LabelSlot>
                  <FieldSlot noPadding>
                    <Input
                      name="clientSecret"
                      type="text"
                      label="Client Secret"
                      required
                      value={oktaDomainDetails.clientSecret}
                      onValueChange={(val) =>
                        setOktaDomainDetails({
                          ...oktaDomainDetails,
                          clientSecret: val,
                        })
                      }
                    />
                  </FieldSlot>
                </FormRow>
                <FormRow className={styles.addTenantFormRow}>
                  <LabelSlot noPadding>
                    <Label id="emailDomain">
                      <Tooltip
                        id="email-domain-tooltip"
                        useIcon
                        tooltipMessage="The email domain used for the Okta tenant."
                      >
                        Email domain
                      </Tooltip>
                    </Label>
                  </LabelSlot>
                  <FieldSlot noPadding>
                    <Input
                      name="emailDomain"
                      type="text"
                      label="Email domain"
                      required
                      value={oktaDomainDetails.emailDomain}
                      onValueChange={(val) =>
                        setOktaDomainDetails({
                          ...oktaDomainDetails,
                          emailDomain: val,
                        })
                      }
                    />
                  </FieldSlot>
                </FormRow>
                <FormRow className={styles.addTenantFormRow}>
                  <LabelSlot noPadding>
                    <Label id="tenantName">
                      <Tooltip
                        id="tenant-name-tooltip"
                        useIcon
                        tooltipMessage={`Used as a reference in ${brandName}.`}
                      >
                        Tenant name
                      </Tooltip>
                    </Label>
                  </LabelSlot>
                  <FieldSlot noPadding>
                    <Input
                      name="tenantName"
                      type="text"
                      label="Tenant name"
                      required
                      value={oktaDomainDetails.tenantName}
                      onValueChange={(val) => {
                        setOktaDomainDetails({
                          ...oktaDomainDetails,
                          tenantName: val,
                          duplicateTenantName: !!oktaDomains.find(
                            (dom) => dom.tenantName === val,
                          ),
                        })
                      }}
                      error={oktaDomainDetails.duplicateTenantName}
                    />
                    {oktaDomainDetails.duplicateTenantName && (
                      <span className={styles.tenantError}>
                        Tenant name already used
                      </span>
                    )}
                  </FieldSlot>
                </FormRow>
                <FormRow className={styles.addTenantFormRow}>
                  <LabelSlot noPadding>
                    <Label id="tenantDomain">
                      <Tooltip
                        id="tenant-domain-tooltip"
                        useIcon
                        tooltipMessage="The domain of the Okta tenant."
                      >
                        Tenant domain
                      </Tooltip>
                    </Label>
                  </LabelSlot>
                  <FieldSlot noPadding>
                    <Input
                      name="tenantDomain"
                      type="text"
                      label="Tenant domain"
                      required
                      value={oktaDomainDetails.tenantDomain}
                      onValueChange={(val) => {
                        const newOktaDomainDetails = { ...oktaDomainDetails }

                        if (oktaDomainDetails.invalidTenantDomain) {
                          newOktaDomainDetails.invalidTenantDomain = false
                        }

                        newOktaDomainDetails.tenantDomain = val

                        setOktaDomainDetails(newOktaDomainDetails)
                      }}
                      error={oktaDomainDetails.invalidTenantDomain}
                    />
                    {oktaDomainDetails.invalidTenantDomain && (
                      <span className={styles.tenantError}>
                        Domain must be a URL
                      </span>
                    )}
                  </FieldSlot>
                </FormRow>
              </form>
            </>
          )}
        </>
      )}
    </>
  )
}

export default OktaConnector
