import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import moment from 'moment'
import FileSaver from 'file-saver'
import reactStringReplace from 'react-string-replace'
import _ from 'lodash'
import numeral from 'numeraljs'

import Button, { ClearButton, CopyButton, NavigateButton } from './button'
import { FormLoading } from './form'
import Link from './link'
import Modal from './modal'
import NoDataMessage from './no-data-message'
import Tooltip from './tooltip'
import TrackCreateFormEmailFields from './track-create-email-fields'
import GeneratorParameterFields from './track-create-parameter-fields'
import TrackCreatePreviewLinksTable from './track-create-preview-links-table'
import TrackCreateSubmitFields from './track-create-submit'
import {
  CreateLinkAnchorWarningModal,
  CreateLinkInvalidLengthModal,
} from './track-create-form-warning-modals'
import { InnerBox, OuterBox } from './two-columns'
import { ErrorMessage } from './typography'
import {
  getEloquaTemplateHTML,
  getSalesforceEmailHTML,
  getSalesforceMCEmailTemplateDetail,
  updateEloquaTemplateEmailHTML,
  updateSalesforceEmailHTMLByEmailID,
  updateSalesforceMCEmailTemplate,
} from '../api/graphql/integrations-client'
import { brandName, supportEmail } from '../core/constants'
import { htmlHasLinks, maxBatchShortLinks } from '../helpers/track-module'
import {
  EmailFields,
  EmailForm,
  EmailSources,
  formIsEloquaEmailFields,
  formIsSalesforceMCEmailFields,
  formIsSalesforcePardotEmailFields,
  FullLinkResult,
  GeneratorParameterValues,
  HardWarningModalType,
  maxEmailHtmlLength,
  SoftWarning,
  UpdateFormValuesVars,
} from '../helpers/track-create'
import { UpdateFormOptions } from '../hooks/useTrackCreateSavedValues'
import useTrackCreateFormSubmit from '../hooks/useTrackCreateSubmit'
import styles from '../styles/track-create-form.module.scss'
import { GetCampaignCodeGeneratorQuery } from '../__gql-types__/graphql'

const replaceSalesforceMCLinks = (
  emailFields: EmailFields,
  fullLinks: FullLinkResult[],
  useShortLinks?: boolean,
  customLinkDomain?: string,
) => {
  if (
    !formIsSalesforceMCEmailFields(emailFields) ||
    emailFields.salesforceMCEmailFields?.templateType !== 'templatebasedemail'
  ) {
    return {
      newTextContent: '',
      newSlotMap: [],
    }
  }

  let newTextContent =
    emailFields.salesforceMCEmailFields?.textContent || undefined

  const newSlotMap =
    _.cloneDeep(
      emailFields.salesforceMCEmailFields?.slotMap?.filter(
        ({ hasLinks }) => hasLinks,
      ),
    ) || []

  fullLinks.forEach(({ urlWithHash, lP, fC, shortLinkID }) => {
    const replacedWith =
      useShortLinks && customLinkDomain
        ? `https://${customLinkDomain}/${shortLinkID}`
        : fC

    const urlToReplace = urlWithHash || lP
    const urlToReplaceRegex = urlToReplace.replace(
      /[.*+?^${}()|[\]\\]/g,
      '\\$&',
    )
    // Replace text content for SF MC templatebasedemails
    if (newTextContent) {
      newTextContent = newTextContent.replaceAll(
        new RegExp(urlToReplaceRegex, 'g'),
        replacedWith,
      )
    }

    // Include quotes to prevent replacement of links contained in other links
    const replacerRegex = new RegExp(
      `(\\\'|\\\")${urlToReplaceRegex}(\\\'|\\\")`,
      'g',
    )

    // Replace slotMap for SF MC templatebasedemails
    newSlotMap.forEach((slot, slotIndex) => {
      // Replace content
      const newContent = slot.content.replaceAll(
        replacerRegex,
        `"${replacedWith}"`,
      )

      const newBlocks = slot.blocks.map((block) => {
        const newBlockContent = block.content.replaceAll(
          replacerRegex,
          `"${replacedWith}"`,
        )
        return {
          ...block,
          content: newBlockContent,
        }
      })

      newSlotMap.splice(slotIndex, 1, {
        ..._.cloneDeep(slot),
        content: newContent,
        blocks: newBlocks,
      })
    })
  })

  return {
    newTextContent,
    newSlotMap,
  }
}

/** Build new email HTML with replaced links */
const replaceEmailHtml = (
  currentEmailHtml: string,
  fullLinks: FullLinkResult[],
  useShortLinks?: boolean,
  customLinkDomain?: string,
): GeneratedEmailHTML => {
  let newEmailHtmlToUse = currentEmailHtml

  const replacedStrings: string[] = []

  fullLinks.forEach(({ urlWithHash, lP, fC, shortLinkID }) => {
    const replacedWith =
      useShortLinks && customLinkDomain
        ? `https://${customLinkDomain}/${shortLinkID}`
        : fC

    replacedStrings.push(replacedWith)

    const urlToReplace = urlWithHash || lP
    const urlToReplaceRegex = urlToReplace.replace(
      /[.*+?^${}()|[\]\\]/g,
      '\\$&',
    )

    // Include quotes to prevent replacement of links contained in other links
    const replacerRegex = new RegExp(
      `(\\\'|\\\")${urlToReplaceRegex}(\\\'|\\\")`,
      'g',
    )

    newEmailHtmlToUse = newEmailHtmlToUse.replaceAll(
      replacerRegex,
      `"${replacedWith}"`,
    )
  })

  // Used to highlight replaced text
  const combinedStrings = replacedStrings.reduce((acc, curr, index) => {
    const cleaned = curr.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

    if (replacedStrings.length === 1) return `${acc}${cleaned})`
    if (index === 0) return `${acc}${cleaned}`
    if (index === replacedStrings.length - 1) return `${acc}|${cleaned})`

    return `${acc}|${cleaned}`
  }, `(`)

  const newEmailHtmlPreview = reactStringReplace(
    newEmailHtmlToUse,
    new RegExp(combinedStrings, 'g'),
    (match, i) => (
      <span key={match + i} className={styles.highlight}>
        {match}
      </span>
    ),
  )

  return {
    raw: newEmailHtmlToUse,
    preview: <>{newEmailHtmlPreview}</>,
  }
}

interface IntegrationSuccessModal {
  integrationType: Omit<EmailSources, 'html'>
  isListTemplate?: boolean
}

interface TrackCreateFormEmailProps {
  generatedStructure:
    | GetCampaignCodeGeneratorQuery['campaignCodeGenerator']
    | null
  loadingGenerator?: boolean
  generatorError?: boolean
  formValues: EmailForm
  updateFormValues: (
    newValues: UpdateFormValuesVars,
    options?: UpdateFormOptions,
  ) => void
  formSubmissionState: {
    softDisable: boolean
    fieldsWithErrors: string[]
    showErrorMessages: boolean
  }
  setFormSubmissionState: React.Dispatch<
    React.SetStateAction<{
      softDisable: boolean
      fieldsWithErrors: string[]
      showErrorMessages: boolean
    }>
  >
  /** Used to set links that should appear in recentlyCreated section */
  onCreateLinks: (links: {
    fullLinks: FullLinkResult[]
    customLinkDomain?: string
    useShortLinks?: boolean
    emailSource: EmailSources
    createdEmailHtml: GeneratedEmailHTML
  }) => void
}

const TrackCreateFormEmail = ({
  generatedStructure,
  loadingGenerator,
  generatorError,
  formValues,
  updateFormValues,
  formSubmissionState,
  setFormSubmissionState,
  onCreateLinks,
}: TrackCreateFormEmailProps) => {
  const {
    softDisable,
    fieldsWithErrors,
    showErrorMessages,
  } = formSubmissionState

  const formRef = useRef<HTMLFormElement>(null)

  const {
    linkType,
    setLinkType,
    canUseCustomLinks,
    selectedCustomLinkDomain,
    customLinkAliasDetails,
    setCustomLinkAliasDetails,
    submitForm,
    createLinks,
    createLinkWarning,
    setCreateLinkWarning,
    createLinkLoading,
    uplifterIdLoading,
    uniqueIDError,
    createLinkError,
  } = useTrackCreateFormSubmit({
    formSubmissionState,
    setFormSubmissionState,
    generatedStructure,
  })

  const [fetchSFMCEmailHTML] = useLazyQuery(getSalesforceMCEmailTemplateDetail)
  const [fetchSFPardotEmailHTML] = useLazyQuery(getSalesforceEmailHTML, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })
  const [fetchEloquaEmailHtml] = useLazyQuery(getEloquaTemplateHTML, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  const [updateSFMCEmailHtml] = useMutation(updateSalesforceMCEmailTemplate)
  const [updatePardotEmailHtml] = useMutation(
    updateSalesforceEmailHTMLByEmailID,
  )
  const [updateEloquaEmailHtml] = useMutation(updateEloquaTemplateEmailHTML)

  const [fetchingEmailHtml, setFetchingEmailHtml] = useState(false)
  const [
    integrationUpdateSuccessModal,
    setIntegrationUpdateSuccessModal,
  ] = useState<IntegrationSuccessModal | null>(null)
  const [integrationError, setIntegrationError] = useState(false)
  const [pardotManualUpdateMessage, setPardotManualUpdateMessage] = useState(
    false,
  )

  // Used to ensure submit button isn't triggered by multiple clicks in quick succession
  const [codeCreatedTimeout, setCodeCreatedTimeout] = useState(false)
  const [showLinksPreviewModal, setShowLinksPreviewModal] = useState(false)
  const [linksToPreview, setLinksToPreview] = useState<FullLinkResult[]>([])

  const [domainValidationFailed, setDomainValidationFailed] = useState(false)
  const [totalLinkCount, setTotalLinkCount] = useState(0)

  const showClearButton = useMemo(() => {
    const {
      emailFields: { emailSource, emailHtml },
      generatorParameterValues,
    } = formValues

    // emailHtml should only be clearable if emailSource is html
    if (emailSource === 'emailHtml' && emailHtml.length > 0) {
      return true
    }

    // If any integration-specific fields are populated, show clear button
    if (emailSource !== 'emailHtml') {
      const allIntegrationEmailFields = Object.entries(
        formValues.emailFields,
      ).filter(([key]) => {
        return !['emailHtml', 'emailSource', 'generatedEmailHtml'].includes(key)
      })

      if (allIntegrationEmailFields.some(([key, value]) => !!value)) {
        return true
      }
    }

    return Object.values(generatorParameterValues).some(
      (paramValue) => paramValue && paramValue.length > 0,
    )
  }, [formValues])

  const onSubmit = useCallback(
    async (formVals: EmailForm, ignoreWarnings?: SoftWarning[]) => {
      setTotalLinkCount(0)
      setIntegrationError(false)
      setPardotManualUpdateMessage(false)

      const { success, fullLinks } = await submitForm(formVals, ignoreWarnings)

      setTotalLinkCount(fullLinks.length)

      // None of the links found matched any in the list of domains
      if (fullLinks.length === 0) {
        setDomainValidationFailed(true)
        return
      }

      // Too many links found in the emailHtml
      if (fullLinks.length > maxBatchShortLinks) {
        return
      }

      // Scroll to first errored field if any
      if (!success) {
        const errorFields = formRef.current?.querySelectorAll(
          "div[class*='error']",
        )

        if (errorFields && errorFields.length > 0) {
          errorFields[0].scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
        }

        return
      }

      setLinksToPreview(fullLinks)
      setShowLinksPreviewModal(true)
    },
    [linkType, submitForm, createLinks, formRef.current],
  )

  // Set default values for parameters that have one set
  useEffect(() => {
    if (!generatedStructure) return

    const { paramDefs } = generatedStructure

    const paramsWithEmailDefaults = paramDefs.filter(
      ({ emailDefault }) => emailDefault?.optionID,
    )

    if (paramsWithEmailDefaults.length === 0) return

    const paramsToUpdate: GeneratorParameterValues = {}

    paramsWithEmailDefaults.forEach(({ fieldID, emailDefault }) => {
      if (!emailDefault || !emailDefault.optionID) return

      // Do not change the value if it is already set
      if (
        formValues.generatorParameterValues[fieldID] &&
        formValues.generatorParameterValues[fieldID].length > 0 &&
        formValues.generatorParameterValues[fieldID][0] !== ''
      ) {
        return
      }

      paramsToUpdate[fieldID] = [emailDefault.optionID]
    })

    updateFormValues(
      {
        generatorParameterValues: paramsToUpdate,
      },
      { errorsToRemove: paramsWithEmailDefaults.map(({ fieldID }) => fieldID) },
    )
  }, [generatedStructure])

  if (generatorError) {
    return (
      <OuterBox className={styles.outerBox}>
        <InnerBox>
          <NoDataMessage
            errorMsg={
              <>
                <p>Failed to retrieve link generator parameters.</p>
                <p>
                  <Link type="arrowForward" href={`mailto:${supportEmail}`}>
                    Contact {supportEmail}
                  </Link>
                </p>
              </>
            }
            showSupportLink={false}
          />
        </InnerBox>
      </OuterBox>
    )
  }

  if (loadingGenerator || uplifterIdLoading) {
    return (
      <OuterBox className={styles.outerBox}>
        <InnerBox>
          <FormLoading rowCount={6} includeBorders>
            <Button isDisabled style={{ width: 'fit-content' }}>
              Fetching link builder
            </Button>
          </FormLoading>
        </InnerBox>
      </OuterBox>
    )
  }

  return (
    <>
      <OuterBox className={styles.outerBox}>
        <InnerBox className={styles.innerBox}>
          <div className={styles.formIntro}>
            <p>
              Find and replace links in email templates.{' '}
              <Link
                type="arrowForward"
                href="https://support.uplifter.ai/hc/en-us/articles/7984756219677-How-to-track-email-links-in-Uplifter"
              >
                Learn more
              </Link>
            </p>
            {showClearButton && (
              <ClearButton
                className={styles.clearAllButton}
                iconAfter
                onPress={() => {
                  // Set all form values back to empty
                  const generatorParameterValues = {}
                  Object.keys(formValues.generatorParameterValues).forEach(
                    (param) => {
                      generatorParameterValues[param] = []
                    },
                  )

                  const emailFields = {
                    emailSource: formValues.emailFields.emailSource,
                    emailHtml: '',
                  }

                  // Set all integration fields to null
                  if (formValues.emailFields.emailSource !== 'emailHtml') {
                    const allIntegrationEmailFields = Object.entries(
                      formValues.emailFields,
                    ).filter(([key]) => {
                      return ![
                        'emailHtml',
                        'emailSource',
                        'generatedEmailHtml',
                      ].includes(key)
                    })

                    allIntegrationEmailFields.forEach(([key]) => {
                      emailFields[key] = null
                    })
                  }

                  updateFormValues(
                    {
                      emailFields,
                      generatorParameterValues,
                    },
                    {
                      resetSubmissionState: true,
                    },
                  )

                  setDomainValidationFailed(false)
                  setTotalLinkCount(0)
                }}
              >
                Clear all
              </ClearButton>
            )}
          </div>
          <form
            ref={formRef}
            className={styles.trackCreateForm}
            onSubmit={async (e) => {
              e.preventDefault()

              if (codeCreatedTimeout) return

              setCodeCreatedTimeout(true)

              await onSubmit(formValues)

              window.setTimeout(() => setCodeCreatedTimeout(false), 800)
            }}
          >
            <TrackCreateFormEmailFields
              formValues={formValues.emailFields}
              updateFormValues={(emailFields, options) => {
                updateFormValues({ emailFields }, options)

                setDomainValidationFailed(false)
                setTotalLinkCount(0)
              }}
              showErrorMessages={showErrorMessages}
              fieldsWithErrors={fieldsWithErrors}
              setFetchingEmailHtml={setFetchingEmailHtml}
            />
            <div style={{ paddingBottom: 16 }}>
              <GeneratorParameterFields
                generatedStructure={generatedStructure}
                formValues={formValues.generatorParameterValues}
                allowMultipleValues={false}
                onChange={(fieldID, newVal, options) => {
                  updateFormValues(
                    {
                      generatorParameterValues: {
                        [fieldID]: newVal || [],
                      },
                    },
                    options,
                  )
                }}
                showErrorMessages={showErrorMessages}
                fieldsWithErrors={fieldsWithErrors}
              />
            </div>
            <TrackCreateSubmitFields
              canUseLinkType={canUseCustomLinks}
              validationChecks={generatedStructure?.validationChecks || null}
              linkType={linkType}
              setLinkType={setLinkType}
              // Email should always use batch alias
              linkCount={maxBatchShortLinks}
              customLinkAliasDetails={customLinkAliasDetails}
              setCustomLinkAliasDetails={setCustomLinkAliasDetails}
              submitLoading={createLinkLoading || fetchingEmailHtml}
              softDisable={!createLinkLoading && softDisable}
              submitDisabled={
                codeCreatedTimeout ||
                !!uniqueIDError ||
                totalLinkCount > maxBatchShortLinks ||
                formValues.emailFields.emailHtml.length > maxEmailHtmlLength
              }
            >
              {showErrorMessages && fieldsWithErrors.length > 0 && (
                <ErrorMessage>
                  Please have a value for every required parameter.
                </ErrorMessage>
              )}
              {!softDisable && domainValidationFailed && (
                <ErrorMessage>
                  None of the links found matched any in your list of domains.
                  Admins can edit the list in{' '}
                  <Link href="/track/edit-parameters-and-rules">
                    advanced options
                  </Link>
                  .
                </ErrorMessage>
              )}
              {totalLinkCount > maxBatchShortLinks && (
                <ErrorMessage>
                  You are trying to create up to{' '}
                  {numeral(totalLinkCount).format('0,0')} links. Please reduce
                  this to {numeral(maxBatchShortLinks).format('0, 0')} or less.
                </ErrorMessage>
              )}
              {pardotManualUpdateMessage &&
                formIsSalesforcePardotEmailFields(formValues.emailFields) && (
                  <ErrorMessage className={styles.warning}>
                    Email list templates cannot be updated via API. You will
                    need to copy the new email HTML into the template in your{' '}
                    <NavigateButton
                      onPress={() => {
                        window.open(
                          // @ts-ignore
                          formValues.emailFields.salesforcePardotEmailFields
                            ?.emailTemplate?.directLink as string,
                          '_blank',
                        )
                      }}
                    >
                      Salesforce account
                    </NavigateButton>
                  </ErrorMessage>
                )}
              {uniqueIDError && (
                <ErrorMessage>
                  Error generating a unique link ID. Please reload the page and
                  try again, or contact{' '}
                  <Link href={supportEmail}>{supportEmail}</Link>.
                </ErrorMessage>
              )}
              {createLinkError && (
                <ErrorMessage>
                  Error creating links, please refresh and try again.
                </ErrorMessage>
              )}
              {customLinkAliasDetails.error && (
                <ErrorMessage>
                  There was an error creating your short link. Please refresh it
                  and try again.
                </ErrorMessage>
              )}
              {integrationError && (
                <ErrorMessage>
                  Your links were created in Uplifter, but we were unable to
                  update the email content in your connected platform. Please
                  try copy-pasting the email generated below directly into your
                  email platform.
                </ErrorMessage>
              )}
            </TrackCreateSubmitFields>
          </form>
        </InnerBox>
      </OuterBox>
      {showLinksPreviewModal && linksToPreview.length > 0 && (
        <TrackCreatePreviewLinksTable
          isEmail
          editableParameters
          generatedStructure={generatedStructure}
          setIsOpen={setShowLinksPreviewModal}
          linksToPreview={linksToPreview}
          setLinksToPreview={setLinksToPreview}
          linkType={linkType}
          customLinkAliasDetails={customLinkAliasDetails}
          setCustomLinkAliasDetails={setCustomLinkAliasDetails}
          onSubmit={async (linksToCreate, bulkStart, editedLinkAliases) => {
            setIntegrationError(false)
            setPardotManualUpdateMessage(false)

            const useShortLinks = linkType === 'shortLink'

            const uneditedLinks = _.cloneDeep(linksToCreate)
            const editedLinks: FullLinkResult[] = []

            // Change alias values for edited links
            // These will be in a secondary createLinks call, not using the bulkStart value
            if (
              editedLinkAliases &&
              Object.keys(editedLinkAliases).length > 0
            ) {
              Object.keys(editedLinkAliases).forEach((fC) => {
                const editedLinkIndex = uneditedLinks.findIndex(
                  (link) => link.fC === fC,
                )

                // Might happen if an alias is edited but not selected
                if (editedLinkIndex === -1) return

                const editedLink = uneditedLinks.splice(editedLinkIndex, 1)

                editedLinks.push({
                  ...editedLink[0],
                  shortLinkID: editedLinkAliases[fC].alias,
                })
              })
            }

            // Artificially add the batch suffixes to the unedited links
            const updatedFullLinks: FullLinkResult[] = uneditedLinks.map(
              (uneditedLink, uneditedLinkIndex) => ({
                ...uneditedLink,
                shortLinkID: useShortLinks
                  ? `${uneditedLink.shortLinkID}-0x${(
                      uneditedLinkIndex + 1
                    ).toString(16)}`
                  : uneditedLink.shortLinkID,
              }),
            )

            let fullSuccess = false

            // Do edited links first to prevent weird alias changes in the modal
            if (editedLinks.length > 0) {
              const { success: individualLinksSuccess } = await createLinks(
                editedLinks,
                undefined,
                undefined,
                formValues.emailFields.emailSource,
              )

              fullSuccess = individualLinksSuccess
              updatedFullLinks.push(...editedLinks)
            } else {
              fullSuccess = true
            }

            if (fullSuccess && uneditedLinks.length > 0) {
              const { success } = await createLinks(
                uneditedLinks,
                undefined,
                bulkStart,
                formValues.emailFields.emailSource,
              )

              fullSuccess = success
            }

            if (!fullSuccess) {
              return
            }

            const createdEmailHtml = replaceEmailHtml(
              formValues.emailFields.emailHtml,
              updatedFullLinks,
              useShortLinks,
              selectedCustomLinkDomain?.domainValue || '',
            )

            let isIntegrationEmail = false
            const integrationFields: Partial<EmailFields> = {}
            let integrationUpdateFailed = false

            // Integration-specific updates
            if (formIsSalesforceMCEmailFields(formValues.emailFields)) {
              isIntegrationEmail = true

              // Update links in locations specific to Salesforce Marketing Cloud
              const { newTextContent, newSlotMap } = replaceSalesforceMCLinks(
                formValues.emailFields,
                updatedFullLinks,
                useShortLinks,
                selectedCustomLinkDomain?.domainValue || '',
              )

              const { salesforceMCEmailFields } = formValues.emailFields

              if (
                !salesforceMCEmailFields ||
                !salesforceMCEmailFields.businessUnitId ||
                !salesforceMCEmailFields.templateID
              ) {
                return
              }

              const { businessUnitId, templateID } = salesforceMCEmailFields

              const { errors } = await updateSFMCEmailHtml({
                variables: {
                  mid: businessUnitId,
                  templateID,
                  updatedHTMLMessage: createdEmailHtml.raw,
                  updatedSlotMap:
                    newSlotMap?.map(({ slotID, content, blocks }) => ({
                      slotID,
                      content,
                      blocks,
                    })) || undefined,
                  updatedTextContent: newTextContent,
                },
              })

              if (errors && errors.length > 0) {
                integrationUpdateFailed = true
              }

              // Refetch the selected email's HTML
              const { data: htmlData } = await fetchSFMCEmailHTML({
                variables: {
                  mid: businessUnitId,
                  templateID,
                },
              })

              if (htmlData) {
                const {
                  templateType,
                  templateContent,
                  slotMap,
                  textContent,
                } = htmlData.track.salesforceMarketingCloudQueries.getTemplateDetail

                const flaggedSlotMap =
                  templateType === 'templatebasedemail' &&
                  Array.isArray(slotMap) &&
                  slotMap.length > 0
                    ? slotMap.map((slot) => {
                        let hasLinks = htmlHasLinks(slot.content)

                        // For efficiency, only run this check if main content does not have links
                        if (!hasLinks) {
                          hasLinks = !!slot.blocks.find((block) =>
                            htmlHasLinks(block.content),
                          )
                        }

                        return {
                          ...slot,
                          hasLinks,
                        }
                      })
                    : null

                integrationFields.emailHtml = templateContent || ''

                // @ts-ignore
                integrationFields.salesforceMCEmailFields = {
                  ...formValues.emailFields.salesforceMCEmailFields,
                  slotMap: flaggedSlotMap,
                  textContent,
                }
              } else {
                integrationUpdateFailed = true
              }
            } else if (
              formIsSalesforcePardotEmailFields(formValues.emailFields)
            ) {
              const {
                emailSource,
                salesforcePardotEmailFields,
              } = formValues.emailFields

              if (
                !salesforcePardotEmailFields ||
                !salesforcePardotEmailFields.emailTemplate
              ) {
                return
              }

              // Do not hit 'updatePardotEmailHtml' for classic list templates
              // These must be updated by the user manually
              if (
                !salesforcePardotEmailFields.emailTemplate.isClassicListEmail
              ) {
                isIntegrationEmail = true

                const {
                  emailID,
                  pardotID,
                  pardotName,
                  uiType,
                } = salesforcePardotEmailFields.emailTemplate

                await updatePardotEmailHtml({
                  variables: {
                    emailID,
                    generatedPardotLinks: [],
                    pardotID: pardotID || '',
                    pardotName: pardotName || '',
                    uiType,
                    updatedHTMLMessage: createdEmailHtml.raw,
                  },
                })

                // Refetch the selected email's HTML
                const { data: htmlData } = await fetchSFPardotEmailHTML({
                  variables: {
                    [`${emailSource}PardotTemplateList`]: [
                      {
                        pardotID,
                        pardotName,
                        emailID,
                        uiType,
                        isClassicListEmail: false,
                      },
                    ],
                  },
                })

                if (htmlData) {
                  integrationFields.emailHtml =
                    htmlData.track.salesforcePardotEmailHTML[
                      `${emailSource}Builder`
                    ][0].emailHTML || ''
                } else {
                  integrationUpdateFailed = true
                }
              } else {
                setPardotManualUpdateMessage(true)
              }
            } else if (formIsEloquaEmailFields(formValues.emailFields)) {
              isIntegrationEmail = true

              const { eloquaEmailFields } = formValues.emailFields

              if (
                !eloquaEmailFields ||
                !eloquaEmailFields.eloquaOrg ||
                !eloquaEmailFields.emailTemplate
              ) {
                return
              }

              const {
                eloquaOrg: { eloquaOrgID },
                emailTemplate: { eloquaID },
              } = eloquaEmailFields

              await updateEloquaEmailHtml({
                variables: {
                  eloquaOrgID,
                  templateID: eloquaID,
                  updatedHTMLMessage: createdEmailHtml.raw,
                },
              })

              // Refetch the selected email's HTML
              const { data: htmlData } = await fetchEloquaEmailHtml({
                variables: {
                  eloquaOrgID,
                  templateID: eloquaID,
                },
              })

              if (htmlData) {
                integrationFields.emailHtml =
                  htmlData.track.eloquaQueries.getEloquaEmailTemplateDetail
                    .emailHtml || ''
              } else {
                integrationUpdateFailed = true
              }
            }

            if (integrationUpdateFailed) {
              setIntegrationError(true)
            }

            // Ensure most recently created email is saved to localStorage
            updateFormValues({
              emailFields: {
                ...integrationFields,
                generatedEmailHtml: createdEmailHtml.raw,
              },
            })

            onCreateLinks({
              fullLinks: updatedFullLinks,
              customLinkDomain: selectedCustomLinkDomain.domainValue || '',
              useShortLinks,
              emailSource: formValues.emailFields.emailSource,
              createdEmailHtml,
            })

            // Integration instances need to show a confirmation modal
            if (isIntegrationEmail) {
              setIntegrationUpdateSuccessModal({
                integrationType: formValues.emailFields.emailSource,
                isListTemplate:
                  formIsSalesforcePardotEmailFields(formValues.emailFields) &&
                  formValues.emailFields.salesforcePardotEmailFields
                    ?.emailTemplate?.isClassicListEmail,
              })
            }

            setLinksToPreview([])
            setShowLinksPreviewModal(false)
          }}
          createLinkError={createLinkError}
          customLinkError={customLinkAliasDetails.error}
        />
      )}
      {integrationUpdateSuccessModal && (
        <Modal setIsOpen={() => setIntegrationUpdateSuccessModal(null)}>
          {integrationUpdateSuccessModal.isListTemplate ? (
            <>
              <p>
                This is a list email. You need to manually copy the below HTML
                into Salesforce.
              </p>
              <div className={styles.emailPreviewHeader}>
                <p style={{ margin: 0 }}>
                  <Tooltip
                    id="salesforce-pardot-tooltip"
                    useIcon
                    tooltipPosition="right"
                    tooltipMessage="We've replaced your old HTML with this new HTML."
                  >
                    New Salesforce Pardot HTML
                  </Tooltip>
                </p>
                <CopyButton
                  className={styles.copyButton}
                  value={formValues.emailFields.generatedEmailHtml}
                >
                  Copy
                </CopyButton>
                <Button
                  variant="secondary"
                  onPress={async () => {
                    const data = formValues.emailFields.generatedEmailHtml || ''

                    const blob = new Blob([data], {
                      type: 'text/plain;charset=utf-8',
                    })
                    const now = new Date(Date.now())

                    await FileSaver.saveAs(
                      blob,
                      `${moment(now).format(
                        'YYYY-MM-DD',
                      )} ${brandName} Email HTML.txt`,
                    )
                  }}
                >
                  Download
                </Button>
              </div>
              <div className={styles.previewCode}>
                <code>{formValues.emailFields.generatedEmailHtml}</code>
              </div>
            </>
          ) : (
            <p style={{ margin: 0 }}>
              Your{' '}
              {integrationUpdateSuccessModal.integrationType === 'eloqua'
                ? 'Eloqua'
                : `Salesforce ${
                    integrationUpdateSuccessModal.integrationType ===
                    'salesforceMC'
                      ? 'Marketing Cloud'
                      : 'Pardot'
                  }`}{' '}
              links have been successfuly replaced.
            </p>
          )}
        </Modal>
      )}
      {createLinkWarning?.type === 'has-anchor' && (
        <CreateLinkAnchorWarningModal
          createLinkWarning={createLinkWarning}
          setCreateLinkWarning={setCreateLinkWarning}
          buildLinks={async (alreadyIgnoredWarnings) => {
            const warningsToIgnore = alreadyIgnoredWarnings || []

            warningsToIgnore.push('has-anchor')

            await onSubmit(formValues, [...new Set(warningsToIgnore)])
          }}
        />
      )}
      {['invalid-query-length', 'invalid-landing-page-length'].indexOf(
        createLinkWarning?.type || '',
      ) > -1 && (
        <CreateLinkInvalidLengthModal
          createLinkWarning={createLinkWarning as HardWarningModalType}
          setCreateLinkWarning={setCreateLinkWarning}
        />
      )}
    </>
  )
}

export default TrackCreateFormEmail
