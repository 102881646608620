import { gql } from '../../__gql-types__/gql'

export const switchWorkspaceMutation = gql(`
	mutation SwitchAccount (
		$workspaceID: String!
	) {
		userAccountSettings {
			changeCurrentAccount (accountID: $workspaceID) {
				token
			}
		}
	}
`)
