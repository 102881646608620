import React from 'react'
import classNames from 'classnames'

import styles from '../styles/tag.module.scss'
import Light from '../assets/light-tag.svg'

interface Props {
  icon?: string
  text?: string
  className?: string
  children?: React.ReactNode
  inline?: boolean
  multiLine?: boolean
}

export default function Tag({
  icon = Light,
  text = 'INSTANT INTEGRATION',
  className,
  children,
  inline,
  multiLine,
}: Props): React.ReactElement {
  return (
    <div
      className={classNames(styles.wrapper, className, {
        [styles.inline]: inline,
        [styles.multiLine]: multiLine,
      })}
    >
      <div className={styles.inner}>
        <img src={icon} alt="text" />
        {children ? <span>{children}</span> : <span>{text}</span>}
      </div>
    </div>
  )
}

export function TagNoIcon({ children }: any): React.ReactElement {
  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <span>{children}</span>
      </div>
    </div>
  )
}
