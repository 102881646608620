import React from 'react'
import classNames from 'classnames'

import styles from '../styles/counter.module.scss'

export function CounterLabel({
  setupStatus = '',
  className,
  grey = false,
}: {
  className?: string
  setupStatus?: '' | 'presetup' | 'IN_PROGRESS' | 'failed' | 'completed'
  grey?: boolean
}): React.ReactElement {
  if (setupStatus !== 'completed') {
    const isError = setupStatus === 'failed'
    return (
      <span
        className={classNames(
          styles.issuesCounterOK,
          className,
          styles.inProgress,
          {
            [styles.grey]: grey,
            [styles.error]: isError,
          },
        )}
      >
        <b className={styles.statusLabel}>
          {isError ? 'failed' : 'setting up'}
        </b>
      </span>
    )
  }
  return <></>
}

export default function Counter({
  counter,
  loading = false,
  setupStatus = '',
  grey = false,
}: {
  setupStatus?: '' | 'presetup' | 'IN_PROGRESS' | 'failed' | 'completed'
  loading?: boolean
  counter: number | null
  grey?: boolean
}): React.ReactElement {
  if (setupStatus !== 'completed') {
    return <CounterLabel grey={grey} setupStatus={setupStatus} />
  }

  if (counter === null || counter === 0) {
    return (
      <span
        className={classNames(styles.issuesCounterOK, {
          [styles.issuesCounterLoading]: loading,
          [styles.grey]: grey,
        })}
      >
        0
      </span>
    )
  }

  return (
    <span
      className={classNames(styles.issuesCounter, {
        [styles.grey]: grey,
      })}
    >
      <b>{counter || '?'}</b>
    </span>
  )
}

interface BulletCounterProps {
  count: number | null
  className?: string
}

export function BulletCounter({
  count = null,
  className,
}: BulletCounterProps): React.ReactElement | null {
  if (count === null) {
    return null
  }
  return (
    <span className={classNames(className, styles.bulletCounter)}>{count}</span>
  )
}

interface BetaLabelProps {
  className?: string
  title?: string
}

export function BetaLabel({
  className,
  title = 'Beta',
}: BetaLabelProps): React.ReactElement | null {
  return (
    <span
      className={classNames(
        className,
        styles.bulletCounter,
        styles.bulletCounterBeta,
      )}
      style={{ height: 22 }}
    >
      {title}
    </span>
  )
}

interface BiLineProps {
  children: any
  arrowRight?: boolean
  arrowLeft?: boolean
  arrowTop?: boolean
  arrowBottom?: boolean
  className?: string
  centerAlign?: boolean
}

export function BiLine({
  children,
  arrowRight,
  arrowLeft,
  arrowTop,
  arrowBottom,
  className,
  centerAlign,
}: BiLineProps): React.ReactElement | null {
  return (
    <p
      className={classNames(className, styles.biLine, {
        [styles.arrowRight]: arrowRight,
        [styles.arrowLeft]: arrowLeft,
        [styles.arrowTop]: arrowTop,
        [styles.arrowBottom]: arrowBottom,
        [styles.centerAlign]: centerAlign,
      })}
    >
      {children}
    </p>
  )
}
