import React, { useEffect, useMemo, useState } from 'react'
import { useQuery, useReactiveVar } from '@apollo/client'
import { useLocation } from 'react-router-dom'

import { linkOrCode } from '../api/apollo/variables'
import { getCompanyDetails } from '../api/graphql/company-client'
import { getAccountDataSource } from '../api/graphql/workspace-client'
import Stars from '../assets/star-cluster-yellow.svg'
import Button from '../components/button'
import CampaignIntroContent from '../components/campaign-intro-content'
import Intro from '../components/intro'
import Layout from '../components/layout'
import Link from '../components/link'
import { LoadingLabel } from '../components/loader'
import SiteWrapper from '../components/site-wrapper'
import Tag from '../components/tag'
import TrackViewTable from '../components/track-view-table'
import { NoteText } from '../components/typography'
import { getDataSourceDescription } from '../helpers/report-module'

interface LocationState {
  fromBulk?: boolean
}

export default function TrackView() {
  const { state } = useLocation<LocationState>()

  const linkCopy = useReactiveVar(linkOrCode)

  const [showBulkMessage, setShowBulkMessage] = useState(false)

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const bulkEditParam = searchParams.get('bulkEdit')

    if (bulkEditParam || (state && state.fromBulk)) {
      setShowBulkMessage(true)

      window.setTimeout(() => {
        setShowBulkMessage(false)
      }, 10000)
    }
  }, [])

  const { data: companyData } = useQuery(getCompanyDetails)
  const { data: dataSourceData } = useQuery(getAccountDataSource)

  const dataSourceDescription = useMemo(() => {
    return getDataSourceDescription(dataSourceData, false)
  }, [dataSourceData])

  const isConnected = useMemo(() => {
    if (!companyData && !dataSourceData) return false

    if (
      dataSourceData &&
      dataSourceData.currentAccount.blankAccount &&
      dataSourceData.currentAccount.dataSource?.kind !== 'AA_REPORT'
    )
      return false

    if (
      companyData &&
      companyData.currentCompany.subscriptionLevel === 'preSetup'
    )
      return false

    return (
      dataSourceData && !!dataSourceData.currentAccount.dataSource?.connected
    )
  }, [companyData, dataSourceData])

  return (
    <SiteWrapper>
      <Layout width={2560}>
        <Intro title="View links">
          <CampaignIntroContent
            tagLine={
              <p>
                Review, share and edit {linkCopy}s.
                {isConnected && (
                  <>
                    {dataSourceDescription ? (
                      ` ${dataSourceDescription}`
                    ) : (
                      <>
                        {' '}
                        Metrics from: <LoadingLabel label="Loading" />
                      </>
                    )}
                  </>
                )}
              </p>
            }
          >
            <>
              {!isConnected && (
                <Tag inline icon={Stars}>
                  <p>
                    <Link href="/connect">Connect your analytics platform</Link>{' '}
                    to compare metrics for each link.
                  </p>
                </Tag>
              )}
              {showBulkMessage && (
                <NoteText sameLine>
                  <>
                    Your new {linkCopy} may take up to a minute to be created.{' '}
                    <Button
                      variant="text"
                      color="blue"
                      onPress={() => window.location.reload()}
                    >
                      Reload the page
                    </Button>{' '}
                    to see recent changes.
                  </>
                </NoteText>
              )}
            </>
          </CampaignIntroContent>
        </Intro>

        <TrackViewTable />
      </Layout>
    </SiteWrapper>
  )
}
