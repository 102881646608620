import React, { useState } from 'react'
import numeral from 'numeraljs'

import { ButtonRow } from './button-row'
import Button from './button'
import { Panel } from './header-panel'
import { SearchInput } from './input'
import { LoadingLabel, Preloader } from './loader'
import useLogAction from '../hooks/useLogAction'
import styles from '../styles/lost-links-search-box.module.scss'
import { LostLinksReportDataConfig } from '../types/report-module'

interface LostLinksSearchBoxProps {
  dataConfig: LostLinksReportDataConfig
  totalRows?: number
  loading?: boolean
  error?: boolean
  searchFields: { name: string; value: string }[]
  refetchData: (dataConfig: LostLinksReportDataConfig) => void
}

const LostLinksSearchBox = ({
  dataConfig,
  totalRows,
  loading,
  error,
  searchFields,
  refetchData,
}: LostLinksSearchBoxProps) => {
  const logAction = useLogAction()

  const currentTerm = dataConfig.searchFilter?.searchValue
  const currentType = dataConfig.searchFilter?.searchField

  const [searchTerm, setSearchTerm] = useState(currentTerm || '')
  const [searchType, setSearchType] = useState(currentType || 'all')
  const [isFiltered, setIsFiltered] = useState(false)

  return (
    <Panel>
      <ButtonRow className={styles.panelButtonRow}>
        <SearchInput
          className={styles.searchInput}
          value={searchTerm}
          selectValue={searchType}
          onChange={(value) => setSearchTerm(value || '')}
          searchTypeList={searchFields}
          onChangeSearchType={(type) => setSearchType(type)}
          onKeyUp={async (e) => {
            if (
              !loading &&
              e.key === 'Enter' &&
              (currentTerm !== searchTerm ||
                (searchTerm === '' && !!currentTerm))
            ) {
              await refetchData({
                ...dataConfig,
                searchFilter:
                  searchTerm === '' && searchType === 'all'
                    ? undefined
                    : {
                        searchField: searchType,
                        searchValue: searchTerm,
                      },
              })
              setIsFiltered(true)

              logAction({
                variables: {
                  action: 'update-search-term-link-audit',
                  extra:
                    searchTerm === '' && searchType === 'all'
                      ? ''
                      : JSON.stringify({
                          searchField: searchType,
                          searchValue: searchTerm,
                        }),
                  websiteSection: 'report',
                  functionName: 'linkAuditSearch',
                  pagePath: window.location.pathname,
                },
              })

              // If enter is pressed when search is empty, reset type
              if (searchTerm === '' && !!currentTerm) {
                setSearchType('all')
              }
            }
          }}
        >
          <div className={styles.searchInputButton}>
            <Button
              variant="secondary"
              isDisabled={
                error ||
                loading ||
                (searchTerm === '' && searchTerm === currentTerm)
              }
              onPress={async () => {
                if (currentTerm !== searchTerm || currentType !== searchType) {
                  await refetchData({
                    ...dataConfig,
                    searchFilter:
                      searchTerm === '' && searchType === 'all'
                        ? undefined
                        : {
                            searchField: searchType,
                            searchValue: searchTerm,
                          },
                  })

                  logAction({
                    variables: {
                      action: 'update-search-term-link-audit',
                      extra:
                        searchTerm === '' && searchType === 'all'
                          ? ''
                          : JSON.stringify({
                              searchField: searchType,
                              searchValue: searchTerm,
                            }),
                      websiteSection: 'report',
                      functionName: 'linkAuditSearch',
                      pagePath: window.location.pathname,
                    },
                  })

                  setIsFiltered(true)
                  return
                }

                // Reset filters
                refetchData({
                  ...dataConfig,
                  searchFilter: undefined,
                })
                setIsFiltered(false)
                setSearchTerm('')
                setSearchType('all')
              }}
            >
              {(isFiltered &&
                currentTerm === searchTerm &&
                searchType === currentType) ||
              (searchTerm === '' && !!currentTerm)
                ? 'Clear'
                : 'Search'}
            </Button>
            {!error && loading ? (
              <div className={styles.searchLoading}>
                <Preloader className={styles.searchPreloader} />
                <LoadingLabel label="Retrieving data" />
              </div>
            ) : (
              <p>{numeral(totalRows).format('0,0')} links</p>
            )}
          </div>
        </SearchInput>
      </ButtonRow>
    </Panel>
  )
}

export default LostLinksSearchBox
