import React, { useState, useCallback } from 'react'

import { ButtonRow } from './button-row'
import Button from './button'
import Modal from './modal'
import GoogleAnalytics from '../assets/logos/google-analytics-icon.svg'
import AdobeAnalytics from '../assets/logos/adobe-analytics.svg'
import integrationsData, { isGenericConnector } from '../integrations/data'

interface ConnectAnalyticsModalProps {
  toggleModal: React.Dispatch<React.SetStateAction<boolean>>
  connectId: string
  showGoogle?: boolean
  showAdobe?: boolean
}

export function ConnectAnalyticsModal({
  toggleModal,
  connectId,
  showGoogle = true,
  showAdobe = true,
}: ConnectAnalyticsModalProps) {
  const [loading, setLoading] = useState(false)

  const [connectionFailed, setConnectionFailed] = useState(false)

  const getAuthLink = useCallback(async (adobe = false) => {
    setConnectionFailed(false)
    setLoading(true)

    try {
      const connector = integrationsData.find(
        (integration) =>
          integration.connectorID ===
          (adobe ? 'adobeAnalytics' : 'googleAnalytics'),
      )

      if (!connector || !isGenericConnector(connector.connectButton))
        throw new Error('Unable to connect')

      await connector.connectButton.connectFn(connectId)
    } catch {
      setConnectionFailed(true)
    }
  }, [])

  return (
    <Modal setIsOpen={toggleModal} modalHeader="Connect your website analytics">
      <>
        <p>
          Each workspace can be connected to one Google Analytics property (or
          view for Universal Analytics) or Adobe Analytics report suite. We
          recommend connecting to a configuration that excludes bot traffic.
        </p>
        <ButtonRow style={{ justifyContent: 'space-around' }}>
          {showGoogle && (
            <Button
              variant="secondary"
              icon={{
                src: GoogleAnalytics,
                alt: 'Google Analytics',
              }}
              isDisabled={loading}
              onPress={() => getAuthLink(false)}
            >
              Connect Google Analytics
            </Button>
          )}
          {showAdobe && (
            <Button
              variant="secondary"
              icon={{
                src: AdobeAnalytics,
                alt: 'Adobe Analytics',
              }}
              isDisabled={loading}
              onPress={() => getAuthLink(true)}
            >
              Connect Adobe Analytics
            </Button>
          )}
        </ButtonRow>
        {connectionFailed && <p>Connection failed. Please try again later.</p>}
      </>
    </Modal>
  )
}
