import React, { useRef, useState, useMemo } from 'react'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import classNames from 'classnames'

import Input from './input'
import Row, { Slot } from './row'
import { currentUserDetails } from '../api/apollo/variables'
import {
  getEmailNotifications,
  getPreferredHomepage,
  getProfileInfo,
  toggleEmailNotifications,
  updateMarketingPermissions,
  updatePreferredHomepage,
} from '../api/graphql/user-client'
import {
  ExplainIcon,
  ReportIcon,
  TrackIcon,
  WelcomeIcon,
} from '../assets/svgs/menu/module-icons'
import { isAdminUser } from '../helpers'
import useLogAction from '../hooks/useLogAction'
import styles from '../styles/preferences.module.scss'

interface PreferencesProps {
  className?: string
}

export default function Preferences({ className }: PreferencesProps) {
  const { userPermission, workspaceID } = useReactiveVar(currentUserDetails)

  const { data: profileData } = useQuery(getProfileInfo)
  const { data: homepageData } = useQuery(getPreferredHomepage)
  const { data: notificationsData } = useQuery(getEmailNotifications)

  const [updateUserPreferredHomepage] = useMutation(updatePreferredHomepage)
  const [updateMarketingPermission] = useMutation(updateMarketingPermissions)
  const [updateEmailNotifications] = useMutation(toggleEmailNotifications)

  const logAction = useLogAction()

  const acceptedMarketing = useMemo(() => {
    if (!profileData) return false

    return !!JSON.parse(profileData.currentUser.acceptedMarketing)
  }, [profileData])

  const preferredHomepage = useMemo(() => {
    if (!homepageData) return 'welcome'

    return homepageData.currentUser.preferredHomepage
  }, [homepageData])

  const availableModules = useMemo(() => {
    if (!homepageData)
      return {
        track: false,
        report: false,
      }

    return {
      track: homepageData.currentAccount.trackAvailable,
      report: homepageData.currentAccount.reportAvailable,
    }
  }, [homepageData])

  const emailSettings = useMemo(() => {
    if (!notificationsData)
      return {
        // explainEmail: false,
        reportEmail: false,
        trackEmail: false,
      }

    const currentAccountProfile = notificationsData.currentUser.userAccountProfiles.find(
      (profile) => profile.accountID === workspaceID,
    )

    if (!currentAccountProfile)
      return {
        // explainEmail: false,
        reportEmail: false,
        trackEmail: false,
      }

    return {
      // explainEmail: currentAccountProfile.explainEmail,
      reportEmail: currentAccountProfile.reportEmail,
      trackEmail: currentAccountProfile.trackEmail,
    }
  }, [notificationsData, workspaceID])

  const notificationsRef = useRef<HTMLSpanElement>(null)

  const [homepageError, setHomepageError] = useState(false)

  const homepageLinks = ['welcome', 'track', 'report', 'explain']

  return (
    <div className={className}>
      <h3>Homepage</h3>
      <p className={styles.byline}>Change the page you see after you login.</p>
      <div className={styles.subsection}>
        <div className={styles.updateHomepageWrapper}>
          {homepageLinks.map((page) => {
            if (page !== 'welcome' && !availableModules[page]) return null

            let icon: React.ReactElement | null = null

            switch (page) {
              case 'welcome':
                icon = <WelcomeIcon />
                break
              case 'track':
                icon = <TrackIcon />
                break
              case 'report':
                icon = <ReportIcon />
                break
              case 'explain':
                icon = <ExplainIcon />
                break
              default:
                break
            }

            return (
              <Input
                className={classNames(styles.pageSelector, {
                  [styles.checked]: preferredHomepage === page,
                })}
                name="select-homepage-welcome"
                id="select-homepage-welcome"
                label={
                  <>
                    {icon}
                    <span
                      className={styles.pageName}
                    >{`${page[0].toUpperCase()}${page.substring(1)}`}</span>
                  </>
                }
                type="radio"
                value="/track"
                checked={preferredHomepage === page}
                onClick={async (e) => {
                  e.preventDefault()
                  e.stopPropagation()

                  setHomepageError(false)

                  if (preferredHomepage !== page) {
                    const { errors } = await updateUserPreferredHomepage({
                      variables: {
                        newPage: page,
                      },
                    })

                    if (errors) {
                      setHomepageError(true)
                    }
                  }
                }}
              />
            )
          })}
        </div>
        {homepageError && (
          <p className={styles.errorMessage}>
            Update failed. Please try again later or contact support.
          </p>
        )}
      </div>

      <span className={styles.spacer} ref={notificationsRef} />

      <h3>Email notifications</h3>
      <p className={styles.byline}>Change which emails you receive from us.</p>
      <div className={styles.subsection}>
        <div className={styles.box}>
          <Row className={styles.notifs}>
            <Slot>
              <h3>Product updates</h3>
              <p>
                <strong>
                  {acceptedMarketing ? "You'll receive" : "You won't receive"}
                </strong>{' '}
                an email explaining new features and tips.
              </p>
            </Slot>
            <Slot width={100} align="center" vAlign="center">
              <button
                className={classNames({
                  [styles.enabled]: acceptedMarketing,
                  [styles.disabled]: !acceptedMarketing,
                })}
                onClick={() => {
                  const allow = !acceptedMarketing

                  updateMarketingPermission({
                    variables: {
                      allowMarketing: allow,
                    },
                  })

                  logAction({
                    variables: {
                      action: allow ? 'allow-marketing' : 'deny-marketing',
                      extra: '',
                      websiteSection: 'settings',
                      functionName: 'acceptMarketing',
                      pagePath: '/settings',
                    },
                  })
                }}
              >
                {acceptedMarketing ? 'Enabled' : 'Disabled'}
              </button>
            </Slot>
          </Row>

          {/* Explain module should be hidden for all users */}
          {/* <Row className={styles.notifs} odd>
            <Slot>
              <h3>Explain alerts</h3>
              <p>
                <strong>
                  {emailSettings.explainEmail
                    ? "You'll receive"
                    : "You won't receive"}
                </strong>{' '}
                updates about new explain results.
              </p>
            </Slot>
            <Slot width={100} align="center" vAlign="center">
              <button
                className={
                  emailSettings.explainEmail ? styles.enabled : styles.disabled
                }
                onClick={async () => {
                  await updateEmailNotifications({
                    variables: {
                      accountID: workspaceID,
                      explain: !emailSettings.explainEmail,
                      report: emailSettings.reportEmail,
                      track: emailSettings.trackEmail,
                    },
                  })
                }}
              >
                {emailSettings.explainEmail ? 'Enabled' : 'Disabled'}
              </button>
            </Slot>
          </Row> */}

          {isAdminUser(userPermission) && (
            <Row className={styles.notifs}>
              <Slot padding="0 16px 0 0">
                <h3>Track requests</h3>
                <p>
                  <strong>
                    {emailSettings.trackEmail
                      ? "You'll receive"
                      : "You won't receive"}
                  </strong>{' '}
                  new requests from teammates to add new values to your campaign
                  code creator.
                </p>
              </Slot>
              <Slot width={100} align="center" vAlign="center">
                <button
                  className={
                    emailSettings.trackEmail ? styles.enabled : styles.disabled
                  }
                  onClick={async () => {
                    await updateEmailNotifications({
                      variables: {
                        accountID: workspaceID,
                        // explain: emailSettings.explainEmail,
                        report: emailSettings.reportEmail,
                        track: !emailSettings.trackEmail,
                      },
                    })
                  }}
                >
                  {emailSettings.trackEmail ? 'Enabled' : 'Disabled'}
                </button>
              </Slot>
            </Row>
          )}
        </div>
      </div>
    </div>
  )
}
