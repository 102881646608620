export function aggregateGraphData(
  dates: string[],
  metrics: number[],
  anomaliesUnsorted: Anomaly[],
): [any, any] {
  const anomalyHistory: any = {}
  const anomalies: any = []

  for (let i = 0; i < anomaliesUnsorted.length; i += 1) {
    if (anomaliesUnsorted[i]) {
      anomalyHistory[anomaliesUnsorted[i].date] = anomaliesUnsorted[i]
    }
  }

  const data = dates.map((item, index): any => {
    const x = index
    const y = typeof metrics[index] === 'undefined' ? 0 : metrics[index]

    if (typeof anomalyHistory[item] !== 'undefined') {
      anomalies.push({
        ...anomalyHistory[item],
        x,
        y,
      })
    }

    return { x, y }
  })

  return [data, anomalies]
}

export default {}
