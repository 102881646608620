import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'

import styles from '../styles/display-error.module.scss'

interface Props {
  children: React.ReactNode
  attempts: number
  className?: string
}

export default function DisplayError({
  children,
  attempts,
  className,
}: Props): React.ReactElement {
  const [container, setContainer] = useState(styles.container)

  useEffect((): void => {
    if (attempts > 1) {
      setContainer(styles.shakeContainerActive)
      setTimeout((): void => {
        setContainer(styles.containerActive)
      }, 500)
    } else if (attempts > 0) {
      setContainer(styles.containerActive)
    } else {
      setContainer(styles.container)
    }
  }, [attempts])

  return (
    <section className={classNames(className, container)}>
      <div className={styles.inner}>
        {typeof children === 'string' ? (
          <ReactMarkdown plugins={[gfm]} linkTarget="_blank">
            {children}
          </ReactMarkdown>
        ) : (
          <p>{children}</p>
        )}
      </div>
    </section>
  )
}
