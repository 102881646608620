import React, { useState, useEffect } from 'react'
import { useReactiveVar } from '@apollo/client'

import styles from '../styles/anomaly-breakdown.module.scss'
import SimpleCarousel from './simple-carousel'
import Loader from './loader'
import {
  explainAnomalyDataReactive,
  explainCauseDataReactive,
} from '../api/apollo/legacy-reducers'
import { returnUnique } from '../helpers'

export default function CausesAndSolutionsCarousels({
  viewOnly = false,
}: {
  viewOnly?: boolean
}) {
  const explainAnomalyData = useReactiveVar(explainAnomalyDataReactive)
  const explainCausesData = useReactiveVar(explainCauseDataReactive)

  const [causeCarouselActive, setCauseCarouselActive] = useState(0)
  const [solutionCarouselActive, setSolutionCarouselActive] = useState(0)

  const { dislikedActions, dislikedCauses, likedActions, likedCauses } =
    explainAnomalyData?.description || {}

  const { recommendedCauses, loading } = explainCausesData || {}

  useEffect(() => {
    setCauseCarouselActive(0)
    setSolutionCarouselActive(0)
  }, [recommendedCauses])

  if (loading) {
    return (
      <>
        <Loader height="30px" />
        <Loader height="60px" />
        <Loader height="30px" />
        <Loader height="60px" />
      </>
    )
  }

  if (
    !recommendedCauses ||
    (recommendedCauses && recommendedCauses.length === 0)
  ) {
    return (
      <>
        <p>
          No possible causes available for the dimensions selected - please add
          your own above
        </p>
      </>
    )
  }

  const updateStatus = (anom: any) => {
    if (explainAnomalyData.description !== null) {
      // ! V1 endpoint no longer used
      // const data = { ...anomaly.description, ...anom }
      // const req = await make.post({
      //   endpoint: 'update-anomaly-date-description',
      //   data,
      // })
      // explainAnomalyDataReactive({
      //   description: { ...explainAnomalyData, ...req?.res?.data },
      // })
      // const {
      //   anomalyDate,
      //   anomalyNotInteresting,
      //   explainedAnomaly,
      //   selectedRelevantDimensions,
      //   userSuppliedAction,
      //   userSuppliedCause,
      //   userSuppliedEffort,
      //   userSuppliedBenefit,
      // } = {
      //   ...explainAnomalyData.description,
      //   ...anom,
      // }
      // const fDate = moment(anomalyDate, 'YYYYMMDD').format('DD/MM/YYYY')
      // const found = getItemByKeyValue(
      //   explainAnomaliesData[metricID].anomalyRange,
      //   'date',
      //   fDate,
      // )
      // const anomalyRange =
      //   found === -1
      //     ? [
      //         ...explainAnomaliesData[metricID].anomalyRange,
      //         { ...createAnomalyRangeObject(action.payload) },
      //       ]
      //     : explainAnomaliesData[metricID].anomalyRange.map((item) => {
      //         if (item.date === fDate) {
      //           return {
      //             ...item,
      //             anomalyNotInteresting,
      //             explainedAnomaly,
      //             selectedRelevantDimensions,
      //             userSuppliedAction,
      //             userSuppliedCause,
      //             userSuppliedEffort,
      //             userSuppliedBenefit,
      //           }
      //         }
      //         return item
      //       })
      // explainAnomaliesDataReactive({
      //   ...explainAnomaliesData,
      //   [metricID]: {
      //     ...explainAnomaliesData[metricID],
      //     anomalyRange
      //   }
      // })
    }
  }

  return (
    <SimpleCarousel
      viewOnly={viewOnly}
      defaultActive={causeCarouselActive}
      onClickHelpful={(index) => {
        const { causeID } = recommendedCauses[index]
        updateStatus({
          likedCauses: returnUnique([...(likedCauses || []), causeID]),
        })
      }}
      onClickNotHelpful={(index) => {
        const { causeID } = recommendedCauses[index]
        updateStatus({
          dislikedCauses: returnUnique([...(dislikedCauses || []), causeID]),
        })
      }}
      title="Possible causes"
      onChangeSlide={(slide) => {
        setSolutionCarouselActive(0)
        setCauseCarouselActive(slide)
      }}
      items={recommendedCauses.map((item) =>
        item ? (
          <section aria-label="solution" key={item.causeID}>
            <article className={styles.carouselBody}>
              <p>{item.cause}</p>
            </article>
            <SimpleCarousel
              viewOnly={viewOnly}
              defaultActive={solutionCarouselActive}
              title="Possible solutions"
              onClickHelpful={(index) => {
                const { causeID } = recommendedCauses[index]
                updateStatus({
                  likedActions: returnUnique([
                    ...(likedActions || []),
                    causeID,
                  ]),
                })
              }}
              onClickNotHelpful={(index) => {
                const { causeID } = recommendedCauses[index]
                updateStatus({
                  dislikedActions: returnUnique([
                    ...(dislikedActions || []),
                    causeID,
                  ]),
                })
              }}
              onChangeSlide={(slide) => {
                setSolutionCarouselActive(slide)
              }}
              items={item.actions.map((action) =>
                item ? (
                  <section aria-label="solution">
                    <article className={styles.carouselBody}>
                      <p>{action.action}</p>
                    </article>
                  </section>
                ) : null,
              )}
            />
          </section>
        ) : null,
      )}
    />
  )
}
