import React from 'react'

interface SVGProps {
  color?: string
  className?: string
  style?: object
}

export const PersonIcon = ({
  color = '#ffffff',
  ...props
}: SVGProps): React.ReactElement<React.ReactSVGElement> => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M9.99988 11C13.0393 11 15.5026 8.53789 15.5026 5.5C15.5026 2.46211 13.0393 0 9.99988 0C6.96048 0 4.49716 2.46211 4.49716 5.5C4.49716 8.53789 6.96048 11 9.99988 11ZM13.8518 12.375H13.1338C12.1795 12.8133 11.1176 13.0625 9.99988 13.0625C8.88214 13.0625 7.82458 12.8133 6.86591 12.375H6.14797C2.95811 12.375 0.370117 14.9617 0.370117 18.15V19.9375C0.370117 21.0762 1.2944 22 2.43364 22H17.5661C18.7053 22 19.6296 21.0762 19.6296 19.9375V18.15C19.6296 14.9617 17.0416 12.375 13.8518 12.375Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect x="0.370117" width="19.2595" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const PeopleIcon = ({
  color = '#ffffff',
  ...props
}: SVGProps): React.ReactElement<React.ReactSVGElement> => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="peopleIconTitle"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      color={color}
      {...props}
    >
      <path d="M1 18C1 15.75 4 15.75 5.5 14.25 6.25 13.5 4 13.5 4 9.75 4 7.25025 4.99975 6 7 6 9.00025 6 10 7.25025 10 9.75 10 13.5 7.75 13.5 8.5 14.25 10 15.75 13 15.75 13 18M12.7918114 15.7266684C13.2840551 15.548266 13.6874862 15.3832994 14.0021045 15.2317685 14.552776 14.9665463 15.0840574 14.6659426 15.5 14.25 16.25 13.5 14 13.5 14 9.75 14 7.25025 14.99975 6 17 6 19.00025 6 20 7.25025 20 9.75 20 13.5 17.75 13.5 18.5 14.25 20 15.75 23 15.75 23 18" />{' '}
      <path
        strokeLinecap="round"
        d="M12,16 C12.3662741,15.8763472 12.6302112,15.7852366 12.7918114,15.7266684"
        fill={color}
      />{' '}
    </svg>
  )
}
