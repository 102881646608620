import { useEffect } from 'react'

export default function useEvent(
  event: any,
  handler: (e: any) => void,
  passive = false,
) {
  useEffect(() => {
    // initiate the event handler
    window.addEventListener(event, handler, passive)

    // this will clean up the event every time the component is re-rendered
    return function cleanup() {
      window.removeEventListener(event, handler)
    }
  })
}
