import React, { useCallback, useState } from 'react'
import { useLazyQuery, useReactiveVar } from '@apollo/client'

import { Preloader } from './loader'
import Row, { Slot } from './row'
import Tag from './tag'
import { currentUserDetails } from '../api/apollo/variables'
import {
  fetchAdobeAuthLink,
  fetchGaAuthLink,
} from '../api/graphql/oauth-client'
import AAImage from '../assets/logos/adobe-analytics.svg'
import AnalyticsImage from '../assets/logos/google-analytics-icon.svg'
import styles from '../styles/connect-button.module.scss'

interface ConnectButtonProps {
  adobe?: boolean
  reconnect?: boolean
}

export default function ConnectButton({
  adobe,
  reconnect,
}: ConnectButtonProps) {
  const { workspaceID } = useReactiveVar(currentUserDetails)

  const [getGaAuthLink] = useLazyQuery(fetchGaAuthLink)
  const [getAaAuthLink] = useLazyQuery(fetchAdobeAuthLink)

  const [connectionFailed, setConnectionFailed] = useState(false)

  const onClick = useCallback(async () => {
    try {
      const { data } = await getGaAuthLink({
        variables: {
          data: JSON.stringify({
            connectId: workspaceID,
            fromId: workspaceID,
            reconnect,
          }),
        },
      })

      if (!data) throw new Error('Link not found')

      window.location.assign(data.accountSettings.dataSourceQueries.gaAuthLink)
    } catch {
      setConnectionFailed(true)
    }
  }, [workspaceID])

  const onClickAdobe = useCallback(async () => {
    try {
      const { data } = await getAaAuthLink({
        variables: {
          data: JSON.stringify({
            connectId: workspaceID,
            fromId: workspaceID,
            reconnect,
          }),
        },
      })

      if (!data) throw new Error('Link not found')

      window.location.assign(
        data.accountSettings.dataSourceQueries.adobeAuthLink,
      )
    } catch {
      setConnectionFailed(true)
    }
  }, [workspaceID])

  if (!workspaceID) return <Preloader />

  return (
    <>
      <div
        className={styles.card}
        onClick={adobe ? onClickAdobe : onClick}
        onKeyDown={adobe ? onClickAdobe : onClick}
        role="button"
        tabIndex={-1}
      >
        <Row>
          <Slot className={styles.logo}>
            <img
              src={adobe ? AAImage : AnalyticsImage}
              alt={`Connect to ${adobe ? 'Adobe' : 'Google'} Analytics`}
              style={{ display: 'block' }}
            />
          </Slot>
          <Slot className={styles.textBoxOuter}>
            <Row>
              <Slot className={styles.textBox}>
                <div>
                  <Tag className={styles.tag} />
                </div>
                <h3 className={styles.title}>
                  Connect {adobe ? 'Adobe' : 'Google'} Analytics
                </h3>
              </Slot>
            </Row>
          </Slot>
        </Row>
      </div>
      {connectionFailed && (
        <p className={styles.errorMsg}>
          Connection failed. Please try again later.
        </p>
      )}
    </>
  )
}
