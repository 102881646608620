import { useEffect, useMemo } from 'react'
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client'

import { currentUserDetails } from '../api/apollo/variables'
import {
  getCompanyDetails,
  getMicrosoftSubscriptionData,
  getPaddleSubscriptionDetails,
} from '../api/graphql/company-client'
import { getCompanySubscriptionLevel } from '../helpers'

export default function useSubscriptionLevel() {
  const { companySubscriptionLevel, isDemoAccount } = useReactiveVar(
    currentUserDetails,
  )

  const { data: companyData } = useQuery(getCompanyDetails)

  const [
    getPaddleDetails,
    { data: paddleData, loading: loadingPaddleData },
  ] = useLazyQuery(getPaddleSubscriptionDetails, {
    notifyOnNetworkStatusChange: true,
  })
  const [
    fetchMicrosoftSubscriptionData,
    { data: microsoftSubscriptionData, loading: loadingMicrosoftData },
  ] = useLazyQuery(getMicrosoftSubscriptionData, {
    notifyOnNetworkStatusChange: true,
  })

  // The Microsoft webhook to update subscriptionLevel sometimes fails
  // But microsoftSubscriptionData gets the level from the source at Microsoft, so is more accurate
  // Is not used by default because it's a slower request that is only made if the org is registered via MS
  // Its value should be used whenever it exists
  const adjustedSubscriptionLevel = useMemo(() => {
    if (!microsoftSubscriptionData) return companySubscriptionLevel

    return (
      microsoftSubscriptionData.currentCompany.microsoftSubscriptionData
        ?.planId || companySubscriptionLevel
    )
  }, [companySubscriptionLevel, microsoftSubscriptionData])

  const { paddleSubscriptionStatus, paymentProcessor } = useMemo(() => {
    if (!companyData)
      return {
        paddleSubscriptionStatus: null,
        paymentProcessor: 'uplifter',
      }

    return companyData.currentCompany
  }, [companyData])

  const {
    isEnterprise,
    isTeam,
    isPaddle,
    isFree,
    isMicrosoftMarketplace,
  } = useMemo(() => {
    const subscriptionLevel = getCompanySubscriptionLevel(
      adjustedSubscriptionLevel,
      paddleSubscriptionStatus,
    )

    return {
      isEnterprise: isDemoAccount || subscriptionLevel === 'enterprise',
      isTeam:
        !isDemoAccount &&
        ['paddle', 'microsoft_team'].indexOf(subscriptionLevel) > -1,
      isPaddle: !isDemoAccount && subscriptionLevel === 'paddle',
      isFree:
        !isDemoAccount &&
        ['free', 'microsoft_free'].indexOf(subscriptionLevel) > -1,
      // Microsoft accounts have same permissions as free/team (Paddle) tiers
      // But should not see Upgrade pages
      isMicrosoftMarketplace: paymentProcessor === 'microsoft',
    }
  }, [
    isDemoAccount,
    adjustedSubscriptionLevel,
    paddleSubscriptionStatus,
    paymentProcessor,
  ])

  const loading = useMemo(() => {
    if (paymentProcessor === 'microsoft') return !microsoftSubscriptionData

    return !companySubscriptionLevel
  }, [companySubscriptionLevel, paymentProcessor, microsoftSubscriptionData])

  // Fetch Paddle subscription details
  useEffect(() => {
    if (
      isTeam &&
      !isMicrosoftMarketplace &&
      !!companyData?.currentCompany.paddleSubscriptionStatus
    ) {
      getPaddleDetails()
    }
  }, [isTeam, isMicrosoftMarketplace, companyData])

  // Fetch MS Subscription details
  useEffect(() => {
    if (paymentProcessor !== 'microsoft') return

    fetchMicrosoftSubscriptionData()
  }, [paymentProcessor])

  return {
    loading,
    isPaid: isEnterprise || isTeam,
    isEnterprise,
    isTeam,
    isPaddle,
    isFree,
    isMicrosoftMarketplace,
    microsoftSubscriptionData,
    loadingMicrosoftData,
    fetchMicrosoftSubscriptionData,
    paddleData,
    loadingPaddleData,
    getPaddleDetails,
  }
}
