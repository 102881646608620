import React, { useState, useMemo } from 'react'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import classNames from 'classnames'
import { nanoid } from 'nanoid'
import _ from 'lodash'

import Button, { NavigateButton } from './button'
import { CounterLabel } from './counter'
import Input, { Label } from './input'
import Modal from './modal'
import Row, { LabelSlot } from './row'
import SelectBox from './select-box'
import Tooltip from './tooltip'
import {
  explainDataReactive,
  explainMetricsDataReactive,
} from '../api/apollo/legacy-reducers'
import { getDataSourceMetricList } from '../api/graphql/report-client'
import { sendFeatureRequest } from '../api/graphql/support-client'
import { getAccountDataSource } from '../api/graphql/workspace-client'
import EditIcon from '../assets/edit.svg'
import { detectionMethods, integrationData } from '../core/constants'
import { getItemByKeyValue } from '../helpers'
import useMobile from '../hooks/useMobile'
import styles from '../styles/explain-manage.module.scss'

/**
 * API V1 Types
  * interface UpdateMetric {
  *   detectionMethod?: 'SD' | 'AE' | 'MANUAL'
  *   displayName?: string
  *   increaseSentimentPositive?: boolean
  *   metricID: string
  *   nPeriods?: number
  *   nStandardDeviations?: number
  *   tPercentile?: number
  *   sendAlert?: boolean
  * }

  * interface AddMetric {
  *   newMetric: string
  *   apiName: string
  *   detectionMethod: 'SD' | 'AE' | 'MANUAL'
  *   increaseSentimentPositive: boolean
  *   sendAlert: boolean
  *   dataSource: 'GA_VIEW' | 'AA_REPORT' | 'SALESFORCE'
  * }
 */

interface ExplainMetric {
  apiName: string
  displayName: string
  detectionMethod: 'AE' | 'SD' | 'MANUAL'
  increaseSentimentPositive: boolean
  sendAlert: boolean
  dataSource: string
}

const defaultMetric: ExplainMetric = {
  apiName: '',
  displayName: '',
  detectionMethod: 'AE',
  increaseSentimentPositive: true,
  sendAlert: true,
  dataSource: 'GA_VIEW',
}

interface FieldsProps {
  item: any
  onChange: any
  edit?: boolean
}

export function Fields({
  item,
  edit = false,
  onChange,
}: FieldsProps): React.ReactElement {
  return (
    <>
      {!edit && (
        <>
          <Label modalHeading>Detection method</Label>
          <div className={styles.radioPanel}>
            <Input
              type="radio"
              id="method-1"
              name="method"
              label={detectionMethods.AE.long}
              checked={!item.detectionMethod || item.detectionMethod === 'AE'}
              onClick={(): void => {
                onChange('detectionMethod', 'AE')
              }}
            >
              <p>{detectionMethods.AE.desc}</p>
            </Input>
            <Input
              type="radio"
              id="method-3"
              name="method"
              label={detectionMethods.SD.long}
              checked={item.detectionMethod === 'SD'}
              onClick={(): void => {
                onChange('detectionMethod', 'SD')
              }}
            >
              <p>{detectionMethods.SD.desc}</p>
            </Input>
            <Input
              type="radio"
              id="method-2"
              name="method"
              label={detectionMethods.MANUAL.long}
              checked={item.detectionMethod === 'MANUAL'}
              onClick={(): void => {
                onChange('detectionMethod', 'MANUAL')
              }}
            >
              <p>{detectionMethods.MANUAL.desc}</p>
            </Input>
          </div>
        </>
      )}

      <Label modalHeading>An increase in {item.displayName}</Label>
      <div className={styles.goodBadPanel}>
        <Input
          type="radio"
          id="increase-checkbox-1"
          name="increase-checkbox"
          label="Is good"
          checked={item.increaseSentimentPositive}
          className={styles.radioBox}
          onClick={() => {
            onChange('increaseSentimentPositive', true)
          }}
        />
        <Input
          type="radio"
          id="increase-checkbox-2"
          name="increase-checkbox"
          label="Is bad"
          checked={!item.increaseSentimentPositive}
          className={classNames(styles.radioBox, styles.radioBoxBad)}
          onClick={() => {
            onChange('increaseSentimentPositive', false)
          }}
        />
      </div>
      <Input
        type="checkbox"
        id="alerts"
        name="alerts"
        label="Enable email alerts for everyone"
        checked={item.sendAlert}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
          const { checked } = e.target as HTMLInputElement
          onChange('sendAlert', checked)
        }}
      />
    </>
  )
}

interface MetricItem {
  isStandard?: boolean
  increasePositive: boolean
  isUplifterClickMetric?: boolean | null
  calculation?: Array<string> | null
  description: string
  displayName: string
  metricID: string
  setupComplete?: boolean
  units: string
  detectionMethod?: 'AE' | 'SD' | 'MANUAL'
}

interface MetricsItem {
  optionValue: string
  optionName: string
  increaseSentimentPositive: boolean
}

interface AddModalProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  metricsItems: MetricsItem[] | null
  onYes: (item: MetricItem | ExplainMetric) => void
  yesButtonLoading?: boolean
  dataSource?: string
}

export function AddModal({
  setIsOpen,
  metricsItems,
  onYes,
  yesButtonLoading,
  dataSource = 'GA4PROP',
}: AddModalProps) {
  const [newMetricItem, setNewMetricItem] = useState<ExplainMetric>({
    ...defaultMetric,
  })
  const [showFindMore, setShowFindMore] = useState('')

  const [requestFeature] = useMutation(sendFeatureRequest)

  const { displayName } = newMetricItem

  const onChange = (key: string, value) => {
    setNewMetricItem({ ...newMetricItem, [key]: value })
  }

  const groupedMetricOptions = useMemo(() => {
    if (dataSource === 'AA_REPORT') {
      return [{ icon: '/aa-logo.svg', label: 'Adobe Analytics', options: [] }]
    }

    return [
      {
        icon: '/ga-logo.svg',
        label: 'Google Analytics',
        options: metricsItems
          ? _.cloneDeep(metricsItems).sort((a, b) => {
              return a.optionName.toLowerCase() > b.optionValue.toLowerCase()
                ? 1
                : -1
            })
          : [],
      },
    ]
  }, [metricsItems, dataSource])

  if (showFindMore !== '') {
    return (
      <Modal
        setIsOpen={setIsOpen}
        modalHeader={`${integrationData[showFindMore].full} integration request`}
        yesText="OK"
        onYes={() => {
          setShowFindMore('')
          setIsOpen(false)
        }}
      >
        <p>Our technical team will contact you to discuss this integration.</p>
      </Modal>
    )
  }

  return (
    <Modal
      className={styles.addmetricModal}
      setIsOpen={setIsOpen}
      modalHeader="Add a key metric"
      yesText="Add"
      yesButtonDisabled={displayName === ''}
      yesButtonLoading={yesButtonLoading}
      onYes={() => onYes(newMetricItem)}
    >
      <>
        <Label modalHeading>Metric</Label>
        {metricsItems ? (
          <>
            {metricsItems.length > 0 ? (
              <>
                <SelectBox
                  className={styles.typeahead}
                  labelKey="optionName"
                  valueKey="optionValue"
                  hideOrphanGroupLabel={false}
                  placeholder="Select metric or start typing"
                  value={metricsItems.find((option) => {
                    return newMetricItem.apiName === option.optionValue
                  })}
                  options={groupedMetricOptions}
                  onChange={(newValue) => {
                    if (!newValue) return

                    const { optionValue: value } = newValue

                    const item = getItemByKeyValue(
                      metricsItems,
                      'optionValue',
                      value,
                    )

                    if (item !== -1) {
                      const data = {
                        ...newMetricItem,
                        ...{
                          apiName: value,
                          displayName: item.optionName,
                          increaseSentimentPositive:
                            item.increaseSentimentPositive,
                        },
                      }
                      setNewMetricItem(data)
                    } else {
                      setNewMetricItem({
                        ...newMetricItem,
                        ...{ apiName: value, displayName: '' },
                      })
                    }
                  }}
                >
                  <>
                    {Object.keys(integrationData).map((key: string): any => {
                      const item = integrationData[key]

                      if (key === 'aa' && dataSource === 'AA_REPORT')
                        return null

                      return (
                        <span key={nanoid()}>
                          <span className={styles.itemLabel}>
                            <span>
                              <img
                                src={item.icon}
                                className={styles.labelIcon}
                                alt="label icon"
                              />
                              <>{item.full}</>
                            </span>
                          </span>
                          <span className={styles.itemLink}>
                            <NavigateButton
                              style={{ padding: '8px 12px' }}
                              onPressStart={async () => {
                                setShowFindMore(key)

                                const message = `${integrationData[key].full} integration request`

                                await requestFeature({
                                  variables: {
                                    page: 'explain',
                                    message,
                                  },
                                })
                              }}
                            >
                              Request integration
                            </NavigateButton>
                          </span>
                        </span>
                      )
                    })}
                  </>
                </SelectBox>
                {displayName !== '' && (
                  <Fields item={newMetricItem} onChange={onChange} />
                )}
              </>
            ) : (
              <p>No metrics found</p>
            )}
          </>
        ) : (
          <p>Loading metric data, please wait...</p>
        )}
      </>
    </Modal>
  )
}

interface EditModalProps {
  editMetricItem: MetricItem | null
  setEditMetricItem: React.Dispatch<React.SetStateAction<MetricItem | null>>
}

export function EditModal({
  editMetricItem,
  setEditMetricItem,
}: EditModalProps) {
  const onChange = (key: string, value, key1 = '', value1 = '') => {
    if (editMetricItem) {
      if (key1 === '') {
        setEditMetricItem({ ...editMetricItem, [key]: value })
      } else {
        setEditMetricItem({
          ...editMetricItem,
          [key]: value,
          [key1]: value1,
        })
      }
    }
  }

  if (!editMetricItem) return null

  const { displayName, detectionMethod } = editMetricItem

  return (
    <>
      <Label modalHeading>Metric</Label>
      <Input
        name="displayName"
        id="displayName"
        type="text"
        value={displayName}
        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
          const { value: val } = event.target
          onChange('displayName', val)
        }}
      />
      <LabelSlot topAlign>
        <Label modalHeading>
          <Tooltip
            id="detection-method-tooltip"
            useIcon
            tooltipMessage="To use a different anomaly detection method, please add this metric again with another detection method."
          >
            Detection method
          </Tooltip>
        </Label>
      </LabelSlot>
      {detectionMethod && (
        <div className={styles.radioPanel}>
          <Input
            id="detectionMethod"
            name="detectionMethod"
            type="radio"
            checked
            label={detectionMethods[detectionMethod].long}
            readOnly
          >
            <p>{detectionMethods[detectionMethod].desc}</p>
          </Input>
        </div>
      )}
      <Fields item={editMetricItem} edit onChange={onChange} />
    </>
  )
}

type SetupStatus = '' | 'presetup' | 'IN_PROGRESS' | 'failed' | 'completed'

interface ManageProps {
  active: boolean
  toggleActive: React.Dispatch<React.SetStateAction<boolean>>
}

export default function Manage({ active, toggleActive }: ManageProps) {
  const isMobile = useMobile(769)

  /**
   * Old types used in V1 API
   * Will eventually be rebuilt
   */
  const explainData = useReactiveVar(explainDataReactive)
  const explainMetricsData = useReactiveVar(explainMetricsDataReactive)

  const { data: dataSourceData } = useQuery(getAccountDataSource)
  const { data: dataSourceMetricListData } = useQuery(getDataSourceMetricList)

  const [addNewMetric, setAddNewMetric] = useState(false)
  const [editMetric, setEditMetric] = useState(false)
  const [editMetricItem, setEditMetricItem] = useState<MetricItem | null>(null)

  const dataSource = useMemo(() => {
    if (!dataSourceData) return 'GA4PROP'

    return dataSourceData.currentAccount.dataSource?.kind
  }, [dataSourceData])

  // ! V1 endpoint no longer used
  // useEffect(() => {
  //   const req = await make.get({
  //     endpoint: 'get-all-ga-metrics',
  //   })
  //   explainMetricsDataReactive(({...explainMetricsData, metrics: req.res.data.metrics}))
  // }, [])

  const metricsItems: MetricsItem[] | null = useMemo(() => {
    if (explainMetricsData && explainMetricsData.metrics.length > 0) {
      return explainMetricsData.metrics.map((item) => {
        return {
          optionValue: item.apiName,
          optionName: item.displayName,
          increaseSentimentPositive: item.increaseSentimentPositive,
        }
      })
    }

    return []
  }, [dataSourceMetricListData, explainMetricsData])

  const onClickEdit = (metricID: string) => {
    const metricItem = getItemByKeyValue(
      explainMetricsData.currentKeyMetrics,
      'metricID',
      metricID,
    )
    if (metricItem !== -1) {
      setEditMetricItem(metricItem)
      setEditMetric(true)
    }
  }

  if (!active) return null

  if (editMetric && editMetricItem) {
    return (
      <Modal
        setIsOpen={setEditMetric}
        modalHeader="Edit key metrics"
        yesText="Save"
        onYes={async () => {
          // ! V1 endpoint no longer used
          //  await make.post({
          //    endpoint: 'update-key-metric',
          //    data: editMetricItem,
          //  })

          setEditMetric(false)
        }}
      >
        <EditModal
          key={nanoid()}
          editMetricItem={editMetricItem}
          setEditMetricItem={setEditMetricItem}
        />
      </Modal>
    )
  }

  if (addNewMetric) {
    return (
      <AddModal
        setIsOpen={setAddNewMetric}
        metricsItems={metricsItems}
        onYes={async (metricItemToBeAdded) => {
          const data = { ...defaultMetric, ...metricItemToBeAdded }

          if (data.detectionMethod === 'MANUAL' && data.sendAlert) {
            data.sendAlert = false
          }

          // ! V1 endpoint no longer used
          // await make.post({
          //   endpoint: 'add-key-metric',
          //   data,
          // })

          setAddNewMetric(false)
        }}
        dataSource={dataSource}
      />
    )
  }

  return (
    <Modal
      className={styles.manageModal}
      setIsOpen={toggleActive}
      width="wide"
      modalHeader="Edit key metrics"
      yesText="Add metric"
      onYes={() => setAddNewMetric(true)}
    >
      {explainData.pageLayout.length === 0 && (
        <p>Please add your first key metric.</p>
      )}
      {explainData.pageLayout.length > 0 && (
        <>
          <div
            className={classNames(styles.wrapper, {
              [styles.actionPanelActive]: addNewMetric,
            })}
          >
            <div className={styles.body}>
              <div className={styles.metricsList}>
                <div className={styles.table}>
                  <div className={styles.tableHeader}>
                    <div className={styles.tableCell}>
                      <span className={styles.tableCellContent}>Metric</span>
                    </div>
                    <div className={styles.tableCell}>
                      <span className={styles.tableCellContent}>
                        An increase
                      </span>
                    </div>
                    <div className={styles.tableCell}>
                      <span className={styles.tableCellContent}>
                        Detection method
                      </span>
                    </div>
                    <div className={styles.tableCell}>
                      <span className={styles.tableCellContent}>Alerts</span>
                    </div>
                    <div className={styles.tableCell}>
                      <span className={styles.tableCellContent}>Actions</span>
                    </div>
                  </div>
                  <DragDropContext
                    onDragEnd={(result) => {
                      const { destination, source } = result

                      if (!destination) return

                      if (
                        destination.droppableId === source.droppableId &&
                        destination.index === source.index
                      ) {
                        // eslint-disable-next-line no-useless-return
                        return
                      }

                      const newOrder = [...explainData.pageLayout]
                      const movedIndex = newOrder.splice(source.index, 1)
                      newOrder.splice(destination.index, 0, movedIndex[0])

                      // ! V1 endpoint no longer used
                      // explainDataReactive({ ...explainData, pageLayout: newOrder })
                      // await make.put({
                      //   endpoint: 'update-explain-page-layout',
                      //   data: {
                      //     ...explainData,
                      //     pageLayout: newOrder,
                      //   },
                      // })
                    }}
                  >
                    <Droppable droppableId="explainMetricsData">
                      {(droppableProvided) => {
                        return (
                          <div
                            ref={droppableProvided.innerRef}
                            {...droppableProvided.droppableProps}
                            className={styles.tableBody}
                          >
                            <>
                              {explainData.pageLayout.map((item, index) => {
                                const metricItem = getItemByKeyValue(
                                  [
                                    ...explainMetricsData.currentKeyMetrics,
                                    ...explainMetricsData.metrics,
                                  ],
                                  'metricID',
                                  item.metricID,
                                )

                                if (metricItem === -1) {
                                  return <span key={nanoid()} />
                                }

                                const {
                                  displayName,
                                  increaseSentimentPositive,
                                  detectionMethod,
                                  sendAlert,
                                } = metricItem

                                const setupStatus: SetupStatus = metricItem
                                  ? metricItem.setupStatus
                                  : ''

                                return (
                                  <Draggable
                                    key={item.metricID}
                                    draggableId={item.metricID}
                                    index={index}
                                    isDragDisabled={isMobile}
                                  >
                                    {(draggableProvided) => {
                                      return (
                                        <div
                                          key={item.metricID}
                                          ref={draggableProvided.innerRef}
                                          {...draggableProvided.draggableProps}
                                          {...draggableProvided.dragHandleProps}
                                        >
                                          <Row
                                            align="space-between"
                                            className={styles.row}
                                            odd={!(index % 2)}
                                          >
                                            <div className={styles.tableCell}>
                                              <span
                                                className={styles.mobileOnly}
                                              >
                                                Metric
                                              </span>
                                              <span
                                                className={
                                                  styles.tableCellContent
                                                }
                                              >
                                                <span
                                                  className={
                                                    styles.tableCellContentPadded
                                                  }
                                                >
                                                  {displayName}
                                                </span>{' '}
                                                <CounterLabel
                                                  grey
                                                  setupStatus={setupStatus}
                                                  className={
                                                    styles.counterLabel
                                                  }
                                                />
                                              </span>
                                            </div>
                                            <div className={styles.tableCell}>
                                              <span
                                                className={styles.mobileOnly}
                                              >
                                                An increase
                                              </span>
                                              <span
                                                className={classNames(
                                                  styles.tableCellContent,
                                                  styles.tagWrapper,
                                                )}
                                              >
                                                is{' '}
                                                <span
                                                  className={classNames(
                                                    styles.tag,
                                                    {
                                                      [styles.tagIsBad]: !increaseSentimentPositive,
                                                    },
                                                  )}
                                                >
                                                  {increaseSentimentPositive
                                                    ? 'good'
                                                    : 'bad'}
                                                </span>
                                              </span>
                                            </div>
                                            {detectionMethod && (
                                              <div className={styles.tableCell}>
                                                <span
                                                  className={styles.mobileOnly}
                                                >
                                                  Method
                                                </span>
                                                <span
                                                  className={
                                                    styles.tableCellContent
                                                  }
                                                >
                                                  {
                                                    detectionMethods[
                                                      detectionMethod
                                                    ].long
                                                  }
                                                </span>
                                              </div>
                                            )}
                                            {sendAlert !== null && (
                                              <div className={styles.tableCell}>
                                                <span
                                                  className={styles.mobileOnly}
                                                >
                                                  Alerts
                                                </span>
                                                <span
                                                  className={
                                                    styles.tableCellContent
                                                  }
                                                >
                                                  {sendAlert ? 'Email' : 'None'}
                                                </span>
                                              </div>
                                            )}
                                            <div
                                              className={classNames(
                                                styles.tableCell,
                                                styles.tableCellButtons,
                                              )}
                                            >
                                              <Button
                                                variant="text"
                                                icon={{
                                                  src: EditIcon,
                                                  alt: 'Edit',
                                                  iconAfter: true,
                                                  imgHeight: 16,
                                                }}
                                                onPress={() =>
                                                  onClickEdit(item.metricID)
                                                }
                                              >
                                                Edit
                                              </Button>
                                            </div>
                                          </Row>
                                        </div>
                                      )
                                    }}
                                  </Draggable>
                                )
                              })}
                              {droppableProvided.placeholder}
                            </>
                          </div>
                        )
                      }}
                    </Droppable>
                  </DragDropContext>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Modal>
  )
}
