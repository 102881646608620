import React from 'react'
import classNames from 'classnames'

import styles from '../styles/order-arrow.module.scss'

interface Props {
  currentKey: string
  sortKey: string
  orderAsc?: boolean
  className?: string
  type?: 'bidirectional' | null
}

export default function OrderArrow({
  currentKey,
  sortKey,
  orderAsc,
  className,
  type = null,
}: Props): React.ReactElement | null {
  if (currentKey === sortKey) {
    const elementClassName = classNames(className, styles.arrow, {
      [styles.ascOrder]: orderAsc,
      [styles.descOrder]: !orderAsc,
      [styles.arrowTypeBi]: type === 'bidirectional',
    })
    return <span className={elementClassName} />
  }
  return null
}
