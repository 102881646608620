import React, { useEffect, useMemo, useState } from 'react'
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client'
import { useLocation } from 'react-router-dom'
import _ from 'lodash'

import { linkOrCode } from '../api/apollo/variables'
import {
  getAvailableSalesforcePardotAccounts,
  getIntegrationsStatus,
  getSavedPardotAcctList,
  handleSalesforcePardotCallback,
  setSavedPardotAccounts,
} from '../api/graphql/integrations-client'
import Button from '../components/button'
import Link from '../components/link'
import { Preloader } from '../components/loader'
import { FormRow } from '../components/row'
import { SelectBoxChecklist } from '../components/select-box'
import { BoxedText } from '../components/typography'
import { dynamicTextValues, supportEmail } from '../core/constants'
import styles from '../styles/integrations-settings.module.scss'

interface ConnectorLocationState {
  connectorID: string
  code: string
  state: string
}

const SalesforcePardotConnector = () => {
  const { state: locationState } = useLocation<ConnectorLocationState>()

  const linkCopy = useReactiveVar(linkOrCode)

  const salesforceCode =
    !locationState || locationState.connectorID !== 'salesforcePardot'
      ? null
      : (locationState.code as string) || null

  const salesforceState =
    !locationState || locationState.connectorID !== 'salesforcePardot'
      ? null
      : (locationState.state as string) || null

  const { data: connectorData, loading: loadingConnectorStatus } = useQuery(
    getIntegrationsStatus,
  )
  const [
    getPardotBusinessUnits,
    {
      data: pardotBusinessUnitsData,
      loading: fetchingBusinessUnits,
      error: businessUnitsError,
    },
  ] = useLazyQuery(getAvailableSalesforcePardotAccounts)
  const [
    getSavedPardotBusinessUnits,
    { data: savedBusinessUnitsData, loading: fetchingSavedBusinessUnits },
  ] = useLazyQuery(getSavedPardotAcctList)
  const [
    handleOauth,
    { data: saveOauthData, loading: loadingIntegration, error: oauthError },
  ] = useMutation(handleSalesforcePardotCallback)
  const [
    savePardotBusinessUnits,
    { loading: savingPardotBusinessUnits, error: errorSavingBusinessUnits },
  ] = useMutation(setSavedPardotAccounts)

  const [selectedBusinessUnits, setSelectedBusinessUnits] = useState<string[]>(
    [],
  )
  const [connectionSuccess, setConnectionSuccess] = useState(false)

  // Check if the connector is already active
  // 'active' | 'pendingBoardCreation' | 'pendingAuth' | 'inactive'
  const connectorStatus = useMemo(() => {
    if (!connectorData) return 'inactive'

    return connectorData.currentCompany.pardotIntegrationStatus
  }, [connectorData])

  // Handle oauth response if locationState is present
  useEffect(() => {
    if (connectorStatus === 'active') {
      getPardotBusinessUnits()
      getSavedPardotBusinessUnits()
    } else if (
      connectorStatus !== 'active' &&
      salesforceCode &&
      salesforceState
    ) {
      handleOauth({
        variables: {
          code: salesforceCode,
          encryptedState: salesforceState,
        },
      })
    }
  }, [connectorStatus, salesforceCode, salesforceState])

  // List all business units found in SF account
  const businessUnits = useMemo(() => {
    if (!saveOauthData && !pardotBusinessUnitsData) return []

    const fullBusinessUnits: {
      pardotID: string
      pardotName: string
      pardotDisplayName: string
    }[] = []

    if (saveOauthData) {
      fullBusinessUnits.push(
        ...saveOauthData.userAccountSettings.salesforcePardotMutations
          .handleSalesforcePardotCallback.pardotList,
      )
    } else if (pardotBusinessUnitsData) {
      fullBusinessUnits.push(
        ...pardotBusinessUnitsData.track.getAvailableSalesforcePardotAccounts
          .pardotList,
      )
    }

    return fullBusinessUnits
  }, [saveOauthData, pardotBusinessUnitsData])

  // Set saved business units from DB
  const savedBusinessUnits = useMemo(() => {
    if (!savedBusinessUnitsData) return []

    const savedBusinessUnitIDs = savedBusinessUnitsData.track.getSavedPardotAcctList.pardotList.map(
      ({ pardotID }) => pardotID,
    )

    setSelectedBusinessUnits(savedBusinessUnitIDs)

    return savedBusinessUnitIDs
  }, [savedBusinessUnitsData])

  if (loadingConnectorStatus || loadingIntegration) {
    return <Preloader />
  }

  if (businessUnitsError || errorSavingBusinessUnits || oauthError) {
    return (
      <p className={styles.errorMsg}>
        There was an error connecting to your Salesforce Pardot. Please try
        again later or contact support (
        <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>
        ).
      </p>
    )
  }

  return (
    <>
      {businessUnits.length > 0 && (
        <>
          <p>
            <strong>Your Salesforce account is connected.</strong>
          </p>
          {savedBusinessUnits.length === 0 ? (
            <p>
              To enable the integration, select one or more business units to
              connect.
            </p>
          ) : (
            <p>
              You can view your email templates on the{' '}
              <BoxedText>
                <Link href="/track/create-links">
                  {dynamicTextValues.trackCreatePage[linkCopy]}
                </Link>
              </BoxedText>{' '}
              page.
            </p>
          )}
        </>
      )}
      <p>Select the business units you'd like to connect:</p>
      <FormRow
        className={styles.businessUnitDropdownRow}
        last
        align="flex-start"
      >
        <SelectBoxChecklist
          id="pardot-business-units"
          className={styles.businessUnitDropdown}
          menuPlacement="auto"
          isDisabled={businessUnits.length === 0}
          isLoading={fetchingBusinessUnits || fetchingSavedBusinessUnits}
          placeholder="Select accounts"
          labelKey="pardotDisplayName"
          valueKey="pardotID"
          value={businessUnits.filter(
            (option) => selectedBusinessUnits.indexOf(option.pardotID) > -1,
          )}
          options={businessUnits}
          onChange={(newValue) => {
            setConnectionSuccess(false)
            setSelectedBusinessUnits(newValue.map((option) => option.pardotID))
          }}
        />
        <Button
          loading={savingPardotBusinessUnits}
          isDisabled={
            businessUnits.length === 0 ||
            fetchingBusinessUnits ||
            fetchingSavedBusinessUnits ||
            _.isEqual(savedBusinessUnits, selectedBusinessUnits)
          }
          onPress={async () => {
            const newSavedBusinessUnits = businessUnits
              .filter(
                (businessUnit) =>
                  selectedBusinessUnits.indexOf(businessUnit.pardotID) > -1,
              )
              .map(({ pardotDisplayName, pardotID, pardotName }) => ({
                pardotDisplayName,
                pardotID,
                pardotName,
              }))

            await savePardotBusinessUnits({
              variables: {
                pardotAcctList: newSavedBusinessUnits,
              },
              refetchQueries: [
                getAvailableSalesforcePardotAccounts,
                getSavedPardotAcctList,
              ],
            })

            setConnectionSuccess(true)
          }}
        >
          Connect
        </Button>
      </FormRow>
      {connectionSuccess && (
        <p className={styles.tenantSuccess}>
          Your Salesforce Pardot connection has been updated.
        </p>
      )}
    </>
  )
}

export default SalesforcePardotConnector
