import React from 'react'
import { useReactiveVar } from '@apollo/client'

import { Label, ClickEditInput } from './input'
import { explainAnomalyDataReactive } from '../api/apollo/legacy-reducers'
import styles from '../styles/custom-cause-input.module.scss'

interface Props {
  viewOnly?: boolean
}

export default function CustomCauseInput({ viewOnly = false }: Props) {
  const explainAnomalyData = useReactiveVar(explainAnomalyDataReactive)

  const {
    userSuppliedCause,
    userSuppliedAction,
    userSuppliedEffort,
    userSuppliedBenefit,
    anomalyDateID,
  } = explainAnomalyData?.description || {}

  const updateStatus = (anom: any) => {
    if (explainAnomalyData.description !== null) {
      // ! V1 endpoint no longer used
      // const data = { ...anomaly.description, ...anom }
      // const {
      //   userSuppliedCause: a = '',
      //   userSuppliedAction: b = '',
      //   userSuppliedEffort: c = '',
      //   userSuppliedBenefit: d = '',
      // } = data
      // const req = await make.post({
      //   endpoint: 'update-anomaly-date-description',
      //   data: {
      //   ...data,
      //   explainedAnomaly: `${a}${b}${c}${d}` !== '',
      // },
      // })
      // explainAnomalyDataReactive({
      //   description: { ...explainAnomalyData, ...req?.res?.data },
      // })
      // const {
      //   anomalyDate,
      //   anomalyNotInteresting,
      //   explainedAnomaly,
      //   selectedRelevantDimensions,
      //   userSuppliedAction,
      //   userSuppliedCause,
      //   userSuppliedEffort,
      //   userSuppliedBenefit,
      // } = {
      //   ...explainAnomalyData.description,
      //   ...anom,
      // }
      // const fDate = moment(anomalyDate, 'YYYYMMDD').format('DD/MM/YYYY')
      // const found = getItemByKeyValue(
      //   explainAnomaliesData[metricID].anomalyRange,
      //   'date',
      //   fDate,
      // )
      // const anomalyRange =
      //   found === -1
      //     ? [
      //         ...explainAnomaliesData[metricID].anomalyRange,
      //         { ...createAnomalyRangeObject(action.payload) },
      //       ]
      //     : explainAnomaliesData[metricID].anomalyRange.map((item) => {
      //         if (item.date === fDate) {
      //           return {
      //             ...item,
      //             anomalyNotInteresting,
      //             explainedAnomaly,
      //             selectedRelevantDimensions,
      //             userSuppliedAction,
      //             userSuppliedCause,
      //             userSuppliedEffort,
      //             userSuppliedBenefit,
      //           }
      //         }
      //         return item
      //       })
      // explainAnomaliesDataReactive({
      //   ...explainAnomaliesData,
      //   [metricID]: {
      //     ...explainAnomaliesData[metricID],
      //     anomalyRange
      //   }
      // })
    }
  }

  return (
    <div className={styles.form} key={anomalyDateID}>
      {!(viewOnly && userSuppliedCause === '') && (
        <>
          <Label className={styles.label} id="message">
            What happened
          </Label>
          <ClickEditInput
            id="message"
            viewOnly={viewOnly}
            onChange={(val: string): void => {
              updateStatus({
                userSuppliedCause: val,
              })
            }}
            value={userSuppliedCause}
            type="textArea"
            placeholder={
              viewOnly && userSuppliedCause === ''
                ? 'N/A'
                : 'Explained what happened in the anomaly here...'
            }
          />
        </>
      )}
      {!(viewOnly && userSuppliedAction === '') && (
        <>
          <Label className={styles.label} id="explain">
            What to do next
          </Label>
          <ClickEditInput
            id="explain"
            viewOnly={viewOnly}
            onChange={(val: string): void => {
              updateStatus({
                userSuppliedAction: val,
              })
            }}
            value={userSuppliedAction}
            type="textArea"
            placeholder={
              viewOnly && userSuppliedAction === ''
                ? 'N/A'
                : 'Explained what to do next...'
            }
          />
        </>
      )}
      <div className={styles.twoColumns}>
        {!(viewOnly && userSuppliedBenefit === '') && (
          <div>
            <Label className={styles.label} id="benefit">
              Benefit
            </Label>
            <ClickEditInput
              className={styles.input}
              id="benefit"
              viewOnly={viewOnly}
              onChange={(val: string): void => {
                updateStatus({
                  userSuppliedBenefit: val,
                })
              }}
              value={userSuppliedBenefit}
              type="text"
              // inputWhenEmpty
              placeholder={
                viewOnly && userSuppliedBenefit === ''
                  ? 'N/A'
                  : 'Please add benefit required...'
              }
            />
          </div>
        )}
        {!(viewOnly && userSuppliedEffort === '') && (
          <div>
            <Label className={styles.label} id="effort">
              Effort
            </Label>
            <ClickEditInput
              className={styles.input}
              id="effort"
              viewOnly={viewOnly}
              onChange={(val: string): void => {
                updateStatus({
                  userSuppliedEffort: val,
                })
              }}
              value={userSuppliedEffort}
              type="text"
              // inputWhenEmpty
              placeholder={
                viewOnly && userSuppliedEffort === ''
                  ? 'N/A'
                  : 'Please add effort required...'
              }
            />
          </div>
        )}
      </div>
    </div>
  )
}
