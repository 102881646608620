import { gql } from '../../__gql-types__/gql'

export const getIntegrationsStatus = gql(`
	query GetIntegrationsStatus {
		currentCompany {
			companyID
			pardotIntegrationStatus
			mondayIntegrationStatus
			eloquaIntegrationStatus
			salesforceMcIntegrationStatus
			salesforceMcIntegrationList {
				mid
				businessUnitName
			}
		}
	}
`)

export const getOktaDomains = gql(`
	query GetOktaDomains {
		currentCompany {
			companyID
			oktaDomainList {
				oktaDomains {
					clientID
					dateCreated
					emailDomain
					loginLink
					tenantDomain
					tenantName
				}
			}
		}
	}
`)

export const addOktaDomain = gql(`
	mutation AddOktaDomain (
		$clientID: String!
		$clientSecret: String!
		$emailDomain: String!
		$tenantName: String!
		$tenantDomain: String!
	) {
		userAccountSettings {
			addOktaDomain(
				clientID: $clientID,
				clientSecret: $clientSecret,
				emailDomain: $emailDomain,
				tenantName: $tenantName,
				tenantDomain: $tenantDomain
			) {
				clientID
				emailDomain
				loginLink
				tenantName
				dateCreated
				tenantDomain
			}
		}
	}
`)

export const updateOktaDomain = gql(`
	mutation UpdateOktaDomain (
		$clientID: String!
		$emailDomain: String!
		$tenantName: String
		$clientSecret: String
	) {
		userAccountSettings {
			updateOktaDomain(
				clientID: $clientID,
				clientSecret: $clientSecret,
				emailDomain: $emailDomain,
				tenantName: $tenantName,
			) {
				clientID
				emailDomain
				loginLink
				tenantName
				dateCreated
				tenantDomain
			}
		}
	}
`)

export const deleteOktaDomain = gql(`
	mutation DeleteOktaDomain (
		$clientID: String!
		$emailDomain: String!
	) {
		userAccountSettings {
			deleteOktaDomain(
				clientID: $clientID,
				emailDomain: $emailDomain,
			) {
				clientID
				emailDomain
				loginLink
				tenantName
				dateCreated
				tenantDomain
			}
		}
	}
`)

export const getMondayOauthLink = gql(`
	query GetMondayOauthLink ($state: String!) {
		accountSettings {
			mondayQueries {
				genMondayoauth2Link(state: $state) {
					oauth2Link
				}
			}
		}
	}
`)

export const saveMondayOauth = gql(`
	mutation SaveMondayOAuth ($code: String!) {
		userAccountSettings {
			mondayMutations {
				saveMondayAuth (code: $code) {
					mondayWorkspaces {
						mondayWorkspaceDescription
						mondayWorkspaceId
						mondayWorkspaceName
					}
				}
			} 
		}
	}
`)

export const getMondayWorkspaces = gql(`
	query GetMondayWorkspaces {
		accountSettings {
			mondayQueries {
				getWorkspaces {
					mondayWorkspaces {
						mondayWorkspaceDescription
						mondayWorkspaceId
						mondayWorkspaceName
					}
				}
			}
		}
	}
`)

export const createMondayBoard = gql(`
	mutation CreateMondayBoard (
		$workspaceID: String!,
		$workspaceName: String!
	) {
      userAccountSettings {
        mondayMutations {
          mondayCreateBoard (
						workspaceID: $workspaceID,
						workspaceName: $workspaceName
					) {
            mondayUrlSlug
            mondayWorkspaceId
            mondayWorkspaceName
            mondayBoardId
            mondayBoardName
            mondayGroupId
            mondayGroupName
          }
        }
      }
    }
`)

export const getMondayIDs = gql(`
	query GetMondayIDs {
		accountSettings {
			mondayQueries {
				getMondayIds {
					mondayUrlSlug
					mondayBoardId
					mondayBoardName
					mondayGroupId
					mondayGroupName
					mondayWorkspaceId
					mondayWorkspaceName
				}
			}
		}
	}
`)

export const deleteMondayIntegration = gql(`
	mutation RemoveMondayIntegration {
		userAccountSettings {
			mondayMutations {
				mondayRemoveIntegration
			}
		}
	}
`)

export const getSalesforceOauthLink = gql(`
	query GetSalesforceOauthLink ($state: String!) {
		accountSettings {
			dataSourceQueries {
				genSalesforceOauthLink(state: $state) {
					oauth2Link
				}
			}
		}
	}
`)

export const handleSalesforcePardotCallback = gql(`
	mutation HandleSalesforcePardotCallback (
		$code: String!
		$encryptedState: String!
	) {
		userAccountSettings {
			salesforcePardotMutations {
				handleSalesforcePardotCallback (
					code: $code,
					encryptedState: $encryptedState
				) {
					pardotList {
						pardotID
						pardotName
						pardotDisplayName
					}
				}
			}
		}
	}
`)

export const getAvailableSalesforcePardotAccounts = gql(`
	query GetAvailableSalesforcePardotAccounts {
		track {
			getAvailableSalesforcePardotAccounts {
				pardotList {
					pardotDisplayName
					pardotID
					pardotName
				}
			}
		}
	}
`)

export const getSavedPardotAcctList = gql(`
	query GetSavedPardotAcctList {
		track {
			getSavedPardotAcctList {
				pardotList {
					pardotDisplayName
					pardotID
					pardotName
				}
			}
		}
	}
`)

export const setSavedPardotAccounts = gql(`
	mutation SetSavedPardotAccounts (
		$pardotAcctList: [PardotAccountInput!]!
	) {
		userAccountSettings {
			salesforcePardotMutations {
				savePardotAccountList(
					pardotAcctList: $pardotAcctList
				) {
					pardotList {
						pardotDisplayName
						pardotID
						pardotName
					}
				}
			}
		}
	}
`)

export const getSalesforceEmailTemplates = gql(`
	query GetSalesforceEmailTemplates (
		$pardotID: String
		$templateLocation: String!
	) {
		track {
			availableSalesforcePardotEmailTemplates(
				pardotID: $pardotID,
				templateLocation: $templateLocation
			) {
				classicBuilder {
					uiType
					pardotName
					pardotID
					pardotCampaignID
					isClassicListEmail
					emailSubject
					emailName
					emailLinks
					emailID
					emailHTML
					directLink
					dateUpdated
					dateAdded
				}
				lightningBuilder {
					dateAdded
					dateUpdated
					directLink
					emailHTML
					emailID
					emailLinks
					emailName
					isClassicListEmail
					emailSubject
					pardotCampaignID
					pardotID
					pardotName
					uiType
				}
			}
		}
	}
`)

export const getSalesforceEmailHTML = gql(`
	query GetSalesforceEmailHTML(
		$classicPardotTemplateList: [SalesforcePardotEmailTemplateInput!]
		$lightningPardotTemplateList: [SalesforcePardotEmailTemplateInput!]
	) {
		track {
			salesforcePardotEmailHTML(
				classicPardotTemplateList: $classicPardotTemplateList,
				lightningPardotTemplateList: $lightningPardotTemplateList
			) {
				classicBuilder {
					dateAdded
					dateUpdated
					directLink
					emailHTML
					emailID
					emailLinks
					emailName
					emailSubject
					isClassicListEmail
					pardotCampaignID
					pardotID
					pardotName
					uiType
				}
				lightningBuilder {
					uiType
					pardotName
					pardotID
					pardotCampaignID
					isClassicListEmail
					emailSubject
					emailName
					emailLinks
					emailID
					emailHTML
					directLink
					dateUpdated
					dateAdded
				}
			}
		}
	}
`)

export const updateSalesforceEmailHTMLByEmailID = gql(`
	mutation UpdateSalesforceEmailHTMLByEmailID (
		$emailID: String!
		$generatedPardotLinks: [PardotLinkInput!]!
		$pardotID: String!
		$pardotName: String!
		$uiType: String!
		$updatedHTMLMessage: String!
	) {
		track {
			sendPardotUpdate(
				emailID: $emailID
				generatedPardotLinks: $generatedPardotLinks
				pardotID: $pardotID
				pardotName: $pardotName
				uiType: $uiType
				updatedHTMLMessage: $updatedHTMLMessage
			) {
				emailHTML
				emailID
				dateUpdated
				emailName
				pardotID
				pardotName
			}
		}
	}
`)

export const getEloquaOrgList = gql(`
	query GetEloquaOrgList {
		track {
			eloquaQueries {
				getConnectedEloquaOrgs {
					eloquaOrgList {
						eloquaOrgDisplayName
						eloquaOrgID
						eloquaOrgName
					}
				}
			}
		}
	}
`)

export const updateEloquaOrgDisplayName = gql(`
	mutation UpdateEloquaOrgDisplayName (
		$eloquaOrgID: String!
		$eloquaDisplayName: String!
	) {
  userAccountSettings {
    oracleEloquaMutations {
      updateEloquaOrgName(
				eloquaOrgID: $eloquaOrgID,
				eloquaDisplayName: $eloquaDisplayName
			) {
        eloquaOrgDisplayName
        eloquaOrgID
        eloquaOrgName
      }
    }
  }
}
`)

export const saveEloquaBasicKey = gql(`
	mutation SaveEloquaBasicKey (
		$eloquaPassword: String!
		$userName: String!
		$companyName: String!
	) {
		userAccountSettings {
			oracleEloquaMutations {
				saveEloquaBasicKey(
						eloquaPassword: $eloquaPassword
						userName: $userName
						companyName: $companyName
			) {
					companyID
					eloquaIntegrationStatus
				}
			}
		}
	}
`)

export const getAvailableEloquaEmailGroups = gql(`
	query GetAvailableEloquaEmailGroups (
		$eloquaOrgID: String!
	) {
		track {
			eloquaQueries {
				getAvailableEloquaEmailGroups (
					eloquaOrgID: $eloquaOrgID
				) {
					eloquaEmailGroupList {
						groupID
						name
					}
				}
			}
		}
	}
`)

export const getAvailableEloquaTemplates = gql(`
	query GetAvailableEloquaTemplates (
		$eloquaOrgID: String!
		$groupID: String
		$search: String
	) {
		track {
			eloquaQueries {
				getAvailableEloquaTemplateList (
					eloquaOrgID: $eloquaOrgID
					groupID: $groupID
					search: $search
				) {
					eloquaTemplateList {
						eloquaID
						name
					}
				}
			}
		}
	}
`)

export const getEloquaTemplateHTML = gql(`
	query GetEloquaTemplateHTML (
		$eloquaOrgID: String!
		$templateID: String!
	) {
		track {
			eloquaQueries {
				getEloquaEmailTemplateDetail (
					eloquaOrgID: $eloquaOrgID
					templateID: $templateID
				) {
					eloquaID
					name
					emailHtml
					eloquaLinkList {
						linkType
						linkName
						linkURL
						linkID
					}
				}
			}
		}
	}
`)

export const updateEloquaTemplateEmailHTML = gql(`
	mutation UpdateEloquaTemplateEmailHTML (
		$eloquaOrgID: String!
		$templateID: String!
		$updatedHTMLMessage: String!
	) {
		track {
			eloquaMutations {
				updateTemplate (
					eloquaOrgID: $eloquaOrgID
					templateID: $templateID
					updatedHTMLMessage: $updatedHTMLMessage
				) {
					eloquaID
					eloquaLinkList {
						linkID
						linkName
						linkType
						linkURL
					}
					emailHtml
					name
				}
			}
		}
	}
`)

export const fetchEloquaPicklists = gql(`
	query FetchEloquaPicklists (
		$eloquaOrgID: String!
	) {
		currentCompany {
			companyID
			eloquaIntegrationData(
				eloquaOrgID: $eloquaOrgID
			) {
				parameterIDList
			}
		}
	}
`)

export const updateEloquaPicklists = gql(`
	mutation UpdateEloquaPicklists (
		$eloquaOrgID: String!
		$parameterIDList: [String!]!
	) {
		track {
			eloquaMutations {
				syncEloquaTrack(
						eloquaOrgID: $eloquaOrgID, 
						parameterIDList: $parameterIDList
				) {
					eloquaIntegrationData(
						eloquaOrgID: $eloquaOrgID
					) {
						parameterIDList
					}
				}
			}
		}
	}
`)

export const genSalesforceMCOauthLink = gql(`
	query GenSalesforceMCOauthLink (
		$state: String!
	) {
		accountSettings {
			dataSourceQueries {
				genMCOauthLink (
					state: $state
				) {
					oauth2Link
				}
			}
		}
	}
`)

export const handleSalesforceMCCallback = gql(`
	mutation HandleSalesforceMCCallback (
		$businessUnitName: String!
		$code: String!
		$encryptedState: String!
		$mid: String!
		$tssd: String
	) {
		userAccountSettings {
			salesforceMCMutations {
				handleSalesforceMCCallback (
					businessUnitName: $businessUnitName,
					code: $code,
					encryptedState: $encryptedState,
					mid: $mid,
					tssd: $tssd,
				) {
					salesforceMcIntegrationList {
						businessUnitName
						mid
					}
					salesforceMcIntegrationStatus
				}
			}
		}
	}
`)

export const getSalesforceMCTemplateList = gql(`
	query GetSalesforceMCTemplateList (
		$mid: String!
	) {
		track {
			salesforceMarketingCloudQueries {
				getTemplateList (
					mid: $mid
				) {
					templateID
					templateName
					templateType
					dateLastUpdated
				}
			}
		}
	}
`)

export const getSalesforceMCEmailTemplateDetail = gql(`
	query GetSalesforceMCEmailTemplateDetail (
		$mid: String!
		$templateID: String!
	) {
		track {
			salesforceMarketingCloudQueries {
				getTemplateDetail (
					mid: $mid,
					templateID: $templateID
				) {
					dateLastUpdated
					templateID
					templateName
					templateContent
					templateType
					slotMap {
						slotID
						content
						blocks {
							blockID
							content
						}
					}
					textContent
				}
			}
		}
	}
`)

export const updateSalesforceMCEmailTemplate = gql(`
	mutation UpdateSalesforceMCEmailTemplate (
		$mid: String!
		$templateID: String!
		$updatedHTMLMessage: String!
		$updatedSlotMap: [MCSlotMapInput!]
		$updatedTextContent: String
	) {
		track {
			salesforceMCMutations {
				updateTemplate (
					mid: $mid,
					templateID: $templateID,
					updatedHTMLMessage: $updatedHTMLMessage,
					updatedSlotMap: $updatedSlotMap
					textContent: $updatedTextContent
				) {
					dateLastUpdated
					templateContent
					templateID
					templateName
					templateType
					slotMap {
						slotID
						content
						blocks {
							blockID
							content
						}
					}
				}
			}
		}
	}
`)
