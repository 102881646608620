import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client'

import { currentUserDetails } from '../api/apollo/variables'
import {
  connectAaAccount,
  getAvailableAAAccounts,
  reconnectAaAccount,
} from '../api/graphql/oauth-client'
import { getAccountDataSource } from '../api/graphql/workspace-client'
import Button from '../components/button'
import { Label } from '../components/input'
import Link from '../components/link'
import { Preloader } from '../components/loader'
import { FieldSlot, FormRow, LabelSlot } from '../components/row'
import SelectBox from '../components/select-box'
import { supportEmail } from '../core/constants'
import { getItemByKeyValue } from '../helpers'
import useOnboarding from '../hooks/useOnboarding'
import styles from '../styles/integrations-settings.module.scss'

interface ConnectorLocationState {
  connectorID: string
  code: string
  state: string
  credentials?: string
  reconnect?: boolean
}

interface AdobeSuite {
  adobeReportSuiteId: string
  adobeReportSuiteName: string
}

interface AdobeAccount {
  adobeCompanyName: string
  adobeGlobalCompanyId: string
  adobeImsOrgId: string
  reportSuites: AdobeSuite[]
}

interface AAForm {
  company: AdobeAccount | null
  reportSuite: AdobeSuite | null
}

interface AADetails {
  status: 'active' | 'requiresReconnect'
  adobeReportSuiteName: string
  adobeSegmentName: string
}

const AdobeAnalyticsConnector = () => {
  const { workspaceID } = useReactiveVar(currentUserDetails)

  const { state: locationState } = useLocation<ConnectorLocationState>()

  const { updateOnboardingSection } = useOnboarding()

  const credentials = locationState?.credentials || null
  const reconnect = locationState?.reconnect || false

  const {
    data: connectorData,
    loading: loadingConnectorStatus,
    error: errorFetchingIntegrationStatus,
  } = useQuery(getAccountDataSource)

  const [
    getAAAccounts,
    { data: aaAccountsData, loading: loadingIntegrationData },
  ] = useLazyQuery(getAvailableAAAccounts)

  const [
    connectAAAccount,
    { loading: loadingConnectAA, error: errorConnectingAA },
  ] = useMutation(connectAaAccount)

  const [
    reconnectAA,
    { loading: loadingReconnect, error: errorReconnecting },
  ] = useMutation(reconnectAaAccount)

  const [form, setForm] = useState<AAForm>({
    company: null,
    reportSuite: null,
  })

  // Check if the connector is already active
  // Or requires a reconnect
  const connectorDetails: AADetails | null = useMemo(() => {
    const details = connectorData?.currentAccount.dataSource

    if (!details || details.kind !== 'AA_REPORT') return null

    return {
      status: details.connected ? 'active' : 'requiresReconnect',
      adobeReportSuiteName: details.adobeReportSuiteName || '',
      adobeSegmentName: details.adobeSegmentName || '',
    }
  }, [connectorData])

  // If not connected, fetch accounts
  useEffect(() => {
    if (connectorData && !connectorDetails && credentials) {
      getAAAccounts({
        variables: { credentials },
      })
    }

    if (
      connectorDetails?.status === 'requiresReconnect' &&
      reconnect &&
      credentials
    ) {
      reconnectAA({
        variables: {
          credentials,
          accountID: workspaceID,
        },
        refetchQueries: [getAccountDataSource],
      })
    }
  }, [connectorData, connectorDetails, credentials])

  const fullAccountsList = useMemo(() => {
    if (!aaAccountsData) return []

    return aaAccountsData.accountSettings.dataSourceQueries.availableAAAccounts
      .aaAccountList
  }, [aaAccountsData])

  if (loadingConnectorStatus || loadingIntegrationData || loadingReconnect) {
    return <Preloader />
  }

  if (
    errorFetchingIntegrationStatus ||
    errorConnectingAA ||
    errorReconnecting
  ) {
    return (
      <p className={styles.errorMsg}>
        There was an error connecting to your Adobe Analytics account. Please
        try again later or contact support (
        <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>
        ).
      </p>
    )
  }

  return (
    <div>
      {connectorDetails?.status === 'active' && (
        <p>Your Adobe Analytics account is connected.</p>
      )}
      {/* Full accounts list is only fetched when attempting to connect */}
      {fullAccountsList.length === 0 ? (
        <>
          <p>
            <strong>Report suite:</strong>{' '}
            {!connectorDetails
              ? '(Not connected)'
              : connectorDetails.adobeReportSuiteName}
          </p>
          <p>
            <strong>Segment applied:</strong>{' '}
            {!connectorDetails
              ? '(Not connected)'
              : connectorDetails.adobeSegmentName || 'None'}
          </p>
        </>
      ) : (
        <>
          <form
            onSubmit={async (e) => {
              e.preventDefault()

              if (!form.company || !form.reportSuite) return

              const {
                adobeCompanyName,
                adobeGlobalCompanyId,
                adobeImsOrgId,
              } = form.company

              const {
                adobeReportSuiteId,
                adobeReportSuiteName,
              } = form.reportSuite

              const { data } = await connectAAAccount({
                variables: {
                  accountID: workspaceID,
                  creds: credentials,
                  dataSourceDesc: {
                    adobeCompanyName,
                    adobeGlobalCompanyId,
                    adobeImsOrgId,
                    adobeReportSuiteId,
                    adobeReportSuiteName,
                  },
                },
              })

              if (data) {
                // Update onboarding sections
                updateOnboardingSection('connectAnalytics', 'account')
              }
            }}
          >
            <FormRow last>
              <LabelSlot column>
                <Label id="adobe-comp">Company</Label>
              </LabelSlot>
              <FieldSlot column>
                <SelectBox
                  key={`adobe-comp${fullAccountsList.length}`}
                  id="adobe-comp"
                  isDisabled={fullAccountsList.length === 0}
                  placeholder="Choose your company"
                  labelKey="adobeCompanyName"
                  valueKey="adobeGlobalCompanyId"
                  value={fullAccountsList.find(
                    (account) =>
                      account.adobeGlobalCompanyId ===
                      form.company?.adobeGlobalCompanyId,
                  )}
                  options={fullAccountsList}
                  onChange={(newValue) => {
                    if (!newValue) return

                    const acc = getItemByKeyValue(
                      fullAccountsList,
                      'adobeGlobalCompanyId',
                      newValue.adobeGlobalCompanyId,
                    )

                    setForm({
                      company: acc === -1 ? null : acc,
                      reportSuite: null,
                    })
                  }}
                />
              </FieldSlot>
            </FormRow>
            <FormRow last>
              <LabelSlot column>
                <Label id="report-suite">Report suite</Label>
              </LabelSlot>
              <FieldSlot column>
                <SelectBox
                  key={`report-suite-${JSON.stringify(form.company)}`}
                  id="report-suite"
                  isDisabled={!form.company}
                  placeholder="Choose your report suite"
                  labelKey="adobeReportSuiteName"
                  valueKey="adobeReportSuiteId"
                  value={form.reportSuite}
                  options={form.company?.reportSuites || []}
                  onChange={(newValue) => {
                    if (!newValue) return

                    const prop = form.company
                      ? getItemByKeyValue(
                          form.company.reportSuites,
                          'adobeReportSuiteId',
                          newValue.adobeReportSuiteId,
                        )
                      : null

                    setForm({ ...form, reportSuite: prop === -1 ? null : prop })
                  }}
                />
              </FieldSlot>
            </FormRow>
            <Button
              type="submit"
              className={styles.addDataSourceBtn}
              isDisabled={!form.company || !form.reportSuite}
              loading={loadingConnectAA}
            >
              Add data source
            </Button>
          </form>
        </>
      )}
      {connectorDetails?.status === 'requiresReconnect' && (
        <p className={styles.errorMsg}>
          Your account needs to be reconnected. Press the 'Reconnect' button
          below.
        </p>
      )}
    </div>
  )
}

export default AdobeAnalyticsConnector
