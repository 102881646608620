export const emailRegex = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
)

export function validatePassword(password?: string) {
  if (!password || password.split('').length < 8) {
    return 'Password must be at least 8 characters'
  }

  if (!/[A-Z]/.test(password)) {
    return 'Password must contain at least 1 upper case letter'
  }

  if (!/[a-z]/.test(password)) {
    return 'Password must contain at least 1 lower case letter'
  }

  // if (!/[ !@#$%~^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password)) {
  //   return 'Password must contain at least 1 special character (!@#$%~^&*()_+-=[]{};\':"\\|,.<>/?)'
  // }

  if (!/\d/.test(password)) {
    return 'Password must contain at least 1 number'
  }

  return ''
}
interface FormPassword {
  password: string
  passwordConfirmation: string
}

export function onBoardingV2FormValidationPassword(
  form: FormPassword,
  onError: (message: string) => void,
): boolean {
  const sendDispatch = onError
  // eslint-disable-next-line
  // debugger
  const error = form.password === '' || form.passwordConfirmation === ''

  if (error) {
    sendDispatch('Please fill in all required fields')
    return false
  }

  if (form.password !== form.passwordConfirmation) {
    sendDispatch('Passwords do not match')
    return false
  }

  const validPassword = validatePassword(form.password)
  if (validPassword !== '') {
    sendDispatch(validPassword)
    return false
  }

  return true
}

interface FormNoPassword {
  fName: string
  lName: string
  email: string
  organisation: string
  terms: boolean
}

export function onBoardingV2FormValidation(
  form: FormNoPassword,
  onError: (message: string) => void,
): boolean {
  const sendDispatch = onError

  const error =
    !emailRegex.test(form.email) ||
    !form.fName ||
    !form.lName ||
    !form.organisation ||
    !form.terms

  if (error && !form.terms) {
    sendDispatch(
      'Please accept the terms of service and privacy policy before continuing',
    )
    return false
  }
  if (error) {
    sendDispatch('Please fill in all required fields')
    return false
  }

  return true
}

interface LoginForm {
  email: string
  password: string
}

export function loginFormValidation(
  form: LoginForm,
  dispatch: (message: string) => void,
): boolean {
  if (!form.email) {
    dispatch('Email field cannot be empty')
    return false
  }

  if (!emailRegex.test(form.email)) {
    dispatch('Email must be valid')
    return false
  }

  if (!form.password) {
    dispatch('Password field cannot be empty')
    return false
  }

  return true
}

export function validateEmail(email: string): boolean

export function validateEmail(email: string[], returnResult: false): boolean

export function validateEmail(
  email: string[],
  returnResult: true,
): {
  valid: string[]
  invalid: string[]
}

export function validateEmail(
  email: string | string[],
  returnResult = false,
):
  | boolean
  | {
      valid: string[]
      invalid: string[]
    } {
  if (typeof email === 'string') {
    return emailRegex.test(email)
  }
  const invalidEmails = email.filter((eml) => {
    const isValid = emailRegex.test(eml)
    return !isValid
  })
  if (returnResult) {
    return {
      valid: email.filter((eml) => invalidEmails.indexOf(eml) === -1),
      invalid: invalidEmails,
    }
  }
  return invalidEmails.length === 0
}

export default {
  emailRegex,
}
