import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client'
import { useLocation } from 'react-router-dom'
import _ from 'lodash'

import { currentUserDetails, linkOrCode } from '../api/apollo/variables'
import {
  genSalesforceMCOauthLink,
  getIntegrationsStatus,
  handleSalesforceMCCallback,
} from '../api/graphql/integrations-client'
import Input, { Label } from '../components/input'
import Link from '../components/link'
import { Preloader } from '../components/loader'
import { FieldSlot, FormRow, LabelSlot } from '../components/row'
import Tooltip from '../components/tooltip'
import { BoxedText, OrderedList } from '../components/typography'
import { brandName, dynamicTextValues } from '../core/constants'
import {
  getLocalItem,
  removeLocalItem,
  setLocalItem,
} from '../helpers/local-client'
import styles from '../styles/integrations-settings.module.scss'

interface SalesforceMCFormDetails {
  businessUnitName: string
  mid: string
}

interface ConnectorLocationState {
  connectorID: string
  code: string
  state: string
}

const SalesforceMCConnector = () => {
  const { workspaceID } = currentUserDetails()

  const linkCopy = useReactiveVar(linkOrCode)

  const { state: locationState } = useLocation<ConnectorLocationState>()

  const salesforceCode =
    !locationState || locationState.connectorID !== 'salesforceMarketingCloud'
      ? null
      : (locationState.code as string) || null

  const salesforceState =
    !locationState || locationState.connectorID !== 'salesforceMarketingCloud'
      ? null
      : (locationState.state as string) || null

  const { data: connectorData, loading: loadingConnectorStatus } = useQuery(
    getIntegrationsStatus,
  )
  const [
    getOauthLink,
    { loading: loadingOauthLink, error: errorFetchingOauth },
  ] = useLazyQuery(genSalesforceMCOauthLink)
  const [
    handleOauth,
    { data: saveOauthData, loading: loadingIntegration, error: oauthError },
  ] = useMutation(handleSalesforceMCCallback)

  const [formDetails, setFormDetails] = useState<SalesforceMCFormDetails>({
    businessUnitName: '',
    mid: '',
  })
  const [connectionSuccess, setConnectionSuccess] = useState(false)

  // List all business units found in SF account
  const businessUnits = useMemo(() => {
    if (!saveOauthData && !connectorData) return []

    const fullBusinessUnits: {
      businessUnitName: string
      mid: string
    }[] = []

    if (saveOauthData) {
      fullBusinessUnits.push(
        ...saveOauthData.userAccountSettings.salesforceMCMutations
          .handleSalesforceMCCallback.salesforceMcIntegrationList,
      )
    }

    if (connectorData) {
      fullBusinessUnits.push(
        ...connectorData.currentCompany.salesforceMcIntegrationList,
      )
    }

    return fullBusinessUnits
  }, [saveOauthData, connectorData])

  // TODO: Tidy this
  const addNewBusinessUnit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      const { mid, businessUnitName } = formDetails

      // Save businessUnitName and mid to LS
      setLocalItem('salesforceMCNewBusinessUnit', {
        mid,
        businessUnitName,
      })

      const { data: oauthLinkData } = await getOauthLink({
        variables: {
          state: workspaceID,
        },
      })

      if (!oauthLinkData) return

      const {
        accountSettings: {
          dataSourceQueries: {
            genMCOauthLink: { oauth2Link },
          },
        },
      } = oauthLinkData

      window.open(oauth2Link, '_self')
    },
    [],
  )

  // Handle oauth response if locationState is present
  // Must also fetch new businessUnit info from LS
  // Should have been saved when generating the oauth link
  useEffect(() => {
    const handleOauthResponse = async () => {
      if (salesforceCode && salesforceState) {
        const newBusinessUnit = getLocalItem('salesforceMCNewBusinessUnit')

        if (newBusinessUnit) {
          const { businessUnitName, mid } = newBusinessUnit

          await handleOauth({
            variables: {
              businessUnitName,
              code: salesforceCode,
              encryptedState: salesforceState,
              mid,
            },
          })

          removeLocalItem('salesforceMCNewBusinessUnit')

          setConnectionSuccess(true)

          window.setTimeout(() => setConnectionSuccess(false), 5000)
        }
      }
    }

    handleOauthResponse()
  }, [salesforceCode, salesforceState])

  if (loadingConnectorStatus) return <Preloader />

  return (
    <>
      {businessUnits.length > 0 && (
        <>
          {connectionSuccess && (
            <p className={styles.tenantSuccess}>
              Your Salesforce Pardot connection has been updated.
            </p>
          )}
          <p>
            <strong>
              Your Salesforce Marketing Cloud account is connected.
            </strong>
          </p>
          <p>
            You can view your email templates for the below businuess units on
            the{' '}
            <BoxedText>
              <Link href="/track/create-links">
                {dynamicTextValues.trackCreatePage[linkCopy]}
              </Link>
            </BoxedText>{' '}
            page:
          </p>
          <OrderedList>
            {businessUnits.map(({ businessUnitName, mid }) => {
              return (
                <li key={mid}>
                  {businessUnitName} (ID: {mid})
                </li>
              )
            })}
          </OrderedList>
          <div className={styles.divider} />
        </>
      )}
      <p>
        <strong>Add a new Salesforce Marketing Cloud instance:</strong>
      </p>
      <p style={{ marginBottom: 0 }}>
        The <strong>mid</strong> can be found by following the instructions in
        this video:{' '}
        <Link href="https://salesforce.vidyard.com/watch/WEjEkkwn9onVyPYKyM6h5t">
          https://salesforce.vidyard.com/watch/WEjEkkwn9onVyPYKyM6h5t
        </Link>
      </p>
      {loadingIntegration || loadingOauthLink ? (
        <Preloader />
      ) : (
        <>
          {oauthError || errorFetchingOauth ? (
            <p className={styles.errorMsg}>
              There was an error connecting to your Salesforce Marketing Cloud
              account. Please try again later or contact support (
              <Link href="mailto:support@uplifter.ai">support@uplifter.ai</Link>
              ).
            </p>
          ) : (
            <form id="salesforce-mc-connect-form" onSubmit={addNewBusinessUnit}>
              <FormRow className={styles.addTenantFormRow}>
                <LabelSlot noPadding>
                  <Label id="businessUnitName">
                    <Tooltip
                      id="business-unit-name-tooltip"
                      useIcon
                      tooltipMessage={`The name you will use to reference the Business unit in ${brandName}.`}
                    >
                      Business unit name
                    </Tooltip>
                  </Label>
                </LabelSlot>
                <FieldSlot noPadding>
                  <Input
                    name="businessUnitName"
                    type="text"
                    label="Business Unit Name"
                    required
                    value={formDetails.businessUnitName}
                    onValueChange={(val) =>
                      setFormDetails({
                        ...formDetails,
                        businessUnitName: val,
                      })
                    }
                  />
                </FieldSlot>
              </FormRow>
              <FormRow className={styles.addTenantFormRow}>
                <LabelSlot noPadding>
                  <Label id="mid">
                    <Tooltip
                      id="business-unit-id-tooltip"
                      useIcon
                      tooltipMessage="The business unit ID in Salesforce Marketing Cloud."
                    >
                      Business unit ID (mid)
                    </Tooltip>
                  </Label>
                </LabelSlot>
                <FieldSlot noPadding>
                  <Input
                    name="mid"
                    type="text"
                    label="Business Unit ID"
                    required
                    value={formDetails.mid}
                    onValueChange={(val) =>
                      setFormDetails({
                        ...formDetails,
                        mid: val,
                      })
                    }
                  />
                </FieldSlot>
              </FormRow>
            </form>
          )}
        </>
      )}
    </>
  )
}

export default SalesforceMCConnector
